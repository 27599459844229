// Components
import Button from './../../../../../../../../components/common/buttons/Button';
import Icon from './../../../../../../../../components/common/Icon/index';

// Styles
import styles from './styles.module.css';

const RemoveCpButtonCell = () => {
  return (
    <div className={styles.removeBtnContainer}>
      <Button propStyles={`${styles.removeBtn} ${styles.rejectBtn}`}>
        <Icon name='close' propStyles={styles.closeIcon}></Icon>
      </Button>
    </div>
  );
};

export default RemoveCpButtonCell;
