import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BookedUnitCartCard from './BookedUnitCartCard';
import SummaryCard from './SummaryCard';

import { getUser } from '../../apps/booking/slices/auth';
import { useLazyGetUnitcartListQuery } from '../../api/unitCart';
import { useSiteVisitsByUserIdOldMutation } from '../../api/siteVisit';

import SiteVisitItem from './SiteVisitItem';
import ScheduleSiteVisitItem from './ScheduleSiteVisitItem';
import { BLOCKED, BOOKED } from '../../constants/status';
import { dashboardHeadingBannerUrl, URLS } from '../../constants/urls';
import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';

const Dashbaord = () => {
  const [siteVisits, setSiteVisits] = useState([]);

  const user = useSelector(getUser);
  const [getUnitcartListAPI, { data: unitCarts = [] }] =
    useLazyGetUnitcartListQuery();
  const [siteVisitsByUserIdAPI] = useSiteVisitsByUserIdOldMutation(user.id);

  useEffect(() => {
    const getUnitCarts = async () => {
      await getUnitcartListAPI(user.id);
    };
    getUnitCarts();
    const getSiteVisites = async () => {
      const { data: siteVisits } = await siteVisitsByUserIdAPI(user.id);
      setSiteVisits(siteVisits || []);
      // TODO: Handle error
    };
    getSiteVisites();
  }, [user, getUnitcartListAPI, siteVisitsByUserIdAPI]);

  const bookedUnitCarts = unitCarts
    .filter((unitCart) => unitCart.unit.status === BOOKED)
    .slice()
    .sort((firstEl, secondEl) => {
      return firstEl.progress === SIDEBAR_ITEM_INDEX[URLS.AGREEMENT] ? -1 : 1;
    });

  const exploredUnitCarts = unitCarts
    .filter((unitCart) => unitCart.unit.status !== BOOKED)
    .slice()
    .sort((firstEl, secondEl) => {
      return firstEl.unit.status === BLOCKED ? -1 : 1;
    });

  return (
    <div className='dashboard'>
      <header className='dashboard-header'>
        <div className='dashboard__welcome-title'>
          Welcome to your <span className='font-accent-bold'>Dashboard</span>
        </div>
        <div className='dashboard__heading'>
          <div className='dashboard__heading-label'>
            <div className='dashboard__heading-title'>
              <span className='f-bold c-56'>Be A part</span>{' '}
              <span className='f-medium'>of Bengaluru's biggest and</span>{' '}
              <span className='font-accent-bold'>best planned City</span>
            </div>
            <div className='dashboard__heading-desc'>
              Embassy Springs by owning India's First Alexa Enabled Homes -
              Embassy Edge @ Embassy Springs
            </div>
          </div>
          <figure className='dashboard__heading-banner'>
            <img
              src={dashboardHeadingBannerUrl}
              alt='Dashboard heading banner'
            />
          </figure>
        </div>
      </header>

      {bookedUnitCarts.length > 0 && (
        <section className='dashboard-section'>
          <header className='dashboard-section__heading'>
            <div className='dashboard-section__heading-title'>Your Homes</div>
            <div className='dashboard-section__heading-desc'>
              Track your booking and other status
            </div>
          </header>
          <main className='dashboard-section__body'>
            {bookedUnitCarts.map((bookedUnitCart) => (
              <BookedUnitCartCard unitCart={bookedUnitCart} />
            ))}
          </main>
        </section>
      )}
      {/* end -- dashboard-section*/}

      {exploredUnitCarts.length > 0 && (
        <section className='dashboard-section'>
          <header className='dashboard-section__heading'>
            <div className='dashboard-section__heading-title'>Summary</div>
            <div className='dashboard-section__heading-desc'>
              Track your explored, blocked and other status
            </div>
          </header>
          <main className='dashboard-section__body'>
            {exploredUnitCarts.map((unitCart) => (
              <SummaryCard unitCart={unitCart} />
            ))}
          </main>
        </section>
      )}

      <section className='dashboard-section'>
        <header className='dashboard-section__heading'>
          <div className='dashboard-section__heading-title'>
            Scheduled Calls & Site Visits
          </div>
          <div className='dashboard-section__heading-desc'>
            Track your booking and other status
          </div>
        </header>
        <main className='dashboard-section__body dashboard-section__body-disable-horizontal-scroll'>
          {siteVisits
            .slice()
            .sort((firstEl, secondEl) => {
              const firstElTime = new Date(firstEl.datetime).getTime();
              const secondElTime = new Date(secondEl.datetime).getTime();
              return firstElTime < secondElTime ? -1 : 1;
            })
            .map((siteVisit) => (
              <SiteVisitItem siteVisit={siteVisit} />
            ))}
          <ScheduleSiteVisitItem />
        </main>
      </section>
    </div>
  );
};

export default Dashbaord;
