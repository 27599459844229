import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../constants/urls';

export const applicantAPISlice = createApi({
  reducerPath: 'applicantAPISlice',
  baseQuery: fetchBaseQuery({
    baseUrl,
    tagTypes: ['Posts'],
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    getApplicants: build.query({
      query: (id) => `get_applicants?id=${id}`,
      // Provides a list of `Posts` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Posts` element was added.
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: 'Posts', id })),
              { type: 'Posts', id: 'LIST' },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: 'Posts', id: 'LIST' }],
    }),
    addApplicant: build.mutation({
      query: (body) => ({
        url: `post_applicant`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Posts', id: 'LIST' }],
    }),

    updateApplicant: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `update_applicant?id=${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Posts', id }],
    }),
    deleteApplicant: build.mutation({
      query(id) {
        return {
          url: `delete_applicant?id=${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => [{ type: 'Posts', id }],
    }),
  }),
});

export const {
  useGetApplicantsQuery,
  useAddApplicantMutation,
  useUpdateApplicantMutation,
  useDeleteApplicantMutation,
} = applicantAPISlice;
