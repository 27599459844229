import LeadsDashboardHeader from './LeadsHeader';
import { LeadsHeaderData, ActiveLeadsGridData } from './MockData';
import { useState } from 'react';
import ActiveLeads from '.';
import CpLeadRequests from './CpLeadRequests';
import { IConstant } from '../../../../../interfaces';

interface ITabs {
  ACTIVE_LEADS: IConstant;
  INACTIVE_LEADS: IConstant;
  AGREEMENT: IConstant;
  CP_LEADS: IConstant;
  CP_LEAD_REQUESTS: IConstant;
}

export const Tabs: ITabs = {
  ACTIVE_LEADS: { value: 'ACTIVE_LEADS', displayText: 'Active Leads' },
  INACTIVE_LEADS: { value: 'INACTIVE_LEADS', displayText: 'Inactive Leads' },
  AGREEMENT: { value: 'AGREEMENT', displayText: 'Agreement' },
  CP_LEADS: { value: 'CP_LEADS', displayText: 'CP Leads' },
  CP_LEAD_REQUESTS: {
    value: 'CP_LEAD_REQUESTS',
    displayText: 'CP Lead Requests',
  },
};

const LeadsDashboard = () => {
  const [selectedTabTag, setSelectedTabTag] = useState(Tabs.ACTIVE_LEADS.value);

  const tabs = [
    {
      name: Tabs.ACTIVE_LEADS.displayText,
      tag: Tabs.ACTIVE_LEADS.value,
      content: <ActiveLeads rowData={ActiveLeadsGridData} />,
      isDisabled: false,
    },
    {
      name: Tabs.AGREEMENT.displayText,
      tag: Tabs.AGREEMENT.value,
      content: '',
      isDisabled: false,
    },
    {
      name: Tabs.INACTIVE_LEADS.displayText,
      tag: Tabs.INACTIVE_LEADS.value,
      content: '',
      isDisabled: false,
    },
    {
      name: Tabs.CP_LEADS.displayText,
      tag: Tabs.CP_LEADS.value,
      content: <ActiveLeads rowData={ActiveLeadsGridData} />,
      isDisabled: false,
    },
    {
      name: Tabs.CP_LEAD_REQUESTS.displayText,
      tag: Tabs.CP_LEAD_REQUESTS.value,
      content: '',
      isDisabled: false,
    },
  ];

  return (
    <>
      <div className='leads-dashboard-container'>
        <LeadsDashboardHeader {...LeadsHeaderData} />
        <div className='leads-dashboard-container__tabs'>
          <div className='tab-container'>
            <div className='tab-container__header'>
              {tabs.map((tab) => (
                <div
                  key={tab.tag}
                  className={`tab-container__header-item
                    ${selectedTabTag === tab.tag && 'active'}
                    ${tab.isDisabled && 'disabled'}
                  `}
                  onClick={() => setSelectedTabTag(tab.tag)}>
                  {tab.name}
                </div>
              ))}
            </div>

            <div className='tab-container__body'>
              {selectedTabTag === Tabs.ACTIVE_LEADS.value && (
                <ActiveLeads rowData={ActiveLeadsGridData} />
              )}
              {selectedTabTag === Tabs.AGREEMENT.value}
              {selectedTabTag === Tabs.INACTIVE_LEADS.value}
              {selectedTabTag === Tabs.CP_LEADS.value && (
                <ActiveLeads rowData={ActiveLeadsGridData} />
              )}
              {selectedTabTag === Tabs.CP_LEAD_REQUESTS.value && (
                <CpLeadRequests />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsDashboard;
