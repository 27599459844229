import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import ProjectNameWithProjectSelection from '../../../common/ProjectNameWithProjectSelection';
import { IProject } from '../../SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

export interface IAdminDashboardAnalyticsProjectList {
  projectName: string;
  developerName: string;
  projectId: string;
}

interface IAdminDashboardAnalytics {
  project: IProject;
}

interface ILocationState {
  project: IProject;
}

const AdminDashboardAnalytics = (props: IAdminDashboardAnalytics) => {
  const location = useLocation<ILocationState>();
  const { project: _project = location?.state?.project } = props;

  const [project, setSelectedProject] = useState<IProject>(_project);

  return (
    <>
      <ProjectNameWithProjectSelection
        project={project}
        onProjectSelect={setSelectedProject}
      />
      {project ? (
        <div className={styles.analyticsContainer}>
          <iframe
            title='d'
            width='100%'
            height='100%'
            frameBorder='0'
            src={`https://datastudio.google.com/embed/u/0/reporting/240a12af-0566-42d1-8e55-4059d2860dd0/page/6gnnC?params=%7B%22df16%22:%22include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${project.id}%22%7D`}
            style={{ border: 0 }}
            allowFullScreen></iframe>
        </div>
      ) : (
        'Project is not available'
      )}
    </>
  );
};

export default AdminDashboardAnalytics;
