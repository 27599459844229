import { useState } from 'react';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUpdateDemoRoomMutation } from '../../../../../../services/projectsAPISlice';
import useToast from '../../../../../../../../hooks/useToast';
import Modal from '../../../../../../../../components/common/modal/Modal';
import TextInput from '../../../../../../../../components/common/inputs/TextInput';
import Button from '../../../../../../../../components/common/buttons/Button';
import Icon from '../../../../../../../../components/common/Icon';
import { IDemoRoom } from '../AddDemoRoomModal/index';

import styles from '../VsgLinkModal/styles.module.css';

interface IUpdateDemoRoomModal {
  projectId: string;
  onModalClose: Function;
  roomDetails: IDemoRoom;
  onUpdateDemoRoom: Function;
}

// Yup Validation schema
const schema = yup.object().shape({
  virtualRoomId: yup.string().required(),
  hostPassword: yup.string().required(),
  fallbackVirtualRoomUrl: yup.string().required(),
});

const UpdateDemoRoomModal = (props: IUpdateDemoRoomModal) => {
  const { roomDetails, projectId, onModalClose, onUpdateDemoRoom } = props;

  const [demoRoomBackupUrl, setDemoRoomBackupUrl] = useState(
    roomDetails.fallbackVirtualRoomUrl
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();

  const [updateDemoRoom] = useUpdateDemoRoomMutation();

  const handleUpdateDemoRoom = async (demoRoom: IDemoRoom) => {
    const demoRoomId = roomDetails.id;
    try {
      const updatedProject = await updateDemoRoom({
        projectId,
        demoRoomId,
        demoRoom,
      }).unwrap();

      onUpdateDemoRoom(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Update Demo Room Successful',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Update Demo Room Failed',
      });
    }
  };

  return (
    <Modal
      onOutsideClick={() => onModalClose()}
      additionalClasses={styles.modalAdditionalClasses}>
      {/* Modal Header */}
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Update Demo Rooms Details</h1>
          <p>
            Edit the following details that you want to update for this demo
            room.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={() => onModalClose()}
        />
      </header>

      {/* Modal Body */}
      <main>
        <TextInput
          defaultValue={roomDetails.virtualRoomId}
          label='Room ID'
          placeHolder='Add Room ID from 3DV'
          register={register}
          setValue={setValue}
          name='virtualRoomId'
          errorMessage={errors?.virtualRoomId ? 'Room ID is required' : ''}
        />
        <div className={styles.projectIdInputContainer}>
          <div className={styles.projectIdInput}>
            <TextInput
              defaultValue={roomDetails.hostPassword}
              label='Host Password'
              placeHolder='Add Host Password'
              register={register}
              setValue={setValue}
              name='hostPassword'
              errorMessage={
                errors?.hostPassword ? 'Host Password is required' : ''
              }
            />
          </div>
          <div className={styles.projectIdInput}>
            <TextInput
              defaultValue={roomDetails.fallbackVirtualRoomUrl}
              label='Fall Back video call URL'
              placeHolder='Add Fallback video call URL (Zoom, Google Meet, Teams, etc)'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDemoRoomBackupUrl(e.target.value);
              }}
              register={register}
              setValue={setValue}
              name='fallbackVirtualRoomUrl'
              errorMessage={
                demoRoomBackupUrl === ''
                  ? errors?.fallbackVirtualRoomUrl
                    ? 'Fall Back video call URL is required'
                    : ''
                  : demoRoomBackupUrl.includes('http')
                  ? ''
                  : 'URL should start with http:// or https://'
              }
            />
          </div>
        </div>
      </main>

      {/* Modal Footer */}
      <footer className={styles.modalFooter}>
        <div className={styles.projectIdInput}>
          <p className={styles.footerDisclaimer}>
            By adding executive, an autogenerated email will be sent to the
            executive with their randomly generated password and URL for the
            dashboard. Do this step the last, just before going for UAT.
          </p>
        </div>
        <div className={styles.projectIdInput}>
          <Button
            type='submit'
            disabled={!demoRoomBackupUrl.includes('http')}
            onClick={() => handleSubmit(handleUpdateDemoRoom)()}
            propStyles={`${styles.verifyNowBtn}`}>
            Update
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default UpdateDemoRoomModal;
