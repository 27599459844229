import React from 'react';
import { useDispatch } from 'react-redux';

import { ReactSVG } from 'react-svg';
import ClipLoader from 'react-spinners/ClipLoader';

import { setSelectedTower } from '../../apps/booking/slices/contentHeader/contentHeaderNav';

export default function SVGLoading({ name }) {
  const dispatch = useDispatch();

  const selectSector = (selectedTowerName) => {
    dispatch(setSelectedTower(selectedTowerName));
  };

  return (
    <ReactSVG
      src={`https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/svg/${name}.svg`}
      loading={() => <ClipLoader />}
      id='svg'
      className='svg'
      beforeInjection={(svg) => {
        const svgId = 'Layer_1';
        const polygons = svg.querySelectorAll('#' + svgId + ' > polygon');
        for (let polygon of polygons) {
          polygon.style.fill = 'rgb(142 76 255)';
          polygon.style.opacity = 0.2;
          polygon.style.cursor = 'pointer';
          polygon.classList.add('unit');
          //polygon.style.pointerEvents = 'auto';
          polygon.addEventListener('click', () => selectSector(polygon.id));
        }
      }}
    />
  );
}
