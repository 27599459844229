import S3 from 'react-aws-s3';

// This implementation, we no long in use. Use S3ClientNew instead.
// In future we will deprecate this implementation.
class S3Client {
  static adharDocType = 'aadhar_card';
  static panDocType = 'pan_card';
  static electricityBillDocType = 'electricity_bill';
  static passportDocType = 'passport';
  static passportSizePhotoDocType = 'passport_size_photo';
  static drivingLicenseDocType = 'driving_license';

  static fileName(url) {
    return url?.substring(url?.lastIndexOf('/') + 1);
  }

  static id(userId, documentType) {
    return `${documentType}-${userId}`;
  }

  static uploadFile(file, fileName, userId, documentType) {
    const s3DirName = `embassy/docs/${userId}/${documentType}`;
    const config = {
      bucketName: 'relata-temp',
      dirName: s3DirName /* optional */,
      region: 'ap-south-1',
      accessKeyId: 'AKIARTCQAJHCDTN775AH',
      secretAccessKey: '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',
    };
    const ReactS3Client = new S3(config);
    return ReactS3Client.uploadFile(file, fileName);
  }
}

export default S3Client;
