import { useHistory, useLocation } from 'react-router-dom';

import ReadMoreLess from '../../../../../components/common/buttons/ReadMoreLess';
import Icon from '../../../../../components/common/Icon';
import HeadTitle from '../../../../../components/common/TitleHeading';
import { IProject } from '../../../../admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import Button from '../../../../../components/common/buttons/Button';
import PosterSlider from './PosterSlider';
import GallerySlider from './GallerySlider';

import styles from './styles.module.css';

const ProductDetailsPage = () => {
  const history = useHistory();

  const location = useLocation<{ project: IProject }>();
  const project = location?.state?.project;

  const handleRedirectToCollateralsPage = () => {
    history.push('/cp/dashboard/project-collaterals', { project });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.iconWrapper}>
          <Icon
            propStyles={styles.headerIcon}
            name='arrow_back'
            onClick={() => history.goBack()}
          />
          {/* TODO: to enable after functionlity integrated
          <Icon propStyles={styles.headerIcon} name='favorite_border' /> */}
        </div>
        <div className={styles.headerWrap}>
          <HeadTitle propStyles={styles.headTitle}>{project.name}</HeadTitle>
          {/* TODO: Show appropriate value here
          
          <p className={styles.subTitle}>
            House Of Light
            <ul>
              <li>House Of Light</li>
            </ul>
          </p> */}
        </div>

        <PosterSlider
          imageUrls={project.posters.map((poster) => poster.imageUrl)}
        />

        <div className={styles.textContent}>
          <p className={styles.subHeading}>About</p>
          <ReadMoreLess limitCharacter={130}>{project.about}</ReadMoreLess>
        </div>
        <div className={styles.featureWrap}>
          <p className={styles.subHeading}>Features</p>
          {project.features.map((feature) => (
            <div key={feature.name} className={styles.specsWrap}>
              <p className={styles.priceType}>{feature.name}</p>
              <p className={styles.priceValue}>{feature.value}</p>
            </div>
          ))}
        </div>

        {project.location?.googleMapUrl && (
          <div className={styles.locationWrap}>
            <HeadTitle>Location</HeadTitle>
            <div className={styles.iframeWrapper}>
              <iframe
                width='100%'
                height='250'
                style={{ border: 0, borderRadius: 20 }}
                loading='lazy'
                allowFullScreen
                src={project.location?.googleMapUrl}></iframe>
            </div>
          </div>
        )}

        <div className={styles.amenities}>
          <p className={styles.subHeading}>Amenities</p>
          <ul className={styles.animieWrapper}>
            {project.amenities.map((amenity) => (
              <li key={amenity} className={styles.listAmenity}>
                {amenity}
              </li>
            ))}
          </ul>
        </div>

        <GallerySlider
          imageUrls={project.gallery.map((galleryObj) => galleryObj.imageUrl)}
        />

        <div className={styles.nearByWrap}>
          <p className={styles.subHeading}>Near by area</p>
          {project.nearByAreas.map((area, index) => (
            <div key={index} className={styles.specsWrap}>
              <p className={styles.priceType}>{area.name}</p>
              <p className={styles.priceValue}>{area.distance.value}</p>
            </div>
          ))}
        </div>

        <div className={styles.fixedButton}>
          {/* 
            TODO: Enable these button in future
            <Button propStyles={styles.callBtn}>REQUEST CALL</Button>
            <Button propStyles={styles.register}>REGISTER</Button> 
          */}
        </div>
        <div className={styles.showCollateralsBtnContainer}>
          <Button
            crApp
            accent='primary'
            propStyles={styles.showCollateralsBtn}
            onClick={handleRedirectToCollateralsPage}>
            SHARE COLLATERALS
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProductDetailsPage;
