import { useRef, useState } from 'react';
import useOnClickOutside from '../../../utils/useOnClickOutside';

import SiteVisitForm from './SiteVisitForm';

const SiteVisitFormContainer = (props) => {
  const { onClose } = props;
  const [showSiteVisitForm, setShowSiteVisitForm] = useState(true);

  const modalContentRef = useRef();
  useOnClickOutside(modalContentRef, () => wrapSetShowSiteVisitForm(false));

  const wrapSetShowSiteVisitForm = (val) => {
    setShowSiteVisitForm(val);
    onClose(val);
  };
  return (
    <>
      {showSiteVisitForm && (
        <div className={`modal ${showSiteVisitForm && 'modal--show'}`}>
          <div className='modal-content' ref={modalContentRef}>
            <div className='site-visit'>
              <div className='site-visit-desc'>
                <span className='site-visit-desc__title'>
                  SCHEDULE A SITE VISIT IN JUST FEW CLICKS
                </span>
                <span className='site-visit-desc__desc'>
                  Embassy Edge @ Embassy Springs , India's First Alexa Enabled
                  Homes
                </span>
              </div>
              <SiteVisitForm setShowSiteVisitForm={wrapSetShowSiteVisitForm} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SiteVisitFormContainer;
