import { createSlice } from '@reduxjs/toolkit';
import { unitCartAPISlice } from '../../../api/unitCart';

export const seletedUnitSlice = createSlice({
  name: 'selectedUnit',
  initialState: {
    unit: {},
    unitCart: {},
    isFetchingUnitCart: false,
  },
  reducers: {
    setSelectedUnit: (state, action) => {
      // Reset unitCart on user update or unit update
      const isUnitChanged = state.unit.id !== action.payload.id;
      if (isUnitChanged) {
        state.unitCart = {};
      }
      state.unit = action.payload;
    },
    setUnitCart: (state, action) => {
      state.unitCart = action.payload;
    },
    setSelectedSchemeId: (state, action) => {
      state.unitCart['scheme_sfdc_id'] = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Update unitCart in store
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchFulfilled,
      (state, { payload }) => {
        state.unitCart = payload || {};
      }
    );

    // handle isFetchingUnitCart
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchFulfilled,
      (state, { payload }) => {
        state.isFetchingUnitCart = false;
      }
    );
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchRejected,
      (state, { payload }) => {
        state.isFetchingUnitCart = false;
      }
    );
    builder.addMatcher(
      unitCartAPISlice.endpoints.getUnitCart.matchPending,
      (state, { payload }) => {
        state.isFetchingUnitCart = true;
      }
    );
  },
});

export const { setSelectedUnit, setUnitCart, setSelectedSchemeId } =
  seletedUnitSlice.actions;

export const getSelectedUnit = (state) => state.selectedUnit.unit;
export const getEmbassyUnitId = (state) =>
  state.selectedUnit.unit.embassy_unit_id;

export const getUnitCart = (state) => state.selectedUnit.unitCart;
export const getIsFetchingUnitCart = (state) =>
  state.selectedUnit.isFetchingUnitCart;
export const getSelectedSchemeId = (state) => {
  const costSheet = getCostSheet(state);
  const unitCart = getUnitCart(state);

  const selectedSchemeSfdcId =
    unitCart?.scheme_sfdc_id || costSheet?.scheme_details[0].scheme_sfdc_id;
  return selectedSchemeSfdcId;
};
export const getSelectedScheme = (state) => {
  const selectedSchemeId = getSelectedSchemeId(state);
  const costSheet = getCostSheet(state);
  const selectedScheme = costSheet?.scheme_details.find(
    (scheme) => scheme.scheme_sfdc_id === selectedSchemeId
  );
  return selectedScheme;
};
export const getCostSheet = (state) => {
  const selectedUnit = state.selectedUnit.unit;
  const unitCart = state.selectedUnit.unitCart;
  const costSheet = unitCart?.cost_sheet || selectedUnit?.cost_sheet;
  return costSheet;
};
