import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Icon from '../../../../../components/common/Icon';
import HeadTitle from '../../../../../components/common/TitleHeading';
import { isEmpty } from '../../../../../utils/utils';
import { useGetCpUserDashboardStatsByCpUserIdQuery } from '../../../services/cpUserAPISlice';
import { getCpUser } from '../../../slices/cpUserSlice';
import EarningsCard from './EarningsCard';
import PerformanceCardList from './PerformanceCardList';
import ProjectCardList from './ProjectCardList';
import Image from '../../../../../components/common/Image';
import { userProfileDefaultImage } from '../../../../../constants/urls';

import styles from './styles.module.css';

const CpDashboardPage = () => {
  const notificationCount = 0;

  const history = useHistory();

  const cpUser = useSelector(getCpUser);

  const { data: cpUserDashboardStats, isLoading } =
    useGetCpUserDashboardStatsByCpUserIdQuery(cpUser?.id);

  const handleShowProjectDetails = () => {
    history.push('/cp/dashboard/profilePage');
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.headerWrap}>
        <div onClick={handleShowProjectDetails} className={styles.userImg}>
          <Image src={userProfileDefaultImage} />
        </div>
        {/* TODO: Enable once it's functional */}
        {/* <div className={styles.notificationIcons}>
          <Icon propStyles={styles.headerIcon} name='event_note' />
          <Icon propStyles={styles.headerIcon} name='new_releases' />
          <div className={styles.iconWrap}>
            <Icon propStyles={styles.headerIcon} name='notifications' />
            {!!notificationCount && (
              <p className={styles.notificationCount}>(notificationCount)</p>
            )}
          </div>
        </div> */}
        <EarningsCard
          cpUserDashboardStats={cpUserDashboardStats}
          loading={isLoading}
        />
      </div>

      {/* Performancee Card */}
      <div className={styles.conatinerWrap}>
        <div className={styles.innerWrap}>
          <div className={styles.performanceWrap}>
            <div className={styles.performanceHeaderWrap}>
              <HeadTitle>Performance</HeadTitle>
            </div>
            <PerformanceCardList
              cpUserDashboardStats={cpUserDashboardStats}
              loading={isLoading}
            />
          </div>

          {/* Projects card */}
          <ProjectCardList />
        </div>
      </div>
    </div>
  );
};

export default CpDashboardPage;
