import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { URLS } from '../../constants/urls';

import { useBlockUnitMutation } from '../../api/units';
import {
  getAppSliceIsFetching,
  setAppSliceIsFetching,
} from '../../slices/appSlice';
import {
  getUnitCart,
  setUnitCart,
} from '../../apps/booking/slices/selectedUnit';
import useToast from '../../hooks/useToast';

const BlockUnitButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const appSliceIsFetching = useSelector(getAppSliceIsFetching);

  const unitCart = useSelector(getUnitCart);
  const selectedUnit = unitCart.unit;

  const [addToast] = useToast();

  const [blockUnitAPI] = useBlockUnitMutation();

  const handleBlockUnit = async () => {
    const {
      data: unitCartdata,
      error: { data: { message: errorMessage } = {} } = {},
    } = await blockUnitAPI(unitCart.id);

    if (errorMessage) {
      addToast({
        type: 'ERROR',
        primaryMessage: 'Please explore another unit!',
        secondaryMessage: errorMessage,
      });
      history.push(URLS.UNIT_SELECTION);
      return false;
    }

    const countDownDate = new Date(unitCartdata.expire_on).getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    hours = days * 24 + hours;

    addToast({
      type: 'SUCCESS',
      primaryMessage: 'Your unit is blocked Successfully!',
      secondaryMessage: `Your blocked unit expires in ${hours} hours`,
    });
    dispatch(setUnitCart(unitCartdata));

    return true;
  };

  const handleBlockUnitWrap = async () => {
    dispatch(setAppSliceIsFetching(true));
    await handleBlockUnit();
    dispatch(setAppSliceIsFetching(false));
  };

  return (
    <button
      type='button'
      className={`displaynone btn btn--outline 
        ${appSliceIsFetching && 'loading-spin-container'}
        ${
          (selectedUnit.status !== 'AVAILABLE' ||
            unitCart.opportunity_id ||
            appSliceIsFetching) &&
          'btn--outline-disabled'
        }`}
      onClick={handleBlockUnitWrap}>
      <span>
        {selectedUnit.status !== 'AVAILABLE' || unitCart.opportunity_id
          ? 'Unit Blocked'
          : appSliceIsFetching
          ? 'Blocking'
          : 'Block Unit'}
      </span>
      <span className='ic2-fa-spin-blue'></span>
    </button>
  );
};

export default BlockUnitButton;
