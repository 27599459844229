import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from '../../../../../../../../components/common/modal/Modal';
import Icon from '../../../../../../../../components/common/Icon/index';
import TextInput from '../../../../../../../../components/common/inputs/TextInput/index';
import DropDownInput from '../../../../../../../../components/common/inputs/DropDownInput';
import Button from '../../../../../../../../components/common/buttons/Button';
import { DropDownOptionType } from '../../../../../../../../components/common/inputs/DropDownInput';

import styles from './styles.module.css';

interface IProjectRenewalModal {
  projectRenewalModalShown: boolean;
  handleProjectRenewalModalClose: Function;
  projectId: string;
  projectName: string;
  developerName: string;
}
interface IProjectRenewalDetails {
  invoiceNumber: string;
  expiryData: string;
  transactionId: string;
}
const schema = yup.object().shape({
  invoiceNumber: yup.string().required(),
  expiryData: yup.string().required(),
  transactionId: yup.string().required(),
});

const ProjectRenewalModal = (props: IProjectRenewalModal) => {
  const {
    handleProjectRenewalModalClose,
    projectRenewalModalShown,
    projectName,
    developerName,
  } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const paymentModeOptions: DropDownOptionType[] = [
    { value: 'CASH_CHALLAN', displayText: 'Cash Challan' },
    { value: 'CHEQUE', displayText: 'Cheque' },
    { value: 'NETBANKING', displayText: 'Netbanking' },
  ];

  const handleProjectRenewalFormSubmit = (
    projectRenewalDetails: IProjectRenewalDetails
  ) => {
    // TODO: API Call here
  };

  return (
    <div>
      <Modal
        show={projectRenewalModalShown}
        onOutsideClick={handleProjectRenewalModalClose}
        additionalClasses={styles.projectRenewalModal}>
        <header>
          <div className={styles.headingAndCloseIconContainer}>
            <h2 className={styles.modalHeading}>Project Renewal</h2>
            <Icon
              name='close'
              propStyles={styles.closeIcon}
              onClick={() => handleProjectRenewalModalClose()}
            />
          </div>
          <p className={styles.modalDesc}>
            Choose Payment Method & add Transaction ID, Cheque Number, Challan
            Number or others
          </p>
        </header>

        <form onSubmit={handleSubmit(handleProjectRenewalFormSubmit)}>
          <main className={styles.modalBody}>
            <div>
              <p className={styles.projectDetailsText}>Project Details</p>
              <p className={styles.projectName}>{projectName}</p>
              <p
                className={
                  styles.developerName
                }>{`Developer: ${developerName}`}</p>
            </div>

            <div>
              <div className={styles.textInputContainer}>
                <TextInput
                  placeHolder='Add Invoice Number'
                  label='Invoice Number'
                  register={register}
                  setValue={setValue}
                  name='invoiceNumber'
                  errorMessage={
                    errors.invoiceNumber ? 'Invoice Number is required' : ''
                  }
                />
              </div>
              <div className={styles.textInputContainer}>
                <TextInput
                  placeHolder='New Expiry Date'
                  label='Mode of Payment'
                  type='date'
                  register={register}
                  setValue={setValue}
                  name='expiryData'
                  errorMessage={
                    errors.expiryData ? 'Expiry Data is required' : ''
                  }
                />
              </div>
              {/*TODO: Handle dropdown using useForm */}
              <div className={styles.dropDownContainer}>
                <DropDownInput
                  lable='Select Mode of Payment'
                  onValueSelected={() => {}}
                  options={paymentModeOptions}
                  register={register}
                  setValue={setValue}
                  name='paymentMode.m'
                />
              </div>
              <div className={styles.textInputContainer}>
                <TextInput
                  placeHolder='Add Transaction ID'
                  label='Transaction ID, Cheque No, Challan No.'
                  register={register}
                  setValue={setValue}
                  name='transactionId'
                  errorMessage={
                    errors.transactionId ? 'Transaction Id is required' : ''
                  }
                />
              </div>
            </div>
          </main>

          <footer className={styles.modalFooter}>
            <p className={styles.footerDisclaimer}>
              By clicking Mark Payment as Done you are marking the Payment for
              the selected unit as done to the user can. After this user will be
              able to proceed to Signing Draft Agreement etc.
            </p>
            <Button type='submit' accent='primary'>
              Submit
            </Button>
            <Button accent='ghost' propStyles={styles.downloadReceiptBtn}>
              Download Receipt
            </Button>
          </footer>
        </form>
      </Modal>
    </div>
  );
};

export default ProjectRenewalModal;
