import { motion, AnimatePresence } from 'framer-motion';

import styles from './styles.module.css';
import { useState } from 'react';

interface ITooltip {
  title: string;
  children: React.ReactNode;
}

const Tooltip = (props: ITooltip) => {
  const { title, children } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={styles.tooltipContainer}>
      <AnimatePresence>
        {showTooltip && (
          <motion.div
            className={styles.tooltip}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            {title}
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </div>
  );
};

export default Tooltip;
