import { useEffect, useState } from 'react';

import HorizontalSlider from '../../../common/HorizontalSlider';
import ImageModel from '../../../common/ImageModal';

import styles from './styles.module.css';

interface IPoserSliderProps {
  imageUrls: string[];
}

const PoserSlider = (props: IPoserSliderProps) => {
  const { imageUrls } = props;

  const [imageUrlToShow, setImageUrlToShow] = useState<string | undefined>();

  useEffect(() => {
    if (imageUrlToShow) {
      document.body.className = 'no-scroll';
    } else document.body.className = '';
  }, [imageUrlToShow]);

  const handleModelClose = () => {
    setImageUrlToShow(undefined);
  };

  return (
    <div className={styles.imageGalleryWrap}>
      <HorizontalSlider>
        <>
          {/* TODO: change <img> to reuseable <Image> component  */}
          {imageUrls.map((imageUrl) => (
            <img
              className={styles.imageCard}
              onClick={() => setImageUrlToShow(imageUrl)}
              src={imageUrl}
            />
          ))}
        </>
      </HorizontalSlider>
      {imageUrlToShow && (
        <ImageModel
          imageUrls={imageUrls}
          selectedImageUrl={imageUrlToShow}
          onClose={handleModelClose}
        />
      )}
    </div>
  );
};

export default PoserSlider;
