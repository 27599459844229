import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import FadeInFadeOutAnim from '../../../../components/common/FadeInFadeOutAnim';
import ApprovalPending from '../../components/pages/ApprovalPage';
import CpKycPage from '../../components/pages/CpKycPage';
import CpLandingPage from '../../components/pages/CpLandingPage';
import CpLoginPage from '../../components/pages/CpLoginPage';
import CrLandingPage from './../../components/pages/CrLandingPage/index';
import CrKycPage from '../../components/pages/CrKycPage';
import CrLoginPage from '../../components/pages/CrLoginPage';
import WelcomePage from '../../components/pages/WelcomePage';
import { isRelataCpApp } from '../../../admin/components/pages/ExecutiveDashboard/utils';

import styles from './styles.module.css';

const CpKycContent = () => {
  const { path } = useRouteMatch();

  return (
    <FadeInFadeOutAnim width='100%' height='100%'>
      <div className={styles.container}>
        <Switch>
          {isRelataCpApp() ? (
            <Route path={`${path}/landing`} component={CpLandingPage}></Route>
          ) : (
            <Route path={`${path}/landing`} component={CrLandingPage}></Route>
          )}
          {isRelataCpApp() ? (
            <Route path={`${path}/login`} component={CpLoginPage}></Route>
          ) : (
            <Route path={`${path}/login`} component={CrLoginPage}></Route>
          )}
          {isRelataCpApp() ? (
            <Route path={`${path}/register`} component={CpKycPage}></Route>
          ) : (
            <Route path={`${path}/register`} component={CrKycPage}></Route>
          )}
          {isRelataCpApp() && (
            <Route
              path={`${path}/approval-pending`}
              component={ApprovalPending}></Route>
          )}
          <Route path={`${path}/welcome`} component={WelcomePage}></Route>
          <Redirect to='/cp/kyc/landing' />
        </Switch>
      </div>
    </FadeInFadeOutAnim>
  );
};

export default CpKycContent;
