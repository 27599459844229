import { Suspense, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import ContentLayer from '../apps/booking/layoutBluePrints/ContentLayer';

import { useLazyGetUnitCartQuery } from '../api/unitCart';
import { useGetCpUserByMobileMutation } from '../apps/cp/services/cpUserAPISlice';
import { getUser } from '../apps/booking/slices/auth';
import { getSelectedUnit } from '../apps/booking/slices/selectedUnit';
import { getCpUser } from '../apps/cp/slices/cpUserSlice';

import { URLS } from '../constants/urls';
import AdminRoutes from '../apps/admin/routers/AdminRoutes';
import FormRoutes from './../apps/admin/routers/FormRoutes';
import CommonAdminComponents from '../components/CommonAdminComponents';
import ToastContainer from '../components/common/toast/ToastContainer';
import CpContentLayer from '../apps/cp/layoutBluePrints/CpContentLayer/index';

const Routes = () => {
  const [getUnitCartAPI] = useLazyGetUnitCartQuery();
  const [getCpUserByMobileAPI] = useGetCpUserByMobileMutation();
  const user = useSelector(getUser);
  const unit = useSelector(getSelectedUnit);
  const cpUser = useSelector(getCpUser);

  useEffect(() => {
    if (user?.id && unit?.id) {
      // Fetch unit cart for first time
      getUnitCartAPI({ unit_id: unit.id, user_id: user.id });
    }
  }, [user.id, unit.id, getUnitCartAPI]);

  useEffect(() => {
    if (cpUser?.id) {
      // Refetch latest cpUser to keep cpUser upto date with db
      // TODO: Use standard practices to refetch a few apis on app load
      getCpUserByMobileAPI({
        address: cpUser.user.mobile,
        addressType: 'MOBILE',
      }).unwrap();
    }
    return () => {};
  }, [cpUser?.id]);

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key='loading'
              style={{ width: '100%', height: '100%' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}>
                Please wait while we load the experience for you...
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route path='/admin' component={AdminRoutes} />

            <Route path='/forms' component={FormRoutes} />

            <Route
              path='/common-admin-components'
              component={CommonAdminComponents}
            />

            {/* Relata CP - Channel Partner(Broker) */}
            <Route path='/cp'>
              <CpContentLayer />
            </Route>

            {/* TODO: Add inside BookingRoutes */}
            <Route path={[URLS.DASHBOARD_BASE, URLS.BOOKING_BASE]}>
              <ContentLayer />
            </Route>

            {/* Unmatched url redirect to admin dashboard or login page base on if user has already logged in*/}
            <Redirect to={URLS.ADMIN} />
          </Switch>
        </Suspense>
      </AnimatePresence>
      <ToastContainer />
    </>
  );
};

export default Routes;
