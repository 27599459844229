import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { DropDown } from '../DropDown';
import {
  cityDropDown,
  countryDropDown,
} from '../../utils/applicantFormDropDownOptions';
import FileUpload from '../common/FileUpload';
import S3Client from '../../utils/S3Client';
import { isEmpty } from '../../utils/utils';

// Validation
const schema = yup.object().shape({
  primary_address_line_1: yup.string().required(),
  primary_address_country: yup.string().required(),
  primary_address_city: yup.string().required(),
  primary_address_state: yup.string().required(),
  primary_address_pincode: yup.string().required(),

  correspondence_address_line_1: yup.string().required(),
  correspondence_address_country: yup.string().required(),
  correspondence_address_city: yup.string().required(),
  correspondence_address_state: yup.string().required(),
  correspondence_address_pincode: yup.string().required(),

  electricity_bill_doc_url: yup.string().required(),
  passport_size_photo_url: yup.string().required(),
  passport_doc_url: yup.string().required(),
  driving_license_doc_url: yup.string().required(),
});

const KycDetailsFrom = (props) => {
  const {
    handleSave,
    proceedKycDetails = false,
    setProceedKycDetails,
    setProceedBntEnabled,
  } = props;

  const {
    line_1: primary_address_line_1,
    landmark: primary_address_landmark,
    country: primary_address_country,
    city: primary_address_city,
    state: primary_address_state,
    pincode: primary_address_pincode,
  } = props.applicant.primary_address || {};

  const {
    line_1: correspondence_address_line_1,
    landmark: correspondence_address_landmark,
    country: correspondence_address_country,
    city: correspondence_address_city,
    state: correspondence_address_state,
    pincode: correspondence_address_pincode,
  } = props.applicant.correspondence_address || {};

  const {
    id: applicantId,
    electricity_bill_doc_url,
    passport_size_photo_url,
    passport_doc_url,
    driving_license_doc_url,
  } = props.applicant;

  const [saveAsAbove, setSaveAsAbove] = useState(false);
  const [fileUploadingStatuses, setFileUploadingStatuses] = useState({});

  const {
    register,
    trigger,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  });

  // Watch primarry address values

  const selectedPrimaryAddressCity = watch(
    'primary_address_city',
    primary_address_city || cityDropDown.defaultValue
  );

  const selectedCorrespondenceAddressCity = watch(
    'correspondence_address_city',
    correspondence_address_city || cityDropDown.defaultValue
  );

  const selectedPrimaryAddressCountry = watch(
    'primary_address_country',
    primary_address_country || countryDropDown.defaultValue
  );

  // Watch DropDown
  const selectedCorrespondenceAddressCountry = watch(
    'correspondence_address_country',
    correspondence_address_country || countryDropDown.defaultValue
  );
  // File uploads
  const selectedElectricityBillDocUrl = watch(
    'electricity_bill_doc_url',
    electricity_bill_doc_url
  );
  const selectedPassportSizePhotoUrl = watch(
    'passport_size_photo_url',
    passport_size_photo_url
  );
  const selectedPassportDocUrl = watch('passport_doc_url', passport_doc_url);
  const selectedDrivingLicenseDocUrl = watch(
    'driving_license_doc_url',
    driving_license_doc_url
  );

  useEffect(() => {
    // Register dropdown values
    register('primary_address_city', {
      value: selectedPrimaryAddressCity,
    });
    register('correspondence_address_city', {
      value: selectedCorrespondenceAddressCity,
    });
    register('primary_address_country', {
      value: selectedPrimaryAddressCountry,
    });
    register('correspondence_address_country', {
      value: selectedCorrespondenceAddressCountry,
    });
    // File uploads
    register('electricity_bill_doc_url', {
      value: selectedElectricityBillDocUrl,
    });
    register('passport_size_photo_url', {
      value: selectedPassportSizePhotoUrl,
    });
    register('passport_doc_url', {
      value: selectedPassportDocUrl,
    });
    register('driving_license_doc_url', {
      value: selectedDrivingLicenseDocUrl,
    });
  });

  useEffect(() => {
    if (proceedKycDetails) {
      setProceedKycDetails(false);
      handleSubmit(onSubmit, onError)();
    }
  });

  useEffect(() => {
    const isUploadInProgress = Object.values(fileUploadingStatuses).some(
      (status) => status
    );
    setProceedBntEnabled(isEmpty(errors) && !isUploadInProgress);
  });

  const toggleSaveAsAbove = (isChecked) => {
    setSaveAsAbove(isChecked);
    if (isChecked) {
      setValue(
        'correspondence_address_line_1',
        getValues('primary_address_line_1'),
        true
      );
      setValue(
        'correspondence_address_landmark',
        getValues('primary_address_landmark'),
        true
      );
      setValue(
        'correspondence_address_country',
        getValues('primary_address_country'),
        true
      );
      setValue(
        'correspondence_address_city',
        getValues('primary_address_city'),
        true
      );
      setValue(
        'correspondence_address_state',
        getValues('primary_address_state'),
        true
      );
      setValue(
        'correspondence_address_pincode',
        getValues('primary_address_pincode'),
        true
      );
    }
    trigger();
  };

  const handleDropDownSelect = (name, value) => {
    setValue(name, value, true);
  };

  const handleFileUpload = (name, value) => {
    setValue(name, value, true);
    trigger();
  };

  const handleIsFileUploading = (fileId, isUploadInProgress) => {
    setFileUploadingStatuses((prevState) => {
      return {
        ...prevState,
        [fileId]: isUploadInProgress,
      };
    });
  };

  const onSubmit = (data) => {
    const formatedData = {
      primary_address: {
        city: getValues('primary_address_city'),
        country: getValues('primary_address_country'),
        landmark: getValues('primary_address_landmark'),
        line_1: getValues('primary_address_line_1'),
        pincode: getValues('primary_address_pincode'),
        state: getValues('primary_address_state'),
      },
      correspondence_address: {
        city: getValues('correspondence_address_city'),
        country: getValues('correspondence_address_country'),
        landmark: getValues('correspondence_address_landmark'),
        line_1: getValues('correspondence_address_line_1'),
        pincode: getValues('correspondence_address_pincode'),
        state: getValues('correspondence_address_state'),
      },
      electricity_bill_doc_url: getValues('electricity_bill_doc_url'),
      passport_size_photo_url: getValues('passport_size_photo_url'),
      passport_doc_url: getValues('passport_doc_url'),
      driving_license_doc_url: getValues('driving_license_doc_url'),
    };
    handleSave(formatedData);
  };

  const onError = (errors, e) => console.error(errors, e);

  return (
    <form className='kyc-applicant' onSubmit={handleSubmit(onSubmit)}>
      <div className='kyc-applicant__body'>
        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>Permanent Address</span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>
          </div>
          <div className='kyc-applicant-info-section__body'>
            <div className='input'>
              <label className='input_label'>Street / House No *</label>
              <input
                type='text'
                className='input_field'
                {...register('primary_address_line_1')}
                defaultValue={primary_address_line_1}
                maxlength='40'
              />
              <span className='input__error'>
                {errors.primary_address_line_1 &&
                  'Street / House No is required'}
              </span>
            </div>

            <div className='input'>
              <label className='input_label'>Landmark</label>
              <input
                type='text'
                className='input_field'
                {...register('primary_address_landmark')}
                defaultValue={primary_address_landmark}
              />
            </div>
            <div className='input'>
              <label className='input_label'>Country *</label>
              <DropDown
                cname={countryDropDown.cname}
                defaultValue={
                  selectedPrimaryAddressCountry || countryDropDown.defaultValue
                }
                options={countryDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('primary_address_country', value)
                }
              />
              <span className='input__error'>
                {errors.primary_address_country?.message}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>City *</label>
              <DropDown
                cname={cityDropDown.cname}
                defaultValue={
                  selectedPrimaryAddressCity || cityDropDown.defaultValue
                }
                options={cityDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('primary_address_city', value)
                }
              />
              <span className='input__error'>
                {errors.primary_address_city && 'City is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>State *</label>
              <input
                type='text'
                className='input_field'
                {...register('primary_address_state')}
                defaultValue={primary_address_state}
              />
              <span className='input__error'>
                {errors.primary_address_state && 'State is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Pincode *</label>
              <input
                type='text'
                className='input_field'
                {...register('primary_address_pincode')}
                defaultValue={primary_address_pincode}
              />
              <span className='input__error'>
                {errors.primary_address_pincode && 'Pincode is required'}
              </span>
            </div>
          </div>
        </section>
        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>Correspondence Address</span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>

            <label className='checkbox'>
              <input
                type='checkbox'
                onClick={() => toggleSaveAsAbove(!saveAsAbove)}
              />
              <span className='f-18'>Same as Above</span>
            </label>
          </div>
          <div className='kyc-applicant-info-section__body'>
            <div className='input'>
              <label className='input_label'>Street / House No *</label>
              <input
                type='text'
                className='input_field'
                {...register('correspondence_address_line_1')}
                defaultValue={correspondence_address_line_1}
                maxlength='40'
              />
              <span className='input__error'>
                {errors.correspondence_address_line_1 &&
                  'Street / House No is required'}
              </span>
            </div>

            <div className='input'>
              <label className='input_label'>Landmark</label>
              <input
                type='text'
                className='input_field'
                {...register('correspondence_address_landmark')}
                defaultValue={correspondence_address_landmark}
              />
            </div>
            <div className='input'>
              <label className='input_label'>Country *</label>
              <DropDown
                cname={countryDropDown.cname}
                defaultValue={
                  selectedCorrespondenceAddressCountry ||
                  countryDropDown.defaultValue
                }
                options={countryDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('correspondence_address_country', value)
                }
              />
              <span className='input__error'>
                {errors.correspondence_address_country?.message}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>City *</label>
              <DropDown
                cname={cityDropDown.cname}
                defaultValue={
                  selectedCorrespondenceAddressCity || cityDropDown.defaultValue
                }
                options={cityDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('correspondence_address_city', value)
                }
              />
              <span className='input__error'>
                {errors.correspondence_address_city && 'City is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>State *</label>
              <input
                type='text'
                className='input_field'
                {...register('correspondence_address_state')}
                defaultValue={correspondence_address_state}
              />
              <span className='input__error'>
                {errors.correspondence_address_state && 'State is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Pincode *</label>
              <input
                type='text'
                className='input_field'
                {...register('correspondence_address_pincode')}
                defaultValue={correspondence_address_pincode}
              />
              <span className='input__error'>
                {errors.correspondence_address_pincode && 'Pincode is required'}
              </span>
            </div>
          </div>
        </section>

        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>
              Please Upload all required documents
            </span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>
          </div>
          <span className='kyc-applicant-info-section__desc'>
            Note: All required documents are uploaded to server. If you want to
            update image or address proof, you can update them else click skip.
          </span>

          <div className='kyc-applicant-info-section__body kyc-applicant-info-section__body--col-2'>
            <FileUpload
              cname=''
              lable='Upload Address Proof 1 (Electricity Bill) *'
              s3Url={selectedElectricityBillDocUrl}
              userId={applicantId}
              documentType={S3Client.electricityBillDocType}
              handleIsFileUploading={handleIsFileUploading}
              handleFileUpload={(value) =>
                handleFileUpload('electricity_bill_doc_url', value)
              }
              error={
                errors.electricity_bill_doc_url &&
                'Electricity Bill is required'
              }
            />
            <FileUpload
              cname=''
              lable='Passport size photo *'
              s3Url={selectedPassportSizePhotoUrl}
              userId={applicantId}
              documentType={S3Client.passportSizePhotoDocType}
              handleIsFileUploading={handleIsFileUploading}
              handleFileUpload={(value) =>
                handleFileUpload('passport_size_photo_url', value)
              }
              error={
                errors.passport_size_photo_url &&
                'Passport size photo is required'
              }
            />
            <FileUpload
              cname=''
              lable='Upload Address Proof 2 (Passport) *'
              s3Url={selectedPassportDocUrl}
              userId={applicantId}
              documentType={S3Client.passportDocType}
              handleIsFileUploading={handleIsFileUploading}
              handleFileUpload={(value) =>
                handleFileUpload('passport_doc_url', value)
              }
              error={errors.passport_doc_url && 'Passport is required'}
            />
            <FileUpload
              cname=''
              lable='Upload Driving License *'
              s3Url={selectedDrivingLicenseDocUrl}
              userId={applicantId}
              documentType={S3Client.drivingLicenseDocType}
              handleIsFileUploading={handleIsFileUploading}
              handleFileUpload={(value) =>
                handleFileUpload('driving_license_doc_url', value)
              }
              error={
                errors.driving_license_doc_url && 'Driving License is required'
              }
            />
          </div>
        </section>
      </div>
    </form>
  );
};

export default KycDetailsFrom;
