import { useState } from 'react';

import 'ag-grid-enterprise';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import { useGetProjectsQuery } from '../../../../services/projectsAPISlice';
import RegistrationRenderer, {
  DUMMY_GROWTH,
} from './cell-renderers/RegistrationRendererCell';
import SuperAdminProjectListHeader from './SuperAdminProjectListHeader';
import NameCard from '../../../../../../components/common/admin/NameCard';
import MoreActionsMenu, {
  IMoreActionsMenu,
} from './cell-renderers/MoreActionsMenuCell';
import ProjectAnalyticsDashboardCell from './cell-renderers/ProjectAnalyticsDashboardCell';
import AddVsgCell from './cell-renderers/AddVsgCell';
import BlockButtonCell from './cell-renderers/BlockButtonCell/index';
import ProjectStatusDropDownCell from './cell-renderers/ProjectStatusDropDownCell';
import ProjectRenewalModal from './modals/ProjectRenewalModal';
import { ACCESS, ProjectStatus } from '../../../../../../constants/status';
import { awsBaseUrl } from '../../../../../../constants/urls';
import { ICompany, IUser } from '../../../../interfaces';
import { IExecutivesWithDemoRoom } from './modals/AddProjectExecutiveModal';
import { IConstant } from '../../../../../../interfaces';
import { IDemoRoom } from './modals/AddDemoRoomModal';
import DateCell from '../../../common/dates/DateCell';
import LiveSinceDateCell from '../../../common/dates/LiveSinceDateCell';

import styles from './styles.module.css';

export interface ISecondaryCompany {
  company: string | ICompany;
  access: ACCESS;
}

export interface ICollateral {
  type: 'photo_size_select_actual' | 'view_in_ar' | 'description' | 'menu_book';
  title: string;
  subTitle: string;
  thumbnailUrl: string;
  text: string;
  url: string;
}

export interface IPriceRange {
  min: number;
  max: number;
}

export interface ILocation {
  name: string;
  googleMapUrl: string;
}

export interface IProject {
  id: string;
  name: string;
  status: ProjectStatus;
  city: string;
  expiryDate: Date;
  vrUrl: string;
  reraUrl: string;
  createAt: Date;
  demoRooms: IDemoRoom[];
  executivesWithDemoRoom: IExecutivesWithDemoRoom[];
  leads: IUser[];
  company: ICompany;
  secondaryCompanies: ISecondaryCompany[];
  disabled: boolean;
  pocEmail: string;
  pocMobile: string;
  rera: string;

  thumbnailUrl: string;
  posters: [{ title: string; imageUrl: string }];
  gallery: [{ title: string; imageUrl: string }];

  about: string;
  features: [
    {
      name: string;
      value: string;
    }
  ];
  configs: string[];
  priceRange?: IPriceRange;
  location?: ILocation;
  amenities: [string];
  nearByAreas: [
    {
      name: string;
      distance: {
        value: string;
        unit: 'KELOMETER' | 'METER';
      };
    }
  ];
  collaterals: [ICollateral];
}

interface IProjectStatusChangeData {
  selectedOption: IConstant[];
  projectId: string;
  projectName: string;
  developerName: string;
}

const SuperAdminDashboard = () => {
  const [gridApi, setGridApi] = useState(null) as any;

  const [projectRenewalModalData, setProjectRenewalModalData] = useState({
    modalShown: false,
    projectId: '',
    projectName: '',
    developerName: '',
  });

  const { data: projects, isLoading } = useGetProjectsQuery({});

  //AG-grid Default column defination
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  //This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (event: string) => {
    gridApi.setQuickFilter(event);
  };

  if (isLoading) return <div>Loading...</div>;

  const getProjectImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.pocMobile % 5
    }.png`;
  };

  const handelProjectStatusUpdate = (
    updatedProjectStatus: IProjectStatusChangeData
  ) => {
    if (
      updatedProjectStatus.selectedOption[0]?.value === 'EARLY_RENEWAL' ||
      updatedProjectStatus.selectedOption[0]?.value === 'RENEW'
    ) {
      setProjectRenewalModalData({
        modalShown: true,
        projectId: updatedProjectStatus.projectId,
        projectName: updatedProjectStatus.projectName,
        developerName: updatedProjectStatus.developerName,
      });
    }
  };

  return (
    <div className={styles.superAdminDashboard}>
      <SuperAdminProjectListHeader onSearch={handleGridSearch} />

      <ProjectRenewalModal
        projectRenewalModalShown={projectRenewalModalData.modalShown}
        handleProjectRenewalModalClose={() => {
          setProjectRenewalModalData((prevState) => ({
            ...prevState,
            modalShown: false,
          }));
        }}
        projectId={projectRenewalModalData.projectId}
        projectName={projectRenewalModalData.projectName}
        developerName={projectRenewalModalData.developerName}
      />

      <main className={styles.agGridContainer}>
        <div
          className='ag-theme-alpine'
          style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowData={projects}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}
            headerHeight={60}>
            <AgGridColumn
              hide={true}
              headerName=''
              field='name'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p>{params.value}</p>
              )}></AgGridColumn>
            <AgGridColumn
              flex={4}
              headerName='Projects'
              cellRendererFramework={(params: ICellRendererParams) => {
                return (
                  <div className={styles.projectsColumnDataContainer}>
                    <NameCard
                      {...{
                        imgUrl: `${getProjectImageUrl(params)}`,
                        title: params.data.name,
                        subtitle: params.data.id,
                        secondarySubtitle: params.data.company.displayName,
                        disabled: params.data.disabled,
                      }}
                    />
                  </div>
                );
              }}></AgGridColumn>
            <AgGridColumn
              flex={3}
              headerName='Status'
              field='Status'
              cellRendererFramework={ProjectStatusDropDownCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  projectData: params.data,
                  onStatusUpdate: (
                    updatedProjectStatus: IProjectStatusChangeData
                  ) => handelProjectStatusUpdate(updatedProjectStatus),
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Live Since'
              field='createdAt'
              cellRendererFramework={LiveSinceDateCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  liveSinceDate: params.data.createdAt,
                  disabled: params.data.disabled,
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Expiry Date'
              field='expiryDate'
              cellRendererFramework={DateCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  date: params.data.expiryDate,
                  disabled: params.data.disabled,
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Registrations'
              field='registeration'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={RegistrationRenderer}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  disabled: params.data.disabled,
                  value: params.data.leads.length,
                  growth: DUMMY_GROWTH, //TODO: Implement growth function
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={1}
              headerName='Analytics'
              headerClass='relata-ag-grid-header-small-font'
              cellRendererFramework={ProjectAnalyticsDashboardCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  disabled: params.data.disabled,
                  project: params.data,
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={1}
              headerName='VSG Link'
              headerClass='relata-ag-grid-header-small-font'
              cellRendererFramework={AddVsgCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  disabled: params.data.disabled,
                  projectId: params.data.id,
                  projectName: params.data.name,
                  vrUrl: params.data.vrUrl,
                  onRowUpdate: (updatedProject: IProject) =>
                    params.node.setData(updatedProject),
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={1}
              headerName='Block'
              headerClass='relata-ag-grid-header-small-font'
              cellRendererFramework={BlockButtonCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  projectId: params.data.id,
                  disabled: params.data.disabled,
                  onRowUpdate: (updatedProject: IProject) =>
                    params.node.setData(updatedProject),
                };
              }}></AgGridColumn>
            <AgGridColumn
              flex={1}
              // More Actions
              headerName=''
              cellRendererFramework={MoreActionsMenu}
              cellRendererParams={(
                params: ICellRendererParams
              ): IMoreActionsMenu => {
                return {
                  project: params.data as IProject,
                  // TODO: Update onProjectUpdate -> onRowUpdate
                  onProjectUpdate: (updatedProject: IProject) =>
                    params.node.setData(updatedProject),
                };
              }}></AgGridColumn>
          </AgGridReact>
        </div>
      </main>
    </div>
  );
};

export default SuperAdminDashboard;
