import { useHistory } from 'react-router-dom';

import HeadTitle from '../../../../../../components/common/TitleHeading';
import { IProject } from '../../../../../admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { CLUB_RHODIUM_COMPANY_ID } from '../../../../constants/ids';
import { useGetClubRhodiumProjectsQuery } from '../../../../services/cpProjectAPISlice';
import HorizontalSlider from '../../../common/HorizontalSlider';
import { fallbackImageUrl } from '../../../../../../constants/urls';

import styles from './styles.module.css';

const ProjectCardList = () => {
  const history = useHistory();

  const { data: clubRhodiumProjects = [], isLoading } =
    useGetClubRhodiumProjectsQuery(CLUB_RHODIUM_COMPANY_ID);

  const handleShowProjectDetails = (project: IProject) => {
    history.push('/cp/dashboard/project-details', { project });
  };

  const handleShowDiscoveryPage = () => {
    history.push('/cp/dashboard/discovery');
  };

  return (
    <>
      <div className={styles.headerWrap}>
        <HeadTitle>Current Projects</HeadTitle>
        <p onClick={handleShowDiscoveryPage} className={styles.viewAll}>
          View All
        </p>
      </div>

      <HorizontalSlider>
        <>
          {clubRhodiumProjects.map((project) => (
            <div
              key={project.id}
              className={styles.imageCard}
              onClick={() => handleShowProjectDetails(project)}>
              {/* TODO: change <img> to reuseable <Image> component  */}
              <img
                src={project.thumbnailUrl || fallbackImageUrl}
                alt='project view'
              />
              <div className={styles.cardDescription}>
                <p className={styles.cardTitle}>{project.name}</p>
                <p className={styles.subTitle}>{project.location?.name}</p>
              </div>
            </div>
          ))}
        </>
      </HorizontalSlider>
    </>
  );
};

export default ProjectCardList;
