import { ICellRendererParams } from 'ag-grid-community';
import Modal from './../../../../../../components/common/modal/Modal';
import Icon from './../../../../../../components/common/Icon/index';

import styles from './styles.module.css';

interface IUnitDetailsModal {
  unitDetailsModalShown: boolean;
  handelUnitDetailsModalClose: Function;
  rowData: ICellRendererParams;
}

const UnitDetailsModal = (props: IUnitDetailsModal) => {
  const { handelUnitDetailsModalClose, unitDetailsModalShown, rowData } = props;

  const unitDetails = [
    {
      label: 'Unit Name',
      value: rowData.data.unitName,
    },
    {
      label: 'Unit Configurations',
      value: rowData.data.config,
    },
    {
      label: 'Tower Name',
      value: rowData.data.unitName.split(' ')[0],
    },
    {
      label: 'Tower Name',
      value: 'East', // TODO: Get correct value from API Data
    },
    {
      label: 'Unit View',
      value: 'East', // TODO: Get correct value from API Data
    },
    {
      label: 'Unit Number',
      value: rowData.data.unitName.split(' ')[1],
    },
    {
      label: 'Unit Facing',
      value: 'Outward Facing', // TODO: Get correct value from API Data
    },
    {
      label: 'Unit Status',
      value: rowData.data.available ? 'Available' : 'Not Available',
    },
  ];

  const costBreakUpDetails = [
    // TODO: Get correct values from API Data
    {
      label: 'Area Unit',
      value: 'Sq.Ft',
    },
    {
      label: 'Carpet Area',
      value: '903.45',
    },
    {
      label: 'Built-Up Area',
      value: '1365.65 Sq.Ft',
    },
    {
      label: 'Super Built Up Area',
      value: '1458.7 Sq.Ft',
    },
    {
      label: 'GST Rate',
      value: '7.5',
    },
    {
      label: 'Floor Rise',
      value: '₹750',
    },
    {
      label: 'PLC',
      value: '₹100',
    },
    {
      label: 'Total Value Of Property',
      value: '₹50,02,357',
    },
    {
      label: 'GST Amount',
      value: '₹2,50,118',
    },
    {
      label: 'Base Price Per Sq.Ft',
      value: '₹5,747',
    },
  ];

  const additionalChargesDetails = [
    // TODO: Get correct values from API Data
    {
      label: 'Water/ Electricity/Backup Charges @ Rs.150/- Per Sq.Ft',
      value: '₹52,52,475',
    },
    {
      label: 'Advance Aintainance Charges (For 3Y)',
      value: '₹52,52,475',
    },
    {
      label: 'City Infra Charges @ Rs.200/-',
      value: '₹52,52,475',
    },
    {
      label: 'Corpus Fund',
      value: '₹1,32,338',
    },
    {
      label: 'Agreement Cost',
      value: '₹52,52,475',
    },
    {
      label: 'Club House & Amenities Charges @ Rs 125000/-',
      value: '₹1,31,250',
    },
    {
      label: 'All Total',
      value: '₹63,24,027',
    },
  ];

  return (
    <Modal
      show={unitDetailsModalShown}
      onOutsideClick={handelUnitDetailsModalClose}
      additionalClasses={styles.unitDetailsModal}>
      <header>
        <div className={styles.headingAndIconContainer}>
          <div className={styles.modalHeadingContainer}>
            <h2 className={styles.modalHeading}>
              Unit Details Of {rowData.data.unitName}
            </h2>
            <p className={styles.reraNumber}>
              (RERA Number{'  '}
              <span className={styles.rearNumberBoldFont}>
                PRM/KA/RERA/1251/309/AG/171120/001474{' '}
              </span>
              )
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.closeIcon}
            onClick={() => handelUnitDetailsModalClose()}></Icon>
        </div>
        <p className={styles.headerDesc}>
          These are the inventory details of selected unit. Executive can see
          the unit information, <br /> cost break up and additional charges.
        </p>
      </header>

      <main className={styles.modalBody}>
        <div className={styles.unitImageContainer}>
          Image will be placed Here
        </div>

        {/* Unit Details */}
        <div className={styles.allDetailsContainer}>
          <div className={styles.unitDetailsContainer}>
            {unitDetails.map((individualDetail) => (
              <div className={styles.individualDetailContainer}>
                <p className={styles.detailsLabel}>{individualDetail.label}</p>
                <h2 className={styles.detailsValue}>
                  {individualDetail.value}
                </h2>
              </div>
            ))}
          </div>

          {/* Cost Break-Up */}
          <div className={styles.costBreakUpDetailsAndHeadingContainer}>
            <h2 className={styles.detailsNameHeading}>Cost Break-Up</h2>
            <div className={styles.costBreakUpDetailsContainer}>
              {costBreakUpDetails.map((individualDetail) => (
                <div className={styles.individualDetailContainer}>
                  <p className={styles.detailsLabel}>
                    {individualDetail.label}
                  </p>
                  <h2 className={styles.detailsValue}>
                    {individualDetail.value}
                  </h2>
                </div>
              ))}
            </div>
          </div>

          {/* Additional Charges */}
          <div className={styles.additionalChargesDetailsAndHeadingContainer}>
            <h2 className={styles.detailsNameHeading}>Additional Charges</h2>
            <div className={styles.costBreakUpDetailsContainer}>
              {additionalChargesDetails.map((individualDetail, index) => (
                <div
                  className={`${
                    individualDetail.label !== 'Corpus Fund'
                      ? styles.additionalChargesDetailsContainer
                      : ''
                  }`}>
                  <p className={styles.detailsLabel}>
                    {individualDetail.label}
                  </p>
                  <h2 className={styles.detailsValue}>
                    {individualDetail.value}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </Modal>
  );
};

export default UnitDetailsModal;
