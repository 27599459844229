import { useState } from 'react';

import { useSelector } from 'react-redux';
import 'ag-grid-enterprise';
import {
  CellClassParams,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { getSearchString } from './../../../../../../slices/leadsSearchSlice';
import { Card } from '../../../../../../../../components/common/admin/Interface';
import ExpandRowCell from './../../../../ActiveLeads/cell-renderers/ExpandRowCell/index';
import HotnessIndicatorCell from './../../../../ActiveLeads/cell-renderers/HotnessIndicatorCell/index';
import NameCard from './../../../../../../../../components/common/admin/NameCard';
import LeadDetailsGrid from './../../../../ActiveLeads/CpLeadRequests/CpLeadRequestGrid/LeadDetailsGrid/index';
import { awsBaseUrl } from './../../../../../../../../constants/urls';
import { IConstant } from './../../../../../../../../interfaces/index';

import styles from './styles.module.css';

export enum HotnessIndicator {
  HOT = 0,
  WARM = 1,
  ROOM = 2,
  COLD = 3,
}

export interface IStatus {
  BLOCKED: IConstant;
  PAYMENT_DONE: IConstant;
  EXPLORED: IConstant;
  KYC_COMPLETED: IConstant;
}

export const EnquiryStatus: IStatus = {
  BLOCKED: {
    value: 'BLOCKED',
    displayText: 'Blocked',
  },
  PAYMENT_DONE: {
    value: 'PAYMENT_DONE',
    displayText: 'Payment Done',
  },
  EXPLORED: {
    value: 'EXPLORED',
    displayText: 'Explored',
  },
  KYC_COMPLETED: {
    value: 'KYC_COMPLETED',
    displayText: 'KYC Completed',
  },
};
export interface IUserAction {
  EXPLORED_NEW_UNIT: IConstant;
  KYC_DETAILS_UPDATED: IConstant;
  EXPLORED_OLD_UNIT: IConstant;
  EXPLORED_NEW_BOOKED_UNIT: IConstant;
  BLOCKED_EXPLORED_UNIT: IConstant;
  DRAFT_AGREEMENT_PENDING: IConstant;
  PAYMENT_DONE: IConstant;
}
export const UserAction: IUserAction = {
  EXPLORED_NEW_UNIT: {
    value: 'EXPLORED_NEW_UNIT',
    displayText: 'Explored New Unit',
  },
  KYC_DETAILS_UPDATED: {
    value: 'KYC_DETAILS_UPDATED',
    displayText: 'KYC Details Updated',
  },
  EXPLORED_OLD_UNIT: {
    value: 'EXPLORED_OLD_UNIT',
    displayText: 'Explored Old Unit',
  },
  EXPLORED_NEW_BOOKED_UNIT: {
    value: 'EXPLORED_NEW_BOOKED_UNIT',
    displayText: 'Explored Now Booked Unit',
  },
  BLOCKED_EXPLORED_UNIT: {
    value: 'BLOCKED_EXPLORED_UNIT',
    displayText: 'Blocked Explored Unit',
  },
  DRAFT_AGREEMENT_PENDING: {
    value: 'DRAFT_AGREEMENT_PENDING',
    displayText: 'Draft Agreement Pending',
  },
  PAYMENT_DONE: { value: 'PAYMENT_DONE', displayText: 'Payment Done' },
};

export interface Enquiry {
  id: number;
  status: string;
  unitDetails: string;
  unitConfig: string;
  lastUpdate: Date | string;
}

export interface ActiveLeadsGrid {
  hotness: HotnessIndicator;
  cpName: string;
  user: Card;
  enquiries: Enquiry[];
  lastActivityOn: Date | string;
  lastAction: string;
  registeredOn: Date | string;
  leadStatus: string;
}

const CpProfileAssignedLeadsGrid = (props: { rowData: ActiveLeadsGrid[] }) => {
  const [rowData, setRowData] = useState<ActiveLeadsGrid[] | null>(null);
  const searchString = useSelector(getSearchString);

  const onGridReady = () => {
    setRowData(props.rowData);
  };

  const getIconName = (val: HotnessIndicator): string => {
    let icon: string;
    switch (val) {
      case HotnessIndicator.HOT:
        icon = 'whatshot';
        break;
      case HotnessIndicator.WARM:
        icon = 'thermostat';
        break;
      default:
        icon = 'ac_unit';
    }
    return icon;
  };

  const getContainerClassName = (val: HotnessIndicator): string => {
    let container: string;
    switch (val) {
      case HotnessIndicator.HOT:
        container = 'hot-indicator';
        break;
      case HotnessIndicator.WARM:
        container = 'warm-indicator';
        break;
      case HotnessIndicator.ROOM:
        container = 'room-indicator';
        break;
      case HotnessIndicator.COLD:
        container = 'cold-indicator';
        break;
      default:
        container = '';
    }
    return container;
  };

  const cellClassRules = {
    explored: (params: CellClassParams) =>
      params.value === EnquiryStatus.EXPLORED.value,
    blocked: (params: CellClassParams) =>
      params.value === EnquiryStatus.BLOCKED.value,
    paymentdone: (params: CellClassParams) =>
      params.value === EnquiryStatus.PAYMENT_DONE.value,
    kyccompleted: (params: CellClassParams) =>
      params.value === EnquiryStatus.KYC_COMPLETED.value,
  };

  const getLeadImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.cpName[0].toUpperCase()}${+params
      .data.hotness}.png`;
  };

  return (
    <>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={rowData}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          isExternalFilterPresent={() => searchString !== ''}
          doesExternalFilterPass={(node: RowNode) => {
            return (
              node.data.user &&
              node.data.user.title
                .toLowerCase()
                .includes(searchString.toLowerCase())
            );
          }}
          masterDetail={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          frameworkComponents={{
            detailCellRenderer: LeadDetailsGrid,
          }}
          animateRows={true}
          onGridReady={() => onGridReady()}>
          <AgGridColumn
            flex={1}
            cellClass='ag-grid-column-custom-cell'
            cellRendererFramework={ExpandRowCell}
            cellRendererParams={(params: ICellRendererParams) =>
              params
            }></AgGridColumn>
          <AgGridColumn
            flex={1}
            headerComponentFramework={HotnessIndicatorCell}
            headerComponentParams={{
              containerClassName: 'ag-grid-header-indicator-icon',
              iconName: 'thermostat',
            }}
            field='hotness'
            cellRendererFramework={HotnessIndicatorCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                iconName: getIconName(params.value),
                containerClassName: getContainerClassName(params.value),
              };
            }}></AgGridColumn>
          <AgGridColumn
            flex={3}
            headerName='Name'
            field='user'
            getQuickFilterText={(params) => {
              return params.value.title;
            }}
            cellRendererFramework={(params: ICellRendererParams) => {
              return (
                <div className={styles.projectsColumnDataContainer}>
                  <NameCard
                    {...{
                      imgUrl: `${getLeadImageUrl(params)}`,
                      title: params.data.cpName,
                      subtitle: params.data.email,
                    }}
                  />
                </div>
              );
            }}></AgGridColumn>
          <AgGridColumn
            flex={1.5}
            headerName='Enquiries'
            field='enquiries'
            cellRenderer={(params: ICellRendererParams) => {
              return params.value && params.value.length
                ? params.value.length
                : 0;
            }}></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='Last Action'
            field='lastAction'
            cellRendererFramework={(params: ICellRendererParams) => {
              const actionNameValue: keyof IUserAction = params.data.lastAction;
              return (
                <>
                  <p className={styles.lastActionName}>
                    {UserAction[actionNameValue].displayText}
                  </p>
                  <p className={styles.lastActionDate}>
                    {params.data.lastActivityOn}
                  </p>
                </>
              );
            }}></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='Registered On'
            field='registeredOn'></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='CP Name'
            field='cpName'></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='Lead Status'
            field='leadStatus'
            valueFormatter={(prop) => {
              const value: keyof IStatus = prop.value;
              return EnquiryStatus[value].displayText;
            }}
            cellClass='lead-status-column'
            cellClassRules={cellClassRules}></AgGridColumn>
        </AgGridReact>
      </div>
    </>
  );
};

export default CpProfileAssignedLeadsGrid;
