import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import HeadTitle from '../../../../../components/common/TitleHeading';
import RecommendedPropertyCardList from './RecommendedPropertyCardList';
import Image from '../../../../../components/common/Image';
import Icon from '../../../../../components/common/Icon';
import { ICpLead, ICpLeadReq, ICpUser } from '../../../interfaces';
import useToast from '../../../../../hooks/useToast';
import { getCpUser } from '../../../slices/cpUserSlice';
import { useAddCpLeadMutation } from '../../../services/cpUserAPISlice';
import { TObjectId } from '../../../../../interfaces';
import { useGetClubRhodiumProjectsQuery } from '../../../services/cpProjectAPISlice';
import { CLUB_RHODIUM_COMPANY_ID } from '../../../constants/ids';
import { setAppSliceIsFetching } from '../../../../../slices/appSlice';

import styles from './styles.module.css';

const CrRecommendedProjectsPage = () => {
  const location = useLocation<{ cpLeadReq: ICpLeadReq }>();
  const cpLeadReq = location?.state?.cpLeadReq;

  const history = useHistory();
  const dispatch = useDispatch();

  const [showQrCode, setShowQrCode] = useState(false);
  const [cpLeadRes, setCpLeadRes] = useState<ICpLead>();
  const [selectedProjects, setSelectedProjects] = useState<
    { id: TObjectId; name: string }[]
  >([]);

  const { data: projects = [], isLoading } = useGetClubRhodiumProjectsQuery(
    CLUB_RHODIUM_COMPANY_ID
  );
  const cpUser = useSelector(getCpUser) as ICpUser;
  const [addToast] = useToast();
  const [addCpLeadAPI] = useAddCpLeadMutation();

  const projectNames = useMemo(() => {
    return Object.values(selectedProjects).map((project) => project.name);
  }, [selectedProjects]);

  const handleQrCodeClose = () => {
    setShowQrCode(false);
    history.push('/cp/dashboard/leads', { pendingTabSelected: true });
  };

  // TODO: add generic hook to handle share functionality
  const handleShareClick = async () => {
    if (navigator.share) {
      const response = await fetch(cpLeadRes?.qrCodeUrl || '');
      const blob = await response.blob();
      const file = new File([blob], 'qrCode.png', { type: blob.type });
      const textToShare = `Dear ${
        cpLeadRes?.name
      },\n\nRequest you to kindly show the QR code at the start of the site visit to the Guest Relationship Manager or Sales Manager at our site${
        projectNames.length ? `: *${projectNames.join(',')}*` : ''
      }.\n\nThe team will assist you in case you face any issues.This will help us create a better experience during your visit to our experience center.`;

      navigator
        .share({
          files: [file],
          text: textToShare,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch((error) => {
          console.error('Please try Manually', error);
        });
    }
  };

  const handleAddLead = async () => {
    try {
      dispatch(setAppSliceIsFetching(true));
      const response = await addCpLeadAPI({
        cpUserId: cpUser.id,
        cpLead: { ...cpLeadReq, projects: selectedProjects },
      }).unwrap();

      setCpLeadRes(response.data.cpLead as ICpLead);
      setShowQrCode(true);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    } finally {
      dispatch(setAppSliceIsFetching(false));
    }
  };

  const propertyDetails = useMemo(() => {
    const { propertyConfigs, preferenceAreasTemp } = cpLeadReq;
    if (propertyConfigs && preferenceAreasTemp)
      return propertyConfigs.join(' ') + '-' + ' ' + preferenceAreasTemp;
    if (propertyConfigs) return propertyConfigs;

    return preferenceAreasTemp;
  }, [cpLeadReq]);

  return (
    <div className={styles.pageConatiner}>
      {showQrCode && (
        <div className={styles.qrCodeWrap}>
          <div className={styles.headerButtons}>
            <p className={styles.headerBtn} onClick={handleQrCodeClose}>
              Done
            </p>
            <p className={styles.headerBtn}>Scan QR Code</p>
            <Icon
              name='share'
              onClick={handleShareClick}
              propStyles={styles.shareIcon}
            />
          </div>
          <div className={styles.qrContainer}>
            <div className={styles.topConatiner}>
              <div className={styles.detailsWrap}>
                <div className={styles.leftConatiner}>
                  <div className={styles.leadDetails}>
                    <p className={styles.leadType}>Name of the lead</p>
                    <p className={styles.leadValue}>{cpLeadRes?.name}</p>
                  </div>
                  <div className={styles.spaceLine} />
                  <div className={styles.leadDetails}>
                    <p className={styles.leadType}>Mobile no.</p>
                    <p className={styles.leadValue}>
                      XXXXXX{cpLeadRes?.mobileLastFourDigits}
                    </p>
                  </div>
                </div>
                <div className={styles.imagWrap}>
                  <Image src={cpLeadRes?.qrCodeUrl} alt='QR Code' />
                </div>
              </div>
            </div>
            <div className={styles.spaceLine} />
            <div className={styles.bottomConatiner}>
              <div className={styles.leadDetails}>
                <p className={styles.leadType}>Property type</p>
                <p className={styles.leadValue}>
                  {cpLeadRes?.propertyConfigs.join(' ')}
                </p>
              </div>
              <div className={styles.spaceLine} />
              <div className={styles.leadDetails}>
                <p className={styles.leadType}>Area preferences</p>
                <p className={styles.leadValue}>
                  {cpLeadRes?.preferenceAreasTemp}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {showQrCode && selectedProjects.length === 0 ? null : (
        <div className={styles.pageWrap}>
          <div className={styles.headWrap}>
            <div
              className={`${styles.titleWrap} ${
                showQrCode && styles.activeQrModel
              }`}>
              <div className={styles.btnWrap}>
                <HeadTitle>Interested Properties</HeadTitle>
                {!showQrCode && (
                  <button className={styles.doneBtn} onClick={handleAddLead}>
                    Done
                  </button>
                )}
              </div>
              <p className={styles.locationDetails}>{propertyDetails}</p>
            </div>
          </div>
          <RecommendedPropertyCardList
            projects={projects}
            onSelectedProjectsChange={setSelectedProjects}
            qrCodeShown={showQrCode}
          />
        </div>
      )}
    </div>
  );
};

export default CrRecommendedProjectsPage;
