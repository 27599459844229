import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import {
  setContentHeaderNavState,
  CONTENT_HEADER_NAV_STATE,
} from '../../../apps/booking/slices/contentHeader/contentHeaderNav';
import { URLS } from '../../../constants/urls';

const MasterBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <button
      className='btn btn--accent'
      onClick={() => {
        history.push(URLS.UNIT_SELECTION);
        dispatch(setContentHeaderNavState(CONTENT_HEADER_NAV_STATE.MASTER));
      }}>
      <span>Master Plan</span>
    </button>
  );
};

export default MasterBtn;
