import { useHistory, useLocation } from 'react-router-dom';

import Icon from '../../../../../components/common/Icon';
import HeadTitle from '../../../../../components/common/TitleHeading';
import { IProject } from '../../../../admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import MarketingCollateralCardList from './MarketingCollateralCardList';

import styles from './styles.module.css';

const MarketingCollaterPage = () => {
  const history = useHistory();

  const location = useLocation<{ project: IProject }>();
  const project = location?.state?.project;

  return (
    <div className={styles.pageWrap}>
      <Icon
        onClick={() => history.goBack()}
        name='arrow_back'
        propStyles={styles.backIcon}
      />
      <HeadTitle>Marketing Collateral</HeadTitle>
      <MarketingCollateralCardList project={project} />
    </div>
  );
};

export default MarketingCollaterPage;
