import { useDispatch, useSelector } from 'react-redux';

import BookingAmountCard from '../common/cards/BookingAmountCard';
import BlockedUnitCountDownTimer from '../common/cards/BlockedUnitCountDownTimer';

import {
  getUnitCart,
  getCostSheet,
  getIsFetchingUnitCart,
  setSelectedSchemeId,
} from '../../apps/booking/slices/selectedUnit';

import { DropDown } from '../DropDown';
import { BOOKED } from '../../constants/status';

const getPaymentPlanDropDown = (
  schemeDetails = [],
  selectedEmbassySchemeId = ''
) => {
  const paymentPlanDropDown = {
    cname: 'dropdown--secondary',
    defaultValue: selectedEmbassySchemeId || schemeDetails[0]?.scheme_sfdc_id,
    options: schemeDetails.map((scheme) => {
      return { value: scheme.scheme_sfdc_id, text: scheme.scheme_name };
    }),
  };
  return paymentPlanDropDown;
};

const PaymentPlanContentHeader = () => {
  const dispatch = useDispatch();
  const unitCart = useSelector(getUnitCart);
  const costSheet = useSelector(getCostSheet);
  const isFetchingUnitCart = useSelector(getIsFetchingUnitCart);

  const schemeDetails = costSheet?.scheme_details;
  const paymentPlanDropDown = getPaymentPlanDropDown(
    schemeDetails,
    unitCart?.scheme_sfdc_id
  );

  // Select first scheme if scheme is not selected by default
  if (!unitCart?.scheme_sfdc_id && schemeDetails) {
    dispatch(setSelectedSchemeId(schemeDetails[0].scheme_sfdc_id));
  }

  const handleSchemeSelect = (schemeId) => {
    dispatch(setSelectedSchemeId(schemeId));
  };

  if (isFetchingUnitCart) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <header className='content-header content-header--payment-plan'>
        <div className='content-header__title-plate'>
          <h1 className='content-header__title'>
            <span>You have selected the</span>{' '}
            {/* <span className="font-accent-bold"> */}
            <div className='content-header__drop-down'>
              <DropDown
                cname={`${paymentPlanDropDown.cname} ${
                  unitCart.unit.status === BOOKED && 'click-disabled'
                }`}
                defaultValue={paymentPlanDropDown.defaultValue}
                options={paymentPlanDropDown.options}
                handleDropDownSelect={(value) => handleSchemeSelect(value)}
              />
            </div>
            {/* </span> */}
          </h1>
          <p className='content-header__description'>
            Select amongst the payment schemes available to take complete
            benefit of your Embassy Edge dream home.
          </p>
        </div>

        <div className='content-header__card-container'>
          <BlockedUnitCountDownTimer />
          <BookingAmountCard />
        </div>
      </header>
    </>
  );
};

export default PaymentPlanContentHeader;
