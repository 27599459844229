import { NavLink } from 'react-router-dom';
import Icon from '../common/Icon';

const DashboardSidebarItem = (props) => {
  const { to = '/', labelText, icon, additionClasses = '' } = props;

  return (
    <>
      <NavLink
        to={to}
        className={`dashbaord-sidebar-item ${additionClasses}`}
        onClick={() => {}}>
        <span className='icon-container'>
          <Icon name={icon} />
        </span>
        <span className='dashbaord-sidebar-item__label'>{labelText}</span>
      </NavLink>
    </>
  );
};

export default DashboardSidebarItem;
