import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '../../../../../components/common/buttons/Button';
import Icon from '../../../../../components/common/Icon';
import HeadTitle from '../../../../../components/common/TitleHeading';
import { CLUB_RHODIUM_COMPANY_ID } from '../../../constants/ids';
import { useGetClubRhodiumProjectsQuery } from '../../../services/cpProjectAPISlice';
import Tabs, { ITab } from '../../common/Tabs';
import FeaturedPropertyCard from './FeaturedPropertyCard';
import FiltersModal from './FiltersModal';
import { setAppSliceIsFetching } from '../../../../../slices/appSlice';
import FavouritePropertyCard from './FavoritePropertyCard';

import styles from './styles.module.css';

const CpDiscoveryPage = () => {
  // TODO: Enable offer card once it's functional
  const [propertyCardActive, setPropertyCardHidden] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const dispatch = useDispatch();
  const { data: clubRhodiumProjects = [], isLoading } =
    useGetClubRhodiumProjectsQuery(CLUB_RHODIUM_COMPANY_ID);

  useEffect(() => {
    dispatch(setAppSliceIsFetching(isLoading));
  }, [isLoading]);

  const handleCloseFilters = () => {
    setShowFilters(false);
  };

  const hidePropertyCardElement = () => {
    setPropertyCardHidden(false);
  };

  const tabs: ITab[] = [
    {
      id: 'Featured',
      name: 'Featured',
      content: (
        <>
          <FeaturedPropertyCard projects={clubRhodiumProjects} />
        </>
      ),
      isDisabled: false,
    },
    // TODO: Enable favorite tab
    // {
    //   id: 'Favourite',
    //   name: 'Favourite',
    //   content: (
    //     <>
    //       <FavouritePropertyCard />
    //     </>
    //   ),
    //   isDisabled: false,
    // },
    // {
    //   id: 'Recommended',
    //   name: 'Recommended',
    //   content: <></>,
    //   isDisabled: false,
    // },
  ];

  return (
    <>
      <div className={styles.discoveryWrap}>
        <HeadTitle>Discover</HeadTitle>

        {/* TODO: Enable search once it's functional */}
        {/* <div className={styles.searchWrap}>
          <div className={styles.searchContainer}>
            <Icon name='search' propStyles={styles.searchIcon} />
            <input type='search' placeholder='Search property' />
          </div>
          <div className={styles.SettingIcon}>
            <Icon
              onClick={() => setShowFilters(true)}
              name='tune'
              propStyles={styles.tuneIcon}
            />
          </div>
        </div> */}
        {propertyCardActive && (
          <div className={styles.propertyHighLight}>
            <div className={styles.imageWrap}>
              {/* TODO: change <img> to reuseable <Image> component  */}
              <img
                src='https://picsum.photos/seed/picsum/500/250'
                alt='property'
              />
              <div className={styles.overlayBg} />
              <div className={styles.contentWrap}>
                <p className={styles.propertyName}>Vasant Oasis</p>
                <p className={styles.propertyDealers}>
                  By Guardians Developers
                </p>
                <p className={styles.propertySpecs}>
                  3 BHK Apartment from ₹ 3.50Cr* <br /> Andheri East
                </p>
                <Button crApp propStyles={styles.exploreButton}>
                  Explore
                </Button>
              </div>
              <div className={styles.closeIconWrap}>
                <Icon
                  onClick={hidePropertyCardElement}
                  propStyles={styles.closeIcon}
                  name='close'
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.tabs}>
          <Tabs tabs={tabs} />
        </div>
      </div>
      <FiltersModal show={showFilters} closePopup={handleCloseFilters} />
    </>
  );
};

export default CpDiscoveryPage;
