import { useMemo, useState } from 'react';

import { TObjectId } from '../../../../../../interfaces';
import { IProject } from '../../../../../admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import Checkbox from '../../../common/Checkbox';
import { fallbackImageUrl } from './../../../../../../constants/urls';
import Image from '../../../../../../components/common/Image';

import styles from './styles.module.css';

interface IRecommendedPropertyCardListProps {
  projects: IProject[];
  qrCodeShown: boolean;
  onSelectedProjectsChange: (
    selectedProjects: { id: TObjectId; name: string }[]
  ) => void;
}

const RecommendedPropertyCardList = (
  props: IRecommendedPropertyCardListProps
) => {
  const { projects, onSelectedProjectsChange, qrCodeShown } = props;

  const [selectedProjects, setSelectedProjects] = useState<{
    [projectId: TObjectId]: {
      id: TObjectId;
      name: string;
      checked: boolean;
    };
  }>({});

  const handleCheckboxChange = (
    projectId: TObjectId,
    name: string,
    checked: boolean
  ) => {
    const updatedSelectedProjects = selectedProjects;
    if (checked) {
      updatedSelectedProjects[projectId] = { id: projectId, name, checked };
    } else {
      delete updatedSelectedProjects[projectId];
    }
    setSelectedProjects(updatedSelectedProjects);
    onSelectedProjectsChange(
      Object.values(updatedSelectedProjects).map((p) => ({
        id: p.id,
        name: p.name,
      }))
    );
  };
  const updatedProjects = useMemo(() => {
    if (qrCodeShown) {
      return projects.filter(
        (projects) => projects.id === selectedProjects[projects.id]?.id
      );
    }
    return projects;
  }, [qrCodeShown, projects]);

  return (
    <div
      className={`${styles.cardContainer} ${
        qrCodeShown && styles.qrcodeActive
      }`}>
      {updatedProjects.map((project, index) => (
        <div key={project.id} className={styles.cardWrapper}>
          <div className={styles.checkboxWrap}>
            <Checkbox
              isChecked={selectedProjects[project.id]?.checked || false}
              onChange={(checked: boolean) =>
                handleCheckboxChange(project.id, project.name, checked)
              }
              disabled={qrCodeShown}
            />
          </div>
          <div className={styles.imageConatiner}>
            <Image
              src={project.thumbnailUrl || fallbackImageUrl}
              containerClass={styles.imageWrapOuter}
              alt='Property View'
            />
          </div>
          <div className={styles.textWrapper}>
            <p className={styles.propertyName}>{project.name}</p>
            <p className={styles.propertyLocation}>{project.location?.name}</p>
            <ul className={styles.propertySpecs}>
              {project.features.map((feature, index) => (
                <li key={index}>{feature.value}</li>
              ))}
            </ul>

            <p className={styles.propertyPrice}>{`${
              project.priceRange?.min || '10Lac'
            } - ${project.priceRange?.max || '5Cr'}`}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecommendedPropertyCardList;
