import { DOWNLOAD_ICON } from '../../constants/icons';

const PdfDownloadButton = (props) => {
  const { onDownloadBtnClick, isLoading } = props;

  return (
    <button
      className={`btn btn--pdf-download btn--transparent btn--wrap ${
        isLoading && 'click-disabled'
      } ${isLoading && 'loading-spin-container'}`}
      onClick={() => {
        onDownloadBtnClick();
      }}>
      <span className='icon-container '>
        <span className='icon material-icons icon-download'>
          {DOWNLOAD_ICON}
        </span>
        <span className='ic2-fa-spin-blue'></span>
      </span>
    </button>
  );
};

export default PdfDownloadButton;
