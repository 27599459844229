import { ICellRendererParams } from 'ag-grid-community';

import styles from './styles.module.css';

interface IToggleButtonCell {
  rowData: ICellRendererParams;
}

const ToggleButtonCell = (props: IToggleButtonCell) => {
  const { rowData } = props;

  const handleToggleButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //TODO: Backend API  Will be called here
  };

  return (
    <label className={styles.switch}>
      <input
        type='checkbox'
        defaultChecked={rowData.data.available}
        onChange={handleToggleButtonChange}
      />
      <span className={`${styles.slider} ${styles.roundEdges}`}></span>
    </label>
  );
};

export default ToggleButtonCell;
