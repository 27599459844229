import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { isAdminUserLoggedIn } from '../components/pages/AdminLogin/utils';

import SiteVisit from '../components/pages/forms/SiteVisit';

const FormRoutes = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  if (!isAdminUserLoggedIn()) {
    if (!window.location.href.includes('/admin/login?redirectTo=')) {
      history.push(
        `/admin/login?redirectTo=${`${window.location.pathname}${window.location.search}`}`
      );
    }
  }

  return (
    <Switch>
      {isAdminUserLoggedIn() && (
        <Route path={`${path}/site-visit`} component={SiteVisit} />
      )}
    </Switch>
  );
};

export default FormRoutes;
