import { useHistory } from 'react-router-dom';
import { URLS } from '../../../../../../../../constants/urls';

import { IProject } from '../..';

import IconWithLabel from '../../../../../common/IconWithLabel';

interface IProjectAnalyticsDashboardCell {
  project: IProject;
  disabled: boolean;
}

const ProjectAnalyticsDashboardCell = (
  props: IProjectAnalyticsDashboardCell
) => {
  const { project, disabled } = props;

  const history = useHistory();

  const openAnalyticsIconClickHandler = () => {
    history.push(URLS.ANALYTICS_DASHBOARD, { project });
  };

  return (
    <>
      <IconWithLabel
        iconName='equalizer'
        label='Analytics'
        disabled={disabled}
        onClick={() => openAnalyticsIconClickHandler()}
      />
    </>
  );
};

export default ProjectAnalyticsDashboardCell;
