import { useDispatch } from 'react-redux';
import { updateSearchString } from '../../../slices/leadsSearchSlice';
import Icon from '../../../../../components/common/Icon';
import TextInput from '../../../../../components/common/inputs/TextInput';

const ActiveLeadsHeader = () => {
  const dispatch = useDispatch();
  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateSearchString(e.target.value));
  };
  return (
    <div className='active-leads-filter-container'>
      <div className='legend'>
        These are the leads that are actively engaging with your booking form.
        Leads are also marked as hot or cold as per the legend on the right
      </div>
      <div className='vertical-line-divider'></div>
      <div className='indicators'>
        <div className='indicator-title'>
          <span className=''>Hot</span>
          <span className=''>Leads</span>
        </div>
        <div className='line-connector'></div>
        <div className='hot-indicator'>
          <Icon name='whatshot' />
        </div>
        <div className='line-connector'></div>
        <div className='warm-indicator'>
          <Icon name='thermostat' />
        </div>
        <div className='line-connector'></div>
        <div className='room-indicator'>
          <Icon name='ac_unit' />
        </div>
        <div className='line-connector'></div>
        <div className='cold-indicator'>
          <Icon name='ac_unit' />
        </div>

        <div className='line-connector'></div>
        <div className='indicator-title'>
          <span className=''>Cold</span>
          <span className=''>Leads</span>
        </div>
      </div>
      <div className='vertical-line-divider'></div>
      <div className='filter-panel'>
        <div className='leads-search-box'>
          <TextInput
            startIconName='search'
            startIconVariant='SECONDARY'
            placeHolder='Search Project'
            onChange={onSearchInputChange}
          />
        </div>
        <span className='filter-title'>Filter</span>
        <Icon name='filter_alt' propStyles='filter-icon' />
      </div>
    </div>
  );
};
export default ActiveLeadsHeader;
