import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ICpUserDashboardStats } from '../../../../interfaces';

import styles from './styles.module.css';

interface IEarningsCardProps {
  cpUserDashboardStats: ICpUserDashboardStats | undefined;
  loading: boolean;
}

const EarningsCard = (props: IEarningsCardProps) => {
  const { cpUserDashboardStats, loading = false } = props;

  // TODO: Use generic percentage function
  const progressPercentage = Math.round(
    ((cpUserDashboardStats?.assignedSiteVisits || 0) /
      (cpUserDashboardStats?.allSiteVisits || 1)) *
      100
  );

  if (!cpUserDashboardStats || loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.earningsWrap}>
        <div className={styles.earnValues}>
          <p className={styles.headTitle}>Site Visits tagged to you</p>

          <p className={styles.value}>
            {cpUserDashboardStats?.assignedSiteVisits} Visits
          </p>
          <p className={styles.subText}>
            Out of <strong>{cpUserDashboardStats?.allSiteVisits}</strong>
            &nbsp; total visits
          </p>
        </div>
        <div className={styles.progressWrap}>
          <CircularProgressbarWithChildren
            styles={buildStyles({
              strokeLinecap: 'butt',
              pathColor: '#796DCC',
              trailColor: 'transparent',
              textColor: '#2B2A2A',
            })}
            value={progressPercentage}>
            <span className={styles.valuesBold}>{progressPercentage}%</span>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </div>
  );
};

export default EarningsCard;
