import { useState } from 'react';

import KycApplicantFrom from './KycApplicantFrom';

import { AddKycApplicant } from './AddKycApplicant';

import { useDispatch, useSelector } from 'react-redux';
import {
  getUnitCart,
  setUnitCart,
} from '../../apps/booking/slices/selectedUnit';
import { useUpdateUnitCartMutation } from '../../api/unitCart';
import { isEmpty } from '../../utils/utils';

const CoApplicants = (props) => {
  const { updateIsEditFormOpenStatus } = props;

  const dispatch = useDispatch();

  const [newApplicant, setNewApplicant] = useState();
  const unitCart = useSelector(getUnitCart);
  const { applicants: coApplicants } = unitCart;
  const [updateUnitCartAPI] = useUpdateUnitCartMutation();

  const coApplicantsWithId = coApplicants.map((coApplicant, index) => {
    // Server does not set id for co-applicant, set co-applicant id as it's used in KycApplicantForm
    const coApplicantWithId = JSON.parse(JSON.stringify(coApplicant));
    coApplicantWithId.id = `${coApplicantWithId.fname}-${index}`;
    return coApplicantWithId;
  });

  const updateApplicantOnServer = async (applicants) => {
    const unitCartBody = {
      id: unitCart.id,
      applicants,
    };
    const { data: unitCartdata, error: unitCartError } =
      await updateUnitCartAPI({ ...unitCartBody });

    if (unitCartError) {
      console.error('unitCartError', unitCartError);
      return false;
    }

    if (!isEmpty(unitCartdata)) {
      dispatch(setUnitCart(unitCartdata));
      // TODO: Update unit status or fetch the latest unit details
    }
  };

  const handleDelete = (index = -1, applicantId) => {
    if (index >= 0) {
      // Delete existing applicant - applicant exists on server
      // Please keep same relative sequence of applicants
      let updatedCoApplicants = [
        ...coApplicantsWithId.slice(0, index),
        ...coApplicantsWithId.slice(index + 1),
      ];
      updateApplicantOnServer(updatedCoApplicants);
    } else {
      // Delete new applicant - applicant yet not created on server
      setNewApplicant(null);
    }
    updateIsEditFormOpenStatus(applicantId, false);
  };

  const handleCreate = (applicant) => {
    setNewApplicant(applicant);
  };

  const handleSave = async (applicant, index) => {
    let updatedCoApplicants = [];
    if (index >= 0) {
      // Replace co-applicant with updated co-applicant data
      updatedCoApplicants = [
        ...coApplicantsWithId.slice(0, index),
        applicant,
        ...coApplicantsWithId.slice(index + 1),
      ];
    } else {
      // Append new co-applicant
      updatedCoApplicants = [...coApplicantsWithId, applicant];
    }

    updateApplicantOnServer(updatedCoApplicants);
    setNewApplicant(null);
  };

  return (
    <>
      {coApplicantsWithId.map((applicant, index) => (
        <KycApplicantFrom
          key={applicant.id}
          applicant={applicant}
          handleDelete={() => handleDelete(index, applicant.id)}
          handleSave={(modifiedApplicant) =>
            handleSave(modifiedApplicant, index)
          }
          isEditFormOpenByDefault={false}
          updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
        />
      ))}
      {newApplicant && (
        <KycApplicantFrom
          key={newApplicant.id}
          applicant={newApplicant}
          handleDelete={() => handleDelete(-1, newApplicant.id)}
          handleSave={(modifiedApplicant) => handleSave(modifiedApplicant, -1)}
          isEditFormOpenByDefault={true}
          updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
        />
      )}
      <AddKycApplicant handleCreate={handleCreate} />
    </>
  );
};

export default CoApplicants;
