import { useSelector } from 'react-redux';

import {
  getContentHeaderNavState,
  getSelectedTower,
  CONTENT_HEADER_NAV_STATE,
} from '../../../apps/booking/slices/contentHeader/contentHeaderNav';

const ContentHeaderTitle = (props) => {
  const { showFilterPreference } = props;
  const contentHeaderNavState = useSelector(getContentHeaderNavState);
  const tower = useSelector(getSelectedTower);

  let title = 'Embassy Edge Master Plan';
  if (showFilterPreference) {
    title = 'Preferences';
  } else if (contentHeaderNavState === CONTENT_HEADER_NAV_STATE.TOWER) {
    title = `Block ${tower}`;
  }
  return (
    <>
      <div className='content-header__title-plate'>
        <h1 className='content-header__title'>
          You are viewing{' '}
          <span className='font-accent-bold text-capitalize'>{title}</span>
        </h1>
        <p className='content-header__description'>
          Select the unit you want to view on the floor map. Click on it to view
          details & availability
        </p>
      </div>
    </>
  );
};

export default ContentHeaderTitle;
