import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useLazyGetUnitcartListQuery } from '../../../../api/unitCart';
import { URLS } from '../../../../constants/urls';
import { getUser } from '../../slices/auth';

const DashboardNavItem = () => {
  const user = useSelector(getUser);
  const [getUnitcartListAPI, { data: unitCarts = [] }] =
    useLazyGetUnitcartListQuery();

  useEffect(() => {
    if (user?.id) {
      getUnitcartListAPI(user.id);
    }
  }, [user, getUnitcartListAPI]);

  return (
    <li
      className={`nav__item 
        ${
          !(user?.id && unitCarts && unitCarts.length > 0) &&
          'nav__item--disabled'
        }`}>
      <NavLink to={URLS.DASHBOARD_BASE}>Dashboard</NavLink>
    </li>
  );
};

export default DashboardNavItem;
