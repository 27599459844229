import { useState } from 'react';
import Icon from '../../../../../components/common/Icon';

import styles from './styles.module.css';

interface IImageModelProps {
  imageUrls: string[];
  selectedImageUrl?: string;
  onClose: () => void;
}

const ImageModel = (props: IImageModelProps) => {
  const { imageUrls, selectedImageUrl, onClose } = props;

  const [imageUrlToShow, setImageUrlToShow] = useState(
    selectedImageUrl || imageUrls[0]
  );

  const handleNext = () => {
    const currentIndex = imageUrls.indexOf(imageUrlToShow);

    if (currentIndex === imageUrls.length - 1) {
      return;
    }

    const nextImageUrl = imageUrls[currentIndex + 1];
    setImageUrlToShow(nextImageUrl);
  };

  const handlePrev = () => {
    const currentIndex = imageUrls.indexOf(imageUrlToShow);

    if (currentIndex <= 0) {
      return;
    }

    const nextImageUrl = imageUrls[currentIndex - 1];
    setImageUrlToShow(nextImageUrl);
  };

  return (
    <div className={styles.lightboxWrapper}>
      <div className={styles.bigImage}>
        {/* TODO: change <img> to reuseable <Image> component  */}
        {/* TODO: <Image className={styles.lightboxImg} src={imageUrlToShow} /> */}
        <img className={styles.lightboxImg} src={imageUrlToShow} />
        <div className={styles.closeIconWrap} onClick={onClose}>
          <Icon name='close' propStyles={styles.closeIcon} />
        </div>
        <div className={styles.arrowLeft} onClick={handlePrev}>
          <Icon name='navigate_before' propStyles={styles.previousIcon} />
        </div>
        <div className={styles.arrowRight} onClick={handleNext}>
          <Icon name='navigate_next' propStyles={styles.nextIcon} />
        </div>
      </div>
    </div>
  );
};

export default ImageModel;
