import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from '../../../../../../../../components/common/modal/Modal';
import Icon from '../../../../../../../../components/common/Icon/index';
import TextInput from '../../../../../../../../components/common/inputs/TextInput/index';
import Button from '../../../../../../../../components/common/buttons/Button';
import { userSchema } from '../AddCompanyUserModal';
import { IUser } from '../../../../../../interfaces';
import { useUpdateAdminUserMutation } from '../../../../../../services/adminUserAPISlice';
import useToast from '../../../../../../../../hooks/useToast';
import { capitalizeFirstLetter } from '../../../../../../../../utils/utils';

import styles from '../AddCompanyUserModal/styles.module.css';

interface IUpdateUserModal {
  onModalClose: Function;
  user: IUser;
  onUserUpdate: (updatedUser: IUser) => void;
}

const UpdateUserModal = (props: IUpdateUserModal) => {
  const { onModalClose, user, onUserUpdate } = props;

  const { name, mobile, email } = user;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [updateUserAPI] = useUpdateAdminUserMutation();
  const [addToast] = useToast();

  const handleUpdateAdminDetailsFormSubmit = async (userData: {
    email: string;
    name: string;
    mobile: string;
  }) => {
    try {
      const updateUser = await updateUserAPI({
        userId: user.id as string,
        user: userData,
      }).unwrap();

      onUserUpdate(updateUser.data);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated User',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }

    onModalClose(false);
  };

  return (
    // Styles here are being used from CreateNewAdminModal's CSS file as there is same styling for two modals and to follow DRY principle
    <Modal
      onOutsideClick={() => onModalClose(false)}
      additionalClasses={styles.createNewAdminModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeaderHeading}>
            Update {capitalizeFirstLetter(user.role.toLowerCase())} Details
          </h2>
          <p className={styles.modalHeaderDes}>
            Edit the following info to update{' '}
            {capitalizeFirstLetter(user.role.toLowerCase())} details.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.closeIcon}
          onClick={() => {
            onModalClose(false);
          }}
        />
      </header>

      <form onSubmit={handleSubmit(handleUpdateAdminDetailsFormSubmit)}>
        <main>
          <TextInput
            label={`${capitalizeFirstLetter(user.role.toLowerCase())} EmailID`}
            placeHolder={`Add Email of the ${capitalizeFirstLetter(
              user.role.toLowerCase()
            )}`}
            defaultValue={email}
            register={register}
            setValue={setValue}
            name='email'
            errorMessage={errors.email && 'Email is required'}
          />
          <div className={styles.twoTextInputsRowContainer}>
            <div className={styles.addNameInput}>
              <TextInput
                label='Full Name'
                placeHolder='Add Name'
                defaultValue={name}
                register={register}
                setValue={setValue}
                name='name'
                errorMessage={errors.name && 'Name is required'}
              />
            </div>
            <div className={styles.addNameInput}>
              <TextInput
                label='Mobile Number'
                placeHolder='Add Mobile'
                defaultValue={mobile}
                register={register}
                setValue={setValue}
                name='mobile'
                errorMessage={errors.mobile && 'Mobile number is required'}
              />
            </div>
          </div>
        </main>

        <footer className={styles.modalFooter}>
          <p className={styles.footerDisclaimer}>
            By adding {capitalizeFirstLetter(user.role.toLowerCase())}, an
            autogenerated email will be sent to the{' '}
            {capitalizeFirstLetter(user.role.toLowerCase())} with their randomly
            generated password and URL for the dashboard. Do this step the last,
            just before going for UAT.
          </p>
          <Button
            propStyles={styles.footerAddAdminButton}
            accent='primary'
            type='submit'>
            Update Details
          </Button>
        </footer>
      </form>
    </Modal>
  );
};

export default UpdateUserModal;
