import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  edgeLogo,
  embassyLogo,
  profileFallback,
  URLS,
} from '../../constants/urls';
import { getUser } from '../../apps/booking/slices/auth';
import {
  getCostSheet,
  getUnitCart,
} from '../../apps/booking/slices/selectedUnit';

import {
  ordinalSuffixOf,
  toIndianRupee,
  rankToWordMapping,
} from '../../utils/utils';

const AgreementPlank = (props) => {
  const { editAllowed } = props;

  const history = useHistory();

  const unitCart = useSelector(getUnitCart) || {};
  const user = useSelector(getUser);
  const selectedUnit = unitCart.unit;
  const costSheet = useSelector(getCostSheet);

  const applicantDetails = [
    {
      'First Name': `${user.title} ${user.fname}`,
      'Middle Name': user.mname || 'sachin aglave',
      'Last Name': user.lname,
      'Email Id': user.email,
      'Mobile Number': user.mobile_no,
      DOB: user.dob,
      "Farther's Name": user.fathers_name,
      'Applicant Type': user.type,
      'Educational Qualification': user.educational_qualification,
      Profession: user.profession,
      'Name of the Company': user.company_name,
      Designation: user.designation,
      'Marrital Status': user.marriage_status,
    },
  ];

  applicantDetails.concat(
    unitCart.applicants.map((applicant) => ({
      'First Name': `${applicant.title} ${applicant.fname}`,
      'Middle Name': applicant.mname || 'sachin aglave',
      'Last Name': applicant.lname,
      'Email Id': applicant.email,
      'Mobile Number': applicant.mobile_no,
      DOB: applicant.dob,
      "Farther's Name": applicant.fathers_name,
      'Applicant Type': applicant.type,
      'Educational Qualification': applicant.educational_qualification,
      Profession: applicant.profession,
      'Name of the Company': applicant.company_name,
      Designation: applicant.designation,
      'Marrital Status': applicant.marriage_status,
    }))
  );

  const addresses = [
    {
      'Street / House No': {
        cname: 'agreement-applicant__body-cell--full-width',
        value: user.primary_address?.line_1,
      },
      Landmark: { cname: '', value: user.primary_address.landmark },
      Country: { cname: '', value: user.primary_address.country },
      City: { cname: '', value: user.primary_address.city },
      State: { cname: '', value: user.primary_address.state },
      Pincode: { cname: '', value: user.primary_address.pincode },
    },
    {
      'Street / House No': {
        cname: 'agreement-applicant__body-cell--full-width',
        value: user.correspondence_address.line_1,
      },
      Landmark: { cname: '', value: user.correspondence_address.landmark },
      Country: { cname: '', value: user.correspondence_address.country },
      City: { cname: '', value: user.correspondence_address.city },
      State: { cname: '', value: user.correspondence_address.state },
      Pincode: { cname: '', value: user.correspondence_address.pincode },
    },
  ];

  return (
    <div className='agreement-plank'>
      <header className='agreement__header'>
        <div className='agreement__header-left'>
          <span>
            Unit{' '}
            <span className='f-bold'>{`${selectedUnit.unit_group} ${selectedUnit.block}`}</span>
          </span>
          <span>
            Project <span className='f-bold'>Embassy Edge</span>
          </span>
          <span>
            Promoter <span className='f-bold'>NAM Estates Private Limited</span>
          </span>
        </div>
        <div className='agreement__header-right'>
          <span className='agreement__header-logo-container'>
            <img src={edgeLogo} alt='Edge Logo' />
            <img src={embassyLogo} alt='Embassy Logo' />
          </span>
          <span className='f-bold'>PR/KN/18049/002674</span>
          <span>RERA Acknowledgement No.</span>
        </div>
      </header>
      <section className='agreement__section'>
        <div className='agreement__section-title'>
          <span className='c-accent f-18'>
            Applicants, Details of Apartment & Total Sale Consideration
          </span>
          <div className='agreement__section-title-dot-line'></div>
        </div>
        <div className='agreement__applicant-names'>
          {[]
            .concat(user)
            .concat(unitCart.applicants)
            .map((item) => (
              <div className='agreement__applicant-names-item'>
                <div className='thumb'>
                  <img className='img-fluid' src={profileFallback} alt='' />
                </div>
                <div className='agreement__applicant-name-container'>
                  <span className='agreement__applicant-type'>
                    {item.type === 'primary'
                      ? 'Primary Applicant'
                      : 'Co-Applicant'}
                  </span>
                  <span className='agreement__applicant-name'>{`${item.fname} ${item.lname}`}</span>
                </div>
              </div>
            ))}
        </div>
        <div className='agreement-unit-details'>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable'>
              {selectedUnit.unit_group}{' '}
              {`${selectedUnit.block}${selectedUnit.number}`}
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              Embassy Edge
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              (Unit Number & Project)
            </span>
          </div>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable'>
              {selectedUnit.unit_group} {selectedUnit.block}
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              Tower & Block
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              (on 2/3rd of total value)
            </span>
          </div>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable'>
              {selectedUnit.area.carpet} Sq.Ft
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              Carpet Area
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              (Area of unit per sq.ft)
            </span>
          </div>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable'>
              {selectedUnit.area.super_build_up} Sq.Ft
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              Super Built up Area
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              {`(${selectedUnit.unit_group} ${selectedUnit.block}, ${selectedUnit.type})`}
            </span>
          </div>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable'>
              {toIndianRupee(selectedUnit.area.super_build_up)}
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              Base Price per Sq.ft
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              (Cost of Footprint/sq.ft)
            </span>
          </div>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable'>
              {toIndianRupee(costSheet.floor_rise)}
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              Floor Rise
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              (As per {ordinalSuffixOf(selectedUnit.floor_number)} Floor
              Selection)
            </span>
          </div>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable'>
              {toIndianRupee(
                parseFloat(costSheet.total_additional_amount) +
                  parseFloat(costSheet.tax_amount_on_additional)
              )}
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              Additionals
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              (As per {ordinalSuffixOf(selectedUnit.floor_number)} Floor
              Selection)
            </span>
          </div>
          <div className='agreement-unit-details-cell'>
            <span className='agreement-unit-details-cell__value-lable c-accent'>
              {toIndianRupee(costSheet.total_inclusive_amount)}
            </span>
            <span className='agreement-unit-details-cell__title-lable'>
              All Inclusive Total
            </span>
            <span className='agreement-unit-details-cell__desc-lable'>
              (excluding registration charges)
            </span>
          </div>
        </div>
      </section>
      <section className='agreement__section'>
        <div className='agreement__section-title'>
          <span className='c-accent f-18'>Details of Project</span>
          <div className='agreement__section-title-dot-line'></div>
        </div>
        <div className='agreement-project-details'>
          <div className='agreement-project-details-row'>
            <div className='agreement-project-details-cell'>
              <span className='agreement-project-details-cell__title-lable'>
                Project
              </span>
              <span className='agreement-project-details-cell__value-lable'>
                Apartments
              </span>
            </div>
            <div className='agreement-project-details-cell'>
              <span className='agreement-project-details-cell__title-lable'>
                Sanction of Plan By
              </span>
              <span className='agreement-project-details-cell__value-lable'>
                BIAAPA
              </span>
            </div>
            <div className='agreement-project-details-cell'>
              <span className='agreement-project-details-cell__title-lable'>
                Plan Sanction/ LP No's
              </span>
              <span className='agreement-project-details-cell__value-lable'>
                BIAAPA/TP/01/CC/02/2017-18 dated 26.10.2017
              </span>
            </div>
          </div>
          <div className='agreement-project-details-row'>
            <div className='agreement-project-details-cell'>
              <span className='agreement-project-details-cell__title-lable'>
                Plan Approval No.
              </span>
              <span className='agreement-project-details-cell__value-lable'>
                BIAAPA/TP/01/CC/02/2017-18 dated 26.10.2017
              </span>
            </div>
            <div className='agreement-project-details-cell'>
              <span className='agreement-project-details-cell__title-lable'>
                Website Under Real Estate(Development & Regulation)Act, 2016
              </span>
              <span className='agreement-project-details-cell__value-lable'>
                <a
                  href='http://embassyedge.com/'
                  target='_blank'
                  rel='noreferrer'>
                  http://embassyedge.com/
                </a>
              </span>
            </div>
          </div>
          <div className='agreement-project-details-row'>
            <div className='agreement-project-details-cell'>
              <span className='agreement-project-details-cell__title-lable'>
                Land
              </span>
              <span className='agreement-project-details-cell__value-lable'>
                Sy. No. 67/287 measuring 4.24 Acres situated at Navarathna
                Agrahara Village, Jala Hobli, Banglore North Taluk, Sy. No. 72/2
                measuring 2.20 Acres and Sy. No. 72/3 measuring 1033 Acress both
                situated at Hegganahalli village, Kasaba Hobli, Devanahalli
                Taluk.
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className='agreement__section'>
        <div className='agreement__section-title'>
          <span className='c-accent f-18'>Details of Applicant</span>
          <div className='agreement__section-title-dot-line'></div>
          {editAllowed && (
            <button
              type='button'
              className={'btn agreement__edit-btn btn--accent'}
              onClick={() => {
                history.push(URLS.KYC);
              }}>
              Edit Applicants
            </button>
          )}
        </div>

        {applicantDetails.map((applicant, index) => (
          <>
            <div className='agreement-applicant'>
              <div className='agreement-applicant__title'>
                {index === 0
                  ? 'Primary Applicant'
                  : `${rankToWordMapping[index]} Co-Applicant`}
              </div>
              <div className='agreement-applicant__body'>
                {Object.keys(applicant).map((applicantKey) => (
                  <div className='agreement-applicant__body-cell'>
                    <span className='agreement-applicant__body-cell-lable'>
                      {applicantKey}
                    </span>
                    <span className='agreement-applicant__body-cell-value'>
                      {applicant[applicantKey]}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        ))}
      </section>
      <section className='agreement__section'>
        <div className='agreement__section-title'>
          <span className='c-accent f-18'>Address</span>
          <div className='agreement__section-title-dot-line'></div>
          {editAllowed && (
            <button
              type='button'
              className={'btn agreement__edit-btn btn--accent'}
              onClick={() => {
                history.push(URLS.KYC_DETAILS);
              }}>
              Edit Address
            </button>
          )}
        </div>

        {addresses.map((addresse, index) => (
          <>
            <div className='agreement-applicant'>
              <div className='agreement-applicant__title'>
                {index === 0 ? 'Permanent Address' : 'Correspondence Address'}
              </div>
              <div className='agreement-applicant__body'>
                {Object.keys(addresse).map((addresseKey) => (
                  <div
                    className={`agreement-applicant__body-cell ${addresse[addresseKey].cname}`}>
                    <span className='agreement-applicant__body-cell-lable'>
                      {addresseKey}
                    </span>
                    <span className='agreement-applicant__body-cell-value'>
                      {addresse[addresseKey].value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        ))}
      </section>
    </div>
  );
};

export default AgreementPlank;
