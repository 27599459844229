import * as yup from 'yup';

// Email Validation with Yup
export const emailValidationSchema = yup.object().shape({
  value: yup.string().email().required(),
});

export const mobileValidationSchema = yup.object().shape({
  value: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
});
