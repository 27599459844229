import Icon, { IconNames } from '../../../../../../components/common/Icon';
import { ICpUserDashboardStats } from '../../../../interfaces';
import HorizontalSlider from '../../../common/HorizontalSlider';

import styles from './styles.module.css';

interface IPerformanceCardListProps {
  cpUserDashboardStats: ICpUserDashboardStats | undefined;
  loading: boolean;
}

const PerformanceCardList = (props: IPerformanceCardListProps) => {
  const { cpUserDashboardStats, loading = false } = props;

  const performanceItems = [
    {
      icon: 'laptop',
      title: 'Registered Leads',
      performanceCount: cpUserDashboardStats?.cpLeads,
      performanceCountSuffix: 'Leads',
      completedText: (
        <>
          Across &nbsp;
          {<strong>{cpUserDashboardStats?.assignedProjects}&nbsp;</strong>}{' '}
          projects
        </>
      ),
      bgColor: styles['green'],
    },
    {
      icon: 'whatshot',
      title: 'Leads tagged to me',
      performanceCount: cpUserDashboardStats?.assignedLeads,
      performanceCountSuffix: 'Leads',
      completedText: (
        <>
          Of &nbsp;
          {<strong>{cpUserDashboardStats?.cpLeads}&nbsp;</strong>} registered
          leads
        </>
      ),
      bgColor: styles['orange'],
    },
    // TODO: Calculation is wrong here
    // {
    //   icon: 'laptop',
    //   title: 'Site Visit Rate',
    //   performanceCount: cpUserDashboardStats?.siteVisitLeads,
    //   performanceCountSuffix: 'Done',
    //   completedText: (
    //     <>
    //       Of &nbsp;
    //       {<strong>{cpUserDashboardStats?.cpLeads}&nbsp;</strong>} registered
    //       leads
    //     </>
    //   ),
    //   bgColor: styles['safforn'],
    // },
    {
      icon: 'whatshot',
      title: 'Lead Tagged ratio',
      performanceCount: `${
        // TODO: Use generic percentage function
        Math.round(
          ((cpUserDashboardStats?.assignedLeads || 0) /
            (cpUserDashboardStats?.cpLeads || 1)) *
            100
        )
      }%`,
      performanceCountSuffix: 'Leads',
      completedText: (
        <>
          {/* {
            <strong>
              {`${Math.round(
                ((cpUserDashboardStats?.assignedProjectLeads || 0) /
                  (cpUserDashboardStats?.siteVisitLeads || 1)) *
                  100
              )}%`}
              &nbsp;
            </strong>
          }{' '}
          of visits tagged */}
        </>
      ),
      bgColor: styles['green'],
    },
  ];

  if (!cpUserDashboardStats || loading) {
    return <div>Loading...</div>;
  }

  return (
    <HorizontalSlider>
      <>
        {performanceItems.map((performanceItem) => (
          <div key={performanceItem.title} className={styles.performanceCard}>
            <Icon
              name={performanceItem.icon as IconNames}
              propStyles={
                styles.performanceIcon + ' ' + performanceItem.bgColor
              }
            />
            <p className={styles.performanceTitle}>{performanceItem.title}</p>
            <p className={styles.performanceType}>
              <span className={styles.performanceCount}>
                {performanceItem.performanceCount}&nbsp;
              </span>
              {performanceItem.performanceCountSuffix}
            </p>
            <p className={styles.completedTask}>
              {performanceItem.completedText}
            </p>
          </div>
        ))}
      </>
    </HorizontalSlider>
  );
};

export default PerformanceCardList;
