import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MasterSVG from './MasterSVG';
import TowerSVG from './TowerSVG';
import {
  getSelectedTower,
  getSelectedSection,
  getSelectedBlock,
  getContentHeaderNavState,
} from '../../apps/booking/slices/contentHeader/contentHeaderNav';
import {
  setSelectedUnit,
  setUnitCart,
} from '../../apps/booking/slices/selectedUnit';

function UnitSelection() {
  const dispatch = useDispatch();
  const navState = useSelector(getContentHeaderNavState);
  const selectedTower = useSelector(getSelectedTower);
  const selectedSection = useSelector(getSelectedSection);
  const selectedBlock = useSelector(getSelectedBlock);

  useEffect(() => {
    dispatch(setSelectedUnit({}));
    dispatch(setUnitCart({}));
  }, [dispatch]);

  return (
    <div className='unit-selection'>
      {navState === 'MASTER' ? (
        <MasterSVG name='MASTER' />
      ) : (
        <TowerSVG
          selectedTower={selectedTower}
          selectedSection={selectedSection}
          selectedBlock={selectedBlock}
        />
      )}
      <div className='svg-path-color-labels'>
        <span className='svg-path-color-labels__item'>
          <div className='svg-path-color-labels__item-color-indicator svg-path-color-labels__item-color-indicator--available'></div>
          <span className='svg-path-color-labels__item-label'>Available</span>
        </span>
        <span className='svg-path-color-labels__item'>
          <div className='svg-path-color-labels__item-color-indicator svg-path-color-labels__item-color-indicator--filtered'></div>
          <span className='svg-path-color-labels__item-label'>Filtered</span>
        </span>
      </div>
    </div>
  );
}

export default UnitSelection;
