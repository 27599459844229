import Icon from '../Icon';
import { Summary } from './Interface';

const SummaryCompoent = (props: Summary) => {
  const {
    titleIcon,
    title,
    mainData,
    changeData,
    changeDataClass,
    changeDesc,
  } = props;
  return (
    <div className='summary-component'>
      <div className='summary-component__header'>
        <Icon name={titleIcon} />
        <span>{title}</span>
      </div>
      <div className='summary-component__body'>{mainData}</div>
      <div className='summary-component__footer'>
        <span className={changeDataClass}>{changeData}</span>
        <span> {changeDesc}</span>
      </div>
    </div>
  );
};

export default SummaryCompoent;
