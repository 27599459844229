// Components
import CpProfilePendingLeadApprovalGrid from './CpLeadRequestGrid';

// Styles
// import styles from './styles.module.css';

const CpLeadRequests = () => {
  return (
    <>
      <main>
        <CpProfilePendingLeadApprovalGrid />
      </main>
    </>
  );
};

export default CpLeadRequests;
