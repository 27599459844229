import { floorPlanFallback } from '../../../constants/urls';
import Image from '../Image';
import { UsersListType } from './Interface';

const UserPhotosList = (props: UsersListType) => {
  const { users, showNumberOfTiles } = props;
  const tilesWithPhoto = users.slice(0, showNumberOfTiles - 1);
  const showMore = users.length > showNumberOfTiles;
  return (
    <>
      <div className='users-photos-list-card'>
        {tilesWithPhoto.map((user) => (
          <div
            className='users-photos-list-card__image-container'
            key={user.id}>
            <Image
              imgClass='users-photos-list-card__image'
              src={user.imgUrl}
              alt='Executive Details'
              fallbackSrc={floorPlanFallback}
            />
          </div>
        ))}
        {users.length === showNumberOfTiles ? (
          <div
            className='users-photos-list-card__image-container'
            key={Math.random()}>
            <Image
              imgClass='users-photos-list-card__image'
              src={users[showNumberOfTiles - 1].imgUrl}
              alt='Executive Details'
              fallbackSrc={floorPlanFallback}
            />
          </div>
        ) : null}
        {showMore ? (
          <div
            className='users-photos-list-card__image-container'
            key={Math.random()}>
            <Image
              imgClass='users-photos-list-card__image users-photos-list-card__show-more'
              src={users[showNumberOfTiles - 1].imgUrl}
              alt='Executive Details'
              fallbackSrc={floorPlanFallback}
            />
            <span className='more-tiles-count'>
              {'+'}
              {users.length - showNumberOfTiles}
            </span>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default UserPhotosList;
