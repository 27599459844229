import { useEffect, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import styles from './styles.module.css';

interface IMultiSelectOptionsProps {
  label?: string;
  options: string[];
  defaultValue?: string[];
  setValue?: Function;
  errorMessage?: string;
  onChange?: (selectedOptions: string[]) => {};

  register?: Function;
  unregister?: Function;
  name?: string;
  clearErrors?: Function;
  validate?: Function;
}

const MultiSelectOptions = (props: IMultiSelectOptionsProps) => {
  const {
    label,
    options: _options,
    defaultValue,
    setValue,
    errorMessage,
    onChange,

    register,
    unregister,
    name,
    clearErrors,
    validate,
  } = props;

  const [options, setOptions] = useState(
    _options.map((optionName, index) => ({
      id: index,
      name: optionName,
      selected: defaultValue?.includes(optionName),
    }))
  );

  useEffect(() => {
    return () => {
      unregister && unregister(name);
    };
  }, [unregister, name]);

  useEffect(() => {
    setValue && setValue(name, []);
  }, [setValue]);

  useEffect(() => {
    register && register(name, { [validate ? 'validate' : '']: validate });
  }, [register, name, validate]);

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div className={styles.filterWrap}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`${styles.option} ${option.selected && styles.active}`}
            onClick={() => {
              const thisElmIndex = options.findIndex((e) => e.id == option.id);
              options[thisElmIndex].selected = !options[thisElmIndex].selected;

              const optionsNew = [...options];
              setOptions(optionsNew);

              const selectedOptions = optionsNew
                .filter((option) => option.selected)
                .map((option) => option.name);

              setValue && setValue(name, selectedOptions);
              onChange && onChange(selectedOptions);

              /*
              Error evaluation is done using yup schema.
              Manually trigger clearErrors to clear the errors with validate function.
              */
              if (validate && validate(selectedOptions)) {
                clearErrors && clearErrors(name);
              }
            }}>
            {option.name}
          </div>
        ))}
      </div>
      <AnimatePresence>
        {errorMessage && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${styles.inputFieldError}`}>
            {errorMessage}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MultiSelectOptions;
