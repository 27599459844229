import {
  Summary,
  UsersListType,
} from '../../../../../components/common/admin/Interface';
import SummaryCompoent from '../../../../../components/common/admin/SummaryCompoent';
import UserNamesList from '../../../../../components/common/admin/UserNamesList';
import UserPhotosList from '../../../../../components/common/admin/UserPhotosList';

export interface LeadsStats {
  liveSince: Summary;
  remainingUnits: Summary;
  registeredUser: Summary;
  projectName: string;
  brandName: string;
  LeadsCardsData: UsersListType;
}

const LeadsDashboardHeader = (props: LeadsStats) => {
  const {
    liveSince,
    remainingUnits,
    registeredUser,
    projectName,
    brandName,
    LeadsCardsData,
  } = props;
  return (
    <>
      <div className='leads-dashboard-header'>
        <div className='names-container'>
          <div className='project-name'>{projectName}</div>
          <div className='brand-name'>{brandName}</div>
          <div className='leads-list'>
            <UserPhotosList {...LeadsCardsData} />
            <div className='leads-names-container'>
              <div className='lead-title'>Lead </div>
              <div className='leads-data'>
                <UserNamesList {...LeadsCardsData} />
              </div>
            </div>
          </div>
        </div>
        <div className='data-container'>
          <SummaryCompoent {...liveSince} />
          <SummaryCompoent {...remainingUnits} />
          <SummaryCompoent {...registeredUser} />
        </div>
      </div>
    </>
  );
};
export default LeadsDashboardHeader;
