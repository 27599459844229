export const constructFilterPreferences = (unitList = []) => {
  const filterPreferences = {};
  const preferenceTypes = {
    type: {
      key: 'type',
      name: 'Bedrooms',
      type: 'LIST',
      items: [],
      defaultItem: {
        type: 'LIST',
        name: '1 BHK',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
    view: {
      key: 'view',
      name: 'Unit View',
      type: 'LIST',
      items: [],
      defaultItem: {
        type: 'LIST',
        name: 'North',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
    facing: {
      key: 'facing',
      name: 'Unit Facing',
      type: 'LIST',
      items: [],
      defaultItem: {
        type: 'LIST',
        name: 'Courtyard Facing',
        isSelected: false,
        availableUnitCount: 0,
        unitIds: [],
      },
    },
    final_payable_amount: {
      key: 'final_payable_amount',
      name: 'Price Range',
      type: 'PRICE',
      items: [
        {
          type: 'PRICE',
          from: 0,
          to: 0.5,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 0.5,
          to: 1,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 1,
          to: 2,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 2,
          to: 5,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'PRICE',
          from: 5,
          to: 10,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
      ],
      defaultItem: {},
    },
    area: {
      key: 'area',
      name: 'Built Up Area',
      type: 'AREA',
      items: [
        {
          type: 'AREA',
          from: 0,
          to: 3000,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'AREA',
          from: 3000,
          to: 6000,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
        {
          type: 'AREA',
          from: 6000,
          to: 30000,
          isSelected: false,
          availableUnitCount: 0,
          unitIds: [],
        },
      ],
      defaultItem: {},
    },
  };

  unitList
    .filter((unit) => ['AVAILABLE', 'OUT-OF-PREFERENCE'].includes(unit.status))
    .forEach((unit) => {
      Object.values(preferenceTypes).forEach((preferenceType) => {
        const type = preferenceType.key;

        if (!filterPreferences[type]) {
          filterPreferences[type] = JSON.parse(JSON.stringify(preferenceType));
        }

        // TODO: Bad code
        let itemIndex;
        if (preferenceType.type === 'PRICE') {
          itemIndex = filterPreferences[type].items.findIndex(
            (item) =>
              item.from <= unit.cost_sheet.total_inclusive_amount / 10000000 &&
              item.to >= unit.cost_sheet.total_inclusive_amount / 10000000
          );
        } else if (preferenceType.type === 'AREA') {
          itemIndex = filterPreferences[type].items.findIndex(
            (item) =>
              item.from <= unit[type].super_build_up &&
              item.to >= unit[type].super_build_up
          );
        } else if (preferenceType.key === 'type') {
          // Monky patching - Bedrooms
          itemIndex = filterPreferences[type].items.findIndex(
            (item) => item.name === unit[type].split('-')[0]
          );
        } else {
          itemIndex = filterPreferences[type].items.findIndex(
            (item) => item.name === unit[type]
          );
        }

        if (itemIndex === -1) {
          const { defaultItem } = filterPreferences[type];
          const defaultItemNew = JSON.parse(JSON.stringify(defaultItem));
          defaultItemNew.name = unit[type];
          // Monky patching - Bedrooms
          if (type === 'type') {
            defaultItemNew.name = unit[type].split('-')[0];
          }
          filterPreferences[type].items.push(defaultItemNew);
          itemIndex = filterPreferences[type].items.length - 1;
        }
        filterPreferences[type].items[itemIndex].availableUnitCount++;
        filterPreferences[type].items[itemIndex].unitIds.push(unit.id);
      });
    });

  return filterPreferences;
};

export const getConfigDetailsFromPath = (configPath) => {
  if (!configPath) {
    return {};
  }
  let unitPath = configPath.replace('a-', '').split('-x-');
  const tower = unitPath[0];
  const section = unitPath[1];
  const block = unitPath[2].replace('block-', '');
  const unitNumber = unitPath.length >= 4 ? unitPath[3].split('-')[0] : '101';

  return { tower, section, block, unitNumber };
};

export const computeSelectedPreferences = (preferenceContainers) => {
  const appliedFilters = {};

  Object.values(preferenceContainers).forEach((preferenceContainer) => {
    appliedFilters[preferenceContainer.key] = [];
    preferenceContainer.items.forEach((item) => {
      if (item.isSelected) {
        if (
          ['area', 'final_payable_amount'].includes(preferenceContainer.key)
        ) {
          appliedFilters[preferenceContainer.key].push({
            from: item.from,
            to: item.to,
          });
        } else {
          appliedFilters[preferenceContainer.key].push(item.name);
        }
      }
    });
  });
  return appliedFilters;
};

export const floorPlanFileName = (unit) => {
  const fileName =
    unit?.type.replaceAll(' ', '').toLowerCase().replace('.', '-x-point-x-') +
    '.jpg';
  return fileName;
};
