import { createRef, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';
import Icon, { IconNames } from '../../Icon';

import styles from './styles.module.css';

interface ITextInput {
  label?: string;
  placeHolder: string;
  errorMessage?: string;
  defaultValue?: string;
  resetValue?: boolean;
  showSuccess?: boolean;
  crApp?: boolean;
  onChange?: Function;
  onBlur?: Function;
  type?:
    | 'text'
    | 'number'
    | 'email'
    | 'tel'
    | 'date'
    | 'datetime-local'
    | 'password';
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  // TODO: Add proper types to literals related to useFormic
  unregister?: Function;
  register?: Function;
  name?: string;
  validate?: Function;
  setValue?: Function;
  defaultBottomMessage?: string;
  startIconVariant?: 'PRIMARY' | 'SECONDARY';
  startIconName?: IconNames;
  startIconText?: string;
  endIconName?: IconNames;
  onEndIconClick?: Function;
  autoFocus?: boolean;
  inputHTMLElementStyles?: string;
  propStyles?: string;
}

const TextInput = (props: ITextInput) => {
  const {
    label,
    placeHolder,
    errorMessage = '',
    defaultValue = '',
    resetValue = false,
    showSuccess = false,
    onChange,
    crApp = false,
    onBlur,
    type = 'text',
    disabled = false,
    readOnly = false,
    maxLength,
    unregister,
    register = () => {
      return {
        name,
        ref: createRef(),
      };
    },
    name = '',
    validate,
    setValue,
    defaultBottomMessage = '',
    startIconVariant = 'PRIMARY',
    startIconName = '',
    endIconName = '',
    startIconText = '',
    onEndIconClick,
    autoFocus = false,
    inputHTMLElementStyles,
    propStyles,
  } = props;

  // TODO: Implement generic form registration
  const {
    onChange: onChangeForm,
    onBlur: onBlurForm,
    name: nameForm = name,
    ref: refForm = createRef(),
  } = register(name, {
    [validate ? 'validate' : '']: validate,
  });

  useEffect(() => {
    setValue && setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    return () => {
      // Anything in here is fired on component unmount.
      unregister && unregister(name);
    };
  }, [unregister, name]);

  useEffect(() => {
    if (resetValue) {
      refForm.current.value = '';
      setValue &&
        setValue(name, refForm.current.value, { shouldValidate: true });
    }
  }, [resetValue]);

  const handleInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    onChangeForm && onChangeForm(event);

    if (maxLength) {
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
        setValue &&
          setValue(name, event.target.value, { shouldValidate: true });
      }
    }
  };

  const HandleInputOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    onBlurForm && onBlurForm(event);
  };
  return (
    <div
      className={`${styles.inputContainer} ${
        crApp && styles.crApp
      } ${propStyles}`}>
      {label && (
        <label htmlFor={label} className={styles.inputLable}>
          {label}
        </label>
      )}
      <div
        className={`${styles.inputBox} ${
          crApp && disabled && styles.cpDisabled
        } ${disabled && styles.disabledBg}`}>
        <div className={styles.inputBoxLeftContent}>
          {startIconName && (
            <div
              className={`${
                styles[
                  `${startIconVariant.toLocaleLowerCase()}StartIconContainer`
                ]
              } ${styles.startIconContainer}`}>
              <span
                className={`icon material-icons  ${
                  styles[`${startIconVariant.toLocaleLowerCase()}StartIcon`]
                }`}>
                {startIconName}
              </span>
            </div>
          )}
          {startIconText && (
            <span
              className={`${styles.startText}  ${
                disabled && styles.disabledBg
              }`}>
              {startIconText}
            </span>
          )}
          <input
            id={label}
            defaultValue={defaultValue}
            name={nameForm}
            ref={refForm}
            className={`${styles.inputField} ${inputHTMLElementStyles}`}
            type={type}
            placeholder={placeHolder}
            onChange={handleInputOnChange}
            onBlur={HandleInputOnBlur}
            disabled={disabled}
            readOnly={readOnly}
            autoFocus={autoFocus}
            maxLength={maxLength}
          />
          {endIconName && (
            <Icon
              name={endIconName}
              propStyles={`${styles.endIcon}`}
              onClick={() => {
                onEndIconClick && onEndIconClick();
              }}
            />
          )}
        </div>
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                default: { duration: 0.4 },
              }}>
              <Icon
                name={showSuccess ? 'check' : 'error_outline'}
                propStyles={`disabled ${styles.errorIcon} ${
                  showSuccess ? styles.successIcon : ''
                }`}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {defaultBottomMessage !== '' && (
        <p className={styles.defaultBottomMessage}>{defaultBottomMessage}</p>
      )}
      <AnimatePresence>
        {errorMessage && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`${styles.inputFieldError}`}>
            {errorMessage}
          </motion.p>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TextInput;
