import { useState } from 'react';

import ConfirmationModal, {
  IConfirmationModal,
} from '../../../../../../../../components/common/genericModals/ConfirmationModal';
import Icon from '../../../../../../../../components/common/Icon';
import useToast from '../../../../../../../../hooks/useToast';
import { useDeleteProjectExecutiveMutation } from '../../../../../../services/projectsAPISlice';
import { IProject } from '../../../../SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

export interface IRemoveProjectExecutiveCell {
  executiveWithDemoRoomId: string;
  projectId: string;
  onExecutiveDelete: (updateProject: IProject) => void;
  disabled: boolean;
}

const RemoveProjectExecutiveCell = (props: IRemoveProjectExecutiveCell) => {
  const { projectId, executiveWithDemoRoomId, onExecutiveDelete, disabled } =
    props;

  const [confirmBlockProject, setConfirmBlockProject] =
    useState<IConfirmationModal>();

  const [deleteProjectExecutive] = useDeleteProjectExecutiveMutation();
  const [addToast] = useToast();

  const handleDelete = () => {
    setConfirmBlockProject({
      accent: 'DANGER',
      heading: 'Do you want to DELETE the executive from the project?',
      onModalClose: () => setConfirmBlockProject(undefined),
      onResponse: async (confirmationResponse: any) => {
        try {
          await confirmationResponse;

          const updatedProject = await deleteProjectExecutive({
            projectId: projectId,
            executiveWithDemoRoomId,
          }).unwrap();

          onExecutiveDelete(updatedProject.data);

          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Removed executive from project',
            secondaryMessage: '',
          });
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
            secondaryMessage: 'Failed to remove executive from project',
          });
        }
      },
    });
  };

  return (
    <>
      {confirmBlockProject && <ConfirmationModal {...confirmBlockProject} />}
      <Icon
        name='highlight_off'
        propStyles={`${styles.actionIcon} ${
          disabled && 'click-disabled--icon'
        } `}
        onClick={() => handleDelete()}
      />
    </>
  );
};

export default RemoveProjectExecutiveCell;
