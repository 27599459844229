import { getAdminUserRole } from '../../pages/AdminLogin/utils';
import AdminSidebarItem from './AdminSidebarItem';
import { IAdminSidebarItem } from './AdminSidebarItem';
import { UserRole } from '../../../../../constants/userRoles';

const superUserAdminSidebarItems: IAdminSidebarItem[] = [
  {
    redirectPath: '/admin/super-admin',
    labelText: 'Super Admin Dashboard',
    icon: 'home_work',
    additionClasses: 'admin-sidebar-item--enabled',
  },
  {
    redirectPath: '/admin/analytics-dashboard',
    labelText: 'Project Analytics',
    icon: 'equalizer',
    additionClasses: 'admin-sidebar-item--enabled',
  },
  {
    redirectPath: '/admin/super-admin-organizations-list',
    labelText: 'Super Admin Company List',
    icon: 'domain',
    additionClasses: 'admin-sidebar-item--enabled',
  },
  // {
  //   redirectPath: '/admin/inventory-management',
  //   labelText: 'Inventory Management',
  //   icon: 'device_hub', // TODO: Add correct icon as per design file
  //   additionClasses: 'admin-sidebar-item--enabled',
  // },
  {
    redirectPath: '/admin/cp-list',
    labelText: 'Active CP List',
    icon: 'people', // TODO: Add correct icon as per design file
    additionClasses: 'admin-sidebar-item--enabled',
  },
  // {
  //   redirectPath: '/admin/cp-profile',
  //   labelText: 'Active CP List',
  //   icon: 'people', // TODO: Add correct icon as per design file
  //   additionClasses: 'admin-sidebar-item--enabled',
  // },
  {
    redirectPath: '/admin/executive/leads',
    labelText: 'Active CP List',
    icon: 'people', // TODO: Add correct icon as per design file
    additionClasses: 'admin-sidebar-item--enabled',
  },
];

const adminUserAdminSidebarItems: IAdminSidebarItem[] = [
  {
    redirectPath: '/admin/admin-dashboard-projects',
    labelText: 'Project List',
    icon: 'home_work',
    additionClasses: 'admin-sidebar-item--enabled',
  },
  {
    redirectPath: '/admin/analytics-dashboard',
    labelText: 'Project Analytics',
    icon: 'equalizer',
    additionClasses: 'admin-sidebar-item--enabled',
  },
  {
    redirectPath: '/admin/admin-dashboard',
    labelText: 'Admin Dashboard',
    icon: 'people',
    additionClasses: 'admin-sidebar-item--enabled',
  },
  {
    redirectPath: '/admin/active-cp-list',
    labelText: 'Active CP List',
    icon: 'people', // TODO: Add correct icon as per design file
    additionClasses: 'admin-sidebar-item--enabled',
  },
];

const executiveUserAdminSidebarItems: IAdminSidebarItem[] = [
  {
    redirectPath: '/admin/analytics-dashboard',
    labelText: 'Project Analytics',
    icon: 'equalizer',
    additionClasses: 'admin-sidebar-item--enabled',
  },
];

const sideBarMenuOptionsByUserRole: { [key: string]: IAdminSidebarItem[] } = {
  [UserRole.SUPERADMIN]: superUserAdminSidebarItems,
  [UserRole.ADMIN]: adminUserAdminSidebarItems,
  [UserRole.EXECUTIVE]: executiveUserAdminSidebarItems,
};

const AdminSidebar = () => {
  const sidebarSteps: IAdminSidebarItem[] =
    sideBarMenuOptionsByUserRole[getAdminUserRole()] || [];

  return (
    <div className='admin-sidebar'>
      {sidebarSteps.map((item) => (
        <AdminSidebarItem
          key={item.redirectPath as string}
          redirectPath={item.redirectPath}
          labelText={item.labelText}
          desc={item.desc}
          icon={item.icon}
          additionClasses={item.additionClasses}
        />
      ))}
    </div>
  );
};

export default AdminSidebar;
