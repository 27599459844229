// Components
import CpLeadRequestsHeader from './CpLeadRequestsHeader';
import CpLeadRequestGrid from './CpLeadRequestGrid';

// Styles
import styles from './styles.module.css';

const CpLeadRequests = () => {
  return (
    <>
      <header className={styles.headerContainer}>
        <CpLeadRequestsHeader />
      </header>

      <main>
        <CpLeadRequestGrid />
      </main>
    </>
  );
};

export default CpLeadRequests;
