import { useState } from 'react';

import styles from './styles.module.css';

interface ICheckboxProps {
  isChecked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}

const Checkbox = (props: ICheckboxProps) => {
  const { isChecked, disabled, onChange } = props;

  const [checked, setChecked] = useState(isChecked);

  const handleCheckBoxChange = () => {
    onChange(!checked);
    setChecked(!checked);
  };
  return (
    <label>
      <svg
        className={`${styles.checkbox} ${checked && styles.checkboxActive}`}
        aria-hidden='true'
        viewBox='0 0 15 11'
        fill='none'>
        <path
          d='M1 4.5L5 9L14 1'
          strokeWidth='1'
          stroke={checked ? '#fff' : 'none'} // only show the checkmark when `isCheck` is `true`
        />
      </svg>
      <input
        type='checkbox'
        onChange={handleCheckBoxChange}
        disabled={disabled}
      />
    </label>
  );
};

export default Checkbox;
