export const URLS = {
  // Booking engine
  BOOKING_BASE: '/booking',
  UNIT_SELECTION: '/booking/unit-selection',
  UNIT_DETAILS: '/booking/unit-details',
  COST_SHEET: '/booking/cost-sheet',
  PAYMENT_PLAN: '/booking/payment-plan',
  KYC: '/booking/kyc',
  PAYMENT: '/booking/payment',
  KYC_DETAILS: '/booking/kyc-details',
  AGREEMENT: '/booking/agreement',

  // Dashboard
  DASHBOARD_BASE: '/dashboard',

  // Admin Login page
  ADMIN_LOGIN: '/admin/login',

  // Project analytics page
  PROJECT_ANALYTICS: '/admin/analytics',
  ANALYTICS_DASHBOARD: '/admin/analytics-dashboard',

  // Site Visit form's login with params
  ADMIN_LOGIN_REDIRECTION_TO_SITE_VISIT_FORM:
    '/admin/login?user-from-site-visit-form=true',

  // Site Visit form
  SITE_VISIT_FORM: '/forms/site-visit',

  // User role based dashboards
  SUPERADMIN: '/admin/super-admin',
  ADMIN: '/admin/admin-dashboard-projects',
  EXECUTIVE: '/admin/executive',
};

const serverByAppEnv = {
  local: 'http://127.0.0.1:3000/',
  development: 'https://api.dev.app.relata.io',
  staging: 'https://api.staging.app.relata.io',
  production: 'https://api.app.relata.io',
};

const appEnv = process.env.REACT_APP_ENV;

if (!Object.keys(serverByAppEnv).includes(appEnv))
  console.error('REACT_APP_ENV not declared');

// export const baseUrl = 'https://api.relata.cognilements.com/';

export const awsBaseUrl = 'https://relata.s3.ap-south-1.amazonaws.com';
export const baseUrl = serverByAppEnv[appEnv] || serverByAppEnv['local'];

export const userProfileDefaultImage = 'https://relata.s3.ap-south-1.amazonaws.com/cpApp/public/user1.jpg'

export const fallbackImageUrl = 'https://picsum.photos/seed/picsum/500/250';

export const floorPlanFallback =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/floor-plan-fallback.jpg';

// Embassy
export const refreshTokenEmbassyUrl =
  'https://test.salesforce.com/services/oauth2/token?grant_type=refresh_token&refresh_token=5Aep8613wlbvVohodL0zmG9OdXn2bFeyRYSi8e7Mg1p2N05YyPkaAR5YdUNaJKMbYY5IxvhU_WpgZ..LWY_XY5B&client_id=3MVG9Gdzj3taRxuNZGYlwtt.EvruuoQJVX4ykpt3kSqTFCGrWP_gEWQThbjvEbIxMOsayLSlmGaPPafa1LX75&client_secret=31ABF63DAEB0E10194153E314F0CFD7EE089FEBD53D59938A6A7DA6116CC8676';

export const insertLeadEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com//services/apexrest/embassyOne/LeadFlows';

export const getCostDetailsEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com/services/apexrest/embassyOne/CBU/';

export const blockUnitEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com/services/apexrest/EmbassyOne/leadProcesses';

// User account
export const updateUserEmbassyUrl =
  'https://embassygroup--uatrelease.my.salesforce.com/services/apexrest/embassyOne/PAP/';

export const profileFallback =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/kyc-profile-fallback.png';

export const dashboardHeadingBannerUrl =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/login-background.jpg';

export const edgeLogo =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/embassy-primary-logo.svg';
export const embassyLogo =
  'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/embassy.png';
