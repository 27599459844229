import { createSlice } from '@reduxjs/toolkit';

import { computeSelectedPreferences } from '../../../../components/unitSelection/utils';
import { NOT_FOUND } from '../../../../constants/status';
export const CONTENT_HEADER_NAV_STATE = {
  MASTER: 'MASTER',
  TOWER: 'TOWER',
};

export const DEFAULT_SELECTED_TOWER = 'tower';
export const DEFAULT_SELECTED_SECTION = 'section-1-12';
export const DEFAULT_SELECTED_BLOCK = 'block';

const updateUnitStatusByAppliedFilters = (
  selectedPreferences,
  filterPreferences,
  isSelected,
  key,
  unitListById
) => {
  Object.values(filterPreferences)
    .filter(
      (preferenceContainer) => !isSelected || preferenceContainer.key !== key
    )
    .forEach((preferenceContainer) => {
      preferenceContainer.items.forEach((item) => {
        item.unitIds.forEach((unitId) => {
          let isFilteredOut = true;

          // TODO: Make following check dynamic
          // Monky patching - Bedrooms
          if (
            (selectedPreferences.type.length === 0 ||
              selectedPreferences.type.includes(
                unitListById[unitId].type.split('-')[0]
              )) &&
            (selectedPreferences.view.length === 0 ||
              selectedPreferences.view.includes(unitListById[unitId].view)) &&
            (selectedPreferences.facing.length === 0 ||
              selectedPreferences.facing.includes(
                unitListById[unitId].facing
              )) &&
            (selectedPreferences.final_payable_amount.length === 0 ||
              selectedPreferences.final_payable_amount.some(
                (amountFilter) =>
                  unitListById[unitId].cost_sheet.total_inclusive_amount /
                    10000000 >=
                    amountFilter.from &&
                  unitListById[unitId].cost_sheet.total_inclusive_amount /
                    10000000 <=
                    amountFilter.to
              )) &&
            (selectedPreferences.area.length === 0 ||
              selectedPreferences.area.some(
                (areaFilter) =>
                  unitListById[unitId].area.super_build_up >= areaFilter.from &&
                  unitListById[unitId].area.super_build_up <= areaFilter.to
              ))
          ) {
            isFilteredOut = false;
          }

          if (isFilteredOut) {
            if (
              unitListById[unitId].status === 'AVAILABLE' &&
              unitListById[unitId].published_on_portal === true
            ) {
              item.availableUnitCount--;
            }
            unitListById[unitId].status = 'OUT-OF-PREFERENCE';
          } else {
            if (unitListById[unitId].status === 'OUT-OF-PREFERENCE') {
              item.availableUnitCount++;
            }
            unitListById[unitId].status = 'AVAILABLE';
          }
        });
      });
    });
};

const updateSectionBlockDropdownOptionsAvailableUnitsCnt = (
  unitSelectionNavMenu,
  unitListById
) => {
  // Update options if needed
  // Update available unit count for section and block
  const tower = unitSelectionNavMenu.unit_group.selectedValue;
  const selectedSection = unitSelectionNavMenu.section.selectedValue;
  const selectedBlock = unitSelectionNavMenu.block.selectedValue;
  const isBlockSelected =
    unitSelectionNavMenu.block.selectedValue !== DEFAULT_SELECTED_BLOCK;
  const updateBlockOptions = () => {
    // Create block optinos according to unitListById.
    // where as we are not creating section option because section options
    // are hardcoded.
    const blockList = Object.values(unitListById)
      .filter((unit) => unit.unit_group.toLowerCase() === tower)
      .map((unit) => unit.block)
      .filter((block, index, array) => array.indexOf(block) === index);
    unitSelectionNavMenu.block.options = blockList.map((block) => {
      return {
        value: `block-${block.toLowerCase()}`,
        text: `Block ${block}`,
        availableUnitCount: 0,
      };
    });
  };
  const updateOptionUnitCount = () => {
    // Re Initialise availableUnitCount to Zero
    unitSelectionNavMenu.section.options.forEach((option) => {
      option.availableUnitCount = 0;
    });
    unitSelectionNavMenu.block.options.forEach((option) => {
      option.availableUnitCount = 0;
    });

    // Update section's available unit count
    Object.values(unitListById)
      .filter(
        (unit) =>
          unit.unit_group.toLowerCase() === tower &&
          unit.status === 'AVAILABLE' &&
          unit.published_on_portal === true &&
          (!isBlockSelected ||
            `block-${unit.block.toLowerCase()}` === selectedBlock)
      )
      .forEach((unit) => {
        unitSelectionNavMenu.section.options.find(
          (option) =>
            option.value ===
            (unit.floor_number <= 12 ? 'section-1-12' : 'section-13-14')
        ).availableUnitCount++;
      });

    // Update block's available unit count
    Object.values(unitListById)
      .filter(
        (unit) =>
          unit.unit_group.toLowerCase() === tower &&
          unit.status === 'AVAILABLE' &&
          unit.published_on_portal === true &&
          ((selectedSection === 'section-1-12' && unit.floor_number <= 12) ||
            (selectedSection === 'section-13-14' && unit.floor_number > 12))
      )
      .forEach((unit) => {
        unitSelectionNavMenu.block.options.find(
          (option) => option.value === `block-${unit.block.toLowerCase()}`
        ).availableUnitCount++;
      });
  };

  updateBlockOptions();
  updateOptionUnitCount();
};

const updateTowerDropdownOptionsAvailableUnitsCnt = (
  unitSelectionNavMenu,
  unitListById
) => {
  const updateTowerOptions = () => {
    // Create tower optinos according to unitListById.
    const towerList = Object.values(unitListById)
      .map((unit) => unit.unit_group)
      .filter(
        (unit_group, index, array) => array.indexOf(unit_group) === index
      );
    unitSelectionNavMenu.unit_group.options = towerList.map((tower) => {
      return {
        value: tower.toLowerCase(),
        text: tower,
        availableUnitCount: 0,
      };
    });
  };

  const updateTowerOptionUnitCount = () => {
    Object.values(unitListById)
      .filter(
        (unit) =>
          unit.status === 'AVAILABLE' && unit.published_on_portal === true
      )
      .forEach((unit) => {
        unitSelectionNavMenu.unit_group.options.find(
          (option) => option.value === unit.unit_group.toLowerCase()
        ).availableUnitCount++;
      });
  };

  updateTowerOptions();
  updateTowerOptionUnitCount();
};

export const contentHeaderNavSlice = createSlice({
  name: 'contentHeaderNav',
  initialState: {
    contentHeaderNavState: CONTENT_HEADER_NAV_STATE.MASTER,

    unitListById: {},
    hierarchyUnitList: {},
    selectedUnitConfig: '',

    unitSelectionNavMenu: {
      unit_group: {
        key: 'unit_group',
        fallBackText: 'Tower',
        defaultSelectedValue: DEFAULT_SELECTED_TOWER,
        selectedValue: DEFAULT_SELECTED_TOWER,
        options: [
          { value: 'daisy', text: 'Daisy', availableUnitCount: 0 },
          { value: 'elderberry', text: 'Elderberry', availableUnitCount: 0 },
          { value: 'fuchsia', text: 'Fuschia', availableUnitCount: 0 },
          { value: 'hibisicus', text: 'Hibisicus', availableUnitCount: 0 },
          { value: 'gardenia', text: 'Gardenia', availableUnitCount: 0 },
        ],
      },
      section: {
        key: 'section',
        fallBackText: 'Floor 1-12',
        defaultSelectedValue: DEFAULT_SELECTED_SECTION,
        selectedValue: DEFAULT_SELECTED_SECTION,
        options: [
          { value: 'section-1-12', text: 'Floor 1-12', availableUnitCount: 0 },
          {
            value: 'section-13-14',
            text: 'Floor 13-14',
            availableUnitCount: 0,
          },
        ],
      },
      block: {
        key: 'block',
        fallBackText: 'Block',
        defaultSelectedValue: DEFAULT_SELECTED_BLOCK,
        selectedValue: DEFAULT_SELECTED_BLOCK,
        options: [],
      },
    },
    selectedPreferences: {},
    filterPreferences: {},
  },
  reducers: {
    // Unit
    setHierarchyUnitList: (state, action) => {
      state.hierarchyUnitList = action.payload;
    },
    setUnitListById: (state, action) => {
      state.unitListById = action.payload;

      updateTowerDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },
    setSelectedUnitConfig: (state, action) => {
      state.selectedUnitConfig = action.payload;
    },

    // Unit Selection Nav Menu
    setContentHeaderNavState: (state, action) => {
      state.contentHeaderNavState = action.payload;

      // Set default values
      if (state.contentHeaderNavState === CONTENT_HEADER_NAV_STATE.MASTER) {
        for (const type in state.unitSelectionNavMenu) {
          state.unitSelectionNavMenu[type].selectedValue =
            state.unitSelectionNavMenu[type].defaultSelectedValue;
        }
      }
    },

    updateUnitSelectionNavMenu: (state, action) => {
      updateTowerDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },

    // - Tower
    setSelectedTower: (state, action) => {
      state.contentHeaderNavState = CONTENT_HEADER_NAV_STATE.TOWER;

      state.unitSelectionNavMenu.unit_group.selectedValue = action.payload;
      state.unitSelectionNavMenu.section.selectedValue =
        DEFAULT_SELECTED_SECTION;
      state.unitSelectionNavMenu.block.selectedValue = DEFAULT_SELECTED_BLOCK;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },

    // - Section
    setSelectedSection: (state, action) => {
      state.unitSelectionNavMenu.section.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },

    // - Block
    setSelectedBlock: (state, action) => {
      state.unitSelectionNavMenu.block.selectedValue = action.payload;

      updateSectionBlockDropdownOptionsAvailableUnitsCnt(
        state.unitSelectionNavMenu,
        state.unitListById
      );
    },

    // Filter
    setFilterPreferences: (state, action) => {
      state.filterPreferences = action.payload;
    },
    toggleFilterPreferenceItem: (state, action) => {
      const { key, itemIndex } = action.payload;
      state.filterPreferences[key].items[itemIndex].isSelected =
        !state.filterPreferences[key].items[itemIndex].isSelected;

      const { isSelected } = state.filterPreferences[key].items[itemIndex];

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        isSelected,
        key,
        state.unitListById
      );
    },
    clearFilterPreferences: (state) => {
      Object.values(state.filterPreferences).forEach((preference) =>
        Object.values(preference.items).forEach(
          (item) => (item.isSelected = false)
        )
      );

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        false,
        null,
        state.unitListById
      );
    },
    resetFilterPreferenceType: (state, action) => {
      const preferenceType = action.payload;
      Object.values(state.filterPreferences[preferenceType].items).forEach(
        (item) => (item.isSelected = false)
      );

      state.selectedPreferences = computeSelectedPreferences(
        state.filterPreferences
      );
      const { selectedPreferences } = state;
      updateUnitStatusByAppliedFilters(
        selectedPreferences,
        state.filterPreferences,
        false,
        preferenceType,
        state.unitListById
      );
    },
  },
});

export const {
  // Unit
  setHierarchyUnitList,
  setUnitListById,
  setSelectedUnitConfig,

  // Unit Selection Nav Menu
  setContentHeaderNavState,
  setSelectedTower,
  setSelectedSection,
  setSelectedBlock,
  updateUnitSelectionNavMenu,

  // Filter
  setFilterPreferences,
  toggleFilterPreferenceItem,
  clearFilterPreferences,
  resetFilterPreferenceType,
} = contentHeaderNavSlice.actions;

// Unit
export const getHierarchyUnitList = (state) =>
  state.contentHeaderNav.hierarchyUnitList;
export const getUnitListById = (state) => state.contentHeaderNav.unitListById;
export const getSelectedUnitConfig = (state) =>
  state.contentHeaderNav.selectedUnitConfig;

// Unit Selection Nav Menu
export const getContentHeaderNavState = (state) =>
  state.contentHeaderNav.contentHeaderNavState;
export const getUnitSelectionNavMenu = (state) =>
  state.contentHeaderNav.unitSelectionNavMenu;

export const getTowerBtnState = (state) =>
  state.contentHeaderNav.unitSelectionNavMenu.unit_group;
export const getSelectedTower = (state) => {
  return state.contentHeaderNav.unitSelectionNavMenu.unit_group.selectedValue;
};
export const getSelectedTowerAvailableUnitCount = (state) => {
  const selectedTower = getSelectedTower(state);
  const selectedOption =
    state.contentHeaderNav.unitSelectionNavMenu.unit_group.options.find(
      (option) => option.value === selectedTower
    );

  return selectedOption ? selectedOption.availableUnitCount : NOT_FOUND;
};

export const getSectionBtnState = (state) =>
  state.contentHeaderNav.unitSelectionNavMenu.section;
export const getSelectedSection = (state) =>
  state.contentHeaderNav.unitSelectionNavMenu.section.selectedValue;
export const getSelectedSectionAvailableUnitCount = (state) => {
  const selectedSection = getSelectedSection(state);
  const selectedOption =
    state.contentHeaderNav.unitSelectionNavMenu.section.options.find(
      (option) => option.value === selectedSection
    );

  return selectedOption ? selectedOption.availableUnitCount : NOT_FOUND;
};

export const getBlockBtnState = (state) =>
  state.contentHeaderNav.unitSelectionNavMenu.block;
export const getSelectedBlock = (state) =>
  state.contentHeaderNav.unitSelectionNavMenu.block.selectedValue;
export const getSelectedBlockAvailableUnitCount = (state) => {
  const selectedBlock = getSelectedBlock(state);
  const selectedOption =
    state.contentHeaderNav.unitSelectionNavMenu.block.options.find(
      (option) => option.value === selectedBlock
    );

  return selectedOption ? selectedOption.availableUnitCount : NOT_FOUND;
};
// Filter
export const getFilterPreferences = (state) =>
  state.contentHeaderNav.filterPreferences;
export const getSelectedPreferences = (state) =>
  state.contentHeaderNav.selectedPreferences;
