import { useState } from 'react';

import Modal from '../../../../../../../components/common/modal/Modal';
import AddNewProjectModal from '../modals/AddNewProjectModal';
import Button from '../../../../../../../components/common/buttons/Button';
import Icon from '../../../../../../../components/common/Icon';
import TextInput from '../../../../../../../components/common/inputs/TextInput';

import styles from './styles.module.css';
import genericStyles from '../../../../../../../styles/genericStyles.module.css';

interface ISuperAdminDashboardHeader {
  onSearch: Function;
}

const SuperAdminProjectListHeader = (props: ISuperAdminDashboardHeader) => {
  const { onSearch } = props;

  const [showAddNewProjectModal, setShowAddNewProjectModal] = useState(false);

  // New Project successfully added Modal states
  const [
    showNewProjectSuccessfullyAddedModal,
    setShowNewProjectSuccessfullyAddedModal,
  ] = useState(false);
  const [projectData, setProjectData] = useState({
    projectName: '',
    CompanyName: '',
    projectId: '',
    projectCity: '',
  });

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //Should pass the value through props for search
    onSearch(event.target.value);
  };

  const handleAddProjectExecutiveModalClose = () => {
    setShowAddNewProjectModal(false);
  };

  const onProjectAddedSuccessfully = (projectData: {
    projectName: string;
    CompanyName: string;
    projectId: string;
    projectCity: string;
  }) => {
    setShowNewProjectSuccessfullyAddedModal(true);
    setProjectData({
      projectName: projectData.projectName,
      CompanyName: projectData.CompanyName,
      projectId: projectData.projectId,
      projectCity: projectData.projectCity,
    });
  };

  return (
    <>
      {/* TODO */}
      {showAddNewProjectModal && (
        <AddNewProjectModal
          showProjectAddedModal={onProjectAddedSuccessfully}
          handleModalClose={handleAddProjectExecutiveModalClose}
        />
      )}

      {/* New Project successfully added Modal */}
      <Modal
        show={showNewProjectSuccessfullyAddedModal}
        onOutsideClick={() => {
          setShowNewProjectSuccessfullyAddedModal(false);
        }}
        additionalClasses={
          styles.projectAddedSuccessfullyModalAdditionalClasses
        }>
        <header className={styles.projectAddedModalHeader}>
          <div className={styles.projectAddedModalHeaderTitleContainer}>
            <div className={styles.projectAddedModalHeading}>
              A New Project!
            </div>
            <Icon
              name='close'
              propStyles={styles.modalCloseIcon}
              onClick={() => {
                setShowNewProjectSuccessfullyAddedModal(false);
              }}
            />
          </div>
          <p className={styles.projectAddedModalDesc}>
            Here you will find all the info you need to drive your scheduled
            demo. Best of luck!
          </p>
        </header>
        <main className={styles.projectAddedModalBody}>
          <Icon name='task_alt' propStyles={styles.successIcon} />
          <div className={styles.projectDetails}>
            <div>
              <p className={styles.projectLabel}>Project</p>
              <p className={styles.projectName}>{projectData.projectName}</p>
              <p className={styles.developerName}>
                by {projectData.CompanyName}
              </p>
            </div>
            <div>
              <div className={styles.individualProjectDetail}>
                <div>
                  <p className={styles.projectIdLabel}>Project ID</p>
                  <p className={styles.projectIdDesc}>for further reference</p>
                </div>
                <h2 className={styles.projectId}>{projectData.projectId}</h2>
              </div>
              <div className={styles.individualProjectDetail}>
                <div>
                  <p className={styles.projectIdLabel}>Location</p>
                  <p className={styles.projectIdDesc}>
                    The city of the Project
                  </p>
                </div>
                <h2 className={`${styles.projectId} ${styles.projectLocation}`}>
                  {projectData.projectCity}
                </h2>
              </div>
            </div>
          </div>
        </main>
        <footer className={styles.projectAddedModalFooter}>
          <div
            className={genericStyles.pointer}
            onClick={() => {
              navigator.clipboard.writeText(`${projectData.projectId}`);
            }}>
            <Icon name='content_copy' propStyles={styles.copyProjectIdIcon} />
            <p className={styles.copyButtonText}>Copy Project ID</p>
          </div>
        </footer>
      </Modal>
      {/*End of New Project successfully added Modal */}

      <header className={styles.superAdminProjectListHeader}>
        {/* Header Left Part */}
        <div>
          <h2 className={styles.dashboardHeading}>Projects Dashboard</h2>
          <p className={styles.dashboardDesc}>
            All Projects
            <span className={styles.boldTextForDashboardDesc}>
              Under One Dashboard
            </span>
          </p>

          {/* Header Left Part Search*/}
          <div className={styles.searchContainer}>
            <TextInput
              type='text'
              inputHTMLElementStyles={styles.searchInput}
              placeHolder='Search Name'
              onChange={onInputChange}
              startIconName='search'
              startIconVariant='SECONDARY'
            />
            {/* <Icon name='search' propStyles={styles.searchIcon} /> */}
          </div>
        </div>

        {/* Header Right Part */}
        <div className={styles.dashboardButtonsContainer}>
          <Button
            propStyles={styles.createNewProjetButton}
            onClick={() => {
              window.open(
                `${window.location.origin}/vsgGenerator/index.html?edit=true`,
                '_blank'
              );
            }}>
            VSG Editor
          </Button>
          <Button
            onClick={() => {
              /*TODO */
              setShowAddNewProjectModal(true);
            }}
            propStyles={styles.createNewProjetButton}>
            Create New Project
          </Button>
        </div>
      </header>
    </>
  );
};

export default SuperAdminProjectListHeader;
