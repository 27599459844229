import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from './../../../../../../../components/common/modal/Modal';
import Icon from './../../../../../../../components/common/Icon/index';
import TextInput from '../../../../../../../components/common/inputs/TextInput';
import Button from './../../../../../../../components/common/buttons/Button';

import styles from './styles.module.css';

interface IAddNewCpModal {
  addNewCpModalShown: boolean;
  handelAddNewCpModalClose: Function;
}

interface INewCpFormData {
  name: string;
  email: string;
  mobile: string;
  projectName?: string;
  panNumber?: string;
  rera?: string;
  gst?: string;
  houseNumber?: string;
  city?: string;
  state?: string;
  pincode?: string;
}

// Validation
const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  mobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
  projectName: yup.string(),
  panNumber: yup.string(),
  rera: yup.string(),
  gst: yup.string(),
  houseNumber: yup.string(),
  city: yup.string(),
  state: yup.string(),
  pincode: yup.string(),
});

const AddNewCpModal = (props: IAddNewCpModal) => {
  const { addNewCpModalShown, handelAddNewCpModalClose } = props;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const handleAddNewCpFormSubmit = (formData: INewCpFormData) => {};

  return (
    <Modal
      show={addNewCpModalShown}
      onOutsideClick={handelAddNewCpModalClose}
      additionalClasses={styles.addNewCpModal}>
      <header>
        <div className={styles.headingAndCloseIconContainer}>
          <h3 className={styles.modalHeading}>Add New CP</h3>
          <Icon
            name='close'
            propStyles={styles.closeIcon}
            onClick={() => handelAddNewCpModalClose()}></Icon>
        </div>
        <p className={styles.modalDesc}>
          Fill in the following information to add new cp. Executive will <br />{' '}
          have access to add new CP.
        </p>
      </header>

      <form onSubmit={handleSubmit(handleAddNewCpFormSubmit)}>
        <main>
          <div className={styles.twoParallelTextInputsContainer}>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add Name of the CP'
                label='Name*'
                register={register}
                setValue={setValue}
                name='name'
                errorMessage={errors.name ? 'Name is required' : ''}
              />
            </div>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add email id'
                label='Email Id*'
                register={register}
                setValue={setValue}
                name='email'
                errorMessage={errors.email ? 'Valid Email is required' : ''}
              />
            </div>
          </div>
          <div className={styles.twoParallelTextInputsContainer}>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add phone number'
                label='Phone Number*'
                register={register}
                setValue={setValue}
                name='mobile'
                errorMessage={
                  errors.email ? 'Valid mobile number is required' : ''
                }
              />
            </div>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add project name'
                label='Project Name'
                register={register}
                setValue={setValue}
                name='projectName'
              />
            </div>
          </div>
          <div className={styles.twoParallelTextInputsContainer}>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add PAN Card number'
                label='PAN Card Number'
                register={register}
                setValue={setValue}
                name='panNumber'
              />
            </div>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add rera number'
                label='RERA Number'
                register={register}
                setValue={setValue}
                name='rera'
              />
            </div>
          </div>
          <div className={styles.twoParallelTextInputsContainer}>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add GST number'
                label='GST Number'
                register={register}
                setValue={setValue}
                name='gst'
              />
            </div>
          </div>
          <div className={styles.addressDivider}>
            <h3 className={styles.permanentAddressHeading}>
              Permanent Address
            </h3>
            <span className={styles.dividerLine} />
          </div>
          <div className={styles.twoParallelTextInputsContainer}>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Street/House No, Landmark'
                label='Add Street/House No, Landmark'
                register={register}
                setValue={setValue}
                name='houseNumber'
              />
            </div>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='City'
                label='Add City'
                register={register}
                setValue={setValue}
                name='city'
              />
            </div>
          </div>
          <div className={styles.twoParallelTextInputsContainer}>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add state'
                label='State'
                register={register}
                setValue={setValue}
                name='state'
              />
            </div>
            <div className={styles.smallTextBox}>
              <TextInput
                placeHolder='Add pincode'
                label='Pincode'
                register={register}
                setValue={setValue}
                name='pincode'
              />
            </div>
          </div>
        </main>

        <footer className={styles.modalFooter}>
          <p className={styles.footerDisclaimer}>
            By clicking Apply Discount you are making updates to the cost sheet
            that the user can view. Confirm discount on the Summary on the right
            before clicking on Apply Discount
          </p>
          <div className={styles.addCpBtnContainer}>
            <Button accent='primary' type='submit'>
              Add CP
            </Button>
          </div>
        </footer>
      </form>
    </Modal>
  );
};

export default AddNewCpModal;
