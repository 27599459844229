import { IPriceRange } from '../apps/admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

export const DEFAULT_PROPERTY_CONFIGS = [
  '1 BHK',
  '2 BHK',
  '2 BHK Duplex',
  '3 BHK',
  '3 BHK Duplex',
  '4 BHK +',
];

export const DEFAULT_PROPERTY_PRICE_RANGE: IPriceRange[] = [
  {
    min: 1000000,
    max: 5000000,
  },
  {
    min: 5000001,
    max: 10000000,
  },
  {
    min: 10000001,
    max: 50000000,
  },
  {
    min: 50000001,
    max: 100000000,
  },
];
