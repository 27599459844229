import { useHistory } from 'react-router-dom';

import SendOtp, {
  IAddressDetails,
} from '../../../../../components/common/SendOtp';
import { FORBIDDEN, NOT_FOUND } from '../../../../../constants/status';
import useToast from '../../../../../hooks/useToast';
import { useGetCpUserByMobileMutation } from '../../../services/cpUserAPISlice';

import styles from './styles.module.css';

const projectDetails = {
  projectName: 'Merusri',
  landingPage: {
    desc: 'A better way of helping your clients find their dream home',
    bgImgUrl: 'https://picsum.photos/500/280',
  },
};

const CpLoginPage = () => {
  const history = useHistory();

  const [getCpUserByMobileAPI] = useGetCpUserByMobileMutation();
  const [addToast] = useToast();

  const handleSuccessfulOtpVerification = async (
    addressDetails: IAddressDetails
  ) => {
    try {
      await getCpUserByMobileAPI(addressDetails).unwrap();
      history.push('/cp/kyc/approval-pending');
    } catch (err) {
      const errCode = (err as any).data.code;
      if (errCode === NOT_FOUND) {
        history.push('/cp/kyc/register', { mobile: addressDetails.address });
        return;
      } else if (errCode === FORBIDDEN) {
        const errMsg = (err as any).data.message;
        addToast({
          type: 'ERROR',
          primaryMessage: 'Use other mobile number or Contact admin',
          secondaryMessage: errMsg,
        });
        return;
      }
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  return (
    <div className={styles.container}>
      <header>{projectDetails.projectName} relata.io</header>
      <main>
        <SendOtp
          addressType='MOBILE'
          onSuccessfulOtpVerification={handleSuccessfulOtpVerification}
          crApp
        />
        <p className={styles.termsAndConditions}>
          By clicking continue, you are agreeing to our privacy policy & terms
          of use. More information at &nbsp;
          <a
            className='c-accent'
            href='https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/terms_and_conditions.pdf'
            target='_blank'
            rel='noreferrer'>
            Terms & Conditions.
          </a>
        </p>
      </main>
    </div>
  );
};

export default CpLoginPage;
