import { useState } from 'react';

import Icon from '../../../../../components/common/Icon';
import Modal from '../../../../../components/common/modal/Modal';
import ProjectSelection from '../ProjectSelection';
import { IProject } from '../../pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

interface IProjectNameWithProjectSelection {
  executiveId?: string;
  project: IProject | undefined;
  onProjectSelect: (project: IProject) => void;
}

const ProjectNameWithProjectSelection = (
  props: IProjectNameWithProjectSelection
) => {
  const { executiveId, project, onProjectSelect } = props;

  const [showProjectSelectionModal, setShowProjectSelectionModal] = useState(
    !project
  );

  return (
    <>
      <Modal
        show={showProjectSelectionModal}
        additionalClasses={styles.projectSelectionModal}
        onOutsideClick={() => {}}>
        <ProjectSelection
          executiveId={executiveId}
          project={project}
          onProjectSelection={(project: IProject) => {
            onProjectSelect(project);
            setShowProjectSelectionModal(false);
          }}
        />
      </Modal>

      {project && (
        <div>
          <div className={styles.projectNameAndDropDownArrowContainer}>
            <h2 className={styles.headerProjectName}>{project.name}</h2>
            <Icon
              name='arrow_drop_down'
              propStyles={styles.dropDownArrowIcon}
              onClick={() => {
                setShowProjectSelectionModal(true);
              }}></Icon>
          </div>
          <p className={styles.headerDeveloperName}>
            Brand{'   '}
            <span className={styles.boldDeveloperName}>
              {project.company.displayName}
            </span>
          </p>
        </div>
      )}
    </>
  );
};

export default ProjectNameWithProjectSelection;
