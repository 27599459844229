// Components
import LeadsDashboardHeader from '../ActiveLeads/LeadsHeader';

// Mock Data
import { LeadsHeaderData } from './../ActiveLeads/MockData';

// Styles
import ActiveCpListAndPendingCpApprovalBody from './ActiveCpListAndPendingCpApprovalBody/index';

const ActiveCpListAndPendingCpApproval = () => {
  return (
    <>
      <header>
        <LeadsDashboardHeader {...LeadsHeaderData} />
      </header>

      <main>
        <ActiveCpListAndPendingCpApprovalBody />
      </main>
    </>
  );
};

export default ActiveCpListAndPendingCpApproval;
