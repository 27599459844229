import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Icon from '../../../../../components/common/Icon/index';
import HeadTitle from '../../../../../components/common/TitleHeading';
import { getCpUser } from '../../../slices/cpUserSlice';
import Tabs, { ITab } from '../../common/Tabs';
import CpLeadItem from './CpLeadItem';

import styles from './styles.module.css';

const CrLeadListPage = () => {
  const location = useLocation<{ pendingTabSelected: boolean }>();
  const pendingTabSelected = location?.state?.pendingTabSelected;

  const cpUser = useSelector(getCpUser);

  const pendingLeads = useMemo(() => {
    return (
      cpUser?.cpLeads
        .filter(
          (cpLead) => !cpLead.projects.some((project) => !!project.projectLead)
        )
        .reverse() || []
    );
  }, [cpUser]);

  const assignedLeads = useMemo(() => {
    return (
      cpUser?.cpLeads
        .filter((cpLead) =>
          cpLead.projects.some((project) => !!project.projectLead)
        )
        .reverse() || []
    );
  }, [cpUser]);

  const pendingTab: ITab = {
    id: 'Tabs.AGREEMENT.value',
    name: 'Registered Lead',
    content: (
      <>
        {pendingLeads.map((cpLead) => (
          <CpLeadItem key={cpLead.id} cpLead={cpLead} />
        ))}
        {pendingLeads.length === 0 && (
          <div className={styles.emptyLeads}>
            No Registered Leads are available
          </div>
        )}
      </>
    ),
    isDisabled: false,
  };
  const tabs: ITab[] = [
    {
      id: 'Tabs.ACTIVE_LEADS.value',
      name: 'Tagged Lead',
      content: (
        <>
          {assignedLeads.map((cpLead) => (
            <CpLeadItem key={cpLead.id} cpLead={cpLead} />
          ))}
          {assignedLeads.length === 0 && (
            <div className={styles.emptyLeads}>
              No Tagged Leads are available
            </div>
          )}
        </>
      ),
      isDisabled: false,
    },
    { ...pendingTab },
  ];

  return (
    <div className={styles.listContainer}>
      <div className={styles.headerWrap}>
        <HeadTitle>Lead List</HeadTitle>
        {/*  TODO: TO enable this once search functinality intergrated 
        <Icon name='search' propStyles={styles.searchIcon} /> */}
      </div>
      <div className={styles.leadItemWrap}>
        <Tabs
          tabs={tabs}
          selectedTab={pendingTabSelected ? pendingTab : undefined}
        />
      </div>
    </div>
  );
};

export default CrLeadListPage;
