import Modal from '../../../../../../../../components/common/modal/Modal';
import Icon from '../../../../../../../../components/common/Icon/index';
import { IUser } from '../../../../../../interfaces';
import { useGetProjectsByUserIdQuery } from '../../../../../../services/projectsAPISlice';
import { capitalizeFirstLetter } from '../../../../../../../../utils/utils';

import styles from './styles.module.css';

interface IUserModal {
  onModalClose: Function;
  user: IUser;
}

const UserDetailsModal = (props: IUserModal) => {
  const { onModalClose, user } = props;

  const executiveDetails = [
    { label: 'Executive Name', value: user.name },
    { label: 'Phone Number', value: user.mobile },
    { label: 'Email Id', value: user.email },
  ];

  const { data: projectsByExecutive = [], isLoading } =
    useGetProjectsByUserIdQuery(user.id);

  if (isLoading) {
    return null;
  }

  return (
    <Modal
      onOutsideClick={() => {
        onModalClose(false);
      }}
      additionalClasses={styles.executiveDetailsModal}>
      <header className={styles.modalHeader}>
        <div>
          <h2 className={styles.modalHeading}>
            Details of {capitalizeFirstLetter(user.role.toLowerCase())}
          </h2>
          <p className={styles.modalDesc}>
            These are the details of the {user.role.toLowerCase()}. Admin can
            view the executive details, edit details of executive.
          </p>
        </div>
        <Icon
          name='close'
          onClick={() => {
            onModalClose(false);
          }}></Icon>
      </header>

      <main className={styles.modalBody}>
        <div className={styles.modalBodyLeftContainer}>
          {executiveDetails.map((executiveDetail) => {
            return (
              <>
                <p className={styles.executiveDetailsLabel}>
                  {executiveDetail.label}
                </p>
                <p className={styles.executiveDetailsValue}>
                  {executiveDetail.value}
                </p>
              </>
            );
          })}
        </div>
        <div>
          <p className={styles.executiveDetailsLabel}>
            Name of Assigned projects
          </p>
          <div className={styles.projectDetailsContainer}>
            {projectsByExecutive?.map((project) => {
              return (
                <>
                  <p className={styles.assignedProjectName}>{project.name}</p>
                  <p className={styles.assignedProjectDate}>
                    {/* {project.dateAssigned} */}
                  </p>
                </>
              );
            })}
            {!projectsByExecutive.length && 'No projects assigned'}
          </div>
        </div>
      </main>
    </Modal>
  );
};

export default UserDetailsModal;
