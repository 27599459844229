import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../constants/urls';

export const unitCartAPISlice = createApi({
  reducerPath: 'unitCartsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl,
    tagTypes: ['UnitCarts'],
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    getUnitcartList: build.query({
      query: (user_id) => `unitcart_list?user_id=${user_id}`,

      // Provides a list of `UnitCarts` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `UnitCarts` element was added.

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'UnitCarts', id })),
              { type: 'UnitCarts', id: 'LIST' },
            ]
          : [{ type: 'UnitCarts', id: 'LIST' }],
      keepUnusedDataFor: 120,
    }),

    getUnitCart: build.query({
      query: ({ unit_id = '', user_id = '' } = {}) => {
        return `unitcart_details?user_id=${user_id}&unit_id=${unit_id}`;
      },
      keepUnusedDataFor: 120,
    }),

    postUnitCart: build.mutation({
      query: (body = {}) => {
        return {
          url: 'unit_cart',
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: [{ type: 'UnitCarts', id: 'LIST' }],
    }),

    updateUnitCart: build.mutation({
      query: ({ id, ...body } = {}) => {
        return {
          url: `update_unit_cart?id=${id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'UnitCarts', id }],
    }),

    deleteUnitCart: build.mutation({
      query: (id) => {
        return {
          url: `delete_unit_cart?unit_cart_id=${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, id) => {
        return [{ type: 'UnitCarts', id }];
      },
    }),
  }),
});

export const {
  useGetUnitCartQuery,
  useLazyGetUnitcartListQuery,
  useLazyGetUnitCartQuery,
  usePostUnitCartMutation,
  useUpdateUnitCartMutation,
  useDeleteUnitCartMutation,
} = unitCartAPISlice;
