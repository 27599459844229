import { useState } from 'react';

import { UserRole } from '../../../../../../../constants/userRoles';
import { ICompany } from '../../../../../interfaces';
import UserListGrid from './UserListGrid';

import styles from './styles.module.css';

const adminDashboardTabsEnum = {
  EXECUTIVE_LIST: { value: 'EXECUTIVE_LIST', displayText: 'Executive List' },
  ADMIN_LIST: { value: 'ADMIN_LIST', displayText: 'Admin List' },
};

interface IAdminDashboardBody {
  gridSearchTerm: string;
  company: ICompany;
}

const AdminDashboardBody = (props: IAdminDashboardBody) => {
  const { gridSearchTerm, company } = props;

  const [selectedTabTag, setSelectedTabTag] = useState(
    adminDashboardTabsEnum.EXECUTIVE_LIST.value
  );

  const adminDashboardTabs = [
    {
      name: adminDashboardTabsEnum.EXECUTIVE_LIST.displayText,
      tag: adminDashboardTabsEnum.EXECUTIVE_LIST.value,
      isDisabled: false,
    },
    {
      name: adminDashboardTabsEnum.ADMIN_LIST.displayText,
      tag: adminDashboardTabsEnum.ADMIN_LIST.value,
      isDisabled: false,
    },
  ];

  const handleExecutiveListSearch = (executiveGridSearchHandler: Function) => {
    if (selectedTabTag === adminDashboardTabsEnum.EXECUTIVE_LIST.value) {
      executiveGridSearchHandler(gridSearchTerm);
    }
  };

  return (
    <body>
      <div className={styles.tabsContainer}>
        <div className={styles.tabContainerHeader}>
          {adminDashboardTabs.map((tab) => (
            <div
              key={tab.tag}
              className={`${styles.tabItem} ${
                tab.isDisabled
                  ? styles.disabledTab
                  : selectedTabTag === tab.tag
                  ? styles.activeTab
                  : ''
              }`}
              onClick={() => setSelectedTabTag(tab.tag)}>
              {tab.name}
            </div>
          ))}
        </div>
        <div className={styles.tabContainerBody}>
          {selectedTabTag === adminDashboardTabsEnum.EXECUTIVE_LIST.value && (
            <UserListGrid
              onExecutiveListSearch={handleExecutiveListSearch}
              users={
                company?.users.filter(
                  (user) => user.role === UserRole.EXECUTIVE
                ) || []
              }
              companyId={company.id as string}
            />
          )}
          {selectedTabTag === adminDashboardTabsEnum.ADMIN_LIST.value && (
            <UserListGrid
              onExecutiveListSearch={handleExecutiveListSearch}
              users={
                company?.users.filter((user) => user.role === UserRole.ADMIN) ||
                []
              }
              companyId={company.id as string}
            />
          )}
        </div>
      </div>
    </body>
  );
};

export default AdminDashboardBody;
