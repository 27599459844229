import React from 'react';
function LandscapeFlashScreen() {
  return (
    <div className='LandscapeFlashScreen'>
      <span className='FlashScreen-Message'>
        you are on portrait mode please rotate your phone to switch landscape
        mode
      </span>
    </div>
  );
}

export default LandscapeFlashScreen;
