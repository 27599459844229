import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Icon from '../../../../../../components/common/Icon';
import Modal from '../../../../../../components/common/modal/Modal';
import HeadTitle from '../../../../../../components/common/TitleHeading';

import DropDownInput, {
  DropDownOptionType,
} from '../../../../../../components/common/inputs/DropDownInput';
import TextInput from '../../../../../../components/common/inputs/TextInput';

import styles from './styles.module.css';
import Button from '../../../../../../components/common/buttons/Button';

interface IFiltersModalProps {
  show: boolean;
  closePopup: () => void;
}

const propertyConfiguration = [
  {
    id: 1,
    name: '1 BHK',
  },
  {
    id: 2,
    name: '2 BHK',
  },
  {
    id: 3,
    name: '2 BHK Duplex',
  },
  {
    id: 4,
    name: '3 BHK',
  },
  {
    id: 5,
    name: '3 BHK Duplex',
  },
  {
    id: 6,
    name: '4 BHK +',
  },
];
const priceRange: DropDownOptionType[] = [
  {
    value: '₹ 30,00,000 - ₹ 70,00,000',
    displayText: '₹ 30,00,000 - ₹ 70,00,000',
  },
  {
    value: '₹ 70,00,000 - ₹ 90,00,000',
    displayText: '₹ 70,00,000 - ₹ 90,00,000',
  },
];
const residentialType: DropDownOptionType[] = [
  {
    value: 'Residential',
    displayText: 'Residential',
  },
  {
    value: 'Commercial',
    displayText: 'Commercial',
  },
];
const schema = yup.object({
  company: yup.object({
    name: yup.string().required(),
  }),
  personal: yup.object({}),
});
const FiltersModal = (props: IFiltersModalProps) => {
  const {
    register,
    unregister,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { show, closePopup } = props;
  const [activeFilter, setActiveFilter] = useState(true);

  return (
    <Modal show={show} onOutsideClick={closePopup}>
      <div className={styles.containerWrap}>
        <div className={styles.headerWrap}>
          <HeadTitle>Filters</HeadTitle>
          <Icon
            onClick={closePopup}
            name='close'
            propStyles={styles.closeIcon}
          />
        </div>
        <div className={styles.filterTypes}>
          <div className={styles.searchWrapper}>
            <p className={styles.filtersSubText}>Select area</p>
            <div className={styles.searchContainer}>
              <Icon name='search' propStyles={styles.searchIcon} />
              <input type='search' placeholder='Search property' />
            </div>
            {activeFilter && (
              <div className={styles.selectedItem}>
                <>
                  <p className={styles.item}>Dadar West</p>
                  <Icon
                    propStyles={styles.filterClose}
                    onClick={() => setActiveFilter(false)}
                    name='close'
                  />
                </>
              </div>
            )}
          </div>
        </div>
        <div className={styles.dropDownWrap}>
          <DropDownInput
            lable='Property type'
            options={residentialType}
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='interestedProjects'
          />
        </div>
        <p className={styles.filtersSubText}>Select configuration</p>
        <div className={styles.filterWrap}>
          {propertyConfiguration.map((propertyType, index) => (
            <div
              key={index}
              className={`${styles.propertyConfigs} ${
                propertyType.id === 1 ? styles.active : ''
              }`}>
              {propertyType.name}
            </div>
          ))}
        </div>
        <div className={styles.priceWrap}>
          <DropDownInput
            options={priceRange}
            lable='Price'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='interestedProjects'
          />
        </div>

        <Button crApp propStyles={styles.btnAplly}>
          Apply{' '}
        </Button>
      </div>
    </Modal>
  );
};

export default FiltersModal;
