// Components
import Icon from './../../../../../../components/common/Icon/index';

// Styles
import styles from './styles.module.css';

const CpProfileHeader = () => {
  return (
    <div className={styles.headerContainer}>
      <div>
        <h2 className={styles.cpName}>Kuvalis Cody</h2>
        <p className={styles.userDesignation}>Channel Partner</p>
        <div className={styles.cpDetailsContainer}>
          <div className={styles.cpDetailContainer}>
            <Icon name='call' propStyles={styles.phoneIcon}></Icon>
            <p className={styles.cpPhoneNumber}>+91 9458631250</p>
          </div>
          <div className={styles.cpDetailContainer}>
            <Icon name='mail' propStyles={styles.phoneIcon}></Icon>
            <p className={styles.cpPhoneNumber}>kuvaliscody4@gmai.com</p>
          </div>
        </div>
      </div>
      <div className={styles.statsContainer}>
        <div className={styles.individualStatContainer}>
          <div className={styles.cpDetailContainer}>
            <Icon name='laptop_mac' propStyles={styles.phoneIcon}></Icon>
            <p className={styles.cpPhoneNumber}>Demos Done</p>
          </div>
          <div className={styles.statsValues}>120</div>
          <div className={styles.statsDetails}>
            <span className={styles.statsDecline}>30</span> site visits done
          </div>
        </div>
        <div className={styles.individualStatContainer}>
          <div className={styles.cpDetailContainer}>
            <Icon name='paid' propStyles={styles.phoneIcon}></Icon>
            <p className={styles.cpPhoneNumber}>Estimated Learning</p>
          </div>
          <div className={styles.statsValues}>7.5 Lac</div>
          <div className={styles.statsDetails}>
            <span className={styles.statContain}>21 lac</span> earning this
            month
          </div>
        </div>
        <div className={styles.individualStatContainer}>
          <div className={styles.cpDetailContainer}>
            <Icon
              name='local_fire_department'
              propStyles={styles.phoneIcon}></Icon>
            <p className={styles.cpPhoneNumber}>Converted Leads</p>
          </div>
          <div className={styles.statsValues}>50</div>
          <div className={styles.statsDetails}>
            <span className={styles.statsDecline}>+150</span> Active leads
          </div>
        </div>
      </div>
    </div>
  );
};

export default CpProfileHeader;
