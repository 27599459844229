import { NavLink, useLocation } from 'react-router-dom';

import Icon, { IconNames } from '../../../../../components/common/Icon';

export interface IAdminSidebarItem {
  redirectPath: String;
  labelText: string;
  desc?: String;
  icon: IconNames;
  additionClasses?: String;
}

const AdminSidebarItem = (props: IAdminSidebarItem) => {
  const { redirectPath = '/', icon, additionClasses = '', labelText } = props;

  const { pathname: currentPath } = useLocation();
  const isActiveItem = currentPath === redirectPath;

  return (
    <>
      <NavLink
        title={labelText}
        to={redirectPath as string}
        className={`admin-sidebar-item ${additionClasses} ${
          isActiveItem && 'admin-sidebar-item--active'
        }`}
        onClick={() => {}}>
        <Icon name={icon} />
        {/* <span className="admin-sidebar-item__label">{labelText}</span> */}
      </NavLink>
    </>
  );
};

export default AdminSidebarItem;
