import { useSelector } from 'react-redux';
import {
  getCostSheet,
  getIsFetchingUnitCart,
  getUnitCart,
} from '../../apps/booking/slices/selectedUnit';
import { toIndianRupee } from '../../utils/utils';

const PaymentPlanPlank = () => {
  const costSheet = useSelector(getCostSheet);
  const unitCart = useSelector(getUnitCart);
  const isFetchingUnitCart = useSelector(getIsFetchingUnitCart);

  const scheme = unitCart?.scheme_sfdc_id
    ? costSheet.scheme_details.find(
        (scheme) => scheme.scheme_sfdc_id === unitCart.scheme_sfdc_id
      )
    : costSheet?.scheme_details[0];

  // const scroller = useRef();
  // const seeMoreOverlayBottomElm = useRef();
  // const tableHeader = useRef();
  // useEffect(() => {
  //   const scrollHandler = (event) => {
  //     var element = event.target;
  //     if (
  //       element.scrollHeight - Math.ceil(element.scrollTop) ===
  //       element.clientHeight
  //     ) {
  //       seeMoreOverlayBottomElm.current.classList.add(
  //         'payment-plan-milstone-table__seemore-overlay--hide'
  //       );
  //     } else {
  //       seeMoreOverlayBottomElm.current.classList.remove(
  //         'payment-plan-milstone-table__seemore-overlay--hide'
  //       );
  //     }

  //     if (element.scrollTop === 0) {
  //       tableHeader.current.classList.add(
  //         'payment-plan-milstone-table__seemore-head-overlay--hide'
  //       );
  //     } else {
  //       tableHeader.current.classList.remove(
  //         'payment-plan-milstone-table__seemore-head-overlay--hide'
  //       );
  //     }
  //   };
  //   const scrollerElm = scroller?.current;
  //   if (scrollerElm) {
  //     scrollerElm.addEventListener('scroll', scrollHandler);
  //     return () => {
  //       scrollerElm.removeEventListener('scroll', scrollHandler);
  //     };
  //   }
  // }, [scroller]);

  if (isFetchingUnitCart) {
    return <div>Loading...</div>;
  }

  return (
    <table className='payment-plan-milstone-table'>
      <thead
        comment='ref={tableHeader}'
        className='payment-plan-milstone-table__seemore-head payment-plan-milstone-table__seemore-head-overlay--hide'>
        <tr>
          <th className='payment-plan-milstone-table__title-cell'>
            Milestone Payment Break-up
          </th>
          <th>%</th>
          <th>Total Value Of Property</th>
          <th>Abatement (1/3rd of Value)</th>
          <th>Net Taxable Amount</th>
          <th>GST 7.5%</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {scheme.milestones
          .slice()
          .sort((firstEl, secondEl) => {
            return parseInt(firstEl.displayorder) >
              parseInt(secondEl.displayorder)
              ? 1
              : -1;
          })
          .map((milestone) => (
            <tr>
              <td className='payment-plan-milstone-table__title-cell'>
                {milestone.name}
              </td>
              <td className='payment-plan-milstone-table__percent-cell'>{`${parseFloat(
                milestone.percentage
              ).toString()} %`}</td>
              <td>{toIndianRupee(milestone.property_value)}</td>
              <td>{toIndianRupee(milestone.one_third_value)}</td>
              <td>{toIndianRupee(milestone.net_taxable_amount)}</td>
              <td>
                {toIndianRupee(
                  parseFloat(milestone.sgst) + parseFloat(milestone.cgst)
                )}
              </td>
              <td className='f-bold'>{toIndianRupee(milestone.total_cost)}</td>
            </tr>
          ))}
        <tr>
          <td className='payment-plan-milstone-table__title-cell'>
            ADDITIONALS & DEPOSITS
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td className='f-bold'>
            {toIndianRupee(
              parseFloat(costSheet.total_additional_amount) +
                parseFloat(costSheet.tax_amount_on_additional)
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PaymentPlanPlank;
