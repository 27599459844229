import { useMemo, useState } from 'react';

import useToast from '../../../hooks/useToast';
import TextInput from '../inputs/TextInput';
import Button from '../buttons/Button';
import {
  emailValidationSchema,
  mobileValidationSchema,
} from '../../../utils/yupValidationSchemas';
import {
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
} from '../../../apps/admin/services/adminUserAPISlice';

import styles from './styles.module.css';

export interface IAddressDetails {
  address: string;
  addressType: 'EMAIL' | 'MOBILE';
}

interface ISendOtp {
  addressType: 'EMAIL' | 'MOBILE';
  headerHidden?: boolean;
  mobileLastFourDigits?: string;
  crApp?: boolean;
  onAddressChange?: (address: string) => {};
  address?: string; // Email or Mobile string
  onSuccessfulOtpVerification: (addressDetails: IAddressDetails) => void;
}

const SendOtp = (props: ISendOtp) => {
  const {
    addressType,
    headerHidden,
    mobileLastFourDigits,
    crApp,
    onAddressChange,
    address: _address = '',
    onSuccessfulOtpVerification,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [address, setAddress] = useState(_address);
  const [otp, setOtp] = useState('');

  const [addToast] = useToast();

  const [otpv2SendAPI] = useOtpv2SendMutation();
  const [otpv2VerifyAPI] = useOtpv2VerifyMutation();

  const handelAddressInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const address = event.target.value;
    setAddress(address);
    onAddressChange && onAddressChange(address);
    // On address change, set is otp sent to false as we need to send opt for changed address
    setIsOtpSent(false);

    const validateSchema =
      addressType === 'EMAIL' ? emailValidationSchema : mobileValidationSchema;

    const isValid = await validateSchema.isValid({
      value: address,
    });
    // Address is valid, hide error message
    isValid && setAddressErrorMessage('');
  };
  const handelAddressInputBlur = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const address = event.target.value;
    const validateSchema =
      addressType === 'EMAIL' ? emailValidationSchema : mobileValidationSchema;
    const isValid = await validateSchema.isValid({ value: address });
    if (isValid && mobileLastFourDigits) {
      const last4Str = String(address).slice(-4);
      if (mobileLastFourDigits != last4Str) {
        setAddressErrorMessage(
          `Last 4 digits do not match, please enter a valid Mobile Number`
        );
        return;
      }
    }
    setAddressErrorMessage(
      !isValid
        ? `Please enter a valid ${
            addressType === 'EMAIL' ? 'Email' : 'Mobile'
          } Number`
        : ''
    );
  };

  const handleOtpInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const otp = event.target.value;
    setOtp(otp);
  };

  const handleOtpSend = async () => {
    try {
      setIsLoading(true);

      await otpv2SendAPI({ address, addressType }).unwrap();

      setIsOtpSent(true);
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'OTP Sent',
        secondaryMessage: '',
        timeout: 1000,
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleOtpVerify = async () => {
    try {
      setIsLoading(true);

      await otpv2VerifyAPI({ address, otp }).unwrap();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'OTP verified successfully',
        secondaryMessage: '',
        timeout: 1000,
      });

      onSuccessfulOtpVerification({ address, addressType });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isOtpSent) handleOtpVerify();
    else handleOtpSend();
  };

  const isButtonDisable = useMemo(() => {
    if (addressType !== 'EMAIL') {
      if (address.length !== 10 || (isOtpSent && otp.length !== 6)) return true;
      return false;
    }

    return false;
  }, [addressType, address, otp, isOtpSent]);

  return (
    <>
      {!headerHidden && (
        <>
          <h2 className={styles.loginFormHeading}>
            Verify your identity with OTP to proceed
          </h2>
          <p className={styles.loginFormDesc}>
            Enter your Mobile number to verify your identity.
          </p>
        </>
      )}
      <form onSubmit={handleSubmit}>
        <TextInput
          label={crApp ? '' : addressType === 'EMAIL' ? 'Email' : 'Mobile'}
          placeHolder=''
          crApp
          errorMessage={addressErrorMessage}
          onChange={handelAddressInputChange}
          startIconName='local_phone'
          startIconVariant='SECONDARY'
          onBlur={handelAddressInputBlur}
          startIconText={addressType === 'EMAIL' ? '' : '+91'}
          type={addressType === 'EMAIL' ? 'email' : 'tel'}
          defaultValue={_address}
          readOnly={!!_address}
          maxLength={(addressType === 'MOBILE' && 10) || undefined}
        />
        <TextInput
          label={crApp ? ' ' : 'Enter 6-digit OTP'}
          placeHolder='Enter 6-digit OTP'
          crApp
          startIconVariant='SECONDARY'
          startIconName='lock'
          onChange={handleOtpInputChange}
          type='password'
          disabled={!isOtpSent}
          maxLength={6}
          resetValue={!isOtpSent}
        />
        <Button
          crApp={crApp}
          propStyles={`${styles.loginButton} ${crApp && styles.crLogin}`}
          disabled={!!addressErrorMessage || isButtonDisable}
          loading={isLoading}
          type='submit'>
          {isOtpSent ? 'Verify OTP' : 'Send OTP Now'}
        </Button>
      </form>
    </>
  );
};

export default SendOtp;
