export const getVRUrl = (
  vrUrl: string,
  virtualRoomId: string,
  executiveId: string
) => {
  const envQuery =
    process.env.REACT_APP_ENV === 'production'
      ? ''
      : `&env=${process.env.REACT_APP_ENV || 'local'}`;

  return `${vrUrl}?vri=${virtualRoomId}&ei=${executiveId}${envQuery}`;
};

export const getEnvQueryParam = () => {
  return process.env.REACT_APP_ENV === 'production'
    ? ''
    : `&env=${process.env.REACT_APP_ENV || 'local'}`;
};

export const isRelataCpApp = () => {
  return process.env.REACT_APP_TYPE === 'RELATA-CP';
};

export const isRelataCrApp = () => {
  return process.env.REACT_APP_TYPE === 'RELATA-CR';
};
