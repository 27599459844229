//React
import { useState } from 'react';
import { useSelector } from 'react-redux';

// ThridParty
// import 'ag-grid-enterprise';
// import { AgGridColumn, AgGridReact } from 'ag-grid-react';
// import { ICellRendererParams } from 'ag-grid-community';

// Utils
import { getVRUrl } from './utils';

// APISlices
import { useGetProjectsByUserIdQuery } from '../../../services/projectsAPISlice';

// Components
import ScheduledDemoModal from './ScheduledDemoModal';
import SendInviteModal from './SendInviteModal';
import ScheduledDemoSuccessModal from './ScheduledDemoSuccessModal/index';
import Button from '../../../../../components/common/buttons/Button';
import Modal from '../../../../../components/common/modal/Modal';
import Icon from '../../../../../components/common/Icon';

import { getAdminUser } from '../../../slices/adminSlice';

// Interfaces
import { IDemoRoom } from '../SuperAdminDashboard/SuperAdminProjectList/modals/AddDemoRoomModal';

// Styles
import styles from './styles.module.css';

const ExecutiveDashboard = () => {
  const [showScheduledDemoModal, setShowScheduledDemoModal] = useState(false);
  const [showSendInviteModal, setShowSendInviteModal] = useState(false);
  const [showScheduledDemoSuccessModal, setShowScheduledDemoSuccessModal] =
    useState(false);
  const [showNoVRUrlModal, setShowNoVRUrlModal] = useState(false);

  // const defaultColDef = {
  //   sortable: false,
  //   filter: false,
  //   resizable: false,
  //   suppressMenu: false,
  //   editable: false,
  //   flex: 1
  // };

  const adminUser = useSelector(getAdminUser);
  const executiveId = adminUser?.id as string;

  const { data: projectsByExecutive, isLoading } =
    useGetProjectsByUserIdQuery(executiveId);

  const HandleRescheduleBtnClick = () => {
    setShowScheduledDemoModal(false);
    setShowScheduledDemoSuccessModal(false);
    setShowSendInviteModal(true);
  };

  const handleDemoSuccessfullyScheduled = () => {
    setShowScheduledDemoModal(false);
    setShowScheduledDemoSuccessModal(true);
    setShowSendInviteModal(false);
  };
  const handelScheduledDemoModalClose = () => {
    setShowScheduledDemoModal(false);
  };

  const handelSendInviteModalClose = () => {
    setShowSendInviteModal(false);
  };

  const handelScheduledDemoSuccessModalClose = () => {
    setShowScheduledDemoSuccessModal(false);
  };

  if (isLoading) return <div>Loading...</div>;

  if (!projectsByExecutive?.length)
    return (
      <div>
        Not part of any projects... Please ask Admin to add you to respective
        projects
      </div>
    );

  const project = projectsByExecutive[0];

  const executivesWithDemoRoom = project.executivesWithDemoRoom.find(
    (e) => e.executive.id === executiveId
  );

  const {
    virtualRoomId,
    hostPassword,
    fallbackVirtualRoomUrl: demoRoomUrl,
  } = executivesWithDemoRoom?.demoRoom as IDemoRoom;

  const handleJoinVideoCallBtnClick = () => {
    window.open(`${demoRoomUrl}`, '_blank', 'noopener noreferrer');
  };

  const handleOpenVRBtnClick = () => {
    if (!(executivesWithDemoRoom?.vrUrl || project.vrUrl)) {
      setShowNoVRUrlModal(true);
      return;
    }
    window.open(
      getVRUrl(
        executivesWithDemoRoom?.vrUrl || project.vrUrl,
        virtualRoomId,
        executiveId
      ),
      '_blank',
      'noopener noreferrer'
    );
  };

  return (
    <>
      <Modal
        show={showNoVRUrlModal}
        onOutsideClick={() => setShowNoVRUrlModal(false)}
        additionalClasses={`${styles.noVideoLinkmodalAdditionalClasses}`}>
        <header>
          <div className={styles.noVideoModalTitleContainer}>
            <h2 className={styles.noVideoModalTitle}>
              'There is no VR Link, Please contact admin'
            </h2>
            <Icon
              name='close'
              propStyles={styles.crossIcon}
              onClick={() => setShowNoVRUrlModal(false)}
            />
          </div>
        </header>
        <main className={styles.noVideoModalBody}>
          <Button
            propStyles={styles.noVideoModalOkayBtn}
            onClick={() => setShowNoVRUrlModal(false)}>
            Okay
          </Button>
        </main>
      </Modal>

      <ScheduledDemoModal
        onClose={handelScheduledDemoModalClose}
        showModal={showScheduledDemoModal}
        onRescheduleBtnClick={HandleRescheduleBtnClick}
      />
      <SendInviteModal
        executiveId={executiveId}
        vrUrl={executivesWithDemoRoom?.vrUrl || project.vrUrl}
        deomRoom={
          project.executivesWithDemoRoom.find(
            (e) => e.executive.id === executiveId
          )?.demoRoom as IDemoRoom
        }
        projectName={project.name}
        projectId={project.id}
        developerName={project.company.displayName}
        onClose={handelSendInviteModalClose}
        showModal={showSendInviteModal}
        onDemoScheduled={handleDemoSuccessfullyScheduled}
      />
      <ScheduledDemoSuccessModal
        onClose={handelScheduledDemoSuccessModalClose}
        showModal={showScheduledDemoSuccessModal}
        onRescheduleBtnClick={HandleRescheduleBtnClick}
      />

      {/* Header Left Side Content */}
      <header className={styles.dashboardHeader}>
        <div>
          <h2 className={styles.dashboardHeaderTitle}>{project.name}</h2>
          <p className={styles.dashboardHeaderDesc}>
            Brand{' '}
            <span className={styles.dashboardBoldDes}>
              {project.company.name}
            </span>
          </p>
        </div>

        {/* Header Right Side Content */}
        <div className={styles.headerRightContent}>
          <div
            title='Click on Icon to Copy'
            className={styles.hostPasswordContainer}>
            <p className={styles.hostPassword}>
              VR Host Password :
              <span className={styles.boldText}> {hostPassword}</span>
            </p>
            <Icon
              name='content_copy'
              propStyles={styles.copyIcon}
              onClick={() =>
                navigator.clipboard.writeText(hostPassword)
              }></Icon>
          </div>
          <div className={styles.shareDemoBtnConttainer}>
            {demoRoomUrl && (
              <Button
                onClick={handleJoinVideoCallBtnClick}
                propStyles={styles.shareDemoBtn}>
                Join Video Call
              </Button>
            )}
            <Button
              onClick={handleOpenVRBtnClick}
              propStyles={styles.shareDemoBtn}>
              Open VSG
            </Button>
            <Button
              onClick={() => setShowSendInviteModal(true)}
              propStyles={styles.shareDemoBtn}>
              Share Demo
            </Button>
          </div>

          {/* <div className={styles.connectBtnContainer}>
            <div className={styles.upcommingMeetingMessageContainer}>
              <Icon name='schedule' propStyles={styles.clockIcon}/>
              <h3 className={styles.upcommingMeetingMessage}>
                Upcoming Demo in 5 min
              </h3>
            </div>
            <div>
              <Button
                onClick={() => setShowScheduledDemoModal(true)}
                additionalClasses={styles.connetcNowBtn}>
                Connect Now
              </Button>
            </div>
          </div>
          <Icon name='event' propStyles={styles.calenderIcon}/>  onClick={() => setShowSendInviteModal(true)} */}
        </div>
      </header>

      {/* Body Content */}
      <main className={styles.body}>
        <div className={styles.embedMessageContainer}>
          <iframe
            title='d'
            width='100%'
            height='100%'
            frameBorder='0'
            src={`https://datastudio.google.com/embed/u/0/reporting/240a12af-0566-42d1-8e55-4059d2860dd0/page/6gnnC?params=%7B%22df16%22:%22include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${project.id}%22%7D`}
            style={{ border: 0 }}
            allowFullScreen></iframe>
        </div>
      </main>
    </>
  );
};

export default ExecutiveDashboard;
