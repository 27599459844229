import { useMemo } from 'react';
import Icon from '../../../../../../components/common/Icon';
import Modal from '../../../../../../components/common/modal/Modal';
import { fallbackImageUrl } from '../../../../../../constants/urls';
import { dateToDaythMonthYear } from '../../../../../../utils/dateFormator';
import { getLiveSinceDays } from '../../../../../../utils/utils';
import { useGetSiteVisitsByCpLeadIdQuery } from '../../../../../admin/services/siteVisitAPI';
import { ICpLead } from '../../../../interfaces';
import { getMobileFromCpLead } from '../CpLeadItem';

import styles from './styles.module.css';

interface ILeadDetailsModalProps {
  onClose: () => void;
  cpLead: ICpLead;
}

const LeadDetailsModal = (props: ILeadDetailsModalProps) => {
  const { onClose, cpLead } = props;

  const { data: cpLeadSiteVisits = [], isLoading } =
    useGetSiteVisitsByCpLeadIdQuery(cpLead.id);

  // TODO: isLoading is always true, fix this to enable the loading.
  // useEffect(() => {
  //   dispatch(setAppSliceIsFetching(isLoading));
  // }, [isLoading]);

  const projectNames = useMemo(() => {
    return Object.values(cpLead.projects).map((project) => project.name);
  }, [cpLead]);

  const handleShareClick = async () => {
    if (navigator.share) {
      const response = await fetch(cpLead.qrCodeUrl || fallbackImageUrl);
      const blob = await response.blob();
      const file = new File([blob], 'qrCode.png', { type: blob.type });
      const textToShare = `Dear ${
        cpLead.name
      },\n\nRequest you to kindly show the QR code at the start of the site visit to the Guest Relationship Manager or Sales Manager at our site${
        projectNames.length ? `: *${projectNames.join(',')}*` : ''
      }.\n\nThe team will assist you in case you face any issues.This will help us create a better experience during your visit to our experience center.`;
      navigator
        .share({
          text: textToShare,
          // url: urlToShare,
          files: [file],
        })
        .then((res) => {
          console.log('Successfully shared');
        })
        .catch((error) => {
          console.error('Please try Manually', error);
        });
    }
  };

  return (
    <Modal onOutsideClick={onClose} additionalClasses={styles.detailsContainer}>
      <div className={styles.contentWrap}>
        <Icon
          onClick={() => onClose()}
          name='close'
          propStyles={styles.closeIcon}
        />
        <div className={styles.headerWrap}>
          {/* TODO: change <img> to reuseable <Image> component  */}
          <div className={styles.cpLeadInfo}>
            <img src={cpLead.qrCodeUrl || fallbackImageUrl} alt='QR Code' />
            <div className={styles.nameWrap}>
              <p className={styles.leadsName}>{cpLead.name}</p>
              <p className={styles.timeSpan}>
                <Icon
                  // TODO: Color is not applying in the Modal
                  propStyles={`${styles.statusIcon} ${styles.iconRed}`}
                  name='whatshot'
                />
                {/* TODO: use cpLead.updatedAt instead, we will update once we have updateAt field setup in backend */}
                {getLiveSinceDays(cpLead.createdAt)} days
              </p>
            </div>
          </div>

          <Icon
            name='share'
            onClick={handleShareClick}
            propStyles={styles.shareIcon}
          />
        </div>
        <div className={styles.mainWrap}>
          <div className={styles.detailsWrap}>
            <p className={styles.detailsType}>Mobile no :</p>
            <p className={styles.boldText}>{getMobileFromCpLead(cpLead)}</p>
          </div>
          <div className={styles.detailsWrap}>
            <p className={styles.detailsType}>Property type :</p>
            <p className={styles.boldText}>
              {cpLead.propertyConfigs.join(' ')}
            </p>
          </div>
          <div className={styles.detailsWrap}>
            <p className={styles.detailsType}>Area preferences :</p>
            <p className={styles.boldText}>{cpLead.preferenceAreasTemp}</p>
          </div>
          <div className={styles.horizontalLine} />
          <p className={styles.siteTitle}>Site visit details</p>
          {cpLeadSiteVisits.length == 0 && (
            <p className={styles.noSiteVisit}>No site visits yet</p>
          )}
          {cpLeadSiteVisits.map((cpLeadSiteVisit, index) => (
            <>
              <div key={index} className={styles.cpLeadSiteVisit}>
                <div className={styles.visitWrap}>
                  <p className={styles.detailsType}>Project Name :</p>
                  <p className={styles.boldText}>
                    {cpLeadSiteVisit.project.name}
                  </p>
                </div>
                <div className={styles.dateWrap}>
                  <p className={styles.detailsType}>Date :</p>
                  <p className={styles.boldText}>
                    {dateToDaythMonthYear(cpLeadSiteVisit.visitDate)}
                  </p>
                </div>
              </div>
              <div className={styles.detailsWrap}>
                <p className={styles.detailsType}>Status :</p>
                {/* TODO: Update siteVisit status after Project, Cp, Lead tagging  */}
                <p className={styles.boldText}>
                  Lead is Interested in the property
                </p>
              </div>
              <div className={styles.horizontalLine} />
            </>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default LeadDetailsModal;
