import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import useToast from '../../../../../../hooks/useToast';
import { getCpUser, setCpUser } from '../../../../slices/cpUserSlice';
import { ICpUser } from '../../../../interfaces';
import { useUpdateCpUserMutation } from '../../../../services/cpUserAPISlice';
import DropDownInput, {
  DropDownOptionType,
} from '../../../../../../components/common/inputs/DropDownInput';
import ProfileHeader from '../../../common/ProfileHeader';
import TextInput from '../../../../../../components/common/inputs/TextInput';
import FileUploadNew from '../../../../../../components/common/inputs/FileUploadNew';

import styles from './styles.module.css';

const workingAs: DropDownOptionType[] = [
  {
    value: false,
    displayText: 'Company',
  },
  {
    value: true,
    displayText: 'Self',
  },
];

const schema = yup.object({
  companyTemp: yup.object({
    name: yup.string().required(),
    pocMobile: yup
      .string()
      .matches(/^[0-9]*$/)
      .length(10)
      .required(),
    website: yup.string(),
  }),
  panNumber: yup.string().required(),
  gstNumber: yup.string().required(),
  rera: yup.object({
    number: yup.string().required(),
  }),
});

const UpdateCpUserCompany = () => {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [saveDisabled, setSaveDisabled] = useState(false);
  const [fileUploadingStatus, setFileUploadingStatus] = useState<{
    [fileId: string]: boolean;
  }>({});

  const dispatch = useDispatch();
  const cpUser = useSelector(getCpUser) as ICpUser;

  const [addToast] = useToast();
  const [updateCpUserAPI] = useUpdateCpUserMutation();

  const handleSave = async (cpUserData: ICpUser) => {
    try {
      const response = await updateCpUserAPI({
        cpUserId: cpUser.id,
        ...cpUserData,
      }).unwrap();

      const updatedCpUser: ICpUser = response.data;
      dispatch(setCpUser(updatedCpUser));
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated Company Details',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  const toogleFileUploadStatus = (
    fileId: string,
    isUploadInProgress: boolean
  ) => {
    setFileUploadingStatus((prevState) => {
      return {
        ...prevState,
        [fileId]: isUploadInProgress,
      };
    });
  };

  useEffect(() => {
    const isUploading = Object.values(fileUploadingStatus).some(
      (status) => status
    );
    setSaveDisabled(isUploading);
  }, [fileUploadingStatus]);

  return (
    <div className={styles.containerWrap}>
      <ProfileHeader
        iconName={'arrow_back'}
        pageName={'Company Details'}
        buttonName={'Save'}
        loading={saveDisabled}
        onSave={() => handleSubmit(handleSave)()}
      />
      <div className={styles.formWrap}>
        <DropDownInput
          lable='Working as'
          defaultSelectedOption={{ value: true, displayText: 'Self' }}
          options={workingAs}
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='selfEmployed'
          disabled
        />
        <TextInput
          label='Company Name *'
          placeHolder='Add Company Name'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='companyTemp.name'
          defaultValue={cpUser.companyTemp?.name}
          errorMessage={
            errors.companyTemp?.name ? 'Company name is required' : ''
          }
          crApp
        />
        <TextInput
          label='Company POC Mobile *'
          placeHolder='Add Company POC Mobile'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='companyTemp.pocMobile'
          defaultValue={cpUser.companyTemp?.pocMobile}
          maxLength={10}
          type='number'
          errorMessage={
            errors.companyTemp?.pocMobile
              ? 'Enter valid 10 digit mobile number'
              : ''
          }
          crApp
        />
        <TextInput
          label='Company website URL'
          placeHolder='Add Company website URL'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='companyTemp.websiteUrl'
          defaultValue={cpUser.companyTemp?.websiteUrl}
          crApp
        />

        <TextInput
          label='Pan Number *'
          placeHolder='Add Pan Number'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='panNumber'
          defaultValue={cpUser.panNumber}
          errorMessage={errors.panNumber ? 'Pan number is required' : ''}
          crApp
        />

        <FileUploadNew
          placeholder='Upload Pan Card'
          label='Upload PAN card image'
          defaultValue={cpUser.panUrl}
          s3DirPath={`cpApp/cpUser/${cpUser.id}/documents/pan`}
          onChangeFileUploadStatus={toogleFileUploadStatus}
          register={register}
          unregister={unregister}
          name='panUrl'
          setValue={setValue}
          errorMessage={errors.panUrl && 'Pan Card is required'}
        />

        <TextInput
          label='GST Number *'
          placeHolder='Add GST Number'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='gstNumber'
          defaultValue={cpUser.gstNumber}
          errorMessage={errors.gstNumber ? 'GST number is required' : ''}
          crApp
        />

        <FileUploadNew
          placeholder='Upload Gst Card'
          label='Upload GST card image'
          defaultValue={cpUser.gstUrl}
          s3DirPath={`cpApp/cpUser/${cpUser.id}/documents/gst`}
          onChangeFileUploadStatus={toogleFileUploadStatus}
          register={register}
          unregister={unregister}
          name='gstUrl'
          setValue={setValue}
          errorMessage={errors.gstUrl && 'Gst Card is required'}
        />

        <TextInput
          label='Rera Number *'
          placeHolder='Add GST Number'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='rera.number'
          defaultValue={cpUser.rera.number}
          errorMessage={errors.rera?.number ? 'Rera Number is required' : ''}
          crApp
        />
      </div>
    </div>
  );
};

export default UpdateCpUserCompany;
