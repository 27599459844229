import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TextInput from '../../../../../../../components/common/inputs/TextInput/index';
import DropDownInput, {
  DropDownOptionType,
} from '../../../../../../../components/common/inputs/DropDownInput';
import Button from '../../../../../../../components/common/buttons/Button';
import { DetailsFormState } from '.';
import { useAddSiteVisitMutation } from '../../../../../services/siteVisitAPI';
import useToast from '../../../../../../../hooks/useToast';
import MultiSelectOptions from '../../../../../../../components/common/inputs/MultiSelectOptions';
import { SiteVisitState } from './..';
import { ISiteVisitDetails } from '..';
import { IPriceRange } from '../../../SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

interface IPropertyFormDetails {
  visitDate: string;
  configs: string[];
  preferenceAreasTemp: string;
  priceRange: IPriceRange;
  customerFeedback: string;
}

// Validation
const schema = yup.object().shape({
  visitDate: yup.date().required(),
  configs: yup.array().of(yup.string()).min(1),
  preferenceAreasTemp: yup.string().required(),
  priceRange: yup.object().required(),
  customerFeedback: yup.string().required(),
});

interface IPropertyDetails {
  projectPropertyConfigs: string[];
  projectPropertyPriceRanges: IPriceRange[];
  setSiteVisitState: (state: SiteVisitState) => void;
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitDetails: Function;
  setDetailsFormState: (state: DetailsFormState) => void;
}

const PropertyDetails = (props: IPropertyDetails) => {
  const {
    projectPropertyConfigs,
    projectPropertyPriceRanges,
    setSiteVisitDetails,
    siteVisitDetails,
    setDetailsFormState,
    setSiteVisitState,
  } = props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addSiteVisitAPI] = useAddSiteVisitMutation();
  const [addToast] = useToast();

  const siteVisitFormSubmitBtnClickHandler = async (
    propertyDetails: IPropertyFormDetails
  ) => {
    setSiteVisitDetails((prevState: ISiteVisitDetails) => {
      return { ...prevState, ...propertyDetails };
    });

    const finalSiteVisitDetails: ISiteVisitDetails = {
      ...siteVisitDetails,
      ...propertyDetails,
    };

    try {
      await addSiteVisitAPI(finalSiteVisitDetails).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Site visit details added',
        secondaryMessage: '',
        timeout: 1000,
      });
      setSiteVisitState(SiteVisitState.SUMMERY);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };

  const priceRangeOptions: DropDownOptionType[] =
    projectPropertyPriceRanges.map((range, index) => ({
      value: { ...range, id: String(index) },
      displayText: `${range.min} - ${range.max}`,
    }));

  const propertyDetailsBackBtnClickHandler = () => {
    setDetailsFormState(DetailsFormState.CUSTOMER);
  };

  return (
    <form onSubmit={handleSubmit(siteVisitFormSubmitBtnClickHandler)}>
      <main>
        <div className={styles.textField}>
          <TextInput
            label='Date of Visit *'
            type='datetime-local'
            placeHolder='Enter Date of Visit'
            register={register}
            setValue={setValue}
            name='visitDate'
            defaultValue={siteVisitDetails.visitDate}
            errorMessage={errors.visitDate ? 'Date of visit is required' : ''}
          />
        </div>
        <div className={styles.textField}>
          {/* <TextInput
            label='Enter Configuration'
            placeHolder=''
            register={register}
            setValue={setValue}
            name='configs'
            defaultValue={siteVisitDetails.configs.join(' ')}
            errorMessage={
              errors.configs ? 'Property configuration is required' : ''
            }
          /> */}
          <MultiSelectOptions
            label='Select configuration *'
            options={projectPropertyConfigs}
            setValue={setValue}
            errorMessage={
              errors.configs ? 'Property configuration is required' : ''
            }
            register={register}
            unregister={unregister}
            name='configs'
            clearErrors={clearErrors}
            validate={(value: string[]) => value.length > 0}
          />
        </div>
        <div className={styles.textField}>
          <TextInput
            label='Area Preferences *'
            placeHolder='Add Area Preferences'
            register={register}
            setValue={setValue}
            name='preferenceAreasTemp'
            defaultValue={siteVisitDetails.preferenceAreasTemp}
            errorMessage={
              errors.preferenceAreasTemp ? 'Area preference is required' : ''
            }
          />
        </div>
        <div className={styles.textField}>
          <DropDownInput
            options={priceRangeOptions}
            lable='Price Range *'
            register={register}
            setValue={setValue}
            unregister={unregister}
            name='priceRange'
            errorMessage={errors.priceRange ? 'Price range is required' : ''}
          />
        </div>
        <div className={styles.textField}>
          <TextInput
            label='Customer Feedback *'
            placeHolder='Add Customer Feedback'
            register={register}
            setValue={setValue}
            name='customerFeedback'
            defaultValue={siteVisitDetails.customerFeedback}
            errorMessage={
              errors.customerFeedback ? 'Customer feedback is required' : ''
            }
          />
        </div>
      </main>

      <footer className={styles.footer}>
        <Button
          propStyles={styles.sendOtpButton}
          onClick={propertyDetailsBackBtnClickHandler}>
          Back
        </Button>
        <Button
          propStyles={styles.sendOtpButton}
          accent='primary'
          type='submit'>
          Submit
        </Button>
      </footer>
    </form>
  );
};

export default PropertyDetails;
