import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { getAppSliceIsFetching } from '../../../../slices/appSlice';
import CpDashboardContent from '../../containers/CpDashboardContent';
import CpKycContent from '../../containers/CpKycContent';

const CpContentLayer = () => {
  const { path } = useRouteMatch();

  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  return (
    <>
      <div
        className={`loading-overlay ${
          appSliceIsFetching && 'loading-overlay--enabled'
        }`}>
        <span>loading...</span>
      </div>
      <div
        className={`cp-app-main ${appSliceIsFetching && 'app-main--loading'}`}>
        <Switch>
          <Route path={`${path}/kyc`} component={CpKycContent}></Route>
          <Route
            path={`${path}/dashboard`}
            component={CpDashboardContent}></Route>

          <Redirect to='/cp/dashboard' />
        </Switch>
      </div>
    </>
  );
};

export default CpContentLayer;
