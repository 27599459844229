import { Progress } from './Interface';

const ProgressBar = (props: Progress) => {
  const {
    target,
    currentvalue,
    bgcolorProgress = '#405361',
    bgcolorBar = '#D4D4D4',
    width = '20.8rem',
    height = '1rem',
    borderRadius = '1rem',
    showSummaryRow = true,
    zeroProgressSummaryMessage = 'Yet to Start',
  } = props;
  const percentCompletion = target === 0 ? 0 : (currentvalue * 100) / target;
  const containerStyles = {
    height: height,
    width: width,
    backgroundColor: bgcolorBar,
    borderRadius: borderRadius,
  };

  const fillerStyles = {
    height: '100%',
    width: percentCompletion,
    backgroundColor: bgcolorProgress,
    borderRadius: 'inherit',
  };

  return (
    <>
      <div style={containerStyles}>
        <div style={fillerStyles}></div>
      </div>
      {showSummaryRow ? (
        percentCompletion ? (
          <div className='summary-row'>
            {currentvalue} ({percentCompletion}%) Completed
          </div>
        ) : (
          <div className='summary-row'>{zeroProgressSummaryMessage}</div>
        )
      ) : null}
    </>
  );
};

export default ProgressBar;
