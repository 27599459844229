import { useState } from 'react';

import useToast from '../../../../../../../../hooks/useToast';
import TextInput from '../../../../../../../../components/common/inputs/TextInput';
import Button from '../../../../../../../../components/common/buttons/Button';
import { LoginFormStates } from '../../index';
import { useResetAdminUserPasswordMutation } from '../../../../../../services/adminUserAPISlice';

import styles from '../../styles.module.css';

interface IPasswordResetState {
  setLoginFormState: Function;
  email: String;
}

const PasswordResetState = (props: IPasswordResetState) => {
  const { setLoginFormState, email } = props;

  const [password, setPassword] = useState('');
  const [matchPasswordErrorMessage, setMatchPasswordErrorMessage] =
    useState('');
  const [addToast] = useToast();

  const [adminResetPasswordAPI] = useResetAdminUserPasswordMutation();

  const handelPasswordBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handelReenteredPasswordBlur = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const matchPassword = event.target.value;
    matchPassword === password
      ? setMatchPasswordErrorMessage('')
      : setMatchPasswordErrorMessage("Passwords don't match");
  };

  const handleResetPassword = async () => {
    try {
      await adminResetPasswordAPI({ email, password }).unwrap();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Password reset successfully',
        secondaryMessage: '',
        timeout: 1000,
      });

      setLoginFormState(LoginFormStates.PASSWORD_RESET_SUCCESS);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };
  return (
    <>
      <h2 className={styles.loginFormHeading}>
        Let us know what is your New Password
      </h2>
      <p className={styles.loginFormDesc}>
        Enter the new password you want to set for your account. These both have
        to match!
      </p>
      <TextInput
        label='Enter New Password'
        placeHolder=''
        onBlur={handelPasswordBlur}
        type='password'
      />
      <TextInput
        label='Re-enter New Password'
        placeHolder=''
        errorMessage={matchPasswordErrorMessage}
        onBlur={handelReenteredPasswordBlur}
        type='password'
      />
      <Button propStyles={styles.loginButton} onClick={handleResetPassword}>
        Reset Password
      </Button>
      <Button
        propStyles={styles.forgotPasswordButton}
        onClick={() => setLoginFormState(LoginFormStates.LOGIN)}>
        Back To Login
      </Button>
    </>
  );
};

export default PasswordResetState;
