// Components
import CpProfileAssignedLeadsGrid from './CpProfileAssignedLeadsGrid';

// Mock Data
import { cpProfileAssignedLeads } from './mockData';

const CpProfileAssignedLeads = () => {
  return (
    <main>
      <CpProfileAssignedLeadsGrid rowData={cpProfileAssignedLeads} />
    </main>
  );
};

export default CpProfileAssignedLeads;
