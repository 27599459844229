import { motion } from 'framer-motion';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.3,
};

const FadeInFadeOutAnim = (props) => {
  const { children, height, width } = props;
  return (
    <motion.div
      style={{ width, height }}
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
      transition={pageTransition}>
      {children}
    </motion.div>
  );
};

export default FadeInFadeOutAnim;
