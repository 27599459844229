import { useHistory } from 'react-router-dom';

import Icon, { IconNames } from '../../../../../components/common/Icon';

import styles from './styles.module.css';

interface IProfileHeader {
  iconName: IconNames;
  pageName: string;
  buttonName: string;
  loading?: boolean;
  onSave: () => void;
}

const ProfileHeader = (props: IProfileHeader) => {
  const { iconName, pageName, buttonName, loading, onSave } = props;

  const history = useHistory();

  const handleBackBtnClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.headerWrap}>
      <Icon
        name={iconName}
        propStyles={styles.backIcon}
        onClick={handleBackBtnClick}
      />
      <p className={styles.heading}>{pageName}</p>
      <button
        className={`${styles.saveBtn} ${loading && 'loading-spin-container'}`}
        disabled={loading}
        onClick={onSave}>
        <span className={`ic2-fa-spin-blue ${styles.spinnerLeft}`}></span>
        {buttonName}
      </button>
    </div>
  );
};

export default ProfileHeader;
