import { useState } from 'react';

import 'ag-grid-enterprise';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import TextInput from './../../../../../../components/common/inputs/TextInput/index';
import Icon from './../../../../../../components/common/Icon/index';
import ToggleButtonCell from './ToggleButtonCell';
import UnitNameCell from './UnitNameCell';
import { inventory } from './mockData';

import styles from './styles.module.css';

const InventoryListGrid = () => {
  const [gridOneApi, setGridOneApi] = useState(null) as any; // We used type "any" at all other places previously as there is no type specified in the documentation

  const [gridTwoApi, setGridTwoApi] = useState(null) as any; // We used type "any" at all other places previously as there is no type specified in the documentation

  const [gridThreeApi, setGridThreeApi] = useState(null) as any; // We used type "any" at all other places previously as there is no type specified in the documentation

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  //This function will automatically be called when ag grid is ready
  const onGridOneReady = (params: GridReadyEvent) => {
    setGridOneApi(params.api);
  };
  const onGridTwoReady = (params: GridReadyEvent) => {
    setGridTwoApi(params.api);
  };
  const onGridThreeReady = (params: GridReadyEvent) => {
    setGridThreeApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridOneApi?.setQuickFilter(searchString);
    gridTwoApi?.setQuickFilter(searchString);
    gridThreeApi?.setQuickFilter(searchString);
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.searchBoxContainer}>
          <TextInput
            placeHolder='Search Name'
            startIconName='search'
            startIconVariant='SECONDARY'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleGridSearch(event.target.value)
            }
          />
        </div>
        <div className={styles.filterContainer}>
          <p className={styles.filterText}>Filter</p>
          <Icon name='filter_alt' propStyles={styles.filterIcon} />
        </div>
      </header>

      <main className={styles.threeGridsContainer}>
        {/* First Grid */}
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ height: '100%', width: '33%' }}>
          <AgGridReact
            rowData={inventory}
            defaultColDef={defaultColDef}
            onGridReady={onGridOneReady}
            domLayout={'autoHeight'}
            headerHeight={60}>
            <AgGridColumn
              hide={true}
              headerName=''
              field='name'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p>{params.value}</p>
              )}></AgGridColumn>
            <AgGridColumn
              headerName='Unit Name'
              field='unitName'
              flex={1}
              cellRendererFramework={UnitNameCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  rowData: params,
                };
              }}></AgGridColumn>
            <AgGridColumn
              headerName='Config'
              field='config'
              flex={1}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.configText}>{params.data.config}</p>
              )}></AgGridColumn>
            <AgGridColumn
              headerName='Config'
              field='config'
              flex={0.7}
              cellRendererFramework={ToggleButtonCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  rowData: params,
                };
              }}></AgGridColumn>
          </AgGridReact>
        </div>

        {/* Second Grid */}
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ height: '100%', width: '33%' }}>
          <AgGridReact
            rowData={inventory}
            defaultColDef={defaultColDef}
            onGridReady={onGridTwoReady}
            domLayout={'autoHeight'}
            headerHeight={60}>
            <AgGridColumn
              hide={true}
              headerName=''
              field='name'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p>{params.value}</p>
              )}></AgGridColumn>
            <AgGridColumn
              headerName='Unit Name'
              field='unitName'
              flex={1}
              cellRendererFramework={UnitNameCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  rowData: params,
                };
              }}></AgGridColumn>
            <AgGridColumn
              headerName='Config'
              field='config'
              flex={1}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.configText}>{params.data.config}</p>
              )}></AgGridColumn>
            <AgGridColumn
              headerName='Config'
              field='config'
              flex={0.7}
              cellRendererFramework={ToggleButtonCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  rowData: params,
                };
              }}></AgGridColumn>
          </AgGridReact>
        </div>

        {/* Third Grid */}
        <div
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ height: '100%', width: '33%' }}>
          <AgGridReact
            rowData={inventory}
            defaultColDef={defaultColDef}
            onGridReady={onGridThreeReady}
            domLayout={'autoHeight'}
            headerHeight={60}>
            <AgGridColumn
              hide={true}
              headerName=''
              field='name'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p>{params.value}</p>
              )}></AgGridColumn>
            <AgGridColumn
              headerName='Unit Name'
              field='unitName'
              flex={1}
              cellRendererFramework={UnitNameCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  rowData: params,
                };
              }}></AgGridColumn>
            <AgGridColumn
              headerName='Config'
              field='config'
              flex={1}
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className={styles.configText}>{params.data.config}</p>
              )}></AgGridColumn>
            <AgGridColumn
              headerName='Config'
              field='config'
              flex={0.7}
              cellRendererFramework={ToggleButtonCell}
              cellRendererParams={(params: ICellRendererParams) => {
                return {
                  rowData: params,
                };
              }}></AgGridColumn>
          </AgGridReact>
        </div>
      </main>
    </>
  );
};

export default InventoryListGrid;
