import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { removeAdminUserLocal } from '../../pages/AdminLogin/utils';
import Icon from '../../../../../components/common/Icon';
import MoreMenu from '../MoreMenu';
import AlphabetAvatar from '../../../../../components/common/AlphabetAvatar/index';
import { useGetProjectsByUserIdQuery } from '../../../services/projectsAPISlice';
import { IProject } from '../../pages/SuperAdminDashboard/SuperAdminProjectList';
import { getAdminUser } from '../../../slices/adminSlice';
import { MenuItem } from '../MoreMenu';

import styles from './styles.module.css';

// Local Interfaces
interface IProjectSelection {
  executiveId?: string;
  project?: IProject; // Default selected project
  headerMoreMenuOptions?: MenuItem[];
  onProjectSelection: (project: IProject) => void;
}

const ProjectSelection = (props: IProjectSelection) => {
  let {
    executiveId,
    project,
    headerMoreMenuOptions = [],
    onProjectSelection,
  } = props;

  const history = useHistory();

  const adminUser = useSelector(getAdminUser);

  const { data: projectsByExecutive, isLoading } = useGetProjectsByUserIdQuery(
    executiveId || adminUser?.id
  );

  headerMoreMenuOptions.push({
    displayText: 'Log Out',
    icon: 'logout',
    onClick: () => {
      removeAdminUserLocal();
      history.push('/admin');
    },
  } as MenuItem);

  const handleProjectSelection = (project: IProject) => {
    onProjectSelection(project);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className={styles.projectSelection}>
      <header>
        <div className={styles.iconsContainer}>
          <div className={styles.apartmentIconContainer}>
            <Icon name='apartment' propStyles={styles.apartmentIcon}></Icon>
          </div>
          <div className={styles.fullscreenIconContainer}>
            <MoreMenu menuItems={headerMoreMenuOptions} />
          </div>
        </div>
        <h2 className={styles.headerTitle}>Select Project Name</h2>
        <p className={styles.headerDesc}>
          Choose the project from the list to add siteVisitDetails of site visit{' '}
          <br />
          customer for that project.
        </p>
      </header>

      <main>
        {projectsByExecutive && projectsByExecutive.length
          ? projectsByExecutive.map((_project) => {
              return (
                <div
                  className={styles.projectSelectorRadioBtnContainer}
                  key={_project.name}>
                  <input
                    type='radio'
                    name='projectsList'
                    id={_project.name}
                    value={_project.name}
                    checked={project?.id === _project.id ? true : false}
                    onChange={() => handleProjectSelection(_project)}
                  />
                  <label htmlFor={_project.name}>
                    <div className={styles.labelContainer}>
                      <div>
                        <AlphabetAvatar
                          alphabet={_project.name[0]}
                          uniqueNumber={0}
                        />
                      </div>
                      <div className={styles.radioBtnTextContainer}>
                        <p className={styles.projectNameInRadioBtn}>
                          {_project.name}
                        </p>
                        <p className={styles.developerNameInRadioBtn}>
                          {_project.company.displayName}
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              );
            })
          : 'No projects available'}
      </main>
    </div>
  );
};

export default ProjectSelection;
