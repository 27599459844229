// Components
import CpProfileBody from './CpProfileBody';
import CpProfileHeader from './CpProfileHeader/index';

const CpProfile = () => {
  return (
    <>
      <header>
        <CpProfileHeader />
      </header>
      <main>
        <CpProfileBody />
      </main>
    </>
  );
};

export default CpProfile;
