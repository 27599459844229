import { useState } from 'react';

import UserDetailsModal from '../../../modals/UserDetailsModal';
import { IUser } from '../../../../../../../interfaces';
import Icon from '../../../../../../../../../components/common/Icon';

interface IUserDetailsCell {
  user: IUser;
}

const UserDetailsCell = (props: IUserDetailsCell) => {
  const { user } = props;

  const [executiveDetailsModalShown, setExecutiveDetailsModalShown] =
    useState(false);

  return (
    <>
      {executiveDetailsModalShown && (
        <UserDetailsModal
          onModalClose={() => setExecutiveDetailsModalShown(false)}
          user={user}
        />
      )}
      <Icon
        name='details'
        onClick={() => setExecutiveDetailsModalShown(true)}
      />
    </>
  );
};

export default UserDetailsCell;
