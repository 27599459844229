import { useHistory } from 'react-router-dom';

import { isAdminUserLoggedIn } from './utils';
import LoginForm from './LoginForm';

import styles from './styles.module.css';

const AdminLogin = () => {
  const history = useHistory();

  if (isAdminUserLoggedIn()) {
    history.push('/');
  }

  const relataLoginPageBulletPoints = [
    'Monitor how your project is doing',
    'Work with your inventory to boost demand',
    'Manage Customer Leads and Queries',
    'Track Customer Engagement and Behaviour',
    'Manage Bookings,Payments and Agreement Generation',
  ];
  return (
    <div className={styles.adminLogin}>
      <div className={styles.adminLoginLeftContainer}>
        <div className={styles.relataLogo}>relata.io</div>
        <h2>Hi, lets get started!</h2>
        <ul>
          {relataLoginPageBulletPoints.map((point) => (
            <li key={point}>
              <span className={styles.bulletCircle}></span>
              <div>{point}</div>
            </li>
          ))}
        </ul>
      </div>
      <LoginForm />
    </div>
  );
};

export default AdminLogin;
