import { useSelector } from 'react-redux';
import { getSelectedScheme } from '../../../apps/booking/slices/selectedUnit';
import { toIndianRupee } from '../../../utils/utils';

const BookingAmountCard = (props) => {
  const selectedScheme = useSelector(getSelectedScheme);

  if (!selectedScheme) {
    return null;
  }

  // const firstMilestone =
  //   selectedScheme.milestones.slice().sort((firstEl, secondEl) => {
  //     return parseInt(firstEl.displayorder) > parseInt(secondEl.displayorder)
  //       ? 1
  //       : -1;
  //   })[0] || 0;

  // # 10% of total cost of first milestone
  const orderAmount = 100000;

  return (
    <div>
      <div className='info-card'>
        <header className='info-card__header'>Blocking Amount</header>
        <div className='info-card__body'>{toIndianRupee(orderAmount)}</div>
      </div>
    </div>
  );
};

export default BookingAmountCard;
