import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import TextInput from '../../../../../../../components/common/inputs/TextInput/index';
import Button from '../../../../../../../components/common/buttons/Button';
import { DetailsFormState } from './index';

import styles from './styles.module.css';

import { ISiteVisitDetails } from '..';
// import {
//   useOtpv2SendMutation,
//   useOtpv2VerifyMutation,
// } from '../../../../../services/adminUserAPISlice';
// import { useState } from 'react';

// enum OtpState {
//   IDLE,
//   SENT,
//   VERIFIED,
// }

//Local Interfaces
interface ICustomerDetailsProps {
  leadExists: boolean;
  onGoToSelectProjectPage: Function;
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitDetails: Function;
  setDetailsFormState: Function;
}

interface ICustomerDetails {
  customerName: string;
  customerEmail: string;
  customerMobile: string;
  location: string;
}

// Validation
const schema = yup.object().shape({
  customerName: yup.string().required(),
  customerEmail: yup.string().required(),
  // customerMobile: yup
  //   .string()
  //   .matches(/^[0-9]*$/)
  //   .length(10)
  //   .required(),
  location: yup.string().required(),
});

const CustomerDetails = (props: ICustomerDetailsProps) => {
  const {
    leadExists,
    setDetailsFormState,
    onGoToSelectProjectPage,
    siteVisitDetails,
    setSiteVisitDetails,
  } = props;

  // const dispatch = useDispatch();

  // const [otpState, setOtpState] = useState(OtpState.IDLE);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  // const [otpv2SendAPI] = useOtpv2SendMutation();
  // const [adminOtpVerifyAPI] = useOtpv2VerifyMutation();
  // const [addToast] = useToast();

  const customerDetailsNextBtnClickHandler = async (
    customerDetails: ICustomerDetails
  ) => {
    setSiteVisitDetails((prevState: ISiteVisitDetails) => {
      return { ...prevState, ...customerDetails };
    });

    setDetailsFormState(DetailsFormState.PROJECT);

    // TODO : Enable OTP
    // if (otpState === OtpState.VERIFIED) {
    //   setDetailsFormState(DetailsFormState.PROJECT);
    //   return;
    // }

    // if (otpState === OtpState.IDLE) {
    //   try {
    //     await otpv2SendAPI({
    //       address: customerDetails.customerMobile,
    //       type: 'MOBILE',
    //     }).unwrap();

    //     addToast({
    //       type: 'SUCCESS',
    //       primaryMessage: 'OTP Sent',
    //       secondaryMessage: '',
    //       timeout: 1000,
    //     });

    //     setOtpState(OtpState.SENT);
    //   } catch (err) {
    //     const errMsg = (err as any).customerFromDetails.message;
    //     dispatch(
    //       addToast({
    //         type: 'ERROR',
    //         primaryMessage: errMsg,
    //         secondaryMessage: '',
    //         timeout: 1000,
    //       })
    //     );
    //   }
    //   return;
    // }
  };

  const customerDetailsBackBtnClickHandler = () => {
    onGoToSelectProjectPage();
  };

  return (
    <form onSubmit={handleSubmit(customerDetailsNextBtnClickHandler)}>
      <main>
        <div className={styles.textField}>
          <TextInput
            label='Customer Name'
            placeHolder='Add Customer Name'
            register={register}
            setValue={setValue}
            name='customerName'
            defaultValue={siteVisitDetails.customerName}
            errorMessage={
              errors.customerName ? 'Customer name is required' : ''
            }
            readOnly={leadExists}
          />
        </div>
        <div className={styles.textField}>
          <TextInput
            label='Email Id *'
            placeHolder='Add Email Id'
            register={register}
            setValue={setValue}
            name='customerEmail'
            defaultValue={siteVisitDetails.customerEmail}
            errorMessage={errors.customerEmail ? 'Email Id is required' : ''}
            readOnly={leadExists}
          />
        </div>
        <div className={styles.textField}>
          <TextInput
            label='Phone Number'
            placeHolder='Add Phone Number'
            defaultValue={siteVisitDetails.customerMobile}
            disabled
            startIconText='+91'
          />
        </div>
        <div className={styles.textField}>
          <TextInput
            label='Location *'
            placeHolder='Add Location'
            register={register}
            setValue={setValue}
            name='location'
            defaultValue={siteVisitDetails.location}
            errorMessage={errors.location ? 'Location is required' : ''}
          />
        </div>
        {/* {detailsFormState === DetailsFormState.OTP && ( */}
        {/* {true && (
          <div className={styles.textField}>
            <p className={styles.otpMessage}>
              An 6 digit one time password has been sent to
              <span
                className={
                  styles.boldcustomerMobile
                }>{`  ${siteVisitDetails.customerMobile}`}</span>
            </p>
            <TextInput label='OTP' placeHolder='' />
          </div>
        )} */}
      </main>

      <footer className={styles.footer}>
        <Button
          propStyles={styles.sendOtpButton}
          onClick={customerDetailsBackBtnClickHandler}>
          Back
        </Button>
        <Button
          propStyles={styles.sendOtpButton}
          accent='primary'
          type='submit'>
          Next
        </Button>
      </footer>
    </form>
  );
};

export default CustomerDetails;
