import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import Icon from '../../../../../../../../components/common/Icon';

import { ICpUser } from '../../../../../../../cp/interfaces';
import UpdateCpUserModal from '../../../../../common/UpdateCpUserModal';

export interface IUpdateCpUserCellProps {
  cpUser: ICpUser;
  rowNode: ICellRendererParams['node'];
  onCpUserUpdate?: (updatedCpUser: ICpUser) => void;
  disabled: boolean;
}

const UpdateCpUserCell = (props: IUpdateCpUserCellProps) => {
  const { cpUser, rowNode, onCpUserUpdate, disabled } = props;

  const [updateCpUserModalShown, setUpdateCpUserModalShown] = useState(false);

  const handleUserUpdate = (updatedCpUser: ICpUser) => {
    rowNode.setData(updatedCpUser);
    onCpUserUpdate && onCpUserUpdate(updatedCpUser);
  };

  return (
    <>
      {updateCpUserModalShown && (
        <UpdateCpUserModal
          onModalClose={() => setUpdateCpUserModalShown(false)}
          cpUser={cpUser}
          onCpUserUpdate={handleUserUpdate}
        />
      )}
      <Icon
        name='person_outline'
        propStyles={`${disabled && 'click-disabled--icon'} `}
        onClick={() => setUpdateCpUserModalShown(true)}
      />
    </>
  );
};

export default UpdateCpUserCell;
