export const inventory = [
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Elderberry - A205',
    config: '3BHK - 3T',
    available: false,
  },
  {
    unitName: '3BHK - 3T',
    config: '3BHK - 3T',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
  {
    unitName: 'Daisy - A102',
    config: '3BHK - 3T - C1',
    available: true,
  },
];
