import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MasterBtn from './MasterBtn';
import TowerBtn from './TowerBtn';
import SectionBtn from './SectionBtn';
import FilterBtn from './FilterBtn';
import BlockBtn from './BlockBtn';
import FilterPreferences from './FilterPreferences';
import FilterPreferencesSummery from './FilterPreferencesSummery';
import ContentHeaderTitle from './ContentHeaderTitle';

import { NOT_FOUND } from '../../../constants/status';

import {
  updateUnitSelectionNavMenu,
  clearFilterPreferences,
  getSelectedTowerAvailableUnitCount,
  getSelectedSectionAvailableUnitCount,
  getSelectedBlockAvailableUnitCount,
} from '../../../apps/booking/slices/contentHeader/contentHeaderNav';

const UnitSelectionContentHeader = () => {
  const dispatch = useDispatch();
  const selectedTowerAvaialableUnitCount = useSelector(
    getSelectedTowerAvailableUnitCount
  );
  const selectedSectionAvaialableUnitCount = useSelector(
    getSelectedSectionAvailableUnitCount
  );
  const selectedBlockAvaialableUnitCount = useSelector(
    getSelectedBlockAvailableUnitCount
  );
  const [showFilterPreference, setFilterPreference] = useState(false);
  const unitSelectionContentHeaderRef = useRef();
  const setFilterPreferenceWrap = (show) => {
    if (!show) {
      dispatch(updateUnitSelectionNavMenu());
    }
    setFilterPreference(show);
  };
  const handleClearFilter = () => {
    dispatch(clearFilterPreferences());
    dispatch(updateUnitSelectionNavMenu());
  };
  return (
    <div ref={unitSelectionContentHeaderRef}>
      <header className='content-header'>
        <ContentHeaderTitle showFilterPreference={showFilterPreference} />
        <div
          className='content-header__nav'
          onClick={() =>
            showFilterPreference && setFilterPreferenceWrap(false)
          }>
          <nav className='nav'>
            <ul className='list nav__list'>
              <li className='nav__item'>
                <MasterBtn />
              </li>
              <li className='nav__item'>
                <span className='icon material-icons'></span>
              </li>
              <li className='nav__item'>
                <TowerBtn />
                {selectedTowerAvaialableUnitCount !== NOT_FOUND && (
                  <small className='nav__item-desc'>
                    <span className='f-bold'>
                      {selectedTowerAvaialableUnitCount}
                    </span>{' '}
                    units available
                  </small>
                )}
              </li>
              <li className='nav__item'>
                <span className='icon material-icons'></span>
              </li>
              <li className='nav__item'>
                <SectionBtn />
                {selectedTowerAvaialableUnitCount !== NOT_FOUND &&
                  selectedSectionAvaialableUnitCount !== NOT_FOUND && (
                    <small className='nav__item-desc'>
                      <span className='f-bold'>
                        {selectedSectionAvaialableUnitCount}
                      </span>{' '}
                      units available
                    </small>
                  )}
              </li>
              <li className='nav__item'>
                <BlockBtn />
                {selectedBlockAvaialableUnitCount !== NOT_FOUND && (
                  <small className='nav__item-desc'>
                    <span className='f-bold'>
                      {selectedBlockAvaialableUnitCount}
                    </span>{' '}
                    units available
                  </small>
                )}
              </li>
              <li className='nav__item nav__vbar'></li>
              <li className='nav__item '>
                <FilterBtn
                  showFilterPreference={showFilterPreference}
                  toggleFilterPreferenceContainer={(val) =>
                    setFilterPreferenceWrap(val)
                  }
                  handleClearFilter={handleClearFilter}
                  unitSelectionContentHeaderRef={unitSelectionContentHeaderRef}
                />
              </li>
            </ul>
          </nav>
        </div>
        {!showFilterPreference && (
          <FilterPreferencesSummery
            toggleFilterPreferenceContainer={() =>
              setFilterPreferenceWrap(!showFilterPreference)
            }
          />
        )}
      </header>

      {showFilterPreference && <FilterPreferences />}
    </div>
  );
};

export default UnitSelectionContentHeader;
