import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import FadeInFadeOutAnim from '../../../../components/common/FadeInFadeOutAnim';
import { isAdminUserRole, getAdminUserRole } from '../pages/AdminLogin/utils';
import ExecutiveDashboard from '../pages/ExecutiveDashboard';
import SuperAdminDashboard from '../pages/SuperAdminDashboard/SuperAdminProjectList';
import LeadsDashboard from '../pages/ActiveLeads/LeadsDashboard';
import AdminDashboard from '../pages/AdminDashboard/AdminExecutiveList/index';
import AdminDashboardAnalytics from '../pages/AdminDashboard/AdminDashboardAnalytics/index';
import AdminDashboardProjectList from '../pages/AdminDashboard/ProjectList/index';
import OrganizationsList from '../pages/SuperAdminDashboard/CompanyList/index';
import InventoryManagement from './../pages/InventoryManagement/index';
import CpProfile from './../pages/CpProfile/index';
import ActiveCpListAndPendingCpApproval from './../pages/ActiveCpListAndPendingCpApproval/index';
import { UserRole } from '../../../../constants/userRoles';
import { URLS } from '../../../../constants/urls';
import { getAdminUser } from '../../slices/adminSlice';

const AdminContent = () => {
  const { path } = useRouteMatch();

  const getDefaultRedirect = () => {
    return `${URLS[getAdminUserRole()]}`;
  };

  const adminUser = useSelector(getAdminUser);

  return (
    <div className='content'>
      <div className='content-body'>
        <FadeInFadeOutAnim width='100%' height='100%'>
          <Switch>
            <Route path={`${path}super-admin`}>
              {/* If the logged in user don't have permission to access this url, user will be redirected to appropriate dashboard based on his role */}
              {isAdminUserRole(UserRole.SUPERADMIN) ? (
                <SuperAdminDashboard />
              ) : (
                <Redirect to={URLS[adminUser.role]} />
              )}
            </Route>

            <Route exact path={`${path}executive`}>
              {isAdminUserRole(UserRole.EXECUTIVE) ? (
                <ExecutiveDashboard />
              ) : (
                <Redirect to={URLS[adminUser.role]} />
              )}
            </Route>

            <Route exact path={`${path}admin-dashboard`}>
              {/* If the logged in user don't have permission to access this url, user will be redirected to appropriate dashboard based on his role */}
              {isAdminUserRole(UserRole.ADMIN) ? (
                <AdminDashboard />
              ) : (
                <Redirect to={URLS[adminUser.role]} />
              )}
            </Route>

            <Route exact path={`${path}analytics-dashboard`}>
              <AdminDashboardAnalytics />
            </Route>

            <Route exact path={`${path}admin-dashboard-projects`}>
              {/* If the logged in user don't have permission to access this url, user will be redirected to appropriate dashboard based on his role */}
              {isAdminUserRole(UserRole.ADMIN) ? (
                <AdminDashboardProjectList />
              ) : (
                <Redirect to={URLS[adminUser.role]} />
              )}
            </Route>

            <Route exact path={`${path}super-admin-organizations-list`}>
              {/* If the logged in user don't have permission to access this url, user will be redirected to appropriate dashboard based on his role */}
              {isAdminUserRole(UserRole.SUPERADMIN) ? (
                <OrganizationsList />
              ) : (
                <Redirect to={URLS[adminUser.role]} />
              )}
            </Route>

            <Route exact path={`${path}inventory-management`}>
              <InventoryManagement />
            </Route>

            <Route exact path={`${path}cp-profile`}>
              <CpProfile />
            </Route>

            <Route exact path={`${path}cp-list`}>
              <ActiveCpListAndPendingCpApproval />
            </Route>

            <Route exact path={`${path}executive/leads`}>
              <LeadsDashboard />
            </Route>

            <Redirect to={getDefaultRedirect()} />
          </Switch>
        </FadeInFadeOutAnim>
      </div>
    </div>
  );
};

export default AdminContent;
