import { ICellRendererParams } from 'ag-grid-community';
import Icon from '../../../../../../../components/common/Icon';

const ExpandRowCell = (props: ICellRendererParams) => {
  const { node } = props;

  const handleToggle = () => {
    if (node.isExpandable()) {
      node.setExpanded(!node.expanded);
    }
  };

  return (
    <div
      className={`custom-cell-container ${node.expanded && 'expanded'}`}
      onClick={() => handleToggle()}>
      <Icon name={node.expanded ? 'arrow_drop_up' : 'arrow_drop_down'} />
    </div>
  );
};

export default ExpandRowCell;
