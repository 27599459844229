import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { DropDown } from '../DropDown';
import FileUpload from '../common/FileUpload';
import S3Client from '../../utils/S3Client';
import {
  titleDropDown,
  applicantTypeDropDown,
  countryCodeDropDown,
  nationalityCodeDropDown,
  residentIndiaDropDown,
  marriageStatusDropDown,
  educationalQualificationDropDown,
} from '../../utils/applicantFormDropDownOptions';
import {
  SAVE_ICON,
  EIDT_ICON,
  DELETE_ICON,
  PRIMARY_APPLICANT_ICON,
  SECONDARY_APPLICANT_ICON,
} from '../../constants/icons';
import { profileFallback } from '../../constants/urls';

const APPLICANT_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

// Validation
const schema = yup.object().shape({
  fname: yup.string().required(),
  lname: yup.string().required(),
  fathers_name: yup.string().required(),
  email: yup.string().email().required(),
  mobile_no: yup
    .string()
    .matches(/^[0-9]*$/)
    .required(),
  dob: yup.string().required(),
  pan_no: yup.string().required().length(10),
  aadhar_no: yup.string().length(12).required(),
  nationality: yup.string().required(),
});

const KycApplicantFrom = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleDelete,
    handleMakePrimaryApplicant,
    handleSave,
    isPrimary,
    isEditFormOpenByDefault = false, // Only new applicant will have isEditFormOpenByDefault = true
    updateIsEditFormOpenStatus,
  } = props;

  const {
    id: applicantId,
    fname, //Yes
    // mname = '',
    lname = '', //Yes
    fathers_name = '', //Yes
    mobile_no, //Yes
    dob, //Yes
    email, //Yes
    pan_no, //Yes
    pan_doc_url,
    aadhar_no, //Yes
    aadhar_doc_url,
    educational_qualification,
    profession,
    company_name,
    designation,
    marriage_status,
    // passport_no,
    indian_resident,
    title = 'mr',
    embassy_coapplicant_id,
  } = props.applicant;

  const [isEditFormOpen, setIsEditFormOpen] = useState(isEditFormOpenByDefault);
  const [fileUploadingStatuses, setFileUploadingStatuses] = useState({});
  const submitBtnEnabled = Object.values(fileUploadingStatuses).every(
    (status) => !status
  );

  const type = isPrimary ? APPLICANT_TYPES.PRIMARY : APPLICANT_TYPES.SECONDARY;
  const countrycode = props.applicant.countrycode || '91';
  const nationality = props.applicant.nationality || 'Indian'; //Yes
  const applicatnDefaultValues = props.applicant;

  // Watch dropdown values
  const selectedTitleValue = watch('title', title);
  const selectedTypeValue = watch('type', type);
  const selectedCountrycodeValue = watch('countrycode', countrycode);
  const selectedNationalityValue = watch('nationality', nationality);
  const selectedIsResidentOfIndexValue = watch(
    'indian_resident',
    indian_resident
  );
  const selectedEducationalqualificationValue = watch(
    'educational_qualification',
    educational_qualification
  );
  const selectedMarriageStatusValue = watch('marriage_status', marriage_status);
  // Upload file urls
  const selectedAdharDocUrlValue = watch('aadhar_doc_url', aadhar_doc_url);
  const selectedPanDocUrlValue = watch('pan_doc_url', pan_doc_url);

  useEffect(() => {
    // Register dropdown values
    register('title', { value: title });
    register('type', { value: type });
    register('countrycode', { value: countrycode });
    register('nationality', { value: nationality });
    register('indian_resident', { value: indian_resident });
    register('marriage_status', { value: marriage_status });
    register('eduactional_qualification', { value: educational_qualification });
    // Upload file urls
    register('aadhar_doc_url', { value: aadhar_doc_url });
    register('pan_doc_url', { value: pan_doc_url });
  }, [
    register,
    title,
    type,
    countrycode,
    nationality,
    indian_resident,
    marriage_status,
    educational_qualification,
    aadhar_doc_url,
    pan_doc_url,
  ]);

  useEffect(() => {
    updateIsEditFormOpenStatus(applicantId, isEditFormOpen);
  }, [applicantId, isEditFormOpen, updateIsEditFormOpenStatus]);

  const handleDropDownSelect = (name, value) => {
    setValue(name, value, true);
  };

  const handleFileUpload = (name, value) => {
    setValue(name, value, true);
  };

  const handleIsFileUploading = (fileId, isUploadInProgress) => {
    setFileUploadingStatuses((prevState) => {
      return {
        ...prevState,
        [fileId]: isUploadInProgress,
      };
    });
  };

  const onSubmit = (data) => {
    setIsEditFormOpen(false);
    handleSave({ ...data, embassy_coapplicant_id });
  };

  return (
    <form className='kyc-applicant' onSubmit={handleSubmit(onSubmit)}>
      <header className='kyc-applicant__header'>
        <div className='kyc-applicant__header-title'>
          <div className='thumb'>
            <img className='img-fluid' src={profileFallback} alt='' />
          </div>
          <div className='kyc-applicant-name-container'>
            <span className='kyc-applicant-name c-56 f-20 f-bold'>{`${fname} ${lname}`}</span>
            <span className='kyc-applicant-type f-15'>
              {type === APPLICANT_TYPES.PRIMARY
                ? 'Primary Applicant'
                : 'Co-Applicant'}
            </span>
          </div>
        </div>
        <div className='kyc-applicant__header-action-container c-56'>
          {/* Disabled due to time contraint */}
          {/* <div className="kyc-applicant-pregress-complete">
            <span>Completed</span> <span className="f-bold">10/10</span>
          </div> */}
          <div
            className='kyc-applicant-edit click-enabled'
            onClick={() => {
              if (isEditFormOpen) {
                handleSubmit(onSubmit)();
              } else {
                setIsEditFormOpen(true);
              }
            }}>
            <span className='icon-container'>
              <span className='icon material-icons'>
                {isEditFormOpen ? SAVE_ICON : EIDT_ICON}
              </span>
            </span>
            {isEditFormOpen ? 'Save Info' : 'Edit Info'}
          </div>
          <span
            className={`icon-container ${handleDelete && 'click-enabled'} ${
              !handleDelete && 'click-disabled'
            }`}>
            <span
              className='icon material-icons icon-delete'
              onClick={() => handleDelete(applicantId)}>
              {DELETE_ICON}
            </span>
          </span>
          <span
            className='icon-container click-disabled'
            onClick={() => handleMakePrimaryApplicant(applicantId)}>
            <span className='icon material-icons'>
              {type === APPLICANT_TYPES.PRIMARY
                ? PRIMARY_APPLICANT_ICON
                : SECONDARY_APPLICANT_ICON}
            </span>
          </span>
        </div>
      </header>
      <div className={`kyc-applicant__body ${!isEditFormOpen && 'hide'}`}>
        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>Basic Information</span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>
          </div>
          <div className='kyc-applicant-info-section__body'>
            <div className='input input--secondary'>
              <label className='input_label'>First name *</label>
              <div>
                <DropDown
                  cname={titleDropDown.cname}
                  defaultValue={selectedTitleValue}
                  options={titleDropDown.options}
                  handleDropDownSelect={(value) =>
                    handleDropDownSelect('title', value)
                  }
                />
                <input
                  type='text'
                  className={`input_field ${isPrimary && 'click-disabled'}`}
                  {...register('fname')}
                  defaultValue={fname}
                />
              </div>
              <span className='input__error'>
                {errors.fname && 'First name is required'}
              </span>
            </div>

            <div className='input'>
              <label className='input_label'>Last name *</label>
              <input
                type='text'
                className={`input_field ${isPrimary && 'click-disabled'}`}
                {...register('lname')}
                defaultValue={lname}
              />
              <span className='input__error'>
                {errors.lname && 'Last name is required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Email ID *</label>
              <input
                type='text'
                className={`input_field ${isPrimary && 'click-disabled'}`}
                {...register('email')}
                defaultValue={email}
              />
              <span className='input__error'>
                {errors.email && 'Email ID required'}
              </span>
            </div>
            <div className='input input--secondary'>
              <label className='input_label'>Mobile number *</label>
              <div>
                <DropDown
                  cname={countryCodeDropDown.cname}
                  defaultValue={selectedCountrycodeValue}
                  options={countryCodeDropDown.options}
                  handleDropDownSelect={(value) =>
                    handleDropDownSelect('countrycode', value)
                  }
                />
                <input
                  type='number'
                  className={`input_field ${isPrimary && 'click-disabled'}`}
                  {...register('mobile_no')}
                  defaultValue={mobile_no}
                />
              </div>
              <span className='input__error'>
                {errors.mobile_no && 'Please enter valid mobile number'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>DOB *</label>
              <input
                type='date'
                className='input_field'
                {...register('dob')}
                defaultValue={dob}
              />
              <span className='input__error'>
                {errors.dob && 'DOB required'}
              </span>
            </div>
            <div className='input'>
              <label className='input_label'>Applicant Type</label>
              <DropDown
                cname={`${applicantTypeDropDown.cname} click-disabled`}
                defaultValue={selectedTypeValue}
                options={applicantTypeDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('type', value)
                }
              />
            </div>
            <div className='input'>
              <label className='input_label'>Fathers/Spouse name *</label>
              <input
                type='text'
                className='input_field'
                {...register('fathers_name')}
                defaultValue={fathers_name}
              />
              <span className='input__error'>
                {errors.fathers_name && 'Fathers/Spouse name required'}
              </span>
            </div>
          </div>
        </section>
        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>Upload Documents</span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>
          </div>
          <div className='kyc-applicant-info-section__body'>
            <div className='input input--first-name'>
              <label className='input_label'>Nationality *</label>
              <DropDown
                cname={nationalityCodeDropDown.cname}
                defaultValue={selectedNationalityValue}
                options={nationalityCodeDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('nationality', value)
                }
              />
              <span className='input__error'>
                {errors.nationality?.message}
              </span>
            </div>

            <div className='input'>
              <label className='input_label'>Indian Resident *</label>
              <DropDown
                cname={residentIndiaDropDown.cname}
                defaultValue={selectedIsResidentOfIndexValue}
                options={residentIndiaDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('indian_resident', value)
                }
              />
              <span className='input__error'>
                {errors.indian_resident?.message}
              </span>
            </div>
            {/* TODO remove block-hide  */}
            <div className='input block-hide'>
              {/* <label className="input_label">Citizen of</label>
              <input
                type="text"
                className="input_field"
                {...register('citizenship')}
                defaultValue={citizenship}
              /> */}
            </div>
            <div className='input'>
              <label className='input_label'>Aadhar Card Number *</label>
              <input
                type='text'
                placeholder='Enter Number here'
                className='input_field'
                {...register('aadhar_no')}
                defaultValue={aadhar_no}
                maxlength='12'
              />
              <span className='input__error'>
                {errors.aadhar_no && '12 digit Aadhar Number required'}
              </span>
            </div>

            <FileUpload
              cname='input--upload-width-till-end'
              lable='Upload Aadhar Card'
              s3Url={selectedAdharDocUrlValue}
              userId={applicantId}
              documentType={S3Client.adharDocType}
              handleIsFileUploading={handleIsFileUploading}
              handleFileUpload={(value) =>
                handleFileUpload('aadhar_doc_url', value)
              }
            />
            <div className='input'>
              <label className='input_label'>Pan Card Number *</label>
              <input
                type='text'
                placeholder='Enter Number here'
                className='input_field'
                {...register('pan_no')}
                defaultValue={pan_no}
                maxlength='10'
              />

              <span className='input__error'>
                {errors.pan_no && '10 character Pan Number required'}
              </span>
            </div>

            <FileUpload
              cname='input--upload-width-till-end'
              lable='Upload Pan Card'
              s3Url={selectedPanDocUrlValue}
              userId={applicantId}
              documentType={S3Client.panDocType}
              handleIsFileUploading={handleIsFileUploading}
              handleFileUpload={(value) =>
                handleFileUpload('pan_doc_url', value)
              }
            />
          </div>
        </section>
        <section className='kyc-applicant-info-section'>
          <div className='kyc-applicant-info-section__title'>
            <span className='c-accent f-18'>Education & Profession</span>
            <div className='kyc-applicant-info-section__title-dot-line'></div>
          </div>
          <div className='kyc-applicant-info-section__body'>
            <div className='input input--first-name'>
              <label className='input_label'>Educational qualification</label>
              <DropDown
                cname={educationalQualificationDropDown.cname}
                defaultValue={selectedEducationalqualificationValue}
                options={educationalQualificationDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('educational_qualification', value)
                }
              />
            </div>
            <div className='input'>
              <label className='input_label'>Profession</label>
              <input
                type='text'
                className='input_field'
                {...register('profession')}
                defaultValue={profession}
                placeholder='Enter Profession'
              />
            </div>
            <div className='input'>
              <label className='input_label'>Name of the company</label>
              <input
                type='text'
                className='input_field'
                {...register('company_name')}
                defaultValue={company_name}
                placeholder='Enter Name of the company'
              />
            </div>
            <div className='input'>
              <label className='input_label'>Designation</label>
              <input
                type='text'
                placeholder='Enter Designation'
                className='input_field'
                {...register('designation')}
                defaultValue={designation}
              />
            </div>
            <div className='input'>
              <label className='input_label'>Marrital Status</label>
              <DropDown
                cname={marriageStatusDropDown.cname}
                defaultValue={selectedMarriageStatusValue}
                options={marriageStatusDropDown.options}
                handleDropDownSelect={(value) =>
                  handleDropDownSelect('marriage_status', value)
                }
              />
            </div>
          </div>
        </section>

        <footer className='kyc-applicant__footer'>
          <button
            className='btn btn--transparent'
            onClick={() => {
              reset(applicatnDefaultValues);
            }}>
            Discard
          </button>
          <input
            type='submit'
            className={`btn ${submitBtnEnabled && 'btn--enabled'} ${
              !submitBtnEnabled && 'btn--disabled'
            }
            `}
          />
        </footer>
      </div>
    </form>
  );
};

export default KycApplicantFrom;
