export const CORRECT_TICK_ICON = 'check';
export const CROSS_TICK_ICON = 'close';
export const DELETE_ICON = 'delete';
export const SAVE_ICON = 'save';
export const EIDT_ICON = 'edit';
export const DOWNLOAD_ICON = 'file_download';
export const UPLOAD_ICON = 'upload_file';
export const PRIMARY_APPLICANT_ICON = 'star';
export const SECONDARY_APPLICANT_ICON = 'star_outline';
export const BLOCK = 'block';
export const ADD_LINK = 'add_link';
export const PERSON_ADD = 'person_add';
export const EVENT = 'event';
export const CONTENT_COPY = 'content_copy';
export const SEARCH = 'search';
export const MORE_VERT = 'more_vert';
export const ERROR_OUTLINE = 'error_outline';
export const ROTATE_RIGHT = 'rotate_right';
export const PERSON_OUTLINE = 'person_outline';
export const HIGHLIGHT_OFF = 'highlight_off';
export const FIBER_SMART_RECORD = 'fiber_smart_record';
export const BOOKMARK_ADDED = 'bookmark_added';
export const PERSON = 'person';
export const INFO = 'info';
export const LAYERS = 'layers';
export const REMOVE_CIRCLE = 'remove_circle';
export const APP_BLOCKING = 'app_blocking';

export const LOCATION = 'place';
export const MAIL = 'local_post_office';
export const PHONE = 'local_phone';
export const CALL = 'call';

export const COMPLETE_ICON = 'task_alt';
export const INCOMPLETE_ICON = 'schedule';

export const UP_ICON = 'arrow_upward';
export const DOWN_ICON = 'arrow_downward';
export const GITE = 'gite';
