import { ICpUser } from '../../../interfaces';

const cpUserLocalStorageItemName = 'cpUser';

export const setCpUserLocal = (cpUser: ICpUser) => {
  localStorage.setItem(cpUserLocalStorageItemName, JSON.stringify(cpUser));
};

// Only used to set initial state of cpUserSlice
export const getCpUserLocal = (): ICpUser => {
  const cpUserStr = localStorage.getItem(cpUserLocalStorageItemName) as string;
  return JSON.parse(cpUserStr);
};

export const removeCpUserLocal = () => {
  localStorage.removeItem(cpUserLocalStorageItemName);
};
