import { useState } from 'react';

import VsgLinkModal from '../../modals/VsgLinkModal';
import IconWithLabel from '../../../../../common/IconWithLabel';

interface IAddVsgCell {
  projectId: string;
  disabled: boolean;
  projectName: string;
  vrUrl: string;
  onRowUpdate: Function;
}

const AddVsgCell = (props: IAddVsgCell) => {
  const { projectId, projectName, disabled, vrUrl, onRowUpdate } = props;

  const [showVsgLinkModal, setShowVsgLinkModal] = useState(false);

  return (
    <>
      {showVsgLinkModal && (
        <VsgLinkModal
          projectId={projectId}
          projectName={projectName}
          vrUrl={vrUrl}
          onModalClose={() => setShowVsgLinkModal(false)}
          onRowUpdate={onRowUpdate}
        />
      )}
      <IconWithLabel
        onClick={() => setShowVsgLinkModal(true)}
        iconName='add_link'
        label='VSG Link'
        disabled={disabled}
      />
    </>
  );
};

export default AddVsgCell;
