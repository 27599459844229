import { createRef, useEffect, useMemo, useState } from 'react';

import Loader from 'react-spinners/BarLoader';
import useToast from '../../../../hooks/useToast';
import S3ClientNew from '../../../../utils/S3ClientNew';
import Icon from '../../Icon';

import styles from './style.module.css';

interface IFileUploadNewProps {
  placeholder: string;
  label: string;
  s3DirPath: string;
  onChangeFileUploadStatus?: (
    fileId: string,
    fileUploadStatus: boolean
  ) => void;
  onFileUpload?: (fileLocation: string) => void;
  defaultValue?: string;
  errorMessage?: any;

  // useForm
  register?: Function;
  unregister?: Function;
  name?: string;
  setValue?: Function;

  appType?: 'RELATA' | 'RELATA-CP' | 'RELATA-CR';
  propStyles?: any;
}

const FileUploadNew = (props: IFileUploadNewProps) => {
  const {
    placeholder,
    label,
    s3DirPath,
    onChangeFileUploadStatus,
    onFileUpload,
    defaultValue = '',
    errorMessage,

    // useForm
    register = () => {
      return {
        name,
        ref: createRef(),
      };
    },
    unregister,
    name = '',
    setValue,

    appType,
    propStyles,
  } = props;

  const [showFileUploadingAnimation, setShowFileUploadingAnimation] =
    useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(defaultValue);
  const [addToast] = useToast();

  useEffect(() => {
    setValue && setValue(name, defaultValue);
  }, [defaultValue, name, setValue]);

  useEffect(() => {
    register(name);

    return () => {
      unregister && unregister(name);
    };
  }, [register, unregister, name]);

  const handleFileUpload = (e: any) => {
    if (!e.target.files[0]) {
      // No file selected
      return;
    }

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
    const fileSizeLimit = 5;

    if (fileSize > fileSizeLimit) {
      addToast({
        type: 'ERROR',
        primaryMessage: `File size exceeds ${fileSizeLimit}MB`,
      });
      e.target.value = '';
      return;
    }

    setShowFileUploadingAnimation(true);
    onChangeFileUploadStatus && onChangeFileUploadStatus(s3DirPath, true);

    S3ClientNew.uploadFile(file, fileName, s3DirPath).then(
      (data: { status: number; location: string }) => {
        if (data.status === 204) {
          onFileUpload && onFileUpload(data.location);
          setValue && setValue(name, data.location);
          setUploadedFileName(fileName);
        } else {
          addToast({
            type: 'ERROR',
            primaryMessage: 'File upload error',
          });
        }

        setShowFileUploadingAnimation(false);
        onChangeFileUploadStatus && onChangeFileUploadStatus(s3DirPath, false);
      }
    );
  };

  return (
    <div className={styles.inputContainer}>
      <label className={styles.inputLabel} data-app={appType}>
        {label}
      </label>
      <div className={styles.inputBox} data-app={appType}>
        <div className={styles.inputBoxLeftContent}>
          <input
            id={s3DirPath}
            type='file'
            accept='jpg/jpeg/png/pdf'
            className={styles.inputField}
            placeholder={placeholder}
            onChange={handleFileUpload}
          />
          <label className={styles.fileNameLabel} htmlFor={s3DirPath}>
            {showFileUploadingAnimation
              ? 'Uploading...'
              : S3ClientNew.fileName(uploadedFileName) || placeholder}
          </label>

          {showFileUploadingAnimation && <Loader />}
          <span className={styles.inputError}>{errorMessage}</span>
          <Icon name='upload_file' propStyles={`${styles.endIcon}`} />
        </div>
      </div>
    </div>
  );
};

export default FileUploadNew;
