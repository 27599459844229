import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import UnitDetailsModal from '../../UnitDetailsModal/UnitDetailsModal';

import styles from './styles.module.css';

interface IUnitNameCell {
  rowData: ICellRendererParams;
}

const UnitNameCell = (props: IUnitNameCell) => {
  const { rowData } = props;

  const [unitDetailsModalShown, setUnitDetailsModalShown] = useState(false);

  return (
    <>
      <UnitDetailsModal
        unitDetailsModalShown={unitDetailsModalShown}
        handelUnitDetailsModalClose={() => setUnitDetailsModalShown(false)}
        rowData={rowData}
      />

      <div
        className={styles.unitName}
        onClick={() => setUnitDetailsModalShown(true)}>
        {rowData.data.unitName}
      </div>
    </>
  );
};

export default UnitNameCell;
