import Image from '../Image';
import { BrandCardType } from './Interface';

const BrandCardLarge = (props: BrandCardType) => {
  const { brandName, groupName, imgUrl } = props;
  return (
    <div className='brand-card'>
      <div className='brand-card__logo'>
        <Image
          imgClass='brand-card__logo-image'
          src={imgUrl}
          alt='Brand Details'
        />
      </div>

      <div className='brand-card__content'>
        <div className='brand-card__project-name'>{brandName}</div>
        <div className='brand-card__group-name'>
          <span className='brand-card__group-name-label'>Brand </span>
          <span className='brand-card__group-name-value'>{groupName}</span>
        </div>
        <div className='brand-card__executives'></div>
      </div>
    </div>
  );
};

export default BrandCardLarge;
