import 'ag-grid-enterprise';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import ExpandRowCell from './../../cell-renderers/ExpandRowCell';
import HotnessIndicatorCell from './../../cell-renderers/HotnessIndicatorCell';
import NameCard from './../../../../../../../components/common/admin/NameCard';
import ApproveAndRejectButtonsCell from './ApproveAndRejectButtonsCell';
import LeadDetailsGrid from './LeadDetailsGrid';
import { awsBaseUrl } from './../../../../../../../constants/urls';
import { UserAction, IUserAction } from '../../../ActiveLeads';
import { HotnessIndicator } from '../../../ActiveLeads';
import { cpLeadRequestData } from './../mockData';

import styles from './styles.module.css';

const CpLeadRequestGrid = () => {
  const getHotnessIconName = (val: HotnessIndicator): string => {
    let icon: string;
    switch (val) {
      case HotnessIndicator.HOT:
        icon = 'whatshot';
        break;
      case HotnessIndicator.WARM:
        icon = 'thermostat';
        break;
      default:
        icon = 'ac_unit';
    }
    return icon;
  };

  const getContainerClassName = (val: HotnessIndicator): string => {
    let container: string;
    switch (val) {
      case HotnessIndicator.HOT:
        container = 'hot-indicator';
        break;
      case HotnessIndicator.WARM:
        container = 'warm-indicator';
        break;
      case HotnessIndicator.ROOM:
        container = 'room-indicator';
        break;
      case HotnessIndicator.COLD:
        container = 'cold-indicator';
        break;
      default:
        container = '';
    }
    return container;
  };

  const getLeadImageUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.leadName[0].toUpperCase()}${+params
      .data.hotness}.png`;
  };

  return (
    <div>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={cpLeadRequestData}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          masterDetail={true}
          detailCellRenderer={'detailCellRenderer'}
          detailRowAutoHeight={true}
          frameworkComponents={{
            detailCellRenderer: LeadDetailsGrid,
          }}
          animateRows={true}
          onGridReady={() => {}}>
          <AgGridColumn
            flex={0.8}
            cellClass='ag-grid-column-custom-cell'
            cellRendererFramework={ExpandRowCell}
            cellRendererParams={(params: ICellRendererParams) =>
              params
            }></AgGridColumn>
          <AgGridColumn
            flex={0.6}
            headerComponentFramework={HotnessIndicatorCell}
            headerComponentParams={{
              containerClassName: 'ag-grid-header-indicator-icon',
              iconName: 'thermostat',
            }}
            field='hotness'
            cellRendererFramework={HotnessIndicatorCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                iconName: getHotnessIconName(params.value),
                containerClassName: getContainerClassName(params.value),
              };
            }}></AgGridColumn>
          <AgGridColumn
            flex={2.5}
            headerName='Name'
            field='leadName'
            cellRendererFramework={(params: ICellRendererParams) => {
              return (
                <div className={styles.projectsColumnDataContainer}>
                  <NameCard
                    {...{
                      imgUrl: `${getLeadImageUrl(params)}`,
                      title: params.data.leadName,
                      subtitle: params.data.leadEmail,
                    }}
                  />
                </div>
              );
            }}></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='Request Submitted On'
            cellRendererFramework={(params: ICellRendererParams) => (
              <>
                <p className={styles.cpName}>
                  {params.data.requestSubmittedOn.cpName}
                </p>
                <div className={styles.reqSubmittedDate}>
                  {params.data.requestSubmittedOn.submissionDate}
                </div>
              </>
            )}></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='Last Assigned On'
            cellRendererFramework={(params: ICellRendererParams) => (
              <>
                <p className={styles.cpName}>
                  {params.data.lastAssignedOn.cpName}
                </p>
                <div className={styles.reqSubmittedDate}>
                  {params.data.lastAssignedOn.assignedDate}
                </div>
              </>
            )}></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='Last Activity On'
            cellRendererFramework={(params: ICellRendererParams) => {
              const leadAction: keyof IUserAction =
                params.data.lastActivityOn.userAction;
              return (
                <>
                  <p className={styles.cpName}>
                    {UserAction[leadAction].displayText}
                  </p>
                  <div className={styles.reqSubmittedDate}>
                    {params.data.lastActivityOn.actionDate}
                  </div>
                </>
              );
            }}></AgGridColumn>
          <AgGridColumn
            flex={2}
            cellRendererFramework={ApproveAndRejectButtonsCell}></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
};

export default CpLeadRequestGrid;
