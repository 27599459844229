import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUpdateProjectMutation } from '../../../../../../services/projectsAPISlice';
import useToast from '../../../../../../../../hooks/useToast';
import Modal from '../../../../../../../../components/common/modal/Modal';
import TextInput from '../../../../../../../../components/common/inputs/TextInput';
import Button from '../../../../../../../../components/common/buttons/Button';
import Icon from '../../../../../../../../components/common/Icon';
import { IProject } from '../..';
import SecondaryCompanyList from '../AddNewProjectModal/SecondaryCompanyList';
import { useGetCompaniesQuery } from '../../../../../../services/companyAPISlice';

import styles from '../AddNewProjectModal/styles.module.css';

interface IUpdateProjectModal {
  onModalClose: Function;
  project: IProject;
  onProjectUpdate: (updatedProject: IProject) => void;
}

// Validation
const schema = yup.object().shape({
  name: yup.string().required(),
  rera: yup.string().required(),
  city: yup.string().required(),
  reraUrl: yup.string().required(),
  pocEmail: yup.string().email().required(),
  pocMobile: yup.string().required(),
});

const UpdateProjectModal = (props: IUpdateProjectModal) => {
  const { onModalClose: handleModalClose, project, onProjectUpdate } = props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [addToast] = useToast();
  const [updateProject] = useUpdateProjectMutation();

  const { data: companies } = useGetCompaniesQuery({});

  const handleUpdateProject = async (projectUpdatedData: IProject) => {
    try {
      const updatedProject = await updateProject({
        ...projectUpdatedData,
        projectId: project.id,
      }).unwrap();

      onProjectUpdate(updatedProject.data);

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Project successfully Updated',
      });

      handleModalClose(false);
    } catch (err) {
      const errMsg = (err as any).data.message;

      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Project creation failed',
      });
      return;
    }
  };

  return (
    <div>
      <Modal
        onOutsideClick={() => handleModalClose(false)}
        additionalClasses={styles.modalAdditionalClasses}>
        {/* Modal Header */}
        <header className={styles.modalHeader}>
          <div className={styles.modalHeaderLeftContent}>
            <h1>Update Project!</h1>
            <p>
              Fill in the following info to create a new project associated to
              the account of the selected developer. You will receive Project ID
              that you will need at different steps.
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.modalCloseIcon}
            onClick={() => handleModalClose()}
          />
        </header>
        {/* Modal Body */}
        <main>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                defaultValue={project.name}
                label='Project Name'
                placeHolder='Add Name of the Project'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='name'
                errorMessage={errors.name ? 'Project Name is required' : ''}
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Project RERA'
                defaultValue={project.rera}
                placeHolder='Add Regd RERA number'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='rera'
                errorMessage={errors.rera ? 'Project RERA is required' : ''}
              />
            </div>
          </div>
          <div className={styles.bodyIndividualdRow}>
            <div className={styles.inputsContainer}>
              <TextInput
                label='City of the Project'
                defaultValue={project.city}
                placeHolder='Add Name of the City'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='city'
                errorMessage={
                  errors.city ? 'City of the Project is required' : ''
                }
              />
            </div>
            <div className={styles.inputsContainer}>
              <TextInput
                label='Project RERA URL'
                defaultValue={project.reraUrl}
                placeHolder='Add applicable RERA site'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='reraUrl'
                errorMessage={
                  errors.reraUrl ? 'Project RERA URL is required' : ''
                }
              />
            </div>
          </div>
          <div className={styles.formDividerLineContainer}></div>
          <SecondaryCompanyList
            companyList={companies || []}
            primaryCompanyId={project.company.id}
            secondaryCompanies={project.secondaryCompanies}
            register={register}
            unregister={unregister}
            setValue={setValue}
            name='secondaryCompanies'
          />
          <div className={styles.formDividerLineContainer}></div>
          <div className={styles.bodyIndividualdRow}>
            <div
              className={`${styles.inputsContainer} ${styles.contactDetailsInputContainer}`}>
              <TextInput
                label='Point of Contact Email'
                defaultValue={project.pocEmail}
                placeHolder='POC Email ID'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='pocEmail'
                errorMessage={
                  errors.pocEmail ? 'Please enter valid POC email' : ''
                }
              />
              <p className={styles.inputSuggestion}>
                Taken from last project of the developer
              </p>
            </div>
            <div
              className={`${styles.inputsContainer} ${styles.contactDetailsInputContainer}`}>
              <TextInput
                label='Point of Contact Mobile'
                defaultValue={project.pocMobile}
                placeHolder='POC Mobile Number'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='pocMobile'
                errorMessage={
                  errors?.pocMobile ? 'POC Mobile Number is required' : ''
                }
              />
              <p className={styles.inputSuggestion}>
                Taken from last project of the developer
              </p>
            </div>
          </div>
        </main>
        {/* Modal Footer */}
        <footer className={styles.footer}>
          <p className={styles.footerDisclaimer}>
            By clicking Apply Discount you are making updates to the cost sheet
            that the user can view. Confirm discount on the Summary on the right
            before clicking on Apply Discount
          </p>
          <div className={styles.createProjectBtnContainer}>
            <Button
              type='submit'
              propStyles={`${styles.createProjectBtn}`}
              onClick={() => handleSubmit(handleUpdateProject)()}>
              Update Project
            </Button>
          </div>
        </footer>
      </Modal>
    </div>
  );
};

export default UpdateProjectModal;
