// Components
import CpProfilePendingLeadApprovalGrid from './CpProfilePendingLeadApprovalGrid';

const CpProfilePendingLeadApproval = () => {
  return (
    <>
      <main>
        <CpProfilePendingLeadApprovalGrid />
      </main>
    </>
  );
};

export default CpProfilePendingLeadApproval;
