import { useState } from 'react';

import 'ag-grid-enterprise';
import { ICellRendererParams, CellClassParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import Icon from './../../../../../../../../components/common/Icon/index';
import { Enquiry, EnquiryStatus, IStatus } from '../../..';

const LeadDetailsGrid = (props: ICellRendererParams) => {
  const { data: rowData } = props;

  const [enquiriesData, setEnquiriesData] = useState<Enquiry[] | null>(null);

  const onGridReady = () => {
    setEnquiriesData(rowData.enquiries);
  };

  const cellClassRules = {
    explored: (params: CellClassParams) =>
      params.value === EnquiryStatus.EXPLORED.value,
    blocked: (params: CellClassParams) =>
      params.value === EnquiryStatus.BLOCKED.value,
    paymentdone: (params: CellClassParams) =>
      params.value === EnquiryStatus.PAYMENT_DONE.value,
    kyccompleted: (params: CellClassParams) =>
      params.value === EnquiryStatus.KYC_COMPLETED.value,
  };

  return (
    <div>
      <div
        className='ag-theme-alpine leads-details-enquiries'
        style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={enquiriesData}
          defaultColDef={{
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
          }}
          domLayout={'autoHeight'}
          onGridReady={() => onGridReady()}>
          <AgGridColumn
            flex={1}
            field='status'
            headerName=''
            cellRendererFramework={(params: ICellRendererParams) => {
              return <Icon name='gite' />;
            }}
            cellClass='lead-enquiry-status'
            cellClassRules={cellClassRules}></AgGridColumn>
          <AgGridColumn flex={2} field='unitDetails'></AgGridColumn>
          <AgGridColumn flex={2} field='unitConfig'></AgGridColumn>
          <AgGridColumn
            flex={2}
            field='status'
            headerName='Status of Query'
            valueFormatter={(prop) => {
              const value: keyof IStatus = prop.value;
              return EnquiryStatus[value].displayText;
            }}></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='Update'
            field='lastUpdate'></AgGridColumn>
          <AgGridColumn
            flex={2}
            headerName='CP Name'
            field='cpName'></AgGridColumn>
          <AgGridColumn flex={3}></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
};

export default LeadDetailsGrid;
