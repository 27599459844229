import { useState, useRef } from 'react';

import useOnClickOutside from '../../../../../../../../utils/useOnClickOutside';
import DemoRoomsListModal from '../../modals/DemoRoomsListModal';
import UpdateProjectModal from '../../modals/UpdateProjectModal';
import Icon from '../../../../../../../../components/common/Icon';
import { IProject } from '../..';
import { IconNames } from '../../../../../../../../components/common/Icon';

import styles from './styles.module.css';
import genericStyles from '../../../../../../../../styles/genericStyles.module.css';
import ProjectExecutiveListModal from '../../../../AdminDashboard/ProjectList/ProjectExecutiveListModal';

export interface IMoreActionsMenu {
  project: IProject;
  onProjectUpdate: (updatedProject: IProject) => void;
}

const MoreActionsMenu = (props: IMoreActionsMenu) => {
  const { project, onProjectUpdate } = props;

  const { id: projectId, name: projectName, demoRooms, disabled } = project;

  // TODO: Type actionModal
  const [actionModal, setActionModal] = useState() as any;
  const [showMenu, setShowMenu] = useState(false) as any; //TODO: Remove any

  const menuRef = useRef() as React.LegacyRef<HTMLDivElement>;

  //For Closing menu when clicked outside
  useOnClickOutside(menuRef, () => {
    return setShowMenuWrapper(false);
  });

  const setShowMenuWrapper = (showMenu: Boolean) => {
    setShowMenu(showMenu);
  };

  const actionItems = [
    {
      displayText: 'View Executives',
      icon: 'person',
      actionModal: (
        <ProjectExecutiveListModal
          project={project}
          onModalClose={() => setActionModal()}
          onProjectUpdate={onProjectUpdate}
        />
      ),
      showModal: false,
    },
    {
      displayText: 'View Rooms',
      icon: 'fiber_smart_record',
      actionModal: (
        <DemoRoomsListModal
          projectName={projectName}
          projectId={projectId}
          demoRooms={demoRooms}
          onModalClose={() => setActionModal()}
          onProjectUpdate={onProjectUpdate}
        />
      ),
      showModal: false,
    },
    {
      displayText: 'Update Project',
      icon: 'fiber_smart_record',
      actionModal: (
        <UpdateProjectModal
          project={project}
          onModalClose={() => setActionModal()}
          onProjectUpdate={onProjectUpdate}
        />
      ),
      showModal: false,
    },
  ];

  return (
    <>
      {/* Menu Options */}
      {actionModal}
      {showMenu && (
        <div
          ref={menuRef}
          className={`${genericStyles.pointer} ${styles.actionsMenuContainer}`}>
          {actionItems.map((actionItem) => (
            <div key={actionItem.displayText}>
              <div
                className={styles.individualMenuItem}
                onClick={() => setActionModal(actionItem.actionModal)}>
                <p className={styles.moreActionsIndividualMenuLabel}>
                  {actionItem.displayText}
                </p>
                <Icon
                  name={actionItem.icon as IconNames}
                  propStyles={styles.moreActionsIndividualMenuIcon}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {/* 3 dots menu toogler Icon in Grid */}
      <div
        className={`${genericStyles.pointer} ${
          styles.moreActionsMenuIconContainer
        } ${disabled ? styles.disabled : ''}`}>
        <Icon
          name='more_vert'
          propStyles={`${styles.moreActionsMenuIcon} ${
            disabled ? 'disabled - icon' : ''
          }`}
          onClick={() => setShowMenuWrapper(!showMenu)}
        />
      </div>
    </>
  );
};

export default MoreActionsMenu;
