import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import TextInput from '../../../../../../components/common/inputs/TextInput';
import useToast from '../../../../../../hooks/useToast';
import { ICpUser, ICpUserUpdateReq } from '../../../../interfaces';
import { useUpdateCpUserMutation } from '../../../../services/cpUserAPISlice';
import { getCpUser, setCpUser } from '../../../../slices/cpUserSlice';
import ProfileHeader from '../../../common/ProfileHeader';
import MultiSelectOptions from '../../../../../../components/common/inputs/MultiSelectOptions';

import styles from './styles.module.css';

const UpdateCpUserPreference = () => {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: undefined,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const cpUser = useSelector(getCpUser) as ICpUser;
  const [addToast] = useToast();
  const [updateCpUserAPI] = useUpdateCpUserMutation();

  const handleSave = async (data: any) => {
    try {
      setIsLoading(true);

      const cpUserUpdateReq: ICpUserUpdateReq = {
        cpUserId: cpUser.id,
        preferences: {
          operationArea: [
            {
              area: data.operationArea,
              code: 'NA',
            },
          ],
          propertyTypes: data.preferences.propertyTypes,
        },
      };
      const response = await updateCpUserAPI(cpUserUpdateReq).unwrap();

      const updatedCpUser: ICpUser = response.data;
      dispatch(setCpUser(updatedCpUser));

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated Preferences',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.conatinerWrap}>
      <ProfileHeader
        iconName={'arrow_back'}
        pageName={'Preferences'}
        buttonName={'Save'}
        loading={isLoading}
        onSave={() => handleSubmit(handleSave)()}
      />
      <div className={styles.formWrap}>
        <TextInput
          label='Mention area of operation'
          placeHolder='Address'
          register={register}
          setValue={setValue}
          unregister={unregister}
          name='operationArea'
          defaultValue={cpUser.preferences.operationArea[0]?.area}
          errorMessage={errors.name ? 'Developer name is required' : ''}
          crApp
        />
        <MultiSelectOptions
          label='Property Type'
          options={['RESIDENTIAL', 'COMMERCIAL']}
          setValue={setValue}
          errorMessage={
            errors.configs ? 'Property configuration is required' : ''
          }
          register={register}
          unregister={unregister}
          name='preferences.propertyTypes'
          defaultValue={cpUser.preferences.propertyTypes}
          clearErrors={clearErrors}
        />
        {/* 
        <DropDownInput
          options={residentialType}
          lable='Property type'
          register={register}
          setValue={setValue}
          unregister={unregister}
          name='preferences.propertyTypes'
          multiSelect
        /> */}
      </div>
    </div>
  );
};

export default UpdateCpUserPreference;
