import { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../../../../components/common/buttons/Button';
import TextInput from './../../../../../../components/common/inputs/TextInput/index';
import Icon from './../../../../../../components/common/Icon/index';
import ActiveCpListGrid from './ActiveCpListGrid';
import PendingCpApproval from './PendingCpApproval';
import AddNewCpModal from './AddNewCpModal';
import { getAdminUser } from '../../../../slices/adminSlice';
import { useGetCpUsersByAdminUserIdQuery } from '../../../../../cp/services/cpUserAPISlice';

import styles from './styles.module.css';

const activeCpListAndPendingCpApprovalTabsEnum = {
  ACTIVE_CP_LIST: { value: 'ACTIVE_CP_LIST', displayText: 'Active CP List' },
  PENDING_CP_APPROVAL: {
    value: 'PENDING_CP_APPROVAL',
    displayText: 'Pending CP Approval',
  },
};

const ActiveCpListAndPendingCpApprovalBody = () => {
  const [selectedTabTag, setSelectedTabTag] = useState(
    activeCpListAndPendingCpApprovalTabsEnum.ACTIVE_CP_LIST.value
  );
  const [addNewCpModalShown, setAddNewCpModalShown] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const adminUser = useSelector(getAdminUser);

  const { data: cpUsers = [], isLoading } = useGetCpUsersByAdminUserIdQuery(
    adminUser?.id
  );

  const activeCpListTabs = [
    {
      name: activeCpListAndPendingCpApprovalTabsEnum.ACTIVE_CP_LIST.displayText,
      tag: activeCpListAndPendingCpApprovalTabsEnum.ACTIVE_CP_LIST.value,
      isDisabled: false,
    },
    {
      name: activeCpListAndPendingCpApprovalTabsEnum.PENDING_CP_APPROVAL
        .displayText,
      tag: activeCpListAndPendingCpApprovalTabsEnum.PENDING_CP_APPROVAL.value,
      isDisabled: false,
    },
  ];

  return (
    <>
      <AddNewCpModal
        addNewCpModalShown={addNewCpModalShown}
        handelAddNewCpModalClose={() => setAddNewCpModalShown(false)}
      />

      <div className={styles.tabsContainer}>
        <div className={styles.tabContainerHeader}>
          {activeCpListTabs.map((tab) => (
            <div
              key={tab.tag}
              className={`${styles.tabItem} ${
                tab.isDisabled
                  ? styles.disabledTab
                  : selectedTabTag === tab.tag
                  ? styles.activeTab
                  : ''
              }`}
              onClick={() => setSelectedTabTag(tab.tag)}>
              {tab.name}
            </div>
          ))}
        </div>

        <header className={styles.header}>
          <div className={styles.activeCpListHeader}>
            <div className={styles.addCpBtnContainer}>
              <Button
                accent='primary'
                onClick={() => setAddNewCpModalShown(true)}>
                Add New CP
              </Button>
            </div>

            <div className={styles.filterContainer}>
              <div className={styles.searchBox}>
                <TextInput
                  startIconName='search'
                  startIconVariant='SECONDARY'
                  placeHolder='Search Name'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchTerm(event?.target.value);
                  }}
                />
              </div>
              <span className={styles.filterTitle}>Filter</span>
              <Icon name='filter_alt' propStyles={styles.filterIcon} />
            </div>
          </div>
        </header>

        <main>
          <div className={styles.tabContainerBody}>
            {selectedTabTag ===
              activeCpListAndPendingCpApprovalTabsEnum.ACTIVE_CP_LIST.value && (
              <ActiveCpListGrid
                cpUsers={cpUsers.filter((cpUser) => cpUser.approved)}
                searchTerm={searchTerm}
              />
            )}

            {selectedTabTag ===
              activeCpListAndPendingCpApprovalTabsEnum.PENDING_CP_APPROVAL
                .value && (
              <PendingCpApproval
                cpUsers={cpUsers.filter((cpUser) => !cpUser.approved)}
                searchTerm={searchTerm}
              />
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default ActiveCpListAndPendingCpApprovalBody;
