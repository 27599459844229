import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';

import useOnClickOutside from '../../../utils/useOnClickOutside';
import LoginWithOtp from './LoginWithOtp';
import RegisterForm from './RegisterForm';
import { isEmpty } from '../../../utils/utils';

import {
  getUser,
  setUser,
  getShowAuthForms,
} from '../../../apps/booking/slices/auth';
import { setUnitCart } from '../../../apps/booking/slices/selectedUnit';

const Auth = () => {
  const dispatch = useDispatch();
  const forcefullShowAuthForm = useSelector(getShowAuthForms);
  const [showAuthForms, setShowAuthForms] = useState(forcefullShowAuthForm);
  const [showRegisterFrom, setShowRegisterFrom] = useState(false);
  const [mobile, setMobile] = useState('');
  const modalContentRef = useRef();
  const user = useSelector(getUser);
  const isLoggedIn = !isEmpty(user);

  useEffect(() => {
    if (!showAuthForms) {
      setShowRegisterFrom(false);
    }
  }, [showAuthForms]);

  if (forcefullShowAuthForm === true && !showAuthForms) {
    setShowAuthForms(forcefullShowAuthForm);
  }

  useOnClickOutside(modalContentRef, () => {
    if (!forcefullShowAuthForm) {
      setShowAuthForms(false);
    }
  });

  const handleLoginLogout = () => {
    if (isLoggedIn) {
      // TODO: Handle logout properly
      localStorage.removeItem('user');
      dispatch(setUser({}));
      // TODO: Not good practice - reset unitCart on user update or unit update
      dispatch(setUnitCart({}));
    } else {
      setShowAuthForms(true);
    }
  };
  return (
    <>
      <span className='btn btn--accent' onClick={() => handleLoginLogout()}>
        {isLoggedIn ? 'Logout' : 'Login'}
      </span>
      {showAuthForms && (
        <div className={`modal ${showAuthForms && 'modal--show'}`}>
          <div className='modal-content' ref={modalContentRef}>
            <div className='login'>
              <div className='login-desc'>
                <span className='login-desc__title'>
                  REGISTER/LOGIN TO TRACK YOUR PROGRESS
                </span>
                <span className='login-desc__desc'>
                  Embassy Edge@Embassy Springs , India's First Alexa Enabled
                  Homes
                </span>
              </div>
              {!showRegisterFrom && (
                <LoginWithOtp
                  forcefullShowAuthForm={forcefullShowAuthForm}
                  setShowAuthForms={setShowAuthForms}
                  setShowRegisterFrom={setShowRegisterFrom}
                  setMobile={setMobile}
                />
              )}
              {showRegisterFrom && (
                <RegisterForm
                  forcefullShowAuthForm={forcefullShowAuthForm}
                  setShowAuthForms={setShowAuthForms}
                  setShowRegisterFrom={setShowRegisterFrom}
                  mobile={mobile}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Auth;
