import { ActiveLeadsGrid, EnquiryStatus, UserAction } from '.';
import { IconNames } from '../../../../../components/common/Icon';

export const LeadsHeaderData = {
  projectName: 'Hiranandani Marina Fortune City',
  brandName: 'Brand Sheth Creators, Hiranandani Communities',
  LeadsCardsData: {
    users: [
      {
        imgUrl: 'https://picsum.photos/70',
        name: 'Kiran Rathod',
        role: 'Lead',
        id: 1,
      },
      {
        imgUrl: 'https://picsum.photos/80',
        name: 'Aditi Makheeja',
        role: 'Lead',
        id: 2,
      },
      {
        imgUrl: 'https://picsum.photos/90',
        name: 'Mary Ann Guerrero',
        role: 'Sales Executive',
        id: 3,
      },
      {
        imgUrl: 'https://picsum.photos/50',
        name: 'Mary Ann Guerrero',
        role: 'Sales Executive',
        id: 4,
      },
      {
        imgUrl: 'https://picsum.photos/40',
        name: 'Mary Ann Guerrero',
        role: 'Sales Executive',
        id: 5,
      },
      {
        imgUrl: 'https://picsum.photos/30',
        name: 'Mary Ann Guerrero',
        role: 'Sales Executive',
        id: 6,
      },
      {
        imgUrl: 'https://picsum.photos/20',
        name: 'Mary Ann Guerrero',
        role: 'Sales Executive',
        id: 7,
      },
      {
        imgUrl: 'https://picsum.photos/70',
        name: 'Mary Ann Guerrero',
        role: 'Sales Executive',
        id: 8,
      },
      {
        imgUrl: 'https://picsum.photos/65',
        name: 'Mary Ann Guerrero',
        role: 'Sales Executive',
        id: 9,
      },
    ],
    showNumberOfTiles: 3,
  },
  liveSince: {
    titleIcon: 'stream' as IconNames,
    title: 'Live Since',
    mainData: '28 Jan',
    changeData: '10 Days',
    changeDataClass: 'status-error',
    changeDesc: 'remaining for renewal',
  },
  remainingUnits: {
    titleIcon: 'computer' as IconNames,
    title: 'Remaining Units',
    mainData: '2,500',
    changeData: '15%',
    changeDataClass: 'status-warning-low',
    changeDesc: 'inventory is booked',
  },
  registeredUser: {
    titleIcon: 'group' as IconNames,
    title: 'Registered Users',
    mainData: '50,215',
    changeData: '+150',
    changeDataClass: 'status-success',
    changeDesc: 'Active leads',
  },
};

export const ActiveLeadsGridData: ActiveLeadsGrid[] = [
  {
    hotness: 0,
    cpName: 'Beier Herbert',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.BLOCKED_EXPLORED_UNIT.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.PAYMENT_DONE.value,
  },
  {
    hotness: 1,
    cpName: 'Okuneva Dean',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.KYC_DETAILS_UPDATED.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 2,
    cpName: 'Rodriguez Patience',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.EXPLORED_NEW_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 0,
    cpName: 'VonRueden Heidi',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.EXPLORED_NEW_BOOKED_UNIT.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 1,
    cpName: 'Blick Mable',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.EXPLORED_OLD_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.PAYMENT_DONE.value,
  },
  {
    hotness: 2,
    cpName: 'Turcotte Vivian',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.PAYMENT_DONE.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 0,
    cpName: 'Koepp Leland',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.KYC_DETAILS_UPDATED.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 1,
    cpName: 'Predovic Karen',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.BLOCKED_EXPLORED_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 2,
    cpName: 'Runolfsson Amelie',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.DRAFT_AGREEMENT_PENDING.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 0,
    cpName: 'Connelly Rae',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.EXPLORED_NEW_UNIT.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 1,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.EXPLORED_NEW_BOOKED_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 2,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.EXPLORED_OLD_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 0,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.KYC_DETAILS_UPDATED.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.PAYMENT_DONE.value,
  },
  {
    hotness: 1,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.PAYMENT_DONE.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 2,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.BLOCKED_EXPLORED_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.PAYMENT_DONE.value,
  },
  {
    hotness: 0,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.DRAFT_AGREEMENT_PENDING.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 1,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.EXPLORED_NEW_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 2,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.EXPLORED_NEW_BOOKED_UNIT.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 0,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.EXPLORED_OLD_UNIT.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.PAYMENT_DONE.value,
  },
  {
    hotness: 1,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.KYC_DETAILS_UPDATED.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 2,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.PAYMENT_DONE.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.PAYMENT_DONE.value,
  },
  {
    hotness: 0,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/70',
      title: 'Vita Hyatt',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
    lastActivityOn: '27th Dec 2021',
    lastAction: UserAction.EXPLORED_NEW_UNIT.value,
    registeredOn: '26th Dec 2021',
    leadStatus: EnquiryStatus.EXPLORED.value,
  },
  {
    hotness: 1,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/10',
      title: 'Garrick Shanahan II',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 21,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 22,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 23,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 24,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.KYC_DETAILS_UPDATED.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
  {
    hotness: 2,
    cpName: 'Keeling Gerard',
    user: {
      imgUrl: 'https://picsum.photos/11',
      title: 'Ludwig Farrell',
      subtitle: 'Primary Applicant',
    },
    enquiries: [
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 31,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 32,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 33,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 34,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 35,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 36,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 37,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 38,
      },
      {
        status: EnquiryStatus.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 801',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 39,
      },
      {
        status: EnquiryStatus.KYC_COMPLETED.value,
        unitDetails: 'Gardenia, B 506',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 310,
      },
      {
        status: EnquiryStatus.BLOCKED.value,
        unitDetails: 'Gardenia, A 601',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 311,
      },
      {
        status: EnquiryStatus.EXPLORED.value,
        unitDetails: 'Hibiscus, D 405',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 312,
      },
    ],
    lastActivityOn: '29th Dec 2021',
    lastAction: UserAction.DRAFT_AGREEMENT_PENDING.value,
    registeredOn: '24th Dec 2021',
    leadStatus: EnquiryStatus.BLOCKED.value,
  },
];
