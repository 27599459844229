import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../constants/urls';

export const unitsAPISlice = createApi({
  reducerPath: 'unitsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    // Units
    getUnitDetails: build.query({
      query: ({ id, unit_group, block, number }) => {
        const idQuery = id ? `id=${id}` : '';
        return `unit_details?${idQuery}&block=${block}&unit_group=${unit_group}&number=${number}`;
      },
      keepUnusedDataFor: 120,
    }),

    getUnitList: build.query({
      query: ({
        id = '',
        unit_group = '',
        block = '',
        status = '',
        number = '',
      } = {}) => {
        // const idQuery = id ? `id=${id}` : '';
        // return `unit_list?${idQuery}&block=${block}&unit_group=${unit_group}&number=${number}&status=${status}`;
        return 'temp_unit_list';
      },
      keepUnusedDataFor: 120,
    }),

    blockUnit: build.mutation({
      query: (unitCartId) => {
        return {
          url: `block_unit/${unitCartId}`,
          method: 'PUT',
        };
      },
    }),

    // PDF
    costSheetPdf: build.mutation({
      query: ({ user_id, unit_id }) => {
        return {
          url: `costsheet_pdf?${
            user_id && `user_id=${user_id}`
          }&unit_id=${unit_id}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),

    paymentPlanPdf: build.mutation({
      query: (unitCartId) => {
        return {
          url: `payment_plan_pdf?unit_cart_id=${unitCartId}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),

    agreementPdf: build.mutation({
      query: (unitCartId) => {
        return {
          url: `agreement_pdf?unit_cart_id=${unitCartId}`,
          method: 'POST',
        };
        // TODO: Cache forever
      },
    }),
  }),
});

export const {
  // Unit
  useGetUnitDetailsQuery,
  useGetUnitListQuery,
  useLazyGetUnitListQuery,
  useBlockUnitMutation,

  // PDF
  useCostSheetPdfMutation,
  usePaymentPlanPdfMutation,
  useAgreementPdfMutation,
} = unitsAPISlice;
