import styles from './styles.module.css';

interface IHorizontalSliderProps {
  children: React.ReactChild;
}

const HorizontalSlider =(props: IHorizontalSliderProps) => {
  const { children } = props;

  return(
   <div className={styles.container}>{children}</div>
  );
}

export default HorizontalSlider;


