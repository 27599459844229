import { UsersListType } from './Interface';
import { IconNames } from '../Icon';

export const summaryData = {
  titleIcon: 'file_download' as IconNames,
  title: 'Live Since',
  mainData: '28 Jan',
  changeData: '10 Days',
  changeDataClass: 'status-success',
  changeDesc: 'remaining for renewal',
};
export const progressBarData = {
  target: 800,
  currentvalue: 320,
};

export const ExecutiveListData: UsersListType = {
  users: [
    {
      imgUrl: 'https://picsum.photos/70',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 1,
    },
    {
      imgUrl: 'https://picsum.photos/80',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 2,
    },
    {
      imgUrl: 'https://picsum.photos/90',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 3,
    },
    {
      imgUrl: 'https://picsum.photos/50',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 4,
    },
    {
      imgUrl: 'https://picsum.photos/40',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 5,
    },
    {
      imgUrl: 'https://picsum.photos/30',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 6,
    },
    {
      imgUrl: 'https://picsum.photos/20',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 7,
    },
    {
      imgUrl: 'https://picsum.photos/70',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 8,
    },
    {
      imgUrl: 'https://picsum.photos/65',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 9,
    },
  ],
  showNumberOfTiles: 6,
};

export const ExecutivePerformanceData = {
  imgUrl:
    'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/login-background.jpg',
  role: 'Sales Executive',
  name: 'Patricia Garrett',
  value: '10566',
  isPerformancePositive: true,
  selected: false,
};

export const brandCardLargeData = {
  brandName: 'Hiranandani Fortune City',
  groupName: 'Runwal Group',
  imgUrl:
    'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/login-background.jpg',
};

export const LeadsCardsData: UsersListType = {
  users: [
    {
      imgUrl: 'https://picsum.photos/70',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 1,
    },
    {
      imgUrl: 'https://picsum.photos/80',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 2,
    },
    {
      imgUrl: 'https://picsum.photos/90',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 3,
    },
    {
      imgUrl: 'https://picsum.photos/50',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 4,
    },
    {
      imgUrl: 'https://picsum.photos/40',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 5,
    },
    {
      imgUrl: 'https://picsum.photos/30',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 6,
    },
    {
      imgUrl: 'https://picsum.photos/20',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 7,
    },
    {
      imgUrl: 'https://picsum.photos/70',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 8,
    },
    {
      imgUrl: 'https://picsum.photos/65',
      name: 'Mary Ann Guerrero',
      role: 'Sales Executive',
      id: 9,
    },
  ],
  showNumberOfTiles: 3,
};
