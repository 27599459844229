import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useBlockedUnitExpireCountdown from '../../apps/booking/hooks/useBlockedUnitExpireCountdown';

import { isEmpty } from '../../utils/utils';

import { setUnitCart } from '../../apps/booking/slices/selectedUnit';

import { BLOCKED } from '../../constants/status';
import { URLS } from '../../constants/urls';
import { useDeleteUnitCartMutation } from '../../api/unitCart';

const SummaryCard = (props) => {
  const { unitCart } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const hourMinSecCountDown = useBlockedUnitExpireCountdown(unitCart);
  const [deleteUnitCartAPI] = useDeleteUnitCartMutation();

  const isUnitCartBlocked = unitCart.unit.status === BLOCKED;
  const isUnitExpired =
    !isEmpty(hourMinSecCountDown) && hourMinSecCountDown.hours.length > 2;

  const handleContinue = () => {
    dispatch(setUnitCart(unitCart));

    history.push(URLS.COST_SHEET);
  };

  const handleUnitCartDelete = () => {
    deleteUnitCartAPI(unitCart.id);
  };

  return (
    <article className='summary-card'>
      <main className='summary-card__body'>
        <header className='summary-card__header'>
          <span className='summary-card__header-title'>
            You have {isUnitCartBlocked ? 'blocked' : 'explored'}
          </span>
          {!isUnitCartBlocked && (
            <span
              className='icon material-icons click-enabled'
              onClick={() => handleUnitCartDelete()}>
              
            </span>
          )}
        </header>
        <div>
          <div className='summary-card__unit-details'>
            {`${unitCart.unit.block} ${unitCart.unit.number}, ${unitCart.unit.unit_group}`}
          </div>
          {!isEmpty(hourMinSecCountDown) && (
            <div
              className={`summary-card__status ${
                (hourMinSecCountDown.hours < 1 || isUnitExpired) &&
                'warning-high'
              } ${hourMinSecCountDown.hours >= 1 && 'warning-low'}`}>
              <span className='icon material-icons'></span>
              {isUnitExpired ? (
                <span>Blocking Exipred</span>
              ) : (
                <span>
                  <span className='f-bold'>{hourMinSecCountDown.hours}</span>hr{' '}
                  <span className='f-bold'>{hourMinSecCountDown.minutes}</span>
                  min{' '}
                  <span className='f-bold'>{hourMinSecCountDown.seconds}</span>
                  sec left
                </span>
              )}
            </div>
          )}
        </div>
      </main>
      <footer
        className='summary-card__footer click-enabled'
        onClick={() => {
          handleContinue();
        }}>
        <span>Continue</span>
        <span className='icon material-icons'></span>
      </footer>
    </article>
  );
};

export default SummaryCard;
