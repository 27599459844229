import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import UnitFloorPlanPhoto from './UnitFloorPlanPhoto';
import { URLS } from '../../constants/urls';
import { isEmpty } from '../../utils/utils';
import { getConfigDetailsFromPath } from './utils';

import { useGetUnitDetailsQuery } from '../../api/units';

import {
  getSelectedUnitConfig,
  getHierarchyUnitList,
  getUnitListById,
} from '../../apps/booking/slices/contentHeader/contentHeaderNav';
import {
  setSelectedUnit as setSelectedUnitSlice,
  getSelectedUnit,
} from '../../apps/booking/slices/selectedUnit';

function UnitDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const unitListById = useSelector(getUnitListById);
  const configPath = useSelector(getSelectedUnitConfig);

  const redirectToUnitSelection = () => {
    history.push(URLS.UNIT_SELECTION);
  };

  if (!configPath) {
    redirectToUnitSelection();
  }

  const [selectedUnit, setSelectedUnit] = useState(
    useSelector(getSelectedUnit)
  );
  const { tower, block, unitNumber, section } =
    getConfigDetailsFromPath(configPath);

  const { data: configUnitDetails, isFetching } = useGetUnitDetailsQuery({
    unit_group: tower,
    block,
    number: unitNumber,
  });

  const hierarchyUnitList = useSelector(getHierarchyUnitList);
  const floorUnitList = hierarchyUnitList?.[tower]?.[section]?.[unitNumber]
    .filter((unitId) => unitListById[unitId].block.toLowerCase() === block)
    .map((unitId) => unitListById[unitId]);
  const minFinalPayableAmount = floorUnitList?.reduce(
    (min, unit) =>
      unit.cost_sheet.total_inclusive_amount < min
        ? unit.cost_sheet.total_inclusive_amount
        : min,
    floorUnitList[0].cost_sheet.total_inclusive_amount
  );
  const maxFinalPayableAmount = floorUnitList?.reduce(
    (max, unit) =>
      unit.cost_sheet.total_inclusive_amount > max
        ? unit.cost_sheet.total_inclusive_amount
        : max,
    floorUnitList[0].cost_sheet.total_inclusive_amount
  );

  const handleUnitSelect = (unit) => {
    dispatch(setSelectedUnitSlice(selectedUnit));
    history.push(URLS.COST_SHEET);
  };

  // TODO: isFetching - show loading
  return (
    !isFetching &&
    !isEmpty(configUnitDetails) && (
      <div className='unit-details'>
        <UnitFloorPlanPhoto configUnitDetails={configUnitDetails} />
        <div className='unit-details-plank'>
          <header className='unit-details-plank__header'>
            <div className='details-card'>
              <span className='details-card__title'>Config</span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {configUnitDetails.type}
              </span>
            </div>
            <div className='details-card'>
              <span className='details-card__title'>Block</span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {configUnitDetails.unit_group}
              </span>
              <span className='details-card__desc'>
                Block {configUnitDetails.block}
              </span>
            </div>
            <div className='details-card'>
              <span className='details-card__title'>Super Built-up</span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {configUnitDetails.area.super_build_up}
              </span>
              <span className='details-card__desc'>
                {configUnitDetails.area.unit === 'SQFEET'
                  ? 'Sq. Ft.'
                  : 'Sq. Meter.'}
              </span>
            </div>
            <div className='details-card'>
              <span className='details-card__title'>Unit View</span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {configUnitDetails.facing}
              </span>
            </div>
            <div className='details-card'>
              <span className='details-card__title'>Unit Facing</span>
              <span className='icon icon--primary material-icons'></span>
              <span className='details-card__desc'>
                {configUnitDetails.view}
              </span>
            </div>
          </header>
          <div className='unit-details-plank__body'>
            <div className='floor-list-container'>
              <div className='unit-details-plank__body-header'>
                <span className='unit-details-plank__body-header-title'>
                  Choose Floor
                </span>
                <span className='unit-details-plank__body-header-desc'>
                  Select from one of the available units to proceed
                </span>
              </div>
              <div>
                <div className='floor-list'>
                  {floorUnitList.map((floorUnit) => (
                    <button
                      data-unit-status={floorUnit.status}
                      data-unit-id={floorUnit.id}
                      data-selected-unit-id={selectedUnit.id}
                      className={`btn floor-list__item  ${
                        ((floorUnit.status === 'AVAILABLE' &&
                          floorUnit.published_on_portal === true) ||
                          floorUnit.status === 'OUT-OF-PREFERENCE') &&
                        'available-unit-background click-enabled'
                      } ${
                        !(
                          (floorUnit.status === 'AVAILABLE' &&
                            floorUnit.published_on_portal === true) ||
                          floorUnit.status === 'OUT-OF-PREFERENCE'
                        ) && 'unavailable-unit-background click-disabled'
                      } ${
                        floorUnit.status === 'OUT-OF-PREFERENCE' &&
                        'out-of-preference'
                      } ${
                        floorUnit.id === selectedUnit.id &&
                        'floor-list__item--selected'
                      }`}
                      onClick={() => setSelectedUnit(floorUnit)}>
                      <span className='icon icon--primary material-icons'>
                        
                      </span>
                      {floorUnit.status === 'OUT-OF-PREFERENCE' && (
                        <span className='icon icon--primary material-icons icon--out-of-preference'>
                          
                        </span>
                      )}
                      <span>
                        {floorUnit.block}{' '}
                        <span className='f-bold'>{floorUnit.number}</span>
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className='unit-details-plank__legends'>
              <div className='unit-details-plank__body-header'>
                <span className='unit-details-plank__body-header-title'>
                  Floor Legend
                </span>
                <span className='unit-details-plank__body-header-desc'>
                  Select from one of the available units
                </span>
              </div>
              <ul className='list'>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container available-unit-background'>
                    <span className='icon icon--primary material-icons'>
                      close
                    </span>
                  </span>
                  <span className='unit-details-plank__legends-item-desc'>
                    Available for you to select and book right now!
                  </span>
                </li>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container unavailable-unit-background'>
                    <span className='icon icon--primary material-icons'>
                      close
                    </span>
                  </span>
                  <span className='unit-details-plank__legends-item-desc'>
                    Booked or Unavailable unit. It can't be selected
                  </span>
                </li>
                <li className='unit-details-plank__legends-item'>
                  <span className='icon-container available-unit-background out-of-preference'>
                    <span className='icon icon--primary material-icons'>
                      close
                    </span>
                    <span className='icon icon--primary material-icons icon--out-of-preference'>
                      
                    </span>
                  </span>
                  <span className='unit-details-plank__legends-item-desc'>
                    Outside the preferences you have selected
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <footer className='unit-details-plank__footer'>
            <div className='unit-details-plank__summery'>
              <span className='unit-details-plank__summery-title'>
                A tentative range of all inclusive cost
              </span>
              <span className='unit-details-plank__summery-value'>
                <span className='f-bold'>
                  ₹{(minFinalPayableAmount / 10000000).toFixed(2)}
                </span>{' '}
                Cr to
                <span className='f-bold'>
                  {' '}
                  ₹{(maxFinalPayableAmount / 10000000).toFixed(2)}
                </span>{' '}
                Cr
              </span>
            </div>
            <div className='unit-details-plank__action-btns'>
              <button
                className='btn btn--transparent'
                onClick={() => redirectToUnitSelection()}>
                Back to Floor Plan
              </button>
              <button
                className={`btn ${isEmpty(selectedUnit) && 'btn--disabled'} ${
                  !isEmpty(selectedUnit) && 'btn--enabled'
                }`}
                onClick={() => handleUnitSelect()}>
                Select Unit
              </button>
            </div>
          </footer>
        </div>
      </div>
    )
  );
}

export default UnitDetails;
