// Components
import Image from '../Image';

// Constants
import { awsBaseUrl, floorPlanFallback } from '../../../constants/urls';

// Styles
import styles from './styles.module.css';

// Local Interfaces
interface IAlphabetAvatar {
  alphabet: string;
  uniqueNumber: number;
}

const AlphabetAvatar = (props: IAlphabetAvatar) => {
  const { alphabet, uniqueNumber } = props;

  return (
    <Image
      imgClass={styles.avatar}
      src={`${awsBaseUrl}/common/AZAlphabets/${alphabet.toUpperCase()}${
        uniqueNumber % 5
      }.png`}
      alt='Avatar'
      fallbackSrc={floorPlanFallback}
    />
  );
};

export default AlphabetAvatar;
