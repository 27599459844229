import { useState } from 'react';
import SiteVisitFormContainer from './SiteVisitFormContainer';

const SiteVisit = () => {
  const [showSiteVisitForm, setShowSiteVisitForm] = useState(false);

  const handleClose = () => {
    setShowSiteVisitForm(false);
  };

  return (
    <>
      <span
        className='click-enabled'
        onClick={() => {
          setShowSiteVisitForm(true);
        }}>
        Site Visit
      </span>
      {showSiteVisitForm && <SiteVisitFormContainer onClose={handleClose} />}
    </>
  );
};

export default SiteVisit;
