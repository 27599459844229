import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useLoginAdminUserMutation } from '../../../../../../services/adminUserAPISlice';
import useToast from '../../../../../../../../hooks/useToast';
import Button from '../../../../../../../../components/common/buttons/Button';
import TextInput from '../../../../../../../../components/common/inputs/TextInput/index';
import Icon from '../../../../../../../../components/common/Icon';
import { LoginFormStates } from '../../index';
import { emailValidationSchema } from '../../../../../../../../utils/yupValidationSchemas';

import styles from '../../styles.module.css';

interface ILoginState {
  setLoginFormState: Function;
  handleEmail: Function;
}

const LoginState = (props: ILoginState) => {
  const { setLoginFormState, handleEmail } = props;

  const history = useHistory();

  const [loginCred, setLoginCred] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [addToast] = useToast();

  const [adminLoginAPI] = useLoginAdminUserMutation();

  const handelEmailInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const email = event.target.value;
    setLoginCred((prev) => {
      return { ...prev, email };
    });

    const isValid = await emailValidationSchema.isValid({
      value: event.target.value,
    });

    if (isValid && errorMessage) {
      // Email is valid, hide error message
      setEmailErrorMessage('');
    }
  };
  const handelEmailBlur = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // turned this to async function as yup validation return result as a promise which we have to consume to validate email
    const isValid = await emailValidationSchema.isValid({
      value: event.target.value,
    });
    setEmailErrorMessage(!isValid ? 'Please enter a valid Email Address' : '');
  };

  const handelPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setLoginCred((prev) => {
      return { ...prev, password };
    });
  };

  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  }) as any;

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await adminLoginAPI({
        email: loginCred.email,
        password: loginCred.password,
      }).unwrap();

      const { redirectTo } = urlParams;
      history.push(redirectTo == null ? '/admin' : redirectTo);
    } catch (err) {
      const errMsg = (err as any).data?.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
      setErrorMessage(errMsg);
    }
  };

  return (
    <>
      <h2 className={styles.loginFormHeading}>
        Manage your Project like Never Before!
      </h2>
      {errorMessage ? (
        <div className={styles.accessRevokedMessage}>
          <Icon name='error_outline' propStyles={styles.errorIcon} />
          <div>{errorMessage}</div>
        </div>
      ) : (
        <p className={styles.loginFormDesc}>
          Welcome to the executive login page. To start accessing your leads &
          more, Login now!
        </p>
      )}
      <form onSubmit={handleLogin}>
        <TextInput
          label='Email'
          placeHolder=''
          errorMessage={emailErrorMessage}
          onChange={handelEmailInput}
          onBlur={handelEmailBlur}
          type='email'
        />
        <TextInput
          label='Password'
          placeHolder=''
          onChange={handelPasswordInput}
          type='password'
        />
        <Button propStyles={styles.loginButton} type='submit'>
          Login Now
        </Button>
        <Button
          propStyles={styles.forgotPasswordButton}
          onClick={() => {
            handleEmail(loginCred.email);
            setLoginFormState(LoginFormStates.SEND_OTP);
          }}>
          Forgot Password
        </Button>
      </form>
    </>
  );
};

export default LoginState;
