import { Redirect, Switch } from 'react-router-dom';

import BookingContent from '../containers/BookingContent';
import ConditionalRoutes from './ConditionalRoutes';
// Content header
import UnitSelectionContentHeader from '../../../components/contentHeader/unitSelection/UnitSelectionContentHeader';
import CostSheetContentHeader from '../../../components/contentHeader/CostSheetContentHeader';
import PaymentPlanContentHeader from '../../../components/contentHeader/PaymentPlanContentHeader';
import KycContentHeader from '../../../components/contentHeader/KycContentHeader';
import PaymentContentHeader from '../../../components/contentHeader/PaymentContentHeader';
import KycDetailsContentHeader from '../../../components/contentHeader/KycDetailsContentHeader';
import AgreementContentHeader from '../../../components/contentHeader/AgreementContentHeader';
// Content body
import UnitSelection from '../../../components/unitSelection/UnitSelection';
import UnitDetails from '../../../components/unitSelection/UnitDetails';
import Costsheet from '../../../components/costSheet/CostSheet';
import PaymentPlan from '../../../components/paymentPlan/PaymentPlan';
import Kyc from '../../../components/kyc/Kyc';
import Payment from '../../../components/payment/Payment';
import KycDetails from '../../../components/kyc/KycDetails';
import Agreement from '../../../components/agreement/Agreement';

import { URLS } from '../../../constants/urls';

const BookingRoutes = () => {
  return (
    <Switch>
      {/* <Route path={URLS.UNIT_SELECTION}>
        <BookingContent
          headerComponent={UnitSelectionContentHeader}
          contentBodyComponent={UnitSelection}
        />
      </Route> */}
      <ConditionalRoutes
        path={URLS.UNIT_SELECTION}
        component={BookingContent}
        headerComponent={UnitSelectionContentHeader}
        contentBodyComponent={UnitSelection}
        requiredAuth={true}
        requiredUnit={false}
      />

      {/* <Route path={URLS.UNIT_DETAILS}>
        <BookingContent
          headerComponent={UnitSelectionContentHeader}
          contentBodyComponent={UnitDetails}
        />
      </Route> */}
      <ConditionalRoutes
        path={URLS.UNIT_DETAILS}
        component={BookingContent}
        headerComponent={UnitSelectionContentHeader}
        contentBodyComponent={UnitDetails}
        requiredAuth={true}
        requiredUnit={false}
      />

      <ConditionalRoutes
        path={URLS.COST_SHEET}
        component={BookingContent}
        headerComponent={CostSheetContentHeader}
        contentBodyComponent={Costsheet}
        requiredAuth={false}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={URLS.PAYMENT_PLAN}
        component={BookingContent}
        headerComponent={PaymentPlanContentHeader}
        contentBodyComponent={PaymentPlan}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={URLS.KYC}
        component={BookingContent}
        headerComponent={KycContentHeader}
        contentBodyComponent={Kyc}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={URLS.PAYMENT}
        component={BookingContent}
        headerComponent={PaymentContentHeader}
        contentBodyComponent={Payment}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={URLS.KYC_DETAILS}
        component={BookingContent}
        headerComponent={KycDetailsContentHeader}
        contentBodyComponent={KycDetails}
        requiredAuth={true}
        requiredUnit={true}
      />
      <ConditionalRoutes
        path={URLS.AGREEMENT}
        component={BookingContent}
        headerComponent={AgreementContentHeader}
        contentBodyComponent={Agreement}
        requiredAuth={true}
        requiredUnit={true}
      />

      {/* Unmatched url redirect to dashbaord */}
      <Redirect to={URLS.UNIT_SELECTION} />
    </Switch>
  );
};

export default BookingRoutes;
