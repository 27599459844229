import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adminUserAPISlice } from '../services/adminUserAPISlice';
import {
  getAdminUserLocal,
  setAdminUserLocal,
} from '../components/pages/AdminLogin/utils';
import { ICompany, IUser } from '../interfaces';
import { setAuthTokenLocal } from '../../../utils/localStorage';

const adminUser = getAdminUserLocal();

interface IState {
  user?: IUser;
  company?: ICompany;
}

const initialState: IState = {
  user: adminUser,
};

const sliceName = 'adminSlice';

export interface AdminState {
  [sliceName]: IState;
}

export const adminSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // setUser: (state, action: PayloadAction<IUser>) => {
    //   state.user = action.payload;
    // },
    setAdminCompany: (state, action: PayloadAction<ICompany>) => {
      state.company = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      adminUserAPISlice.endpoints.loginAdminUser.matchFulfilled,
      (state, { payload }) => {
        const { token, ...adminUser } = payload.data;
        setAdminUserLocal(adminUser);
        setAuthTokenLocal(token);
        state.user = adminUser;
      }
    );
  },
});

export const getAdminUser = (state: AdminState) => state.adminSlice.user;
export const getAdminCompany = (state: AdminState) => state.adminSlice.company;

// Action creators are generated for each case reducer function
export const { setAdminCompany } = adminSlice.actions;
