import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ReactSVG } from 'react-svg';
import ClipLoader from 'react-spinners/ClipLoader';

import { setSelectedUnitConfig } from '../../apps/booking/slices/contentHeader/contentHeaderNav';
import { URLS } from '../../constants/urls';
import { isEmpty } from '../../utils/utils';
import { getConfigDetailsFromPath } from './utils';

import {
  getHierarchyUnitList,
  getUnitListById,
} from '../../apps/booking/slices/contentHeader/contentHeaderNav';

export default function SVGLoading(props) {
  const { selectedTower, selectedSection, selectedBlock } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const hierarchyUnitList = useSelector(getHierarchyUnitList);
  const unitListById = useSelector(getUnitListById);

  const updateSvgPathBehaviourWrapper = useCallback(
    (root) => {
      const selectSector = (configPath) => {
        dispatch(setSelectedUnitConfig(configPath));
        history.push(URLS.UNIT_DETAILS);
      };

      function isUnitsAvaialbeForConfig(
        tower,
        section,
        block,
        unitNumber = '101'
      ) {
        var avilableUnitsCtn = 0;
        let configArray = hierarchyUnitList[tower][section][unitNumber];

        if (!configArray) {
          return avilableUnitsCtn;
        }

        // TODO: Bad code - need to put explicitly - .toLowerCase()
        return configArray.some(
          (unitId) =>
            unitListById[unitId].block.toLowerCase() === block &&
            unitListById[unitId].status === 'AVAILABLE' &&
            unitListById[unitId].published_on_portal === true
        );
      }
      const updateSvgPathBehaviour = (root = document) => {
        const svgId = 'SVG1';
        const polygons = root.querySelectorAll('#' + svgId + ' > polygon');
        const paths = root.querySelectorAll('#' + svgId + ' > path');

        const applyStyleOnPathElm = (pathElm) => {
          pathElm.style.stroke = 'white';
          pathElm.style.fill = 'white';
          pathElm.style.opacity = 0.5;
          pathElm.style.cursor = 'default';

          const configPath = pathElm.id;

          const { tower, block, unitNumber, section } =
            getConfigDetailsFromPath(configPath);

          const isAvailableUnit = isUnitsAvaialbeForConfig(
            tower,
            section,
            block,
            unitNumber
          );

          if (isAvailableUnit) {
            pathElm.style.stroke = 'rgb(142 76 255)';
            pathElm.style.fill = 'rgb(142 76 255)';
            pathElm.style.opacity = 0.2;
            pathElm.style.cursor = 'pointer';
            pathElm.addEventListener('click', () => selectSector(pathElm.id));
          }
        };

        for (let pathElm of polygons) {
          applyStyleOnPathElm(pathElm);
        }

        for (let pathElm of paths) {
          applyStyleOnPathElm(pathElm);
        }
      };

      updateSvgPathBehaviour(root);
    },
    [hierarchyUnitList, unitListById, dispatch, history]
  );

  useEffect(() => {
    updateSvgPathBehaviourWrapper();
  }, [updateSvgPathBehaviourWrapper]);

  const svgName =
    selectedTower.toLowerCase() +
    '-x-' +
    selectedSection.toLowerCase() +
    '-x-' +
    selectedBlock.toLowerCase();

  if (isEmpty(hierarchyUnitList)) return null;

  return (
    <ReactSVG
      src={`https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/svg/${selectedSection}/${selectedTower}/${svgName}.svg`}
      loading={() => <ClipLoader />}
      id='svg'
      className='svg'
      beforeInjection={(svg) => {
        updateSvgPathBehaviourWrapper(svg);
      }}
    />
  );
}
