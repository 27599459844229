import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../../constants/urls';
import { getAuthTokenLocal } from '../../../utils/localStorage';

export const adminAPISlice = createApi({
  reducerPath: 'adminAPISlice',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      headers.set('x-auth-token', getAuthTokenLocal());
      return headers;
    },
  }),
  tagTypes: ['Projects'], //['Companies','DemoRooms'],
  endpoints: () => ({}),
});
