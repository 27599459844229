import styles from './styles.module.css';

export interface IHeadTitle {
  propStyles?: string;
  children: React.ReactChild;
}

const HeadTitle = (props: IHeadTitle) => {
  const { children, propStyles } = props;

  return <p className={`${styles.title} ${propStyles}`}>{children}</p>;
};

export default HeadTitle;
