import { useState } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { IUser } from '../../../../../../../interfaces';
import Icon from '../../../../../../../../../components/common/Icon';
import UpdateUserModal from '../../../modals/UpdateUserModal';

export interface IUpdateUserCell {
  user: IUser;
  rowNode: ICellRendererParams['node'];
  onUserUpdate?: (updatedUser: IUser) => void;
  disabled: boolean;
}

const UpdateUserCell = (props: IUpdateUserCell) => {
  const { user, rowNode, onUserUpdate, disabled } = props;
  const [updateUserModalShown, setUpdateUserModalShown] = useState(false);

  const handleUserUpdate = (updatedUser: IUser) => {
    rowNode.setData(updatedUser);
    onUserUpdate && onUserUpdate(updatedUser);
  };

  return (
    <>
      {updateUserModalShown && (
        <UpdateUserModal
          onModalClose={() => setUpdateUserModalShown(false)}
          user={user}
          onUserUpdate={handleUserUpdate}
        />
      )}
      <Icon
        name='person_outline'
        propStyles={`${disabled && 'click-disabled--icon'} `}
        onClick={() => setUpdateUserModalShown(true)}
      />
    </>
  );
};

export default UpdateUserCell;
