import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { CROSS_TICK_ICON } from '../../../constants/icons';

import {
  useSendOtpMutation,
  useVerifyOtpMutation,
  useGetUserByMobileOldMutation,
} from '../../../api/auth';

import { isEmpty } from '../../../utils/utils';

// Validation
const schema = yup.object().shape({
  mobile_no: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
});

const LoginWithOtp = (props) => {
  const {
    forcefullShowAuthForm,
    setShowAuthForms,
    setShowRegisterFrom,
    setMobile,
  } = props;
  const [requestLoading, setRequestLoading] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [formData, setFormData] = useState({
    mobile_no: '',
  });
  const otpInputRef = useRef();
  const [sendOtpAPI] = useSendOtpMutation();
  const [verifyOtpAPI] = useVerifyOtpMutation();
  const [getUserByMobileOldAPI] = useGetUserByMobileOldMutation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid: isFormValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const mobile = watch('mobile_no');

  const resetOtp = () => {
    otpInputRef.current.value = '';
    setOtpError('');
    setIsOtpValid(false);
  };

  const handleSendOtp = (data) => {
    setFormData(data);
    // TODO: Error handling
    sendOtpAPI({ mobile_no: data.mobile_no });
    setIsOtpSent(true);
    resetOtp();
  };

  const handleOptInputFieldChange = (value) => {
    if (value.length !== 6) {
      setIsOtpValid(false);
      setOtpError('');
      if (value.length > 6) {
        setOtpError('OPT contains only 6 digit');
      }
      return false;
    }

    setOtpError('');
    setIsOtpValid(true);
    return true;
  };

  const handleConfirmOtp = async (data) => {
    setRequestLoading(true);

    const { mobile_no } = data;
    const otp = otpInputRef.current.value;

    const {
      data: { otpVerified },
    } = await verifyOtpAPI({ mobile_no, otp });

    if (otpVerified) {
      /*
        Embasy has Email Id as primary key
      */
      let { data: user, error: userError } = await getUserByMobileOldAPI({
        mobile_no,
      });
      if (userError) {
        console.error(userError);
        // TODO: Handle error
      }

      if (isEmpty(user)) {
        // User is new
        setMobile(mobile_no);
        setShowRegisterFrom(true);
      } else {
        setShowAuthForms(false);
      }
    } else {
      setOtpError('OTP is wrong');
    }

    setRequestLoading(false);
  };

  return (
    <>
      <form
        className={`login-form login-form--singin-with-otp ${
          isFormValid && 'login-form--valid'
        }`}>
        <header className='login-form__header'>
          <span className='login-from__header-title'>Hey there!</span>
          {!forcefullShowAuthForm && (
            <span
              className='icon material-icons icon-cross click-enabled'
              onClick={() => setShowAuthForms(false)}>
              {CROSS_TICK_ICON}
            </span>
          )}
        </header>
        <main className='login-form__body'>
          <div className='form-summery-desc'>
            Welcome to the user login page. To start accessing your account,
            Login now!
          </div>
          <div className='input'>
            <label className='input_label'>Phone Number</label>
            <input
              type='text'
              className='input_field'
              {...register('mobile_no')}
              defaultValue={formData.mobile_no}
            />
            <span className='input__error'>
              {errors.mobile_no &&
                mobile.length > 10 &&
                '10 digit Phone Number is required'}
            </span>
          </div>
          <div className={`input input--otp ${!isOtpSent && 'click-disabled'}`}>
            <label className='input_label'>Enter 6-digit OTP</label>
            <input
              ref={otpInputRef}
              type='number'
              className='input_field'
              min='100000'
              max='999999'
              onInput={(e) => handleOptInputFieldChange(e.target.value)}
            />
            <span
              className='input__resend-otp click-enabled'
              onClick={() => handleSubmit(handleSendOtp)()}>
              Resend OTP
            </span>
            <span className='input__error'>{otpError}</span>
          </div>
        </main>
        <footer className='login-form__footer'>
          <div className='input'>
            <button
              type='button'
              className={`input_field 
                ${(isOtpSent ? isOtpValid : isFormValid) && 'click-enabled'}
                ${!(isOtpSent ? isOtpValid : isFormValid) && 'click-disabled'}
                ${requestLoading && 'loading-spin-container'}`}
              onClick={(e) => {
                isOtpSent
                  ? handleSubmit(handleConfirmOtp)()
                  : handleSubmit(handleSendOtp)();
              }}>
              <span>{isOtpSent ? 'SUBMIT' : 'SEND OTP'}</span>
              <span className='ic2-fa-spin-blue'></span>
            </button>
          </div>
          <span className='f-15 c-56'>
            By clicking continue, you are agreeing to our privacy policy & terms
            of use. More information at &nbsp;
            <a
              className='c-accent'
              href='https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/terms_and_conditions.pdf'
              target='_blank'
              rel='noreferrer'>
              Terms & Conditions.
            </a>
          </span>
        </footer>
      </form>
    </>
  );
};

export default LoginWithOtp;
