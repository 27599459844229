import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import useOnClickOutside from '../../../utils/useOnClickOutside';
import DropdownContent, { getDropdownPlaceholderText } from './DropdownContent';

import { URLS } from '../../../constants/urls';

import {
  getTowerBtnState,
  getSelectedTower,
  setSelectedTower,
  getContentHeaderNavState,
  DEFAULT_SELECTED_TOWER,
  CONTENT_HEADER_NAV_STATE,
} from '../../../apps/booking/slices/contentHeader/contentHeaderNav';

const TowerBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const towerBtnState = useSelector(getTowerBtnState);
  const selectedTower = useSelector(getSelectedTower);
  const contentHeaderNavState = useSelector(getContentHeaderNavState);

  const [show, setShow] = useState(false);

  const toggleDropdown = (showOrHide) => {
    setShow(showOrHide);
  };

  const ref = useRef();
  useOnClickOutside(ref, () => toggleDropdown(false));

  const handleSelectedOption = (selectedTowerOptionValue) => {
    history.push(URLS.UNIT_SELECTION);
    dispatch(setSelectedTower(selectedTowerOptionValue));
  };

  return (
    <button
      ref={ref}
      className={`btn btn--icon-label-container dropdown ${
        contentHeaderNavState === CONTENT_HEADER_NAV_STATE.MASTER &&
        'btn--enabled'
      } ${selectedTower !== DEFAULT_SELECTED_TOWER && 'btn--accent'}`}
      onClick={() => toggleDropdown(!show)}>
      <span>{getDropdownPlaceholderText(selectedTower, towerBtnState)}</span>
      <span className='icon material-icons'></span>
      <DropdownContent
        btnState={towerBtnState}
        show={show}
        handleSelectedOption={handleSelectedOption}
      />
    </button>
  );
};

export default TowerBtn;
