import DashboardSidebarItem from '../../../../components/dashboard/DashbaordSidebarItem';

const DashbaordSidebar = () => {
  const sidebarSteps = [
    {
      to: '/dashboard',
      labelText: 'Dashboard',
      icon: '',
      additionClasses:
        'dashbaord-sidebar-item--enabled dashbaord-sidebar-item--active',
    },
    {
      to: '/unit-selection',
      labelText: 'Explore Units',
      icon: '',
      additionClasses: 'dashbaord-sidebar-item--enabled',
    },
    {
      to: '/dashboard/applicants',
      labelText: 'Applicants',
      icon: '',
    },
    {
      to: '/dashboard/settings',
      labelText: 'Settings',
      icon: '',
    },
    {
      to: '/dashboard/help',
      labelText: 'Help',
      icon: '',
    },
    {
      to: '/dashboard/report-bug',
      labelText: 'Report Bug',
      icon: '',
    },
    {
      to: '/dashboard/about-us',
      labelText: 'About Relata.io',
      icon: '',
    },
  ];

  return (
    <div className='dashbaord-sidebar'>
      {sidebarSteps.map((item) => (
        <DashboardSidebarItem
          key={item.sidebarItmeStep}
          to={item.to}
          labelText={item.labelText}
          desc={item.desc}
          icon={item.icon}
          sidebarItmeStep={item.sidebarItmeStep}
          additionClasses={item.additionClasses}
        />
      ))}
    </div>
  );
};

export default DashbaordSidebar;
