import { useState } from 'react';
import SiteVisitFormContainer from '../header/siteVisit/SiteVisitFormContainer';

const ScheduleSiteVisitItem = (props) => {
  const [showSiteVisitForm, setShowSiteVisitForm] = useState(false);

  const handleClose = () => {
    setShowSiteVisitForm(false);
  };

  return (
    <>
      <div
        className='site-visit-item site-visit-item--schedule'
        onClick={() => setShowSiteVisitForm(true)}>
        <div className='site-visite-item__details-container'>
          <span className='icon-container icon-container--with-background'>
            <span className='icon material-icons'></span>
          </span>
          <div className='site-visit-item__label-time-container'>
            <span className='site-visit-item__label'>Scheduled Site Visit</span>
            <span className='site-visit-item__time'>Schedule right away</span>
          </div>
        </div>
      </div>
      {showSiteVisitForm && <SiteVisitFormContainer onClose={handleClose} />}
    </>
  );
};

export default ScheduleSiteVisitItem;
