import Icon from '../../../../../../components/common/Icon';
import Image from '../../../../../../components/common/Image';
import {
  ICollateral,
  IProject,
} from '../../../../../admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

interface IMarketingCollateralCardListProps {
  project: IProject;
}

const MarketingCollateralCardList = (
  props: IMarketingCollateralCardListProps
) => {
  const { project } = props;
  const fallBackIcon = 'home_work';
  const handleShareClick = (collateral: ICollateral) => {
    if (navigator.share) {
      navigator
        .share({
          title: collateral.title,
          text: collateral.text,
          url: collateral.url,
        })
        .then(() => {
          // TODO: add tost here
          console.log('Successfully shared');
        })
        .catch((error) => {
          console.error('Please try Manually', error);
        });
    }
  };

  return (
    <div className={styles.containerWrap}>
      {project.collaterals.map((collateral, index) => (
        <div key={index} className={styles.collateralWrapper}>
          <div className={styles.iconWrapper}>
            <Icon
              name={collateral.type ? collateral.type : fallBackIcon}
              propStyles={`${styles[collateral.type]} ${styles.colletralIcon}`}
            />
            <Icon
              onClick={() => handleShareClick(collateral)}
              name='share'
              propStyles={styles.shareIcon}
            />
          </div>
          <p className={styles.title}>{collateral.title}</p>
          <p className={styles.subTitle}>{collateral.subTitle}</p>
        </div>
      ))}
    </div>
  );
};

export default MarketingCollateralCardList;
