// Components
import Modal from '../../modal/Modal';
import Button from '../../buttons/Button';
import Icon from '../../Icon';

// Constants

// Styles
import styles from './styles.module.css';

// Local enums
enum Response {
  YES,
  CANCEL,
}

// Local Interfaces
export interface IConfirmationModal {
  accent?: 'SUCCESS' | 'DANGER' | 'WARN';
  heading: string;
  desc?: string;
  onModalClose: Function;
  confirmButtonLable?: string;
  cancelButtonLable?: string;
  onResponse: Function;
}

const ConfirmationModal = (props: IConfirmationModal) => {
  const {
    accent = 'WARN',
    heading,
    desc = '',
    onModalClose,
    onResponse,
    confirmButtonLable = 'Yes',
    cancelButtonLable = 'No',
  } = props;

  const onUserInteraction = (response: Response) => {
    onResponse(
      new Promise((resolve, reject) => {
        response === Response.YES
          ? resolve({ data: { message: 'confirm' } })
          : reject({ data: { message: 'Action canceled' } });
      })
    );
  };

  return (
    <Modal
      onOutsideClick={() => {
        onModalClose();
      }}
      additionalClasses={styles.modalAdditionalClasses}>
      {/* Modal header */}
      <header className={styles.header}>
        <div>
          {/*TODO get text from designers*/}
          <h2 className={styles.modalHeading}>{heading}</h2>
          <p className={styles.modalDesc}>{desc}</p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={() => onModalClose()}
        />
      </header>

      {/* Modal Body */}
      <main className={styles.modalBody}>
        <Button
          propStyles={`${styles.confirmButton} ${styles[accent.toLowerCase()]}`}
          onClick={() => onUserInteraction(Response.YES)}>
          {confirmButtonLable}
        </Button>
        <Button
          propStyles={styles.cancelButton}
          onClick={() => {
            onModalClose();
            onUserInteraction(Response.CANCEL);
          }}>
          {cancelButtonLable}
        </Button>
      </main>
    </Modal>
  );
};

export default ConfirmationModal;
