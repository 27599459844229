import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import useOnClickOutside from '../../../utils/useOnClickOutside';
import DropdownContent, { getDropdownPlaceholderText } from './DropdownContent';
import { URLS } from '../../../constants/urls';

import {
  getContentHeaderNavState,
  getSectionBtnState,
  getSelectedTower,
  getSelectedSection,
  setSelectedSection,
  DEFAULT_SELECTED_TOWER,
  CONTENT_HEADER_NAV_STATE,
} from '../../../apps/booking/slices/contentHeader/contentHeaderNav';

const SectionBtn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const sectionBtnState = useSelector(getSectionBtnState);
  const selectedTower = useSelector(getSelectedTower);
  const selectedSection = useSelector(getSelectedSection);
  const contentHeaderNavState = useSelector(getContentHeaderNavState);

  const [show, setShow] = useState(false);
  const [active, setActive] = useState(false);
  const toggleDropdownSection = (showOrHide) => {
    setShow(showOrHide);
  };

  if (contentHeaderNavState === CONTENT_HEADER_NAV_STATE.MASTER && active) {
    setActive(false);
  }

  const ref = useRef();
  useOnClickOutside(ref, () => toggleDropdownSection(false));

  const handleSelectedOption = (selectedSectionName) => {
    setActive(true);
    history.push(URLS.UNIT_SELECTION);
    dispatch(setSelectedSection(selectedSectionName));
  };
  return (
    <button
      ref={ref}
      className={`btn btn--icon-label-container dropdown 
        ${selectedTower !== DEFAULT_SELECTED_TOWER && active && 'btn--accent'}
        ${selectedTower === DEFAULT_SELECTED_TOWER && 'btn--disabled'}
      `}
      onClick={() => toggleDropdownSection(!show)}>
      <span>
        {getDropdownPlaceholderText(selectedSection, sectionBtnState)}
      </span>
      <span className='icon material-icons'></span>
      <DropdownContent
        btnState={sectionBtnState}
        show={show}
        handleSelectedOption={handleSelectedOption}
      />
    </button>
  );
};

export default SectionBtn;
