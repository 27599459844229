import { useSelector, useDispatch } from 'react-redux';

import {
  getFilterPreferences,
  getSelectedPreferences,
  getUnitListById,
  toggleFilterPreferenceItem,
  resetFilterPreferenceType,
} from '../../../apps/booking/slices/contentHeader/contentHeaderNav';

const FilterPreferences = (props) => {
  const dispatch = useDispatch();
  const preferences = useSelector(getFilterPreferences);
  const unitListById = useSelector(getUnitListById);
  const selectedPreferences = useSelector(getSelectedPreferences);

  const toggleIsSelected = (key, itemIndex) => {
    dispatch(toggleFilterPreferenceItem({ key, itemIndex }));
  };

  const resetPreferences = (preferenceType) => {
    dispatch(resetFilterPreferenceType(preferenceType));
  };

  const doesPreferenceSelectedForPreferenceType = (preferenceType) => {
    return preferences[preferenceType].items.some((item) => item.isSelected);
  };

  const getAvaiableUnitsCnt = (preferenceItem, preferenceContainer) => {
    const { isSelected: isItemSelected } = preferenceItem;
    const { key: preferenceContainerType } = preferenceContainer;
    return preferenceItem.unitIds.filter((unitId) => {
      for (const preferenceType in selectedPreferences) {
        const selectedPreferencesOfType = selectedPreferences[preferenceType];
        if (!isItemSelected && preferenceType === preferenceContainerType) {
          continue;
        }
        if (preferenceType === 'area') {
          if (
            selectedPreferencesOfType.length > 0 &&
            !selectedPreferencesOfType.some(
              (areaPreference) =>
                unitListById[unitId].area.super_build_up >=
                  areaPreference.from &&
                unitListById[unitId].area.super_build_up <= areaPreference.to
            )
          ) {
            return false;
          }
        } else if (preferenceType === 'final_payable_amount') {
          if (
            selectedPreferencesOfType.length > 0 &&
            !selectedPreferencesOfType.some(
              (preference) =>
                unitListById[unitId].cost_sheet.total_inclusive_amount /
                  10000000 >=
                  preference.from &&
                unitListById[unitId].cost_sheet.total_inclusive_amount /
                  10000000 <=
                  preference.to
            )
          ) {
            return false;
          }
        } else if (preferenceType === 'type') {
          // Monky patching - Bedrooms
          if (
            selectedPreferencesOfType.length > 0 &&
            !selectedPreferencesOfType.includes(
              unitListById[unitId][preferenceType].split('-')[0]
            )
          ) {
            return false;
          }
        } else if (
          selectedPreferencesOfType.length > 0 &&
          !selectedPreferencesOfType.includes(
            unitListById[unitId][preferenceType]
          )
        ) {
          return false;
        }
      }

      return true;
    }).length;
  };

  return (
    <div className='filter-preference-container'>
      <ul className='preference-list'>
        {Object.values(preferences).map((preference, index) => (
          <li className='preference' key={preference.key}>
            <header className='preference-header'>
              <span className='preference__title'>{preference.name}</span>
              <span
                className={`preference__reset ${
                  !doesPreferenceSelectedForPreferenceType(preference.key) &&
                  ' preference__reset--disabled'
                }`}
                onClick={() => resetPreferences(preference.key)}>
                <span className='icon material-icons'></span>
                <span>Reset</span>
              </span>
            </header>
            <div
              className={`preferance-body ${
                preference.items &&
                preference.items.length > 5 &&
                'preferance-body--col-2'
              } ${
                index === Object.values(preferences).length - 1 &&
                'preferance-body--hide-right-border'
              }`}>
              {preference.items?.map((preferenceItem, itemIndex) => {
                const availableUnitsOnThisPrefenceSelectCnt =
                  getAvaiableUnitsCnt(preferenceItem, preference);

                return (
                  <div
                    className='preference__item'
                    key={`preference__item${itemIndex}`}>
                    <strong className='preference__item-title'>
                      {preference.type === 'LIST' && (
                        <button
                          className={`${
                            preferenceItem.isSelected &&
                            'preference__item-selected'
                          } ${
                            availableUnitsOnThisPrefenceSelectCnt === 0 &&
                            'preference__item-disabled'
                          }`}
                          onClick={() =>
                            toggleIsSelected(preference.key, itemIndex)
                          }>
                          {preferenceItem.name}
                        </button>
                      )}
                      {preference.type === 'PRICE' && (
                        <button
                          className={`${
                            preferenceItem.isSelected &&
                            'preference__item-selected'
                          } ${
                            availableUnitsOnThisPrefenceSelectCnt === 0 &&
                            'preference__item-disabled'
                          }`}
                          onClick={() =>
                            toggleIsSelected(preference.key, itemIndex)
                          }>
                          From ₹
                          <span className='f-bold'>{preferenceItem.from}</span>
                          Cr to ₹
                          <span className='f-bold'>{preferenceItem.to}</span>
                          Cr{' '}
                        </button>
                      )}
                      {preference.type === 'AREA' && (
                        <button
                          className={`${
                            preferenceItem.isSelected &&
                            'preference__item-selected'
                          } ${
                            availableUnitsOnThisPrefenceSelectCnt === 0 &&
                            'preference__item-disabled'
                          }`}
                          onClick={() =>
                            toggleIsSelected(preference.key, itemIndex)
                          }>
                          From{' '}
                          <span className='f-bold'>{preferenceItem.from}</span>{' '}
                          to <span className='f-bold'>{preferenceItem.to}</span>
                        </button>
                      )}
                    </strong>
                    <span className='preference__item-desc'>
                      <span className='f-bold'>
                        {availableUnitsOnThisPrefenceSelectCnt}
                      </span>{' '}
                      Units available
                    </span>
                  </div>
                );
              })}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FilterPreferences;
