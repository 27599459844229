import SidebarItem from '../../../components/SidebarItem';

import { URLS } from '../../../constants/urls';

const BookingSidebar = () => {
  const sidebarSteps = [
    {
      redirectPath: URLS.UNIT_DETAILS,
      labelText: 'Unit Selection',
      desc: 'Select your Unit',
      icon: '',
    },
    {
      redirectPath: URLS.COST_SHEET,
      labelText: 'Cost Sheet',
      desc: 'Summary of your Unit',
      icon: '',
    },
    {
      redirectPath: URLS.PAYMENT_PLAN,
      labelText: 'Payment Plan',
      desc: 'Offers available',
      icon: '',
    },
    {
      redirectPath: URLS.KYC,
      labelText: 'KYC',
      desc: 'Applicant’s Information',
      icon: '',
    },
    {
      redirectPath: URLS.PAYMENT,
      labelText: 'Payment',
      desc: '10% Payment',
      icon: '',
    },
    {
      redirectPath: URLS.KYC_DETAILS,
      labelText: 'Details',
      desc: 'Document Upload',
      icon: '',
    },
    {
      redirectPath: URLS.AGREEMENT,
      labelText: 'Agreement',
      desc: 'Signing the Document',
      icon: '',
      additionClasses: 'sidebar-item--connector-bar-disable',
    },
  ];

  return (
    <>
      <div className='sidebar'>
        {sidebarSteps.map((item) => (
          <SidebarItem key={item.sidebarItmeIndex} {...item}></SidebarItem>
        ))}
      </div>
    </>
  );
};

export default BookingSidebar;
