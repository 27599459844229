import { adminAPISlice } from './index';
import { IProject } from '../components/pages/SuperAdminDashboard/SuperAdminProjectList';

export const projectsAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getProjects: build.query<IProject[], any>({
      query: () => 'api/projects/',
      transformResponse: (response: {
        data: IProject[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
              { type: 'Projects', id: 'LIST' },
            ]
          : [{ type: 'Projects', id: 'LIST' }],
    }),

    getProjectsByUserId: build.query<IProject[], any>({
      query: (userId: string) => `api/projects/users/${userId}`,
      transformResponse: (response: {
        data: IProject[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    addProject: build.mutation({
      query: (project: IProject) => ({
        url: 'api/projects/',
        method: 'POST',
        body: project,
      }),
      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
    }),

    updateProject: build.mutation({
      query: ({ projectId, ...fieldsToUpdate }) => ({
        url: `api/projects/${projectId}`,
        method: 'PUT',
        body: fieldsToUpdate,
      }),
      // invalidatesTags: [{ type: 'Projects', id: 'LIST' }]
    }),

    addDemoRoom: build.mutation({
      query: ({ projectId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms`,
        method: 'POST',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    updateDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId, demoRoom }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'PUT',
        body: demoRoom,
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    deleteDemoRoom: build.mutation({
      query: ({ projectId, demoRoomId }) => ({
        url: `api/projects/${projectId}/demo-rooms/${demoRoomId}`,
        method: 'DELETE',
      }),

      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    addProjectExecutive: build.mutation({
      query: ({
        projectId,
        executiveId,
        vrUrl,
      }: {
        projectId: string;
        executiveId: string;
        vrUrl: string;
      }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom`,
        method: 'POST',
        body: { executiveId, vrUrl },
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),

    deleteProjectExecutive: build.mutation({
      query: ({ projectId, executiveWithDemoRoomId }) => ({
        url: `api/projects/${projectId}/executivesWithDemoRoom/${executiveWithDemoRoomId}`,
        method: 'DELETE',
      }),
      // invalidatesTags: (result, error, { id }) => [{ type: 'Projects', id }]
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectsQuery,
  useGetProjectsByUserIdQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,

  useAddDemoRoomMutation,
  useDeleteDemoRoomMutation,
  useUpdateDemoRoomMutation,

  useAddProjectExecutiveMutation,
  useDeleteProjectExecutiveMutation,
} = projectsAPISlice;
