import { IProject } from '../../admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';
import { cpAPISlice } from './index';

export const cpProjectAPISlice = cpAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getClubRhodiumProjects: build.query<IProject[], any>({
      query: (clubRhodiumCompanyId: string) =>
        `api/projects/club-rhodium/${clubRhodiumCompanyId}`,
      transformResponse: (response: {
        data: IProject[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetClubRhodiumProjectsQuery } = cpProjectAPISlice;
