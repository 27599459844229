import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

// import AdminLogin from '../components/application/AdminLogin';
import AdminLayer from '../components/layoutBluePrints/AdminLayer';
import AdminLogin from '../components/pages/AdminLogin/index';

import { isAdminUserLoggedIn } from '../components/pages/AdminLogin/utils';

const AdminRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/login`} component={AdminLogin} />

      <Route path={`${path}/`}>
        {isAdminUserLoggedIn() ? (
          <AdminLayer />
        ) : (
          <Redirect to={`${path}/login`} />
        )}
      </Route>

      {/* Unmatched url redirect to dashbaord */}
      <Redirect to={`${path}/`} />
    </Switch>
  );
};

export default AdminRoutes;
