export const isEmpty = (obj: any) =>
  !obj || (obj && Object.keys(obj).length === 0 && obj.constructor === Object);

export const toIndianRupee = (number = 0) => {
  const roundOffNumber = Math.round(number);
  return parseFloat(roundOffNumber.toString()).toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'INR',
  });
};

export const ordinalSuffixOf = (number: number) => {
  var j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + 'st';
  }
  if (j === 2 && k !== 12) {
    return number + 'nd';
  }
  if (j === 3 && k !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const rankToWordMapping = [
  'Zeroth',
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
  'Thirteenth',
  'Fourteenth',
  'Fifteenth',
  'Sixteenth',
  'Seventeenth',
  'Eighteenth',
  'Nineteenth',
];

export const randomId = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

export const toTwoDigits = (seconds: number) => {
  return seconds < 10 ? '0' + seconds : seconds;
};

export const getLiveSinceDays = (startDate: Date) => {
  const date1: any = new Date(startDate);
  const date2: any = new Date();

  return Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));
};

// program to convert first letter of a string to uppercase
export const capitalizeFirstLetter = (str: string): string => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};
