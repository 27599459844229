import Button from '../../../../../components/common/buttons/Button';

import styles from './styles.module.css';

interface IImageContentFullPageProps {
  title: string;
  subTitle: string;
  picture: string;
  buttonText: string;
  onClick?: () => void;
}

const ImageContentFullPage = (props: IImageContentFullPageProps) => {
  const { title, subTitle, picture, buttonText, onClick } = props;
  return (
    <div className={styles.container}>
      <div className={styles.contentWrap}>
        {/* TODO: change <img> to reuseable <Image> component  */}
        <img src={picture} className={styles.welcomeImge} alt='People' />
        <p className={styles.title}>{title}</p>
        <p className={styles.subTitle}>{subTitle}</p>
        <Button
          type='submit'
          crApp
          propStyles={styles.getStartedBtn}
          onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default ImageContentFullPage;
