import { useState } from 'react';

import 'ag-grid-enterprise';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import Modal from '../../../../../../../components/common/modal/Modal';
import Icon from '../../../../../../../components/common/Icon';
import Tooltip from '../../../../../../../components/common/Tooltip/index';
import { useSelector } from 'react-redux';
import {
  getAdminCompany,
  getAdminUser,
} from '../../../../../slices/adminSlice';
import AddProjectExecutiveModal from '../../../SuperAdminDashboard/SuperAdminProjectList/modals/AddProjectExecutiveModal';
import { IProject } from '../../../SuperAdminDashboard/SuperAdminProjectList';
import Button from '../../../../../../../components/common/buttons/Button';
import UpdateUserCell, {
  IUpdateUserCell,
} from '../../AdminExecutiveList/Body/UserListGrid/UpdateUserCell';
import { UserRole } from '../../../../../../../constants/userRoles';
import UserDetailsCell from '../../AdminExecutiveList/Body/UserListGrid/UserDetailsCell';
import { IUser } from '../../../../../interfaces';
import RemoveProjectExecutiveCell, {
  IRemoveProjectExecutiveCell,
} from './RemoveProjectExecutiveCell';

import styles from './styles.module.css';
import genericStyles from '../../../../../../../styles/genericStyles.module.css';

interface IProjectExecutiveListModal {
  project: IProject;
  onModalClose: Function;
  onProjectUpdate: (updateProject: IProject) => void;
}

const ProjectExecutiveListModal = (props: IProjectExecutiveListModal) => {
  const { project, onModalClose, onProjectUpdate } = props;

  const [showAddProjectExecutiveModal, setShowAddProjectExecutiveModal] =
    useState(false);
  const [executiveList, setExecutiveList] = useState(
    project.executivesWithDemoRoom.map((e) => e.executive)
  );

  const adminCompany = useSelector(getAdminCompany);
  const loggedInUser = useSelector(getAdminUser);
  const projectName = project.name;

  //Ag-grid default column definitions
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  const handleAddDeleteExecutive = (updatedProject: IProject) => {
    onProjectUpdate(updatedProject);
    setExecutiveList(
      updatedProject.executivesWithDemoRoom.map((e) => e.executive)
    );
  };

  const handleExecutiveUpdate = (updateUser: IUser) => {
    // Update the updatedExecutive details
    const updatedProject = {
      ...project,
      executivesWithDemoRoom: project.executivesWithDemoRoom.map((e) => {
        if (e.executive.id === updateUser.id) {
          return { ...e, executive: updateUser };
        }
        return e;
      }),
    };
    onProjectUpdate(updatedProject);
  };

  if (loggedInUser?.role !== UserRole.SUPERADMIN && !adminCompany?.id) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {showAddProjectExecutiveModal && (
        <AddProjectExecutiveModal
          project={project}
          onModalClose={() => {
            setShowAddProjectExecutiveModal(false);
          }}
          onAddExecutive={handleAddDeleteExecutive}
        />
      )}

      <Modal
        onOutsideClick={() => onModalClose()}
        additionalClasses={`${styles.modalAdditionalClasses}`}>
        {/* Modal Header */}
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>
              {projectName} List of Executives
            </h2>
            <p className={styles.modalDesc}>
              Following executives get access to all project of {projectName}{' '}
              developer. You can edit details or remove users from here.
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.crossIcon}
            onClick={() => onModalClose()}
          />
        </header>

        {/* Modal Body */}
        <main
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowHeight={60}
            rowData={executiveList}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}>
            <AgGridColumn
              flex={4}
              headerName='Name'
              field='name'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <Tooltip title='Click to Copy'>
                  <p
                    onClick={() => {
                      navigator.clipboard.writeText(`${params.data.name}`);
                    }}
                    className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                    {params.data.name}
                  </p>
                </Tooltip>
              )}></AgGridColumn>
            <AgGridColumn
              flex={4}
              headerName='Mobile'
              field='mobile'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <Tooltip title='Click to Copy'>
                  <p
                    onClick={() => {
                      navigator.clipboard.writeText(`${params.data.mobile}`);
                    }}
                    className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                    {params.data.mobile}
                  </p>
                </Tooltip>
              )}></AgGridColumn>
            <AgGridColumn
              flex={6}
              headerName='Email'
              field='email'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <Tooltip title='Click to Copy'>
                  <p
                    onClick={() => {
                      navigator.clipboard.writeText(`${params.data.email}`);
                    }}
                    className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                    {params.data.email}
                  </p>
                </Tooltip>
              )}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Edit'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              field=''
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={UpdateUserCell}
              cellRendererParams={(
                params: ICellRendererParams
              ): IUpdateUserCell => ({
                user: params.data,
                rowNode: params.node,
                onUserUpdate: handleExecutiveUpdate,
                disabled: !(
                  params.data.company === adminCompany?.id ||
                  loggedInUser?.role === UserRole.SUPERADMIN
                ),
              })}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Remove'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              field=''
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={RemoveProjectExecutiveCell}
              cellRendererParams={(
                params: ICellRendererParams
              ): IRemoveProjectExecutiveCell => ({
                executiveWithDemoRoomId: project.executivesWithDemoRoom.find(
                  (e) => e.executive.id === params.data.id
                )?.id as string,
                projectId: project.id,
                onExecutiveDelete: handleAddDeleteExecutive,
                disabled: !(
                  params.data.company === adminCompany?.id ||
                  loggedInUser?.role === UserRole.SUPERADMIN
                ),
              })}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Details'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={UserDetailsCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                user: params.data as IUser,
              })}></AgGridColumn>
          </AgGridReact>
        </main>

        {/* Modal Footer */}
        <footer className={styles.footerContainer}>
          <div className={styles.footerLeftContainer}>
            <Button
              onClick={() => {
                setShowAddProjectExecutiveModal(true);
              }}
              propStyles={styles.addExecutiveButton}>
              Add Executive
            </Button>
            <p className={styles.disclaimer}>
              Adding an executive will send them an email with their randomly
              generated password.
            </p>
          </div>
          <div className={styles.executiveCount}>
            {executiveList.length} Executives
          </div>
        </footer>
      </Modal>
    </>
  );
};

export default ProjectExecutiveListModal;
