import { IAddressDetails } from '../../../components/common/SendOtp';
import { TObjectId } from '../../../interfaces';
import {
  ICpLead,
  ICpLeadReq,
  ICpUser,
  ICpUserDashboardStats,
  ICpUserReq,
  ICpUserUpdateReq,
} from '../interfaces';
import { cpAPISlice } from './index';

export const cpUserAPISlice = cpAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getCpUserByMobile: build.mutation({
      query: (addressDetails: IAddressDetails) => ({
        url: `api/users/cp/${addressDetails.addressType.toLocaleLowerCase()}/${
          addressDetails.address
        }`,
        method: 'GET',
      }),
    }),
    getCpUsersByAdminUserId: build.query<ICpUser[], any>({
      query: (adminUserId: TObjectId) => `api/users/cp/${adminUserId}`,
      transformResponse: (response: {
        data: ICpUser[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),

    addCpUser: build.mutation({
      query: (cpUser: ICpUserReq) => ({
        url: 'api/users/cp',
        method: 'POST',
        body: cpUser,
      }),
    }),
    updateCpUser: build.mutation({
      query: ({ cpUserId, ...cpUser }: ICpUserUpdateReq) => ({
        url: `api/users/cp/${cpUserId}`,
        method: 'PUT',
        body: cpUser,
      }),
    }),

    addCpLead: build.mutation({
      query: ({
        cpUserId,
        cpLead,
      }: {
        cpUserId: TObjectId;
        cpLead: ICpLeadReq;
      }) => ({
        url: `api/users/cp/cp-lead/${cpUserId}`,
        method: 'POST',
        body: cpLead,
      }),
    }),
    getCpLeadById: build.query({
      query: ({ cpLeadId }: { cpLeadId: TObjectId }) =>
        `api/users/cp/cp-lead/${cpLeadId}`,
      transformResponse: (response: {
        data: ICpLead;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getCpUserApprovalStatus: build.query({
      query: ({ cpUserId }: { cpUserId: string }) =>
        `api/users/cp/approval-status/${cpUserId}`,
      keepUnusedDataFor: 1,
    }),

    getCpUserDashboardStatsByCpUserId: build.query<ICpUserDashboardStats, any>({
      query: (cpUserId: TObjectId) => `api/users/cp/reports/${cpUserId}`,
      transformResponse: (response: {
        data: ICpUserDashboardStats;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCpUserByMobileMutation,
  useGetCpUsersByAdminUserIdQuery,

  useAddCpUserMutation,
  useUpdateCpUserMutation,

  useAddCpLeadMutation,
  useGetCpLeadByIdQuery,

  useGetCpUserApprovalStatusQuery,

  useGetCpUserDashboardStatsByCpUserIdQuery,
} = cpUserAPISlice;
