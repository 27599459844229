import { adminAPISlice } from './index';
import { TObjectId } from '../../../interfaces';
import { IProjectLead } from '../../cp/interfaces';

export const projectLeadsAPI = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getProjectLeadsByAdminUserId: build.query<IProjectLead[], any>({
      query: (adminUserId: TObjectId) => `api/projectLeads/${adminUserId}`,
      transformResponse: (response: {
        data: IProjectLead[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProjectLeadsByAdminUserIdQuery } = projectLeadsAPI;
