import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import DropDownInput, {
  DropDownOptionType,
} from '../../../../../../components/common/inputs/DropDownInput';
import TextInput from '../../../../../../components/common/inputs/TextInput';
import useToast from '../../../../../../hooks/useToast';
import { ICpUser } from '../../../../interfaces';
import { useUpdateCpUserMutation } from '../../../../services/cpUserAPISlice';
import { getCpUser, setCpUser } from '../../../../slices/cpUserSlice';
import ProfileHeader from '../../../common/ProfileHeader';
import { stateDropDown } from '../../../../../../utils/applicantFormDropDownOptions';

import styles from './styles.module.css';

const cityList: DropDownOptionType[] = [
  {
    value: 'Pune',
    displayText: 'Pune',
  },
  {
    value: 'Delhi',
    displayText: 'Delhi',
  },
];

const stateList: DropDownOptionType[] = [
  {
    value: 'Maharastra',
    displayText: 'Maharastra',
  },
  {
    value: 'Karnataka',
    displayText: 'Karnataka',
  },
];

const schema = yup.object({
  address: yup.object({
    flatNumber: yup.string().required(),
    street: yup.string().required(),
    landmark: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    pinCode: yup.string().length(6).required(),
  }),
});

const UpdateCpUserAddress = () => {
  const {
    register,
    unregister,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const dispatch = useDispatch();
  const cpUser = useSelector(getCpUser) as ICpUser;
  const [addToast] = useToast();
  const [updateCpUserAPI] = useUpdateCpUserMutation();

  const handleSave = async (cpUserData: ICpUser) => {
    try {
      const response = await updateCpUserAPI({
        cpUserId: cpUser.id,
        ...cpUserData,
      }).unwrap();

      const updatedCpUser: ICpUser = response.data;
      dispatch(setCpUser(updatedCpUser));

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated Address',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  return (
    <div className={styles.containerWrap}>
      <ProfileHeader
        iconName={'arrow_back'}
        pageName={'Address'}
        buttonName={'Save'}
        onSave={() => handleSubmit(handleSave)()}
      />
      <div className={styles.formWrap}>
        {/* fullName is not needed, use cpUser.user.name for address fullName */}
        <TextInput
          label='Flat, House no., Building, Company, Apartment *'
          placeHolder='Add Flat, House no...'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='address.flatNumber'
          defaultValue={cpUser.address?.flatNumber || cpUser.addressTemp}
          errorMessage={
            errors.address?.flatNumber ? 'This field is required' : ''
          }
          crApp
        />
        <TextInput
          label='Area, Street, Sector, Village *'
          placeHolder='Add Area, Street, Sector, Village'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='address.street'
          defaultValue={cpUser.address?.street}
          errorMessage={errors.address?.street ? 'This field is required' : ''}
          crApp
        />
        <TextInput
          label='Landmark'
          placeHolder='Add landmark'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='address.landmark'
          defaultValue={cpUser.address?.landmark}
          crApp
        />
        <TextInput
          label='City *'
          placeHolder='Add city'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='address.city'
          defaultValue={cpUser.address?.city}
          errorMessage={errors.address?.city ? 'This field is required' : ''}
          crApp
        />
        <DropDownInput
          options={stateDropDown}
          lable='State *'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='address.state'
          defaultSelectedOption={stateDropDown.find(
            (state) => state.value === cpUser.address?.state
          )}
          errorMessage={errors.address?.state ? 'This field is required' : ''}
          appType='RELATA-CR'
        />
        <TextInput
          label='Pincode *'
          placeHolder='Add pincode'
          register={register}
          unregister={unregister}
          setValue={setValue}
          name='address.pinCode'
          defaultValue={cpUser.address?.pinCode}
          errorMessage={
            errors.address?.pinCode ? 'Enter valid 6 digit pincode' : ''
          }
          type='number'
          maxLength={6}
          crApp
        />
      </div>
    </div>
  );
};

export default UpdateCpUserAddress;
