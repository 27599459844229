import { Provider } from 'react-redux';
import { store } from './store';
// TODO: Setup eslint to show errors for wrong imports
import { BrowserRouter } from 'react-router-dom';
import Routes from './routers/Routes';

function App() {
  return (
    <div className='App'>
      <Provider store={store}>
        <BrowserRouter basename=''>
          <Routes />
        </BrowserRouter>
      </Provider>
    </div>
  );
}
export default App;
