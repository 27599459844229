import SummaryCompoent from './common/admin/SummaryCompoent';
import BrandCardLarge from './common/admin/BrandCardLarge';
import ExecutivePerformanceCard from './common/admin/ExecutivePerformanceCard';
import ProgressBar from './common/admin/ProgressBar';
import UserPhotosList from './common/admin/UserPhotosList';
import {
  brandCardLargeData,
  ExecutiveListData,
  ExecutivePerformanceData,
  progressBarData,
  summaryData,
} from './common/admin/MockData';
import { PerformanceCard } from './common/admin/Interface';
import { useState } from 'react';

const CommonAdminComponents = () => {
  const [execPerfData, updatePerfData] = useState(ExecutivePerformanceData);
  const togglePerformanceSelection = (card: PerformanceCard) => {
    const data = { ...execPerfData };
    data.selected = !data.selected;
    updatePerfData(data);
  };
  return (
    <div className='common-admin-components-container'>
      <div className='common-admin-components-container__label'>
        Summary Component
      </div>
      <div className='common-admin-components-container__item'>
        <SummaryCompoent {...summaryData} />
      </div>
      <div className='common-admin-components-container__label'>
        Brand Card Large
      </div>
      <div className='common-admin-components-container__item'>
        <BrandCardLarge {...brandCardLargeData} />
      </div>
      <div className='common-admin-components-container__label'>
        Executive Performance Card + hover + selected
      </div>
      <div className='common-admin-components-container__item'>
        <ExecutivePerformanceCard
          card={execPerfData}
          onCardClick={(card: PerformanceCard) =>
            togglePerformanceSelection(card)
          }
        />
      </div>
      <div className='common-admin-components-container__label'>
        Executive Details Card
      </div>

      <div className='common-admin-components-container__label'>
        Progress Bar
      </div>
      <div className='common-admin-components-container__item'>
        <ProgressBar {...progressBarData} />
      </div>
      <div className='common-admin-components-container__label'>
        Executive List
      </div>
      <div className='common-admin-components-container__item'>
        <UserPhotosList {...ExecutiveListData} />
      </div>
    </div>
  );
};

export default CommonAdminComponents;
