import { NavLink, useLocation } from 'react-router-dom';

import { IconNames } from '../../../../../components/common/Icon';
import IconWithLabel from '../../../../admin/components/common/IconWithLabel';

import styles from './styles.module.css';

export interface ICpFooterItem {
  iconName: IconNames;
  labelText: string;
  redirectPath: string;
}

const CpFooterItem = (props: ICpFooterItem) => {
  const { iconName, labelText, redirectPath } = props;

  const { pathname: currentPath } = useLocation();

  const currentItemActive = redirectPath === currentPath;

  return (
    <NavLink to={redirectPath}>
      <IconWithLabel
        onClick={() => {}}
        iconName={iconName}
        label={labelText}
        propStyles={`${styles.footerIcon} ${
          currentItemActive && styles.active
        }`}
      />
    </NavLink>
  );
};

export default CpFooterItem;
