import { useState } from 'react';

import LeadsDashboardHeader from '../ActiveLeads/LeadsHeader';
import { LeadsHeaderData } from './../ActiveLeads/MockData';
import InventoryListGrid from './InventoryListGrid';

import styles from './styles.module.css';

const inventoryManagementTabsEnum = {
  INVENTORY: { value: 'INVENTORY', displayText: 'Inventory' },
  SETTINGS: { value: 'SETTINGS', displayText: 'Settings' },
};

const InventoryManagement = () => {
  const [selectedTabTag, setSelectedTabTag] = useState(
    inventoryManagementTabsEnum.INVENTORY.value
  );

  const adminDashboardTabs = [
    {
      name: inventoryManagementTabsEnum.INVENTORY.displayText,
      tag: inventoryManagementTabsEnum.INVENTORY.value,
      isDisabled: false,
    },
    {
      name: inventoryManagementTabsEnum.SETTINGS.displayText,
      tag: inventoryManagementTabsEnum.SETTINGS.value,
      isDisabled: true,
    },
  ];

  return (
    <>
      <header>
        <LeadsDashboardHeader {...LeadsHeaderData} />
      </header>

      <main>
        <div className={styles.tabsContainer}>
          <div className={styles.tabContainerHeader}>
            {adminDashboardTabs.map((tab) => (
              <div
                key={tab.tag}
                className={`${styles.tabItem} ${
                  tab.isDisabled
                    ? styles.disabledTab
                    : selectedTabTag === tab.tag
                    ? styles.activeTab
                    : ''
                }`}
                onClick={() => setSelectedTabTag(tab.tag)}>
                {tab.name}
              </div>
            ))}
          </div>

          <div className={styles.tabContainerBody}>
            {selectedTabTag === inventoryManagementTabsEnum.INVENTORY.value && (
              <InventoryListGrid />
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default InventoryManagement;
