import { useState } from 'react';
import { IUser } from '../../../../../../../interfaces';
import { useDeleteUserMutation } from '../../../../../../../services/companyAPISlice';
import useToast from '../../../../../../../../../hooks/useToast';
import Icon from '../../../../../../../../../components/common/Icon';
import ConfirmationModal, {
  IConfirmationModal,
} from '../../../../../../../../../components/common/genericModals/ConfirmationModal';

export interface IRemoveCompanyUserCell {
  user: IUser;
  companyId: string;
  onUserDelete: (userId: string) => void;
}

const RemoveCompanyUserCell = (props: IRemoveCompanyUserCell) => {
  const { user, companyId, onUserDelete } = props;

  const [confirmBlockProject, setConfirmBlockProject] =
    useState<IConfirmationModal>();

  const [deleteUserAPI] = useDeleteUserMutation();
  const [addToast] = useToast();

  const handleDeleteUser = () => {
    setConfirmBlockProject({
      accent: 'DANGER',
      heading: 'Do you want to DELETE the user?',
      onModalClose: () => setConfirmBlockProject(undefined),
      onResponse: async (confirmationResponse: any) => {
        try {
          await confirmationResponse;

          const userId = user.id as string;
          await deleteUserAPI({
            userId,
            companyId,
          }).unwrap();

          onUserDelete(userId);

          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Successfully Deleted User',
          });
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
          });
        }
      },
    });
  };

  return (
    <>
      {confirmBlockProject && <ConfirmationModal {...confirmBlockProject} />}
      <Icon name='highlight_off' onClick={() => handleDeleteUser()} />
    </>
  );
};

export default RemoveCompanyUserCell;
