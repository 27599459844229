import { adminAPISlice } from './index';
import { ICompany, ISuperAdminDashboardCompany } from '../interfaces';

export const companyAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<ICompany[], any>({
      query: () => 'api/companies/',
      transformResponse: (response: {
        data: ICompany[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   // is result available?
      //   result
      //     ? // successful query
      //       [
      //         ...result.map(({ id }) => ({ type: 'Companies' as const, id })),
      //         { type: 'Companies', id: 'LIST' }
      //       ]
      //     : // an error occurred, but we still want to refetch this query when `{ type: 'Companies', id: 'LIST' }` is invalidated
      //       [{ type: 'Companies', id: 'LIST' }]
    }),

    getSuperAdminDashboardCompanies: build.query<
      ISuperAdminDashboardCompany[],
      any
    >({
      query: () => 'api/companies/superAdminDashboardCompanies',
      transformResponse: (response: {
        data: ISuperAdminDashboardCompany[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    getCompanyByUser: build.query<ICompany, any>({
      query: (userId: string) => `api/companies/users/${userId}`,
      transformResponse: (response: {
        data: ICompany;
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
    }),

    addCompanyUser: build.mutation({
      query: ({
        user,
        companyId,
      }: {
        user: {
          name: String;
          email: String;
          password: String;
          role: String;
          mobile: string;
        };
        companyId: string;
      }) => ({
        url: 'api/companies/users',
        method: 'POST',
        body: { user, companyId },
      }),
    }),

    deleteUser: build.mutation({
      query: ({
        userId,
        companyId,
      }: {
        userId: string;
        companyId: string;
      }) => ({
        url: `api/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
    }),

    addCompany: build.mutation({
      query: (company: ICompany) => ({
        url: 'api/companies/',
        method: 'POST',
        body: company,
      }),
      // invalidatesTags: [{ type: 'Companies', id: 'LIST' }]
    }),

    updateCompany: build.mutation({
      query: ({ id: companyId, ...company }: ICompany) => ({
        url: `api/companies/${companyId}`,
        method: 'PUT',
        body: company,
      }),
      // invalidatesTags: [{ type: 'Companies', id: 'LIST' }]
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCompaniesQuery,
  useGetSuperAdminDashboardCompaniesQuery,
  useGetCompanyByUserQuery,
  useAddCompanyUserMutation,
  useDeleteUserMutation,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
} = companyAPISlice;
