import { UsersListType } from './Interface';

const UserNamesList = (props: UsersListType) => {
  const { users, showNumberOfTiles } = props;
  /*
    As per design convention. All user names are not shown.
    Only #showNumberOfTiles user's name is displayed.

    Corner cases - If # of users is 3 then all user's name is shown.
  */
  const displayUsers = users.slice(0, showNumberOfTiles - 1);
  const showMore = users.length > showNumberOfTiles;
  return (
    <>
      {displayUsers.map((user, index, arr) => (
        <span key={user.id}>
          {user.name}
          {index < arr.length - 1 ? ', ' : ''}
        </span>
      ))}
      {users.length === showNumberOfTiles ? (
        <span>
          {', '} {users[showNumberOfTiles - 1].name}
        </span>
      ) : null}
      {showMore ? (
        <span>
          {' & '} {users.length - showNumberOfTiles} {'more'}
        </span>
      ) : null}
    </>
  );
};

export default UserNamesList;
