import { useState } from 'react';

import Icon, { IconNames } from '../../../../../../components/common/Icon';
import { fallbackImageUrl } from '../../../../../../constants/urls';
import { TObjectId } from '../../../../../../interfaces';
import { ICpLead } from '../../../../interfaces';
import LeadDetailsModal from '../LeadDetailsModal';

import styles from './styles.module.css';

interface ICpLeadItemProps {
  key: TObjectId;
  cpLead: ICpLead;
}

export const getMobileFromCpLead = (cpLead: ICpLead) => {
  return cpLead.user?.mobile || `******${cpLead.mobileLastFourDigits}`;
};

const CpLeadItem = (props: ICpLeadItemProps) => {
  const { key, cpLead } = props;

  const [showDetailsPopup, setShowDetailsPopup] = useState(false);

  return (
    <div key={key}>
      {showDetailsPopup && (
        <LeadDetailsModal
          onClose={() => setShowDetailsPopup(false)}
          cpLead={cpLead}
        />
      )}
      <div
        onClick={() => setShowDetailsPopup(true)}
        className={styles.leadContainer}>
        <div className={styles.cpLead}>
          <p className={styles.leadName}>{cpLead.name}</p>
          <p className={styles.mobile}>
            Mobile no : {getMobileFromCpLead(cpLead)}
          </p>
          <div className={styles.addressWrap}>
            <Icon
              propStyles={`${styles.statusIcon}  ${styles.iconRed}`}
              name='whatshot'
            />
            <p className={styles.adress}>
              {cpLead.propertyConfigs?.join(' ')} {cpLead.preferenceAreasTemp}
            </p>
          </div>
        </div>
        <div className={styles.leadQRCode}>
          {/* TODO: change <img> to reuseable <Image> component  */}
          <img src={cpLead.qrCodeUrl || fallbackImageUrl} alt='QR Code' />
        </div>
      </div>
    </div>
  );
};

export default CpLeadItem;
