import { useState } from 'react';

import 'ag-grid-enterprise';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

import NameCard from '../../../../../../../../components/common/admin/NameCard';
import { awsBaseUrl } from '../../../../../../../../constants/urls';
import { IUser } from '../../../../../../interfaces';
import Tooltip from '../../../../../../../../components/common/Tooltip';
import UpdateUserCell, { IUpdateUserCell } from './UpdateUserCell';
import UserDetailsCell from './UserDetailsCell/index';

import styles from './styles.module.css';
import genericStyles from '../../../../../../../../styles/genericStyles.module.css';
import RemoveCompanyUserCell, {
  IRemoveCompanyUserCell,
} from './RemoveCompanyUserCell';

interface IUserListGrid {
  onExecutiveListSearch?: Function;
  users: IUser[];
  companyId: string;
}

//AG-grid Default column definition
const defaultColDef = {
  sortable: false,
  filter: false,
  resizable: false,
  suppressMenu: true,
  editable: false,
  flex: 1,
};

const UserListGrid = (props: IUserListGrid) => {
  const { onExecutiveListSearch, users: _users = [], companyId } = props;

  const [users, setUsers] = useState(_users);

  const [gridApi, setGridApi] = useState(null) as any;

  //This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  onExecutiveListSearch && onExecutiveListSearch(handleGridSearch);

  const getExecutiveAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.mobile % 5
    }.png`;
  };

  const handleUserDelete = (userId: string) => {
    setUsers(users.filter((u) => u.id !== userId));
  };

  return (
    <>
      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowData={users}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          headerHeight={60}>
          <AgGridColumn
            hide={true}
            headerName=''
            field='name'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{params.value}</p>
            )}></AgGridColumn>
          <AgGridColumn
            headerName='Names'
            field='name'
            flex={4}
            cellRendererFramework={(params: ICellRendererParams) => (
              <NameCard
                {...{
                  imgUrl: `${getExecutiveAvatarUrl(params)}`,
                  title: params.data.name,
                  subtitle: params.data.email,
                  disabled: params.data.disabled,
                }}
              />
            )}></AgGridColumn>
          <AgGridColumn
            headerName='Mobile'
            field='mobile'
            flex={4}
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{params.data.mobile}</p>
            )}></AgGridColumn>

          <AgGridColumn
            flex={4}
            headerName='Email'
            field='email'
            cellClass='relata-ag-grid-cell-wrap'
            cellRendererFramework={(params: ICellRendererParams) => (
              <Tooltip title='Click to Copy'>
                <p
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.data.email}`);
                  }}
                  className={`${genericStyles.pointer} relata-ag-grid-wrappable-text ${styles.adminDetailsTextHoverAction}`}>
                  {params.data.email}
                </p>
              </Tooltip>
            )}></AgGridColumn>

          <AgGridColumn
            flex={1}
            headerName='Edit'
            headerClass='relata-ag-grid-header-justify-center'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={UpdateUserCell}
            cellRendererParams={(
              params: ICellRendererParams
            ): IUpdateUserCell => ({
              user: params.data as IUser,
              rowNode: params.node,
              disabled: false,
            })}></AgGridColumn>
          <AgGridColumn
            flex={1}
            headerName='Remove'
            headerClass='relata-ag-grid-header-justify-center'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={RemoveCompanyUserCell}
            cellRendererParams={(
              params: ICellRendererParams
            ): IRemoveCompanyUserCell => ({
              user: params.data as IUser,
              companyId: companyId,
              onUserDelete: handleUserDelete,
            })}></AgGridColumn>
          <AgGridColumn
            flex={1}
            headerName='Details'
            headerClass='relata-ag-grid-header-justify-center'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={UserDetailsCell}
            cellRendererParams={(params: ICellRendererParams) => ({
              user: params.data as IUser,
            })}></AgGridColumn>
        </AgGridReact>
      </div>
    </>
  );
};

export default UserListGrid;
