import { useState } from 'react';

import 'ag-grid-enterprise';
import { ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import Modal from '../../../../../../../../components/common/modal/Modal';
import Button from '../../../../../../../../components/common/buttons/Button';
import AddDemoRoomModal from '../AddDemoRoomModal';
import Icon from '../../../../../../../../components/common/Icon';
import { IDemoRoom } from '../AddDemoRoomModal';
import UpdateDemoRoomCell from './UpdateDemoRoomCell';

import styles from './styles.module.css';
import genericStyles from '../../../../../../../../styles/genericStyles.module.css';
import { IProject } from '../..';
import RemoveDemoRoomCell from './RemoveDemoRoomCell';

interface IDemoRoomsListModal {
  projectName: string;
  projectId: string;
  demoRooms: IDemoRoom[];
  onModalClose: Function;
  onProjectUpdate: (updatedProject: IProject) => void;
}

const DemoRoomsListModal = (props: IDemoRoomsListModal) => {
  const {
    onModalClose,
    projectName,
    projectId,
    demoRooms: _demoRooms,
    onProjectUpdate,
  } = props;

  const [demoRooms, setDemoRooms] = useState(_demoRooms);
  const [showAddDemoRoomModal, setShowAddDemoRoomModal] = useState(false);

  //Ag-grid default Column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  const handleAddDeleteDemoRooms = async (updatedProject: IProject) => {
    onProjectUpdate(updatedProject);

    setDemoRooms(updatedProject.demoRooms);
  };

  return (
    <>
      {showAddDemoRoomModal && (
        <AddDemoRoomModal
          projectName={projectName}
          projectId={projectId}
          onModalClose={() => setShowAddDemoRoomModal(false)}
          onAddDemoRoom={handleAddDeleteDemoRooms}
        />
      )}

      <Modal
        show={true}
        onOutsideClick={() => onModalClose(false)}
        additionalClasses={`${styles.modalAdditionalClasses}`}>
        {/* Modal Header */}
        <header className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalHeading}>
              {projectName} List of Demo Rooms
            </h2>
            <p className={styles.modalDesc}>
              Following executivesWithDemoRoom get access to all project of
              Runwal developer. You can edit details, reset password or remove
              users from here.
            </p>
          </div>
          <Icon
            name='close'
            propStyles={styles.crossIcon}
            onClick={() => onModalClose(false)}
          />
        </header>

        {/* Modal Body */}
        <main
          className={`ag-theme-alpine ${styles.gridContainer}`}
          style={{ height: '100%', width: '100%' }}>
          <AgGridReact
            rowHeight={60}
            rowData={demoRooms}
            defaultColDef={defaultColDef}
            domLayout={'autoHeight'}
            pagination={true}
            paginationPageSize={10}>
            <AgGridColumn
              flex={5}
              headerName='Room ID'
              field='virtualRoomId'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p
                  title='Click to Copy'
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.value}`);
                  }}
                  className={`relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                  {params.value}
                </p>
              )}></AgGridColumn>
            <AgGridColumn
              flex={5}
              headerName='Host Password'
              field='hostPassword'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p
                  title='Click to Copy'
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.value}`);
                  }}
                  className={`relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                  {params.value}
                </p>
              )}></AgGridColumn>
            <AgGridColumn
              flex={6}
              headerName='Backup Link'
              field='fallbackVirtualRoomUrl'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p
                  title='Click to Copy'
                  onClick={() => {
                    navigator.clipboard.writeText(`${params.value}`);
                  }}
                  className={`relata-ag-grid-wrappable-text ${genericStyles.onTextHoverAnimation}`}>
                  {params.value}
                </p>
              )}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='In Use'
              cellClass='relata-ag-grid-cell-wrap'
              cellRendererFramework={(params: ICellRendererParams) => (
                <p className='relata-ag-grid-wrappable-text'>
                  {params.data.isAssignedToExecutive ? 'Yes' : 'No'}
                </p>
              )}></AgGridColumn>
            {/* 
                TODO: Add edit pen icon
                TODO: Convert cells into TextInput fields 
            */}
            <AgGridColumn
              flex={2}
              headerName='Edit'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              field='addExe'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={UpdateDemoRoomCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                demoRoom: params.data,
                projectId,
                onDemoRoomUpdate: onProjectUpdate,
                rowNode: params.node,
              })}></AgGridColumn>
            <AgGridColumn
              flex={2}
              headerName='Remove'
              headerClass='relata-ag-grid-header-small-font relata-ag-grid-header-justify-center'
              field='addExe'
              cellClass='relata-ag-grid-cell-justify-center'
              cellRendererFramework={RemoveDemoRoomCell}
              cellRendererParams={(params: ICellRendererParams) => ({
                projectId,
                demoRoomId: params.data.id,
                onDemoRoomDelete: handleAddDeleteDemoRooms,
                disabled: params.data.isAssignedToExecutive,
              })}></AgGridColumn>
          </AgGridReact>
        </main>

        {/* Modal Footer */}
        <footer className={styles.footerContainer}>
          <div className={styles.footerLeftContainer}>
            <Button
              onClick={() => {
                setShowAddDemoRoomModal(true);
              }}
              propStyles={styles.addExecutiveButton}>
              Add Demo Rooms
            </Button>
            <p className={styles.disclaimer}>
              Adding an executive will send them an email with their randomly
              generated password. This action will add the executive to all the
              Runwal's projects
            </p>
          </div>
          <div className={styles.executiveCount}>{demoRooms?.length} Rooms</div>
        </footer>
      </Modal>
    </>
  );
};

export default DemoRoomsListModal;
