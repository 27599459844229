// Components
import Button from './../../../../../../../../components/common/buttons/Button';
import Icon from './../../../../../../../../components/common/Icon/index';

// Styles
import styles from './styles.module.css';

const ApproveAndRejectButtonsCell = () => {
  return (
    <div className={styles.btnsContainer}>
      <div className={styles.approveBtnContainer}>
        <Button propStyles={styles.approveBtn}>
          <Icon name='task_alt' propStyles={styles.approveIcon}></Icon>
        </Button>
      </div>

      <div className={styles.approveBtnContainer}>
        <Button propStyles={`${styles.approveBtn} ${styles.rejectBtn}`}>
          <Icon name='close' propStyles={styles.approveIcon}></Icon>
        </Button>
      </div>
    </div>
  );
};

export default ApproveAndRejectButtonsCell;
