import Icon, {
  IconNames,
} from '../../../../../../../components/common/Icon/index';

interface HotnessIndicator {
  iconName: IconNames;
  containerClassName: string;
}
const HotnessIndicatorCell = (props: HotnessIndicator) => {
  const { iconName, containerClassName } = props;
  return (
    <div className={containerClassName}>
      <Icon name={iconName} />
    </div>
  );
};

export default HotnessIndicatorCell;
