export const getDropdownPlaceholderText = (
  selectedOptionValue,
  dropDownBtnState
) => {
  const selectedTowerOption = dropDownBtnState.options.find(
    (option) => option.value === selectedOptionValue
  );
  return selectedTowerOption
    ? selectedTowerOption.text
    : dropDownBtnState.fallBackText;
};

const DropdownContent = (props) => {
  const { btnState, show, handleSelectedOption } = props;
  return (
    <>
      <div className={`dropdown-content ${show && 'dropdown-content--show'}`}>
        {btnState.options.map((option) => (
          <button
            key={option.value}
            className='dropdown-content__item'
            // 'click-disabled': option.availableUnitCount === 0
            onClick={() => handleSelectedOption(option.value)}>
            <span
              className={`${
                option.value === btnState.selectedValue && 'font-accent-bold'
              }`}>
              {option.text}
            </span>
            <small className='dropdown-content__item-desc'>
              <span className='f-bold'>{option.availableUnitCount}</span> units
              available
            </small>
          </button>
        ))}
      </div>
    </>
  );
};

export default DropdownContent;
