const authTokenLocalKey = 'AUTH-TOKEN';

export const setAuthTokenLocal = (token: string) => {
  localStorage.setItem(authTokenLocalKey, token);
};

export const getAuthTokenLocal = (): string => {
  const token = localStorage.getItem(authTokenLocalKey) as string;
  return token;
};

export const removeAuthTokenLocal = () => {
  localStorage.removeItem(authTokenLocalKey);
};
