import CrLeadListPage from '../CrLeadListPage';
import styles from './styles.module.css';

const CpLeadListPage = () => {
  return (
    <div className={styles.leadListWrap}>
      <CrLeadListPage />
    </div>
  );
};

export default CpLeadListPage;
