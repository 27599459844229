import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ImageContentFullPage from '../../common/ImageContentFullPage';
import { getCpUser } from '../../../slices/cpUserSlice';
import { useGetCpUserApprovalStatusQuery } from '../../../services/cpUserAPISlice';

function ApprovalPending() {
  const history = useHistory();

  const approvalImage =
    'https://relata.s3.ap-south-1.amazonaws.com/common/approve.png';

  const cpUser = useSelector(getCpUser);

  if (!cpUser) {
    history.push('/cp/kyc/landing');
    return null;
  }

  if (cpUser?.approved) {
    history.push('/cp/kyc/welcome');
  }

  const { data: cpUserStatus } = useGetCpUserApprovalStatusQuery({
    cpUserId: cpUser.id,
  });

  if (cpUserStatus?.data.approved) {
    history.push('/cp/kyc/welcome');
  }

  return (
    <>
      <ImageContentFullPage
        title='Approval Pending'
        subTitle="Your process for approval is ongoing, we will notify you once it's approved."
        buttonText='REFRESH'
        picture={approvalImage}
        onClick={() => {
          window.location.reload();
        }}
      />
    </>
  );
}

export default ApprovalPending;
