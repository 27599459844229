import { adminAPISlice } from './index';
import { ISiteVisitDetails } from '../components/pages/forms/SiteVisit';
import { TObjectId } from '../../../interfaces';
import { ISiteVisit } from '../../cp/interfaces';

export const siteVisitAPI = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getSiteVisitsByCpLeadId: build.query<ISiteVisit[], any>({
      query: (cpLeadId: TObjectId) => `api/siteVisits/${cpLeadId}`,
      transformResponse: (response: {
        data: ISiteVisit[];
        code: String;
        error: Object;
        message: String;
      }) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),
    addSiteVisit: build.mutation({
      query: (siteVisitDetails: ISiteVisitDetails) => ({
        url: 'api/siteVisits/',
        method: 'POST',
        body: siteVisitDetails,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetSiteVisitsByCpLeadIdQuery, useAddSiteVisitMutation } =
  siteVisitAPI;
