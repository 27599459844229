import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useToast from '../../hooks/useToast';

import { useDispatch } from 'react-redux';

import { getUser } from '../../apps/booking/slices/auth';
import { useSelector } from 'react-redux';
import UnitSummaryCard from '../UnitSummaryCard';
import { getUnitCart } from '../../apps/booking/slices/selectedUnit';
import {
  useCreatePaymentOrderMutation,
  useSuccessfulPaymentMutation,
} from '../../api/paymentAPISlice';
import { URLS } from '../../constants/urls';
import {
  getAppSliceIsFetching,
  setAppSliceIsFetching,
} from '../../slices/appSlice';
import { useLazyGetUnitCartQuery } from '../../api/unitCart';
import { BOOKED } from '../../constants/status';

const PaymentStatus = Object.freeze({
  Idle: 1,
  Intitiate: 2,
  Processing: 3,
  Successful: 4,
  Failed: 5,
});

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const unitCart = useSelector(getUnitCart);
  const [paymentStatus, setPaymentStatus] = useState(
    unitCart.unit.status === BOOKED
      ? PaymentStatus.Successful
      : PaymentStatus.Intitiate
  );
  const user = useSelector(getUser);
  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const [addToast] = useToast();

  const [successfulPaymentAPI] = useSuccessfulPaymentMutation();
  const [createPaymentOrderAPI] = useCreatePaymentOrderMutation();
  const [getUnitCartAPI] = useLazyGetUnitCartQuery();

  const loadRazorpayScript = (src) => {
    const razorpayScriptId = 'razorpay-checkout';
    const doesScriptAlreadyAdded = document.querySelector(
      `#${razorpayScriptId}`
    );

    return new Promise((resolve) => {
      if (doesScriptAlreadyAdded) {
        resolve(true);
        return;
      }
      const script = document.createElement('script');
      script.id = 'razorpay-checkout';
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const payWithRazorpay = async () => {
    if (
      paymentStatus === PaymentStatus.Processing ||
      paymentStatus === PaymentStatus.Successful
    ) {
      // Already processing
      return;
    }

    const res = await loadRazorpayScript();

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    setPaymentStatus(PaymentStatus.Processing);
    dispatch(setAppSliceIsFetching(true));

    const { data: resPaymentOrder, error: errPaymentOrder } =
      await createPaymentOrderAPI(unitCart.id);

    if (errPaymentOrder) {
      alert(errPaymentOrder.data.message);
      console.error(errPaymentOrder.data.message);
      dispatch(setAppSliceIsFetching(false));
      setPaymentStatus(PaymentStatus.Failed);
      history.push(URLS.UNIT_SELECTION);
      return;
    }

    // Fetch unitCart status as createPaymentOrderAPI blocks unit
    await getUnitCartAPI({
      unit_id: unitCart.unit.id,
      user_id: unitCart.user.id,
    });

    var options = {
      key: 'rzp_test_8fW7EX6iF0Vixh', // Enter the Key ID generated from the Dashboard
      amount: resPaymentOrder.razorpay_order.amount,
      currency: 'INR',
      name: 'Embassy Edge',
      description: 'Test Transaction',
      image:
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/embassy-primary-logo.svg',
      order_id: resPaymentOrder.razorpay_order.order_id,
      handler: async (response) => {
        // TODO: Signiture verfication

        const { errors: { errors: errSuccessfulPayment } = {} } =
          await successfulPaymentAPI({
            paymentOrderId: resPaymentOrder.id,
            transaction_id: response.razorpay_payment_id,
          });

        if (errSuccessfulPayment) {
          // Failed
          addToast({
            type: 'ERROR',
            primaryMessage: 'Payment Failed!',
            secondaryMessage: errSuccessfulPayment,
          });
          setPaymentStatus(PaymentStatus.Failed);
        } else {
          // Successful

          // Fetch unitCart status as successfulPaymentAPI books unit
          await getUnitCartAPI({
            unit_id: unitCart.unit.id,
            user_id: unitCart.user.id,
          });

          addToast({
            type: 'SUCCESS',
            primaryMessage: 'Payment Successful!',
            secondaryMessage: 'Your payment has been recieved',
          });

          setPaymentStatus(PaymentStatus.Successful);
        }

        dispatch(setAppSliceIsFetching(false));
      },
      prefill: {
        name: user.fname + ' ' + user.lname,
        email: user.email,
        contact: user.mobile_no,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      modal: {
        ondismiss: function () {
          document.querySelector('.razorpay-container').remove();
          document.querySelector('#razorpay-checkout').remove();
          setPaymentStatus(PaymentStatus.Failed);
          dispatch(setAppSliceIsFetching(false));
        },
      },
      theme: {
        color: '#906fc8',
      },
    };
    var razorPayInstance = new window.Razorpay(options);
    razorPayInstance.open();
  };

  let statusImagePath;
  switch (paymentStatus) {
    case PaymentStatus.Processing:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-processing.jpg';
      break;
    case PaymentStatus.Successful:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-success.png';
      break;
    case PaymentStatus.Failed:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-fail.jpg';
      break;
    default:
      statusImagePath =
        'https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/payment-processing.jpg';
  }

  useEffect(() => {
    if (paymentStatus === PaymentStatus.Intitiate) {
      payWithRazorpay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProceed = () => {
    history.push(URLS.KYC_DETAILS);
  };

  return (
    <>
      {/* <div>{statusView}</div> */}

      <div className='payment-plan'>
        <div className='payment-plan__body'>
          <div className='payment-plan-milstone-table-container'>
            {/* <div className="payment-plan-milstone-table__seemore-overlay-bottom"> */}
            <div className='payment-image-container'>
              <img
                className='payment-page-status-image'
                src={statusImagePath}
                alt='Unit 2D plan'
              />
            </div>
            {/* </div> */}
          </div>
          <UnitSummaryCard />
        </div>
        <footer className='payment-plan__footer'>
          <button
            type='button'
            className='btn btn--transparent'
            onClick={() => {
              history.push(URLS.KYC);
            }}>
            Back to KYC
          </button>
          <div className='payment-plan__footer-action-btns'>
            <button
              type='button'
              className={`btn btn--outline  ${
                appSliceIsFetching && 'loading-spin-container'
              } ${
                (paymentStatus === PaymentStatus.Successful ||
                  paymentStatus === PaymentStatus.Processing) &&
                'btn--outline-disabled'
              }`}
              onClick={() => payWithRazorpay()}>
              <span>
                {paymentStatus === PaymentStatus.Successful
                  ? 'Payment Done'
                  : paymentStatus === PaymentStatus.Processing
                  ? 'Payment Processing'
                  : 'Retry Payment'}
              </span>
              <span className='ic2-fa-spin-blue'></span>
            </button>

            <button
              type='button'
              className={`btn  payment-plan__footer-proceed-btn  ${
                !(paymentStatus === PaymentStatus.Successful) &&
                'click-disabled'
              } ${paymentStatus === PaymentStatus.Successful && 'btn--accent'}`}
              onClick={handleProceed}>
              Proceed
            </button>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Payment;
