// import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  getCostSheet,
  getSelectedUnit,
  getUnitCart,
} from '../../apps/booking/slices/selectedUnit';
import { isEmpty, ordinalSuffixOf, toIndianRupee } from '../../utils/utils';

const CostSheetPlank = () => {
  const unitCart = useSelector(getUnitCart);
  const costSheet = useSelector(getCostSheet);
  let selectedUnit = useSelector(getSelectedUnit);
  const unitCartUnit = unitCart.unit;
  if (!isEmpty(unitCartUnit)) {
    selectedUnit = unitCartUnit;
  }

  // Comment temporary
  // const scroller = useRef();
  // const seeMoreOverlayBottomElm = useRef();
  // const seeMoreOverlayTopElm = useRef();
  // useEffect(() => {
  //   const scrollHandler = (event) => {
  //     var element = event.target;
  //     if (
  //       element.scrollHeight - Math.ceil(element.scrollTop) ===
  //       element.clientHeight
  //     ) {
  //       seeMoreOverlayBottomElm.current.classList.add(
  //         'scroll-more-overlay--hide'
  //       );
  //     } else {
  //       seeMoreOverlayBottomElm.current.classList.remove(
  //         'scroll-more-overlay--hide'
  //       );
  //     }

  //     if (element.scrollTop === 0) {
  //       seeMoreOverlayTopElm.current.classList.add('scroll-more-overlay--hide');
  //     } else {
  //       seeMoreOverlayTopElm.current.classList.remove(
  //         'scroll-more-overlay--hide'
  //       );
  //     }
  //   };
  //   const scrollerElm = scroller?.current;
  //   if (scrollerElm) {
  //     scrollerElm.addEventListener('scroll', scrollHandler);
  //     return () => {
  //       scrollerElm.removeEventListener('scroll', scrollHandler);
  //     };
  //   }
  // }, [scroller]);

  return (
    <>
      <div className='costsheet-plank'>
        <header className='costsheet-details__header'>
          <div className='costsheet-details__header-labels f-18'>
            <div className='costsheet-details__header-labels-item'>
              Unit{' '}
              <span className='f-bold'>
                {selectedUnit.unit_group}{' '}
                {`${selectedUnit.block}${selectedUnit.number}`}
              </span>
            </div>
            <div className='costsheet-details__header-labels-item'>
              Project <span className='f-bold'>Embassy Edge</span>
            </div>
            {/* {<div className="costsheet-details__header-labels-item">
              Date <span className="f-bold">Wed, 22nd Apr 21</span>
            </div>} */}
          </div>
          <div className='costsheet-details__header-summery'>
            <span className='font-accent-bold costsheet-details__total-cost'>
              {toIndianRupee(costSheet.total_inclusive_amount)}
            </span>
            <span className='f-bold f-15 c-56'>All Inclusive Total</span>
            <span className='f-regular f-15'>
              (excluding registration charges)
            </span>
          </div>
          {/* <div
            ref={seeMoreOverlayTopElm}
            className="scroll-more-overlay scroll-more-overlay--top scroll-more-overlay--hide"></div> */}
        </header>

        <div comment='ref={scroller}' className='costsheet-details__body'>
          <ul className='costsheet-details__body-highlights'>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {selectedUnit.area.super_build_up} Sq.Ft
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                Super Built up Area
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                {selectedUnit.unit_group} {selectedUnit.block},{' '}
                {selectedUnit.type}
              </p>
            </li>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {toIndianRupee(costSheet.base_rate_per_sqft)}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                Base Price per Sq.ft
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                Cost of Footprint/sq.ft
              </p>
            </li>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {toIndianRupee(costSheet.floor_rise)}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                Floor Rise
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                As per {ordinalSuffixOf(selectedUnit.floor_number)} Floor
                Selection
              </p>
            </li>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {toIndianRupee(costSheet.plc)}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                PLC
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                Net Cost
              </p>
            </li>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {toIndianRupee(costSheet.agreement_value)}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                Agreement Cost
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                Net Cost
              </p>
            </li>
            <li>
              <h3 className='costsheet__statistics-value costsheet__statistics-value--primary costsheet__statistics-value--small'>
                {toIndianRupee(costSheet.gst_value)}
              </h3>
              <p className='costsheet__statistics-description costsheet__statistics-description--large f-bold'>
                GST
              </p>
              <p className='costsheet__statistics-description costsheet__statistics-description--tagline costsheet__statistics-description--small'>
                on 2/3rd of total value
              </p>
            </li>
          </ul>
          <div className='costsheet-details__body-section-container'>
            <section className='costsheet-plant__body-section'>
              <h3 className='costsheet__details-heading'>Summary</h3>
              <p className='costsheet__details-description'>
                Select from one of the available units to proceed
              </p>
              <ul>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Unit Number</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {selectedUnit.unit_group},{' '}
                    {`${selectedUnit.block}${selectedUnit.number}`}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    Super Built up Area
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {selectedUnit.area.super_build_up} Sq.Ft
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    Tower and Block
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {`${selectedUnit.unit_group} ${selectedUnit.block}`}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Floor</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {ordinalSuffixOf(selectedUnit.floor_number)} Floor
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Configuration</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {selectedUnit.type}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Carpet Area</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {selectedUnit.area.carpet} Sq.Ft
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Agreement Cost</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(costSheet.agreement_value)}
                  </p>
                </li>
              </ul>
            </section>

            <section className='costsheet-plant__body-section'>
              <h3 className='costsheet__details-heading'>Cost Break-Up</h3>
              <p className='costsheet__details-description'>
                Select from one of the available units to proceed
              </p>
              <ul>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    Base Price Per Sq.ft
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(selectedUnit.area.super_build_up)}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>PLC</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(costSheet.plc)}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Floor Rise</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(costSheet.floor_rise)}
                  </p>
                </li>

                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    Total Value Of Property
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(costSheet.property_value)}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    GST (7.5%) on 2/3rd of total value
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(costSheet.gst_value)}
                  </p>
                </li>
                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>
                    Cost Of The Apartment (Agreement Value)
                  </p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(costSheet.agreement_value)}
                  </p>
                </li>
              </ul>
            </section>

            <section className='costsheet-plant__body-section'>
              <h3 className='costsheet__details-heading'>
                Additionals & Deposits
              </h3>
              <p className='costsheet__details-description'>
                Select from one of the available units to proceed
              </p>
              <ul>
                {costSheet.additional_details.map((additional_cost) => (
                  <li className='costsheet__detail-item'>
                    <p className='costsheet__detail-item-value'>
                      {additional_cost.name}
                    </p>
                    <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                      {toIndianRupee(additional_cost.total_cost)}
                    </p>
                  </li>
                ))}

                <li className='costsheet__detail-item'>
                  <p className='costsheet__detail-item-value'>Total</p>
                  <p className='costsheet__detail-item-value costsheet__detail-item-value--right f-bold'>
                    {toIndianRupee(
                      parseFloat(costSheet.total_additional_amount) +
                        parseFloat(costSheet.tax_amount_on_additional)
                    )}
                  </p>
                </li>
              </ul>
            </section>
          </div>
        </div>
        {/* <div ref={seeMoreOverlayBottomElm} className="scroll-more-overlay f-12">
          See more
        </div> */}
      </div>
    </>
  );
};

export default CostSheetPlank;
