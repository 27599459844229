import { useHistory, useLocation } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import TextInput from '../../../../../components/common/inputs/TextInput';
import Button from '../../../../../components/common/buttons/Button';
import useToast from '../../../../../hooks/useToast';
import { ICpUserReq } from '../../../interfaces';
import { useAddCpUserMutation } from '../../../services/cpUserAPISlice';
import { randomId } from '../../../../../utils/utils';
import { UserRole } from '../../../../../constants/userRoles';
import { useSelector } from 'react-redux';
import { getCpUser } from '../../../slices/cpUserSlice';

import styles from './styles.module.css';

// Validation
const schema = yup.object({
  rera: yup.object({
    number: yup.string().required(),
  }),

  user: yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
  }),
});

const CrKycPage = () => {
  const location = useLocation<{ mobile: string }>();
  const mobile = location?.state?.mobile;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const history = useHistory();
  const [addCpUserAPI] = useAddCpUserMutation();
  const [addToast] = useToast();

  const cpUser = useSelector(getCpUser);

  if (!mobile) {
    history.push('/cp/kyc/login');
  }
  if (cpUser) {
    history.push('/cp/kyc/welcome');
  }

  const handleRegisterUserDetails = async (data: ICpUserReq) => {
    const cpUser: ICpUserReq = {
      ...data,
      approved: true,
      rera: { ...data.rera, state: 'NA' },
      user: { ...data.user, mobile, role: UserRole.CP },
    };
    try {
      await addCpUserAPI(cpUser).unwrap();
      history.push('/cp/kyc/welcome');
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.title}>Register Now</div>
        <div className={styles.titleDesc}>
          Kindly fill in your details to register with us and to start accessing
          your leads & more
        </div>
      </header>
      <main>
        <TextInput
          label='Personal Mobile Number'
          placeHolder='Add Mobile Number'
          onBlur={() => {}}
          startIconText='+91'
          defaultValue={mobile}
          disabled={true}
          crApp
          propStyles={styles.input}
        />
        <TextInput
          label='Personal Name *'
          placeHolder='Add Personal Name'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='user.name'
          crApp
          errorMessage={errors?.user?.name && 'Personal Name is required'}
          propStyles={styles.input}
        />
        <TextInput
          label='Personal Email *'
          placeHolder='Add Email id'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='user.email'
          crApp
          errorMessage={
            errors?.user?.email && 'Enter valid email id & it is required'
          }
          propStyles={styles.input}
        />
        <TextInput
          label='RERA Number*'
          placeHolder='Add RERA Number'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='rera.number'
          crApp
          errorMessage={errors?.rera?.number && 'RERA Number is required'}
          propStyles={styles.input}
        />

        <Button
          type='button'
          crApp
          accent='primary'
          onClick={() => handleSubmit(handleRegisterUserDetails)()}
          propStyles={styles.registerBtn}>
          Register Now
        </Button>
      </main>
    </div>
  );
};

export default CrKycPage;
