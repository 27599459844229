// Components
import Icon from './../../../../../../../components/common/Icon/index';
import TextInput from './../../../../../../../components/common/inputs/TextInput/index';

// Styles
import styles from './styles.module.css';

const CpLeadRequestsHeader = () => {
  return (
    <>
      <header className={styles.cpLeadRequestsHeader}>
        <div className={styles.headerDisclaimer}>
          These are the leads that are actively engaging with your booking form.
          Leads are also marked as hot or cold as per the legend on the right
        </div>
        <div className={styles.verticalLineDivider}></div>
        <div className={styles.indicatorsContainer}>
          <div className={styles.indicatorTitle}>
            Hot <br /> Leads
          </div>
          <div className={styles.indicatorConnectingLine}></div>
          <div className={styles.hotIndicatorIcon}>
            <Icon name='whatshot' />
          </div>
          <div className={styles.indicatorConnectingLine}></div>
          <div className={styles.warmIndicatorIcon}>
            <Icon name='thermostat' />
          </div>
          <div className={styles.indicatorConnectingLine}></div>
          <div className={styles.roomTempIndicatorIcon}>
            <Icon name='ac_unit' />
          </div>
          <div className={styles.indicatorConnectingLine}></div>
          <div className={styles.coldIndicatorIcon}>
            <Icon name='ac_unit' />
          </div>

          <div className={styles.indicatorConnectingLine}></div>
          <div className={styles.indicatorTitle}>
            Cold <br /> Leads
          </div>
        </div>
        <div className={styles.verticalLineDivider}></div>
        <div className={styles.filterContainer}>
          <div className={styles.searchBox}>
            <TextInput
              startIconName='search'
              startIconVariant='SECONDARY'
              placeHolder='Search Name'
              onChange={() => {}}
            />
          </div>
          <span className={styles.filterTitle}>Filter</span>
          <Icon name='filter_alt' propStyles={styles.filterIcon} />
        </div>
      </header>
    </>
  );
};

export default CpLeadRequestsHeader;
