import { useState } from 'react';

import { downloadImage } from './../../utils/downloadImage';
import Image from '../common/Image';
import { floorPlanFallback } from '../../constants/urls';
import { floorPlanFileName } from './utils';

const UnitFloorPlanPhoto = (props) => {
  const { configUnitDetails } = props;
  const fileName = floorPlanFileName(configUnitDetails);

  const [show2DPlan, setShow2DPlan] = useState(true);

  const planPhotoUrls = {
    '2D': `https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/2d-new/${fileName}`,
    '3D': `https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/3d-new/${fileName}`,
  };
  return (
    <div className='unit-details-2d-3d-container'>
      <Image
        src={show2DPlan ? planPhotoUrls['2D'] : planPhotoUrls['3D']}
        alt='Unit 2D plan'
        fallbackSrc={floorPlanFallback}
      />
      <div className='unit-details-2d-3d-container__action-btns'>
        <span
          className='icon-container click-enabled'
          onClick={() =>
            downloadImage(
              show2DPlan ? planPhotoUrls['2D'] : planPhotoUrls['3D'],
              show2DPlan ? 'unit-2D-plan.jpg' : 'unit-3D-plan.jpg'
            )
          }>
          <span className='unit-details-2d-3d-container__action-btn icon material-icons'>
            
          </span>
        </span>
        <span
          className={`icon-container ${!show2DPlan && 'click-enabled'} ${
            show2DPlan && 'click-disabled'
          }`}
          onClick={() => setShow2DPlan(true)}>
          <span className='unit-details-2d-3d-container__action-btn'>2D</span>
        </span>
        <span
          className={`icon-container ${show2DPlan && 'click-enabled'} ${
            !show2DPlan && 'click-disabled'
          }`}
          onClick={() => setShow2DPlan(false)}>
          <span className='unit-details-2d-3d-container__action-btn'>3D</span>
        </span>
      </div>
    </div>
  );
};

export default UnitFloorPlanPhoto;
