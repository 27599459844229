import Button from '../../../../../../../../components/common/buttons/Button';
import Modal from '../../../../../../../../components/common/modal/Modal';
import Icon from '../../../../../../../../components/common/Icon/index';
import { ICompany } from '../../../../../../interfaces';

import styles from './styles.module.css';

interface IOrganizationDetailsModal {
  company: ICompany;
  onModalClose: Function;
}

const CompanyDetailsModal = (props: IOrganizationDetailsModal) => {
  const { company, onModalClose } = props;

  const organizationDetailsLeftColumn = [
    {
      label: 'Company Display Name',
      value: company.name,
    },
    {
      label: 'GST Number',
      value: company.gstNumber,
    },
    {
      label: 'Registered HQ Address',
      value: company.hqAddress,
    },
    {
      label: 'Point of Contact Mobile',
      value: company.pocMobile,
    },
  ];

  const organizationDetailsRightColumn = [
    {
      label: 'Company Name',
      value: company.displayName,
    },
    {
      label: 'Company Website URL',
      value: company.websiteUrl,
    },
    {
      label: 'Point of Contact Email',
      value: company.pocEmail,
    },
  ];

  return (
    <div>
      <Modal
        additionalClasses={styles.organizationDetailsModal}
        onOutsideClick={onModalClose}>
        <header>
          <div className={styles.headingAndCloseIconContainer}>
            <h2 className={styles.modalHeading}>Company Details</h2>
            <Icon
              name='close'
              propStyles={styles.closeIcon}
              onClick={() => onModalClose()}
            />
          </div>
          <p className={styles.modalDesc}>
            Here you will find the all information about company. You can edit{' '}
            <br /> and add company details at any time.
          </p>
        </header>

        <main className={styles.organizationDetailContainer}>
          <div className={styles.organizationDetailsIndividualColumns}>
            {organizationDetailsLeftColumn.map((individualDetail) => (
              <div className={styles.individualDetailContainer}>
                <h2 className={styles.individualDetailValue}>
                  {individualDetail.value}
                </h2>
                <p className={styles.individualDetailLabel}>
                  {individualDetail.label}
                </p>
              </div>
            ))}
          </div>
          <div className={styles.organizationDetailsIndividualColumns}>
            {organizationDetailsRightColumn.map((individualDetail) => (
              <div className={styles.individualDetailContainer}>
                <h2 className={styles.individualDetailValue}>
                  {individualDetail.value}
                </h2>
                <p className={styles.individualDetailLabel}>
                  {individualDetail.label}
                </p>
              </div>
            ))}
          </div>
        </main>

        <footer className={styles.footer}>
          <div className={styles.doneBtnContainer}>
            <Button accent='primary' onClick={() => onModalClose()}>
              Close
            </Button>
          </div>
        </footer>
      </Modal>
    </div>
  );
};

export default CompanyDetailsModal;
