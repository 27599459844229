import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Icon from '../../../../../../components/common/Icon';
import Image from '../../../../../../components/common/Image';
import { fallbackImageUrl } from '../../../../../../constants/urls';
import { IProject } from '../../../../../admin/components/pages/SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

interface IFeaturedPropertyCardProps {
  projects: IProject[];
}

const FeaturedPropertyCard = (props: IFeaturedPropertyCardProps) => {
  const { projects } = props;

  const [filledFavIcon, setFilledFavIcon] = useState(false);

  const history = useHistory();

  const toggleIcon = () => {
    setFilledFavIcon(!filledFavIcon);
  };

  const handleShowProjectDetails = (project: IProject) => {
    history.push('/cp/dashboard/project-details', { project });
  };

  return (
    <div className={styles.cardContainer}>
      {projects.map((project, index) => (
        <div
          key={index}
          className={styles.cardWrapper}
          onClick={() => handleShowProjectDetails(project)}>
          <div className={styles.imageWrapper}>
            <Image
              containerClass={styles.imageWraperInner}
              src={project.thumbnailUrl || fallbackImageUrl}
            />
            {/* TODO: Enable once it's functionality if integrated */}
            {/* <Icon
              onClick={toggleIcon}
              name={filledFavIcon ? 'favorite' : 'favorite_border'}
              propStyles={styles.favoriteIcon}
            /> */}
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.headWrapper}>
              <p className={styles.propertyName}>{project.name}</p>
              {/* TODO: disable price as of now 
              <p className={styles.propertyPrice}>{`${
                project.priceRange?.min || '10Lac'
              } - ${project.priceRange?.max || '5Cr'}`}</p>*/}
            </div>
            {/* TODO: Location component integration */}
            <p className={styles.propertyLocation}>{project.location?.name}</p>

            <ul className={styles.propertySpecs}>
              {project.configs.map((config, index) => (
                <li key={index}>{config}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedPropertyCard;
