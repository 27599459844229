import { useHistory } from 'react-router-dom';

import ImageContentFullPage from '../../common/ImageContentFullPage/index';

const WelcomePage = () => {
  const history = useHistory();

  const getStartedImage =
    'https://relata.s3.ap-south-1.amazonaws.com/common/getStarted.png';

  const handleGetStartedBtnClick = () => {
    history.push('/cp/dashboard');
  };

  return (
    <>
      <ImageContentFullPage
        title='Welcome To Passcode C45'
        subTitle='Your request to register has been approved, you can now start taking
          demos and contacting leads.'
        buttonText='GET STARTED'
        picture={getStartedImage}
        onClick={handleGetStartedBtnClick}
      />
    </>
  );
};

export default WelcomePage;
