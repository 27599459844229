// TODO: convert to typescript
import S3 from 'react-aws-s3';

window.Buffer = window.Buffer || require('buffer').Buffer;

class S3ClientNew {
  static fileName(url) {
    return url?.substring(url?.lastIndexOf('/') + 1);
  }

  static uploadFile(file, fileName, s3DirName, bucketName = 'relata') {
    const config = {
      bucketName,
      dirName: s3DirName,
      // TODO: Fetch secrete key from env
      region: 'ap-south-1',
      accessKeyId: 'AKIARTCQAJHCLIWTRR2M',
      secretAccessKey: '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',
    };
    const ReactS3Client = new S3(config);
    return ReactS3Client.uploadFile(file, fileName);
  }
}

export default S3ClientNew;
