import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LandscapeFlashScreen from '../../../../components/LandscapeFlashScreen';
import AdminHeader from '../containers/AdminHeader';

import ToastContainer from '../../../../components/common/toast/ToastContainer';

import { getAppSliceIsFetching } from '../../../../slices/appSlice';

import AdminSidebar from '../containers/AdminSidebar/AdminSidebar';
import AdminContent from '../containers/AdminContent';
import { getAdminUser, setAdminCompany } from '../../slices/adminSlice';
import { useGetCompanyByUserQuery } from '../../services/companyAPISlice';

const AdminLayer = () => {
  const dispatch = useDispatch();
  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const adminUser = useSelector(getAdminUser);

  const { data: company } = useGetCompanyByUserQuery(adminUser?.id);

  useEffect(() => {
    dispatch(setAdminCompany(company));
  }, [company, dispatch]);

  return (
    <div className='admin'>
      <div
        className={`loading-overlay ${
          appSliceIsFetching && 'loading-overlay--enabled'
        }`}>
        <span>loading...</span>
      </div>
      <LandscapeFlashScreen />
      <div className='app-main'>
        {/* Header */}
        <AdminHeader />

        <div className='app-body'>
          {/* Sidebars */}
          <AdminSidebar />

          {/* Content */}
          <AdminContent />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminLayer;
