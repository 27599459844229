import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import LandscapeFlashScreen from '../../../components/LandscapeFlashScreen';
import Header from '../containers/header/Header';
import BookingSidebar from '../containers/BookingSidebar';
import DashbaordSidebar from '../containers/dashboard/DashboardSidebar';
import BookingRoutes from '../routers/BookingRoutes';
import DashboardRoutes from '../routers/DashboardRoutes';

import {
  getAppSliceIsFetching,
  setAppSliceIsFetching,
} from '../../../slices/appSlice';

import {
  getUnitListById,
  setFilterPreferences,
  setHierarchyUnitList,
  setUnitListById,
} from '../slices/contentHeader/contentHeaderNav';

import { useLazyGetUnitListQuery } from '../../../api/units';
import { isEmpty } from '../../../utils/utils';
import { constructFilterPreferences } from '../../../components/unitSelection/utils';

import { URLS } from '../../../constants/urls';

const SECTION_1_12 = 'section-1-12';
const SECTION_13_14 = 'section-13-14';

const ContentLayer = () => {
  const dispatch = useDispatch();

  const appSliceIsFetching = useSelector(getAppSliceIsFetching);
  const unitListById = useSelector(getUnitListById);

  const [getUnitListAPI, { data: unitList = [] }] = useLazyGetUnitListQuery();

  useEffect(() => {
    if (isEmpty(unitListById)) {
      // Start loading
      dispatch(setAppSliceIsFetching(true));

      getUnitListAPI();
    }
  }, [dispatch, getUnitListAPI, unitListById]);

  useEffect(() => {
    if (unitList.length > 0 && isEmpty(unitListById)) {
      const hierarchyUnitList = {};
      const _unitListById = {};
      for (let i = 0; i < unitList.length; i++) {
        const unit = unitList[i];
        const tower = unit.unit_group.toLowerCase();
        const section = unit.floor_number <= 12 ? SECTION_1_12 : SECTION_13_14;
        const unitNumber = unit.number.slice(-1);
        const config =
          section === SECTION_1_12 ? `10${unitNumber}` : `130${unitNumber}`;

        if (!hierarchyUnitList[tower]) {
          hierarchyUnitList[tower] = {};
        }
        if (!hierarchyUnitList[tower][section]) {
          hierarchyUnitList[tower][section] = {};
        }
        if (!hierarchyUnitList[tower][section][config]) {
          hierarchyUnitList[tower][section][config] = [];
        }
        hierarchyUnitList[tower][section][config].push(unit.id);
        _unitListById[unit.id] = unit;
      }
      const filterPreferences = constructFilterPreferences(unitList);

      dispatch(setFilterPreferences(filterPreferences));
      dispatch(setHierarchyUnitList(hierarchyUnitList));
      dispatch(setUnitListById(_unitListById));
      // Stop loading
      dispatch(setAppSliceIsFetching(false));
    }
  }, [dispatch, unitList, unitListById]);

  return (
    <>
      <div
        className={`loading-overlay ${
          appSliceIsFetching && 'loading-overlay--enabled'
        }`}>
        <span>loading...</span>
      </div>
      <LandscapeFlashScreen />
      <div className={`app-main ${appSliceIsFetching && 'app-main--loading'}`}>
        {/* Header */}
        <Header />

        <div className='app-body'>
          {/* Sidebars */}
          <Switch>
            <Route
              path={URLS.DASHBOARD_BASE}
              component={DashbaordSidebar}></Route>
            <Route path={URLS.BOOKING_BASE} component={BookingSidebar}></Route>
          </Switch>

          {/* Content */}
          <Switch>
            <Route
              path={URLS.DASHBOARD_BASE}
              component={DashboardRoutes}></Route>
            <Route path={URLS.BOOKING_BASE} component={BookingRoutes}></Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default ContentLayer;
