import { floorPlanFallback } from '../../../constants/urls';
import Image from '../Image';
import { ExecutivePerformance } from './Interface';
import Icon from './../Icon/index';

const ExecutivePerformanceCard = (props: ExecutivePerformance) => {
  const { card, onCardClick } = props;
  return (
    <div
      className={`${
        card.selected ? 'selected' : ''
      } performance-executive-card`}
      onClick={() => onCardClick(props)}>
      <div className='performance-executive-card__image-container'>
        <Image
          imgClass='performance-executive-card__image'
          src={card.imgUrl}
          alt='Executive Performance Details'
          fallbackSrc={floorPlanFallback}
        />
        {card.selected && <Icon name='check' propStyles='selected-tick' />}
      </div>
      <div className='performance-executive-card__content'>
        <div className='performance-executive-card__role'>{card.role}</div>
        <div className='performance-executive-card__name'>{card.name}</div>
        <div className='performance-executive-card__performance'>
          <span className='performance-executive-card__value'>
            {card.value}
          </span>
          <Icon
            name={
              card.isPerformancePositive ? 'arrow_upward' : 'arrow_downward'
            }
            propStyles={`${
              card.isPerformancePositive ? 'positive-growth' : 'negative-growth'
            } performance-executive-card__arrow`}
          />
        </div>
      </div>
    </div>
  );
};

export default ExecutivePerformanceCard;
