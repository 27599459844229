import { CellClassParams, ICellRendererParams } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useState } from 'react';
import { ActiveLeadsGrid, Enquiry, EnquiryStatus, IStatus } from '..';
import Icon from '../../../../../../components/common/Icon';

const LeadsEnquiryDetailTable = (props: ICellRendererParams) => {
  const data: ActiveLeadsGrid = props.data;
  const [rowData, setRowData] = useState<Enquiry[] | null>(null);
  const onGridReady = () => {
    setRowData(data.enquiries);
  };
  return (
    <div
      className='ag-theme-alpine leads-details-enquiries'
      style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={rowData}
        defaultColDef={{
          sortable: false,
          filter: false,
          resizable: false,
          suppressMenu: true,
        }}
        domLayout={'autoHeight'}
        onGridReady={() => onGridReady()}>
        <AgGridColumn
          flex={1}
          field='status'
          headerName=''
          cellRendererFramework={StatusColumnRenderer}
          cellRendererParams={(params: ICellRendererParams) => {
            return {
              status: params.value,
            };
          }}
          cellClass='lead-enquiry-status'
          cellClassRules={cellClassRules}></AgGridColumn>
        <AgGridColumn flex={2} field='unitDetails'></AgGridColumn>
        <AgGridColumn flex={2} field='unitConfig'></AgGridColumn>
        <AgGridColumn
          flex={2}
          field='status'
          valueFormatter={(prop) => {
            const value: keyof IStatus = prop.value;
            return EnquiryStatus[value].displayText;
          }}></AgGridColumn>
        <AgGridColumn flex={2} field='lastUpdate'></AgGridColumn>
        <AgGridColumn
          flex={4}
          headerName='Actions'
          cellRendererFramework={ActionsColumnRenderer}></AgGridColumn>
        <AgGridColumn flex={3} headerName='Buttons'></AgGridColumn>
      </AgGridReact>
    </div>
  );
};

const cellClassRules = {
  explored: (params: CellClassParams) =>
    params.value === EnquiryStatus.EXPLORED.value,
  blocked: (params: CellClassParams) =>
    params.value === EnquiryStatus.BLOCKED.value,
  paymentdone: (params: CellClassParams) =>
    params.value === EnquiryStatus.PAYMENT_DONE.value,
  kyccompleted: (params: CellClassParams) =>
    params.value === EnquiryStatus.KYC_COMPLETED.value,
};

const StatusColumnRenderer = () => {
  return <Icon name='gite' />;
};

const ActionsColumnRenderer = (params: ICellRendererParams) => {
  // placeholder buttons for Sachin, please console params to see object
  return (
    <div className='leads-enquiries-actions-column'>
      <div className='action-button'>
        <Icon name='task_alt' />
        <span className='button-title'>Discount</span>
      </div>
      <div className='action-button'>
        <Icon name='task_alt' />
        <span className='button-title'>Payments</span>
      </div>
    </div>
  );
};

export default LeadsEnquiryDetailTable;
