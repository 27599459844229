import { useState } from 'react';

import MoreMenu, { MenuItem } from '../../../../common/MoreMenu';
import ProjectExecutiveListModal from '../ProjectExecutiveListModal';
import { IProject } from '../../../SuperAdminDashboard/SuperAdminProjectList';

export interface IMoreMenuCell {
  project: IProject;
  onProjectUpdate: (updatedProject: IProject) => void;
}

const MoreMenuCell = (props: IMoreMenuCell) => {
  const { project, onProjectUpdate } = props;

  const [executiveListModalShown, setExecutiveListModalShown] = useState(false);

  const moreMenuOptions: MenuItem[] = [
    {
      displayText: 'View Executive',
      icon: 'person',
      onClick: () => {
        setExecutiveListModalShown(true);
      },
    },
  ];

  return (
    <>
      {executiveListModalShown && (
        <ProjectExecutiveListModal
          onModalClose={() => setExecutiveListModalShown(false)}
          project={project}
          onProjectUpdate={onProjectUpdate}
        />
      )}

      <MoreMenu menuItems={moreMenuOptions} disabled={project.disabled} />
    </>
  );
};

export default MoreMenuCell;
