// import { useHistory } from 'react-router';
// import { ERROR } from '../../../constants/status';
// import { URLS } from '../../../constants/urls';
import useBlockedUnitExpireCountdown from '../../../apps/booking/hooks/useBlockedUnitExpireCountdown';
// import { addToast } from '../../../slices/toasts';
import { isEmpty } from '../../../utils/utils';

const BlockedUnitCountDownTimer = (props) => {
  // const history = useHistory();
  const hourMinSecCountDown = useBlockedUnitExpireCountdown();

  const isUnitExpired =
    !isEmpty(hourMinSecCountDown) && hourMinSecCountDown.hours.length > 2;
  // const isBlockedUnitExpired = !isEmpty(hourMinSecCountDown) && hourMinSecCountDown.seconds.length > 2;
  // if(isBlockedUnitExpired){
  //   // addToast({
  //   //   type: ERROR,
  //   //   primaryMessage: 'Blocked Unit Expired!',
  //   //   secondaryMessage: 'Unit will be available soon'
  //   // })
  //   alert("Blocked Unit Expired. Unit will be available soon.");

  //   history.push(URLS.UNIT_SELECTION);
  // }
  return (
    <>
      {!isEmpty(hourMinSecCountDown) && (
        <div>
          <div className='info-card'>
            {isUnitExpired ? (
              <>
                <header className='info-card__header'>
                  Your blocked unit has expired
                </header>
                <div className='info-card__body'>Expired</div>
              </>
            ) : (
              <>
                <header className='info-card__header'>
                  Your blocked unit expires in
                </header>
                <div className='info-card__body'>{`${hourMinSecCountDown.hours}:${hourMinSecCountDown.minutes}:${hourMinSecCountDown.seconds}`}</div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BlockedUnitCountDownTimer;
