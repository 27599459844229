import { useState } from 'react';
import { useSelector } from 'react-redux';

import useOnClickOutside from '../../../utils/useOnClickOutside';

import { getFilterPreferences } from '../../../apps/booking/slices/contentHeader/contentHeaderNav';

const FilterBtn = (props) => {
  const {
    toggleFilterPreferenceContainer,
    showFilterPreference,
    handleClearFilter,
    unitSelectionContentHeaderRef,
  } = props;
  const [showFilterLabelText, setFilterLabelText] = useState(true);

  const preferences = useSelector(getFilterPreferences);
  const isPreferencesApplicable = Object.values(preferences).some(
    (preference) => preference.items.some((item) => item.isSelected)
  );

  useOnClickOutside(unitSelectionContentHeaderRef, () =>
    toggleFilterPreferenceContainer(false)
  );

  return (
    <>
      <div className='content-header-nav-filter'>
        {isPreferencesApplicable && !showFilterPreference && (
          <button
            className='content-header-nav-filter__clear'
            onClick={() => handleClearFilter()}>
            Clear Filters
          </button>
        )}
        <button
          className='btn btn--icon-label-container'
          onClick={() => {
            setFilterLabelText(false);
            toggleFilterPreferenceContainer(!showFilterPreference);
          }}>
          <span className='icon material-icons'>
            
            {isPreferencesApplicable && (
              <div className='content-header__nav-filters-preference-applied'></div>
            )}
          </span>
          {showFilterLabelText && <span>Filter</span>}
        </button>
      </div>
    </>
  );
};

export default FilterBtn;
