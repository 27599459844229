import { ordinalSuffixOf } from '../../utils/utils';

const SiteVisitItem = (props) => {
  const { siteVisit } = props;
  const datetime = new Date(siteVisit.datetime);
  const [weekday, day, month, year] = [
    datetime.toLocaleString('en', { weekday: 'short' }),
    datetime.toLocaleString('en', { day: 'numeric' }),
    datetime.toLocaleString('en', { month: 'short' }),
    datetime.toLocaleString('en', { year: '2-digit' }),
  ];
  const datetime_str = `${weekday}, ${ordinalSuffixOf(day)} ${month} ${year}`;
  return (
    <div className='site-visit-item'>
      <div className='site-visite-item__details-container'>
        <span className='icon material-icons site-visit-type-icon'></span>
        <div className='site-visit-item__label-time-container'>
          <span className='site-visit-item__label'>Scheduled Site Visit</span>
          <span className='site-visit-item__time'>{datetime_str}</span>
        </div>
        <div className='site-visit-item__address'>
          507, 5th Floor, Trade Centre F.P. No.323, T.P.S, Sangamwadi Rd,
          Koregaon Park, Pune, Maharashtra 411001
        </div>
      </div>

      {/* <div className="site-visit-item__action-container">
        <span className="icon material-icons click-enabled"></span>
        <span className="icon material-icons click-enabled"></span>
        <span className="icon material-icons click-enabled"></span>
      </div> */}
    </div>
  );
};

export default SiteVisitItem;
