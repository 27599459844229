import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAdminCompany } from '../../../../slices/adminSlice';
import Body from './Body';
import Header from './Header';

const AdminDashboard = () => {
  const [gridSearchTerm, setGridSearchTerm] = useState('');

  const company = useSelector(getAdminCompany);

  const handleGridSearch = (searchTerm: string) => {
    setGridSearchTerm(searchTerm);
  };

  // TODO: Make Loading generic
  if (!company) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header
        onGridSearch={handleGridSearch}
        companyId={company.id as string}
      />
      <Body gridSearchTerm={gridSearchTerm} company={company} />
    </>
  );
};

export default AdminDashboard;
