export const NOT_FOUND = 'NOT_FOUND';
export const FORBIDDEN = 'FORBIDDEN';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';

export const BLOCK_UNIT_SUCCESS = 'BLOCK_UNIT_SUCCESS';
export const BLOCK_UNIT_FAIL = 'BLOCK_UNIT_FAIL';

export const BOOK_UNIT_SUCCESS = 'BOOK_UNIT_SUCCESS';
export const BOOK_UNIT_FAIL = 'BOOK_UNIT_FAIL';

export const BOOKED = 'BOOKED';
export const BLOCKED = 'BLOCKED';

export enum ProjectStatus {
  ONGOING = 'ONGOING',
  HOLD = 'HOLD',
  ONBOARDING = 'ONBOARDING',
  RENEWAL = 'RENEWAL',
  EXPIRED = 'EXPIRED',
}

export enum ACCESS {
  READ = 'READ',
  WRITE = 'WRITE',
}
