import { useState } from 'react';

import styles from './styles.module.css';

interface IReadMoreLessProps {
  children: string;
  limitCharacter: number;
}

const ReadMoreLess = (props: IReadMoreLessProps) => {
  const { children, limitCharacter } = props;

  const [isReadMoreShown, setReadMoreShown] = useState(false);

  const text = children;

  const readMoreToggle = () => {
    setReadMoreShown((prevState) => !prevState);
  };

  return (
    <div className={styles.container}>
      {isReadMoreShown ? text : text?.substr(0, limitCharacter)}
      {limitCharacter < text?.length && (
        <span className={styles.readMoreLessBtn} onClick={readMoreToggle}>
          {isReadMoreShown ? ' ' : '... Read More'}
        </span>
      )}
    </div>
  );
};

export default ReadMoreLess;
