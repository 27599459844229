import BlockedUnitCountDownTimer from '../common/cards/BlockedUnitCountDownTimer';
import BookingAmountCard from '../common/cards/BookingAmountCard';

const AgreementContentHeader = () => {
  return (
    <header className='content-header'>
      <div className='content-header__title-plate'>
        <h1 className='content-header__title'>
          Confirm & Sign the <span className='font-accent-bold'>Agreement</span>{' '}
          Document
        </h1>
        <p className='content-header__description'>
          Select amongst the payment schemes available to take complete benefit
          of your Embassy Edge dream home.
        </p>
      </div>

      <div className='content-header__card-container'>
        <BlockedUnitCountDownTimer />
        <BookingAmountCard />
      </div>
    </header>
  );
};

export default AgreementContentHeader;
