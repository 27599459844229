import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import Icon from '../../../../../../components/common/Icon';
import TextInput from '../../../../../../components/common/inputs/TextInput';
import HeadTitle from '../../../../../../components/common/TitleHeading';
import ProfileHeader from '../../../common/ProfileHeader';
import { getCpUser, setCpUserProfile } from '../../../../slices/cpUserSlice';
import { ICpUser } from '../../../../interfaces';
import { useUpdateAdminUserMutation } from '../../../../../admin/services/adminUserAPISlice';
import useToast from '../../../../../../hooks/useToast';
import { IUser } from '../../../../../admin/interfaces';
import { userProfileDefaultImage } from '../../../../../../constants/urls';
import Image from '../../../../../../components/common/Image';

import styles from './styles.module.css';

const schema = yup.object({
  name: yup.string().required(),
  email: yup.string().email(),
  mobile: yup
    .string()
    .matches(/^[0-9]*$/)
    .length(10)
    .required(),
});

const UpdateCpUserProfile = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const dispatch = useDispatch();
  const cpUser = useSelector(getCpUser) as ICpUser;
  const [updateUserAPI] = useUpdateAdminUserMutation();
  const [addToast] = useToast();

  const handleSave = async (userData: IUser) => {
    try {
      const response = await updateUserAPI({
        userId: cpUser.user.id as string,
        user: userData,
      }).unwrap();

      const updateUser: IUser = response.data;
      dispatch(setCpUserProfile(updateUser));

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Successfully Updated User',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  return (
    <div className={styles.profileWrapper}>
      <ProfileHeader
        iconName={'arrow_back'}
        pageName={'My Profile'}
        buttonName={'Save'}
        onSave={() => handleSubmit(handleSave)()}
      />
      <div className={styles.mainWrap}>
        <div className={styles.profileWrap}>
          <Image src={userProfileDefaultImage} />
          <div className={styles.cameraIcon}>
            <Icon name='add_a_photo' propStyles={styles.photoIcon} />
          </div>
        </div>
        <div className={styles.nameWrap}>
          <HeadTitle>{cpUser.user.name}</HeadTitle>
          <p className={styles.userType}>Channel partner</p>
        </div>
      </div>
      <div className={styles.formWrapper}>
        <TextInput
          label='Name '
          placeHolder='Add Name'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='name'
          defaultValue={cpUser.user.name}
          errorMessage={errors?.name && 'Name is required'}
          propStyles={styles.input}
          crApp
        />
        <TextInput
          label='Email'
          placeHolder='Add Email id'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='email'
          defaultValue={cpUser.user.email}
          errorMessage={errors?.personal?.email && 'Enter valid email id'}
          propStyles={styles.input}
          crApp
        />
        <TextInput
          label='Mobile Number'
          placeHolder='Add Mobile Number'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='mobile'
          defaultValue={cpUser.user.mobile}
          type='number'
          startIconText='+91'
          errorMessage={errors?.mobile && 'Enter valid 10 digit mobile number'}
          propStyles={styles.input}
          maxLength={10}
          crApp
        />
        {/* <TextInput
          label='RERA Number *'
          placeHolder='Add RERA Number'
          onBlur={() => {}}
          crApp
          register={register}
          setValue={setValue}
          name='company.rera'
          errorMessage={errors?.rera && 'RERA Number is required'}
          propStyles={styles.input}
          disabled={true}
        /> */}
      </div>
    </div>
  );
};

export default UpdateCpUserProfile;
