import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const sliceName = 'leadsSearchSlice';

export interface CounterState {
  [sliceName]: {
    value: string;
  };
}

export const leadsSearchSlice = createSlice({
  name: sliceName,
  initialState: {
    value: '',
  },
  reducers: {
    updateSearchString: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const getSearchString = (state: CounterState) => state[sliceName].value;
// Action creators are generated for each case reducer function
export const { updateSearchString } = leadsSearchSlice.actions;
