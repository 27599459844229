import { useSelector } from 'react-redux';
import { getCostSheet, getUnitCart } from '../apps/booking/slices/selectedUnit';
import { ordinalSuffixOf, toIndianRupee } from '../utils/utils';

const UnitSummaryCard = () => {
  const unitCart = useSelector(getUnitCart);
  const costSheet = useSelector(getCostSheet);
  const selectedUnit = unitCart.unit;
  return (
    <div className='unit-summery-card'>
      <div className='unit-summery-card__header'>
        <h3 className='font-accent-bold unit-summery-card__total-cost'>
          {toIndianRupee(costSheet.total_inclusive_amount)}
        </h3>
        <span className='f-bold f-18 c-56'>All Inclusive Total</span>
        <span className='f-regular f-15'>(excluding registration charges)</span>
      </div>
      <div className='unit-summery-card__summary'>
        <h1 className='unit-summery-card__summary-heading f-18 f-bold c-56'>
          Summary
        </h1>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Unit Number</p>
          <p className='c-56 f-15 f-bold'>
            {selectedUnit.unit_group},{' '}
            {`${selectedUnit.block}${selectedUnit.number}`}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Super Built up Area</p>
          <p className='c-56 f-15 f-bold'>
            {selectedUnit.area.super_build_up} Sq.Ft
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Tower and Block</p>
          <p className='c-56 f-15 f-bold'>{`${selectedUnit.unit_group} ${selectedUnit.block}`}</p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Floor</p>
          <p className='c-56 f-15 f-bold'>
            {ordinalSuffixOf(selectedUnit.floor_number)} Floor
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Configuration</p>
          <p className='c-56 f-15 f-bold'>{selectedUnit.type}</p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Carpet Area</p>
          <p className='c-56 f-15 f-bold'>{selectedUnit.area.carpet} Sq.Ft</p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Agreement Cost</p>
          <p className='c-56 f-15 f-bold'>
            {toIndianRupee(costSheet.agreement_value)}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Base Price Per Sq.ft</p>
          <p className='c-56 f-15 f-bold'>
            {toIndianRupee(selectedUnit.area.super_build_up)}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>PLC</p>
          <p className='c-56 f-15 f-bold'>{toIndianRupee(costSheet.plc)}</p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>Floor Rise</p>
          <p className='c-56 f-15 f-bold'>
            {toIndianRupee(costSheet.floor_rise)}
          </p>
        </div>
        <div className='unit-summery-card__summary-item'>
          <p className='c-56 f-15'>
            All Inclusive total (excluding registration charges)
          </p>
          <p className='c-56 f-15 f-bold'>
            {toIndianRupee(costSheet.total_inclusive_amount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnitSummaryCard;
