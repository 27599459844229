interface IClubRhodiumCompanyIds {
  [key: string]: string;
}
const clubRhodiumCompanyIds: IClubRhodiumCompanyIds = {
  local: '6345446e107689242fcb2462',
  development: '634662369095431000046e5a',
  staging: 'https://api.staging.app.relata.io',
  production: '6367e07a24a66b547861db6d',
};

if (!process.env.REACT_APP_ENV) {
  console.error('REACT_APP_ENV not declared');
}

const appEnv = process.env.REACT_APP_ENV || 'local';

export const CLUB_RHODIUM_COMPANY_ID = clubRhodiumCompanyIds[appEnv];
