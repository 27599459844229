import { useState } from 'react';
import { useSelector } from 'react-redux';

import 'ag-grid-enterprise';
import {
  CellClassParams,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import { getSearchString } from '../../../slices/leadsSearchSlice';
import NameCard, {
  INameCardProps,
} from '../../../../../components/common/admin/NameCard';
import HotnessIndicatorCell from './cell-renderers/HotnessIndicatorCell';
import ActiveLeadsHeader from './ActiveLeadsHeader';
import LeadsEnquiryDetailTable from './LeadsEnquiryDetailsTable';
import ExpandRowCell from './cell-renderers/ExpandRowCell';
import { Card } from '../../../../../components/common/admin/Interface';
import { IConstant } from '../../../../../interfaces';
import { getAdminUser } from '../../../slices/adminSlice';
import { useGetProjectLeadsByAdminUserIdQuery } from '../../../services/projectLeadsAPI';
import DateCell from '../../common/dates/DateCell';

export enum HotnessIndicator {
  HOT = 0,
  WARM = 1,
  ROOM = 2,
  COLD = 3,
}

export interface IStatus {
  BLOCKED: IConstant;
  PAYMENT_DONE: IConstant;
  EXPLORED: IConstant;
  KYC_COMPLETED: IConstant;
}

export const EnquiryStatus: IStatus = {
  BLOCKED: {
    value: 'BLOCKED',
    displayText: 'Blocked',
  },
  PAYMENT_DONE: {
    value: 'PAYMENT_DONE',
    displayText: 'Payment Done',
  },
  EXPLORED: {
    value: 'EXPLORED',
    displayText: 'Explored',
  },
  KYC_COMPLETED: {
    value: 'KYC_COMPLETED',
    displayText: 'KYC Completed',
  },
};
export interface IUserAction {
  EXPLORED_NEW_UNIT: IConstant;
  KYC_DETAILS_UPDATED: IConstant;
  EXPLORED_OLD_UNIT: IConstant;
  EXPLORED_NEW_BOOKED_UNIT: IConstant;
  BLOCKED_EXPLORED_UNIT: IConstant;
  DRAFT_AGREEMENT_PENDING: IConstant;
  PAYMENT_DONE: IConstant;
}
export const UserAction: IUserAction = {
  EXPLORED_NEW_UNIT: {
    value: 'EXPLORED_NEW_UNIT',
    displayText: 'Explored New Unit',
  },
  KYC_DETAILS_UPDATED: {
    value: 'KYC_DETAILS_UPDATED',
    displayText: 'KYC Details Updated',
  },
  EXPLORED_OLD_UNIT: {
    value: 'EXPLORED_OLD_UNIT',
    displayText: 'Explored Old Unit',
  },
  EXPLORED_NEW_BOOKED_UNIT: {
    value: 'EXPLORED_NEW_BOOKED_UNIT',
    displayText: 'Explored Now Booked Unit',
  },
  BLOCKED_EXPLORED_UNIT: {
    value: 'BLOCKED_EXPLORED_UNIT',
    displayText: 'Blocked Explored Unit',
  },
  DRAFT_AGREEMENT_PENDING: {
    value: 'DRAFT_AGREEMENT_PENDING',
    displayText: 'Draft Agreement Pending',
  },
  PAYMENT_DONE: { value: 'PAYMENT_DONE', displayText: 'Payment Done' },
};

// Interfaces
export interface Enquiry {
  id: number;
  status: string;
  unitDetails: string;
  unitConfig: string;
  lastUpdate: Date | string;
  cpName?: string;
}

export interface ActiveLeadsGrid {
  hotness: HotnessIndicator;
  cpName: string;
  user: Card;
  enquiries: Enquiry[];
  lastActivityOn: Date | string;
  lastAction: string;
  registeredOn: Date | string;
  leadStatus: string;
}

const ActiveLeads = (props: { rowData: ActiveLeadsGrid[] }) => {
  const searchString = useSelector(getSearchString);

  const adminUser = useSelector(getAdminUser);

  const { data: projectLeads = [] } = useGetProjectLeadsByAdminUserIdQuery(
    adminUser?.id
  );

  const getIconName = (val: HotnessIndicator): string => {
    let icon: string;
    switch (val) {
      case HotnessIndicator.HOT:
        icon = 'whatshot';
        break;
      case HotnessIndicator.WARM:
        icon = 'thermostat';
        break;
      default:
        icon = 'ac_unit';
    }
    return icon;
  };
  const getContainerClassName = (val: HotnessIndicator): string => {
    let container: string;
    switch (val) {
      case HotnessIndicator.HOT:
        container = 'hot-indicator';
        break;
      case HotnessIndicator.WARM:
        container = 'warm-indicator';
        break;
      case HotnessIndicator.ROOM:
        container = 'room-indicator';
        break;
      case HotnessIndicator.COLD:
        container = 'cold-indicator';
        break;
      default:
        container = '';
    }
    return container;
  };
  const cellClassRules = {
    explored: (params: CellClassParams) =>
      params.value === EnquiryStatus.EXPLORED.value,
    blocked: (params: CellClassParams) =>
      params.value === EnquiryStatus.BLOCKED.value,
    paymentdone: (params: CellClassParams) =>
      params.value === EnquiryStatus.PAYMENT_DONE.value,
    kyccompleted: (params: CellClassParams) =>
      params.value === EnquiryStatus.KYC_COMPLETED.value,
  };

  return (
    <>
      <div className='active-leads'>
        <ActiveLeadsHeader />
        <div className='active-leads-grid'>
          <div
            className='ag-theme-alpine'
            style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              rowData={projectLeads}
              defaultColDef={{
                sortable: false,
                filter: false,
                resizable: false,
                suppressMenu: true,
              }}
              domLayout={'autoHeight'}
              pagination={true}
              paginationPageSize={10}
              isExternalFilterPresent={() => searchString !== ''}
              doesExternalFilterPass={(node: RowNode) => {
                return (
                  node.data.user &&
                  node.data.user.title
                    .toLowerCase()
                    .includes(searchString.toLowerCase())
                );
              }}
              masterDetail={true}
              detailCellRenderer={'detailCellRenderer'}
              detailRowAutoHeight={true}
              frameworkComponents={{
                detailCellRenderer: LeadsEnquiryDetailTable,
              }}
              animateRows={true}>
              <AgGridColumn
                flex={1}
                cellClass='ag-grid-column-custom-cell'
                cellRendererFramework={ExpandRowCell}
                cellRendererParams={(params: ICellRendererParams) =>
                  params
                }></AgGridColumn>
              <AgGridColumn
                flex={1}
                headerComponentFramework={HotnessIndicatorCell}
                headerComponentParams={{
                  containerClassName: 'ag-grid-header-indicator-icon',
                  iconName: 'thermostat',
                }}
                field='hotness'
                cellRendererFramework={HotnessIndicatorCell}
                cellRendererParams={(params: ICellRendererParams) => ({
                  iconName: getIconName(params.value),
                  containerClassName: getContainerClassName(params.value),
                })}></AgGridColumn>
              <AgGridColumn
                flex={3}
                headerName='Name'
                field='lead.name'
                getQuickFilterText={(params) => {
                  return params.value;
                }}
                cellRendererFramework={NameCard}
                cellRendererParams={(
                  params: ICellRendererParams
                ): INameCardProps => ({
                  title: params.data.lead.name,
                })}></AgGridColumn>
              <AgGridColumn
                flex={1.5}
                headerName='Enquiries'
                field='enquiryCount'
                cellRenderer={(params: ICellRendererParams) =>
                  params.value || 0
                }></AgGridColumn>
              <AgGridColumn
                flex={2}
                headerName='Last Action'
                field='lastAction'
                cellRendererFramework={(params: ICellRendererParams) => {
                  // TODO: Integrate Last Action functionality
                  const actionNameValue: keyof IUserAction =
                    params.data.lastAction;
                  return (
                    <div className='last-action-cell'>
                      <p className='last-action-name'>
                        {UserAction[actionNameValue]?.displayText}
                      </p>
                      <p className='last-action-date'>
                        {params.data?.lastActivityOn}
                      </p>
                    </div>
                  );
                }}></AgGridColumn>
              <AgGridColumn
                flex={2}
                headerName='Registered On'
                field='createdAt'
                cellRendererFramework={DateCell}
                cellRendererParams={(params: ICellRendererParams) => ({
                  date: params.data.createdAt,
                })}></AgGridColumn>
              <AgGridColumn
                flex={2}
                headerName='Tagged Name'
                field='taggedUser.name'></AgGridColumn>
              <AgGridColumn
                flex={2}
                headerName='Tagged User Type'
                field='taggedUser.role'></AgGridColumn>
              <AgGridColumn
                flex={2}
                headerName='Lead Status'
                field='status'
                cellClass='lead-status-column'
                cellClassRules={cellClassRules}></AgGridColumn>
            </AgGridReact>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveLeads;
