import { useHistory } from 'react-router-dom';

import Button from '../../../../../components/common/buttons/Button';

import styles from './styles.module.css';

const projectDetails = {
  projectName: 'Merusri',
  landingPage: {
    desc: 'A better way of helping your clients find their dream home',
    bgImgUrl: 'https://picsum.photos/500/280',
  },
};

const CpLandingPage = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <header>{projectDetails.projectName} relata.io</header>
      <main>
        <div className={styles.desc}>
          A better way of helping your clients find their dream home
        </div>
        <li>Add and Claim your leads</li>
        <li>Keep Track of their activity & progress</li>
        <li>Enroll in New Projects</li>
        <li>Always have Updated Collaterals</li>

        <Button
          accent='primary'
          type='submit'
          crApp
          onClick={() => history.push('/cp/kyc/login')}
          propStyles={`${styles.getStartedBtn}`}>
          Get Started
        </Button>
      </main>
    </div>
  );
};

export default CpLandingPage;
