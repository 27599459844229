import { useState } from 'react';

import Icon from '../../../../../../../components/common/Icon/index';
import MoreMenu from '../../../../common/MoreMenu';
import CustomerDetails from './CustomerDetails';
import PropertyDetails from './PropertyDetails';
import { MenuItem } from '../../../../common/MoreMenu';
import { ISiteVisitDetails } from '../index';
import { SiteVisitState } from '../index';
import SendOtp, {
  IAddressDetails,
} from '../../../../../../../components/common/SendOtp';
import useToast from '../../../../../../../hooks/useToast';
import { NOT_FOUND } from '../../../../../../../constants/status';
import { useGetUserByMobileMutation } from '../../../../../services/adminUserAPISlice';
import { UserRole } from '../../../../../../../constants/userRoles';
import { ICpLead } from '../../../../../../cp/interfaces';
import { IPriceRange } from '../../../SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';

export enum DetailsFormState {
  CUSTOMER,
  PROJECT,
}

interface IDetailsForm {
  projectPropertyConfigs: string[];
  projectPropertyPriceRanges: IPriceRange[];
  cpLead?: ICpLead;
  setSiteVisitState: (state: SiteVisitState) => void;
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitDetails: Function;
  headerMoreMenuOptions: MenuItem[];
}

const DetailsForm = (props: IDetailsForm) => {
  const {
    projectPropertyConfigs,
    projectPropertyPriceRanges,
    cpLead,
    setSiteVisitState,
    siteVisitDetails,
    setSiteVisitDetails,
    headerMoreMenuOptions,
  } = props;

  const [leadExists, setLeadExists] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [detailsFormState, setDetailsFormState] = useState(
    DetailsFormState.CUSTOMER
  );

  const [getUserByMobileAPI] = useGetUserByMobileMutation();
  const [addToast] = useToast();

  const handleGoToSelectProjectPage = () => {
    setSiteVisitState(SiteVisitState.PROJECT_SELECTION);
  };

  const handleSuccessfulOtpVerification = async (
    addressDetails: IAddressDetails
  ) => {
    try {
      const response = await getUserByMobileAPI({
        mobile: addressDetails.address,
      }).unwrap();
      const user = response.data;

      if (user.role !== UserRole.LEAD) {
        addToast({
          type: 'ERROR',
          primaryMessage: `User already exists with role ${user.role}.`,
          secondaryMessage: 'Only new users or with lead role can be added',
        });
        return;
      }

      setOtpVerified(true);
      setLeadExists(true);
      setSiteVisitDetails((prevState: ISiteVisitDetails) => {
        return {
          ...prevState,
          customerName: user.name,
          customerEmail: user.email,
          customerMobile: user.mobile,
        };
      });
    } catch (err) {
      const errCode = (err as any).data.code;
      if (errCode === NOT_FOUND) {
        // New user as it is not found in the system
        setOtpVerified(true);
        setSiteVisitDetails((prevState: ISiteVisitDetails) => {
          return {
            ...prevState,
            customerMobile: addressDetails.address,
          };
        });
        return;
      }
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  return (
    <div className={styles.formWrap}>
      <header>
        <div className={styles.iconsContainer}>
          <div className={styles.pinDropIconContainer}>
            <Icon name='pin_drop' />
          </div>
          <MoreMenu menuItems={headerMoreMenuOptions} />
        </div>

        <h2 className={styles.headerTitle}>Site Visit Form</h2>
        {!otpVerified ? (
          cpLead?.mobileLastFourDigits && (
            <p className={styles.headerDesc}>
              Enter Mobile Number Ending with &nbsp;
              <span className={styles.boldText}>
                {cpLead?.mobileLastFourDigits}
              </span>
            </p>
          )
        ) : (
          <p className={styles.headerDesc}>
            Enter customer name, mobile number and other siteVisitDetails to
            keep <br />
            track of customer visit.
          </p>
        )}
      </header>

      <main>
        {!otpVerified ? (
          <div className={styles.otpWrapper}>
            <SendOtp
              addressType={'MOBILE'}
              address={cpLead?.user?.mobile}
              mobileLastFourDigits={cpLead?.mobileLastFourDigits}
              onSuccessfulOtpVerification={handleSuccessfulOtpVerification}
              headerHidden
            />
          </div>
        ) : (
          detailsFormState === DetailsFormState.CUSTOMER && (
            <CustomerDetails
              leadExists={leadExists}
              setDetailsFormState={setDetailsFormState}
              onGoToSelectProjectPage={handleGoToSelectProjectPage}
              siteVisitDetails={siteVisitDetails}
              setSiteVisitDetails={setSiteVisitDetails}
            />
          )
        )}
        {detailsFormState === DetailsFormState.PROJECT && (
          <PropertyDetails
            projectPropertyConfigs={projectPropertyConfigs}
            projectPropertyPriceRanges={projectPropertyPriceRanges}
            setDetailsFormState={setDetailsFormState}
            setSiteVisitState={setSiteVisitState}
            siteVisitDetails={siteVisitDetails}
            setSiteVisitDetails={setSiteVisitDetails}
          />
        )}
      </main>
    </div>
  );
};

export default DetailsForm;
