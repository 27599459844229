import Button from '../../../../../../../components/common/buttons/Button';
import Icon from '../../../../../../../components/common/Icon/index';
import { SiteVisitState } from '..';
import { ISiteVisitDetails } from '..';

import styles from './styles.module.css';

interface ISiteVisitSummery {
  siteVisitDetails: ISiteVisitDetails;
  setSiteVisitState: Function;
}

const SiteVisitSummery = (props: ISiteVisitSummery) => {
  const { siteVisitDetails, setSiteVisitState } = props;

  return (
    <div>
      <header className={styles.header}>
        <Icon name='task_alt' propStyles={styles.checkIcon}></Icon>
        <h2 className={styles.headerTitle}>Data Added Successfully</h2>
        <p className={styles.headerDesc}>
          Customer personal siteVisitDetails added successfully. Now you <br />{' '}
          can keep track of customer site visits
        </p>
      </header>

      <main className={styles.main}>
        <p className={styles.mainHeading}>Personal Information</p>
        <div className={styles.personalInfoSection}>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Customer Name</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.customerName}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Email Id</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.customerEmail}
              </p>
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Phone Number</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.customerMobile}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Location</p>
              <p className={styles.detailsValue}>{siteVisitDetails.location}</p>
            </div>
          </div>
        </div>

        <p className={styles.mainHeading}>Requirement</p>
        <div>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Date Of Visit</p>
              <p className={styles.detailsValue}>
                {`${String(
                  new Date(siteVisitDetails.visitDate).getDate()
                ).padStart(2, '0')}/${String(
                  +new Date(siteVisitDetails.visitDate).getMonth() + 1
                ).padStart(2, '0')}/${new Date(
                  siteVisitDetails.visitDate
                ).getFullYear()}`}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Configuration</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.configs.join(' ')}
              </p>
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Area Preferences</p>
              <p className={styles.detailsValue}>
                {siteVisitDetails.preferenceAreasTemp}
              </p>
            </div>
            <div className={styles.individualDetailsSet}>
              <p className={styles.detailsHeading}>Area Preferences</p>
              <p className={styles.detailsValue}>
                {`${siteVisitDetails.priceRange?.min} - ${siteVisitDetails.priceRange?.max}`}
              </p>
            </div>
          </div>
        </div>
        <p className={styles.detailsHeading}>Customer Feedback</p>
        <p className={styles.detailsValue}>
          {siteVisitDetails.customerFeedback || 'NA'}
        </p>
      </main>

      <footer className={styles.footer}>
        <Button
          propStyles={styles.footerButton}
          onClick={() => setSiteVisitState(SiteVisitState.DETAILS_FORM)}>
          Edit
        </Button>
        <Button
          propStyles={styles.footerButton}
          onClick={() => setSiteVisitState(SiteVisitState.PROJECT_SELECTION)}
          accent='primary'>
          Done
        </Button>
      </footer>
    </div>
  );
};

export default SiteVisitSummery;
