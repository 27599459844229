import CpFooterItem, { ICpFooterItem } from './CpFooterItem';

import styles from './styles.module.css';

const CpFooter = () => {
  const footerItems: ICpFooterItem[] = [
    {
      redirectPath: '/cp/dashboard',
      labelText: 'Dashboard',
      iconName: 'dashboard',
    },
    {
      redirectPath: '/cp/dashboard/discovery',
      labelText: 'Discover',
      iconName: 'explore',
    },
    {
      redirectPath: '/cp/dashboard/addLead',
      labelText: 'Add Lead',
      iconName: 'add_box',
    },
    {
      redirectPath: '/cp/dashboard/leads',
      labelText: 'Leads',
      iconName: 'group',
    },
  ];

  return (
    <div className={styles.container}>
      {footerItems.map((item, index) => (
        <CpFooterItem
          redirectPath={item.redirectPath}
          labelText={item.labelText}
          iconName={item.iconName}
          key={index}
        />
      ))}
    </div>
  );
};

export default CpFooter;
