import { adminAPISlice } from './index';

export const adminUserAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    adminOtpSend: build.mutation({
      query: ({ email }: { email: String }) => ({
        url: 'api/otp/send',
        method: 'POST',
        body: { email },
      }),
    }),
    adminOtpVerify: build.mutation({
      query: ({ email, otp }: { email: String; otp: String }) => ({
        url: 'api/otp/verify',
        method: 'POST',
        body: { email, otp },
      }),
    }),
    otpv2Send: build.mutation({
      query: ({
        address,
        addressType,
      }: {
        address: String;
        addressType: 'EMAIL' | 'MOBILE';
      }) => ({
        url: 'api/otpv2/send',
        method: 'POST',
        body: { address, addressType },
      }),
    }),
    otpv2Verify: build.mutation({
      query: ({ address, otp }: { address: String; otp: String }) => ({
        url: 'api/otpv2/verify',
        method: 'POST',
        body: { address, otp },
      }),
    }),

    getUserByEmail: build.mutation({
      query: ({ email }: { email: String }) => ({
        url: `api/users/${email}`,
        method: 'GET',
      }),
    }),
    getUserByMobile: build.mutation({
      query: ({ mobile }: { mobile: string }) => ({
        url: `api/users/mobile/${mobile}`,
        method: 'GET',
      }),
    }),

    addAdminUser: build.mutation({
      query: ({
        name,
        email,
        password,
        role,
        mobile,
      }: {
        name: String;
        email: String;
        password: String;
        role: String;
        mobile: string;
      }) => ({
        url: 'api/users/',
        method: 'POST',
        body: { name, email, password, role, mobile },
      }),
    }),
    updateAdminUser: build.mutation({
      query: ({
        userId,
        user: { name, email, mobile },
      }: {
        userId: string;
        user: { name: String; email: String; mobile: String };
      }) => ({
        url: `api/users/${userId}`,
        method: 'PUT',
        body: { name, email, mobile },
      }),
    }),

    resetAdminUserPassword: build.mutation({
      query: ({ email, password }: { email: String; password: String }) => ({
        url: 'api/users/reset-password',
        method: 'POST',
        body: { email, password },
      }),
    }),
    loginAdminUser: build.mutation({
      query: ({ email, password }: { email: String; password: String }) => ({
        url: 'api/auth',
        method: 'POST',
        body: { email, password },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useOtpv2SendMutation,
  useOtpv2VerifyMutation,
  useAdminOtpSendMutation,
  useAdminOtpVerifyMutation,

  useGetUserByEmailMutation,
  useGetUserByMobileMutation,

  useAddAdminUserMutation,
  useUpdateAdminUserMutation,

  useResetAdminUserPasswordMutation,
  useLoginAdminUserMutation,
} = adminUserAPISlice;
