import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import FadeInFadeOutAnim from '../../../../components/common/FadeInFadeOutAnim';
import CpFooter from '../../components/Footer';
// import CpAddLeadPage from '../../components/pages/CpAddLeadPage';
import CrAddCpLeadPage from '../../components/pages/CrAddCpLeadPage';
import CpDashboardPage from '../../components/pages/CpDashboardPage';
import CpDiscoveryPage from '../../components/pages/CpDiscoveryPage';
import CpLeadListPage from '../../components/pages/CpLeadListPage';
import ProfilePage from '../../components/pages/ProfilePage';
import UpdateCpUserProfile from '../../components/pages/ProfilePage/UpdateCpUserProfile';
import ProductDetailsPage from '../../components/pages/ProjectDetailPage';
import UpdateCpUserCompany from '../../components/pages/ProfilePage/UpdateCpUserCompany/index';
import UpdateCpUserPreference from '../../components/pages/ProfilePage/UpdateCpUserPreference';
import UpdateCpUserAddress from '../../components/pages/ProfilePage/UpdateCpUserAddress';
import { getCpUser } from '../../slices/cpUserSlice';
import MarketingCollaterPage from '../../components/pages/MarketingCollateralPage';
import CrRecommendedProjectsPage from '../../components/pages/CrRecommendedProjectsPage';
import { isRelataCpApp } from '../../../admin/components/pages/ExecutiveDashboard/utils';

import styles from './styles.module.css';

const CpDashboardContent = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const cpUser = useSelector(getCpUser);

  if (!cpUser) {
    history.push('/cp/kyc');
  } else if (isRelataCpApp() && !cpUser.approved) {
    history.push('/cp/kyc/approval-pending');
  }

  return (
    <FadeInFadeOutAnim width='100%' height='100%'>
      <div className='app-body'>
        <div className={styles.cpWrapper}>
          <Switch>
            <Route exact path={`${path}/`} component={CpDashboardPage}></Route>

            <Route
              path={`${path}/discovery`}
              component={CpDiscoveryPage}></Route>
            {/* <Route path={`${path}/addLead`} component={CpAddLeadPage}></Route> */}
            <Route
              exact
              path={`${path}/addLead`}
              component={CrAddCpLeadPage}></Route>
            <Route
              path={`${path}/addLead/recommendedProjects`}
              component={CrRecommendedProjectsPage}></Route>
            <Route path={`${path}/leads`} component={CpLeadListPage}></Route>
            <Route
              path={`${path}/project-details`}
              component={ProductDetailsPage}></Route>
            <Route
              path={`${path}/project-collaterals`}
              component={MarketingCollaterPage}></Route>

            <Route
              exact
              path={`${path}/profilePage`}
              component={ProfilePage}></Route>
            <Route
              path={`${path}/profilePage/profile`}
              component={UpdateCpUserProfile}></Route>
            <Route
              path={`${path}/profilePage/company`}
              component={UpdateCpUserCompany}></Route>
            <Route
              path={`${path}/profilePage/preference`}
              component={UpdateCpUserPreference}></Route>
            <Route
              path={`${path}/profilePage/address`}
              component={UpdateCpUserAddress}></Route>

            <Redirect to={`${path}/`} />
          </Switch>
        </div>
      </div>
      <CpFooter />
    </FadeInFadeOutAnim>
  );
};

export default CpDashboardContent;
