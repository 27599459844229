import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { removeAdminUserLocal } from '../../AdminLogin/utils';
import ProjectSelection from '../../../common/ProjectSelection';
import DetailsForm from './DetailsForm/index';
import SiteVisitSummery from './SiteVisitSummery';
import { MenuItem } from '../../../common/MoreMenu';

import {
  IPriceRange,
  IProject,
} from '../../SuperAdminDashboard/SuperAdminProjectList';

import styles from './styles.module.css';
import { useGetCpLeadByIdQuery } from '../../../../../cp/services/cpUserAPISlice';
import {
  DEFAULT_PROPERTY_CONFIGS,
  DEFAULT_PROPERTY_PRICE_RANGE,
} from '../../../../../../constants';
import { isEmpty } from '../../../../../../utils/utils';
import { TObjectId } from '../../../../../../interfaces';
import { useSelector } from 'react-redux';
import { getAdminUser } from '../../../../slices/adminSlice';

export enum SiteVisitState {
  PROJECT_SELECTION,
  DETAILS_FORM,
  SUMMERY,
}

export interface ISiteVisitDetails {
  customerName: string;
  customerEmail: string;
  customerMobile: string;

  cpLeadId: TObjectId;
  executive: TObjectId;
  projectId: string;

  location: string;
  visitDate: string;

  configs: string[];
  preferenceAreasTemp: string;
  priceRange: IPriceRange;

  customerFeedback: string;
}

export interface IFullScreenHandle {
  active: boolean;
  // Specifies if attached element is currently full screen.

  enter: () => Promise<void>;
  // Requests this element to go full screen.

  exit: () => Promise<void>;
  // Requests this element to exit full screen.

  node: React.MutableRefObject<HTMLDivElement | null>;
  // The attached DOM node
}

const SiteVisit = () => {
  const [siteVisitState, setSiteVisitState] = useState<SiteVisitState>(
    SiteVisitState.PROJECT_SELECTION
  );

  const [siteVisitDetails, setSiteVisitDetails] = useState({
    customerName: '',
    customerEmail: '',
    customerMobile: '',

    projectId: '',

    location: '',
    visitDate: '',

    preferenceAreasTemp: '',
    customerFeedback: '',
  } as ISiteVisitDetails);
  const [selectedProject, setSelectedProject] = useState<IProject>();

  const history = useHistory();
  const handleFullScreen = useFullScreenHandle();

  const adminUser = useSelector(getAdminUser);

  const urlParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  }) as any;
  const { cpLeadId } = urlParams;
  const { data: cpLead, isLoading } = useGetCpLeadByIdQuery({ cpLeadId });

  useEffect(() => {
    cpLead &&
      setSiteVisitDetails((prev) => {
        return {
          ...prev,
          customerName: cpLead.name,
          configs: cpLead.propertyConfigs,
          preferenceAreasTemp: cpLead.preferenceAreasTemp,

          cpLeadId: cpLead.id,
          executive: adminUser?.id as string,
        };
      });
  }, [cpLead]);

  const headerMoreMenuOptions = [
    {
      displayText: handleFullScreen.active ? 'Exit Fullscreen' : 'Fullscreen',
      icon: handleFullScreen.active ? 'fullscreen_exit' : 'fullscreen',
      onClick: () => {
        handleFullScreen.active
          ? handleFullScreen.exit()
          : handleFullScreen.enter();
      },
    },
    {
      displayText: 'Log Out',
      icon: 'logout',
      onClick: () => {
        removeAdminUserLocal();
        history.push('/admin');
      },
    },
  ] as MenuItem[];

  const handleProjectSelection = (project: IProject) => {
    setSiteVisitDetails((prev) => {
      return {
        ...prev,
        projectId: project.id,

        location: '',
        visitDate: '',

        customerFeedback: '',
      } as ISiteVisitDetails;
    });
    setSelectedProject(project);
    setSiteVisitState(SiteVisitState.DETAILS_FORM);
  };

  const projectPropertyConfigs = selectedProject?.configs.length
    ? selectedProject?.configs
    : DEFAULT_PROPERTY_CONFIGS;
  const projectPropertyPriceRanges = DEFAULT_PROPERTY_PRICE_RANGE;

  if (isLoading) return <div>Loading...</div>;

  return (
    <FullScreen
      handle={handleFullScreen}
      className={styles.fullscreenContainer}>
      <div className={styles.siteVisitForm}>
        {siteVisitState === SiteVisitState.PROJECT_SELECTION && (
          <ProjectSelection
            project={selectedProject}
            onProjectSelection={handleProjectSelection}
          />
        )}

        {siteVisitState === SiteVisitState.DETAILS_FORM && (
          <DetailsForm
            projectPropertyConfigs={projectPropertyConfigs}
            projectPropertyPriceRanges={projectPropertyPriceRanges}
            cpLead={cpLead}
            siteVisitDetails={siteVisitDetails}
            setSiteVisitDetails={setSiteVisitDetails}
            setSiteVisitState={setSiteVisitState}
            headerMoreMenuOptions={headerMoreMenuOptions}
          />
        )}

        {siteVisitState === SiteVisitState.SUMMERY && (
          <SiteVisitSummery
            siteVisitDetails={siteVisitDetails}
            setSiteVisitState={setSiteVisitState}
          />
        )}
      </div>
    </FullScreen>
  );
};

export default SiteVisit;
