import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BLOCKED } from '../../../constants/status';
import { getUnitCart } from '../slices/selectedUnit';
import { isEmpty, toTwoDigits } from '../../../utils/utils';

function useBlockedUnitExpireCountdown(unitCartObj = {}) {
  const [hourMinSecCountDownObj, setHourMinSecCountDownObj] = useState({});

  const unitCart = useSelector(getUnitCart);

  let unitCartInUse = unitCartObj;
  if (isEmpty(unitCartObj)) {
    unitCartInUse = unitCart;
  }

  const countDownDate = new Date(unitCartInUse.expire_on).getTime();

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setHourMinSecCountDownObj({
        hours: toTwoDigits(days * 24 + hours),
        minutes: toTwoDigits(minutes),
        seconds: toTwoDigits(seconds),
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [countDownDate]);

  if (unitCartInUse.unit?.status === BLOCKED) {
    return hourMinSecCountDownObj;
  }

  return {};
}

export default useBlockedUnitExpireCountdown;
