import { useState } from 'react';

import CpProfileAssignedLeads from './CpProfileAssignedLeads';
import CpProfileBodyHeader from './CpProfileBodyHeader';
import CpProfilePendingLeadApproval from './CpProfilePendingLeadApprovalGrid';

import styles from './styles.module.css';

const cpProfileTabsEnum = {
  ASSIGNED_LEADS: { value: 'ASSIGNED_LEADS', displayText: 'Assigned Leads' },
  PENDING_LEADS_APPROVAL: {
    value: 'PENDING_LEADS_APPROVAL',
    displayText: 'Pending Leads Approval',
  },
};

const CpProfileBody = () => {
  const [selectedTabTag, setSelectedTabTag] = useState(
    cpProfileTabsEnum.ASSIGNED_LEADS.value
  );

  const adminDashboardTabs = [
    {
      name: cpProfileTabsEnum.ASSIGNED_LEADS.displayText,
      tag: cpProfileTabsEnum.ASSIGNED_LEADS.value,
      isDisabled: false,
    },
    {
      name: cpProfileTabsEnum.PENDING_LEADS_APPROVAL.displayText,
      tag: cpProfileTabsEnum.PENDING_LEADS_APPROVAL.value,
      isDisabled: false,
    },
  ];

  return (
    <>
      <header></header>

      <main>
        <div className={styles.tabsContainer}>
          <div className={styles.tabContainerHeader}>
            {adminDashboardTabs.map((tab) => (
              <div
                key={tab.tag}
                className={`${styles.tabItem} ${
                  tab.isDisabled
                    ? styles.disabledTab
                    : selectedTabTag === tab.tag
                    ? styles.activeTab
                    : ''
                }`}
                onClick={() => setSelectedTabTag(tab.tag)}>
                {tab.name}
              </div>
            ))}
          </div>

          <header>
            <CpProfileBodyHeader />
          </header>

          <main className={styles.tabContainerBody}>
            {selectedTabTag === cpProfileTabsEnum.ASSIGNED_LEADS.value && (
              <CpProfileAssignedLeads />
            )}

            {selectedTabTag ===
              cpProfileTabsEnum.PENDING_LEADS_APPROVAL.value && (
              <CpProfilePendingLeadApproval />
            )}
          </main>
        </div>
      </main>
    </>
  );
};

export default CpProfileBody;
