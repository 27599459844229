import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import useToast from '../../hooks/useToast';

import UnitSummaryCard from '../UnitSummaryCard';
import PrimaryApplicant from './PrimaryApplicant';
import CoApplicants from './CoApplicants';
import BlockUnitButton from '../common/BlockUnitButton';

import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';
import { URLS } from '../../constants/urls';
import { BOOKED } from '../../constants/status';

import {
  getUnitCart,
  setUnitCart,
} from '../../apps/booking/slices/selectedUnit';
import { setAppSliceIsFetching } from '../../slices/appSlice';
import { useUpdateUnitCartMutation } from '../../api/unitCart';
import { isEmpty } from '../../utils/utils';

const Kyc = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [agree, setAgree] = useState(false);
  const [isEditFormOpenStatuses, setIsEditFormOpenStatuses] = useState({});
  const [canProceed, setCanProceed] = useState(false);
  const unitCart = useSelector(getUnitCart);
  const [addToast] = useToast();

  const [updateUnitCartAPI] = useUpdateUnitCartMutation();

  useEffect(() => {
    addToast({
      delay: 2000,
      primaryMessage: 'You can add Co-Applicants!',
      secondaryMessage: 'Click on add Co-Applicant button',
    });
  }, [addToast]);

  const toggleAgree = (agree) => {
    setAgree(agree);
    // TODO: add field on DB
  };

  const updateIsEditFormOpenStatus = (id, isOpenStatus) => {
    /* 
      - Track if any editForm is open for Primary applicant or co-applicants
      - Allow proceed only when all edit forms are closed as all editForm is 
      validated before it closes. This will make sure that all mandatory fields 
      required for User and CoApplicants are saved on server.
    */
    isEditFormOpenStatuses[id] = isOpenStatus;
    const isAllFormsClosed = Object.values(isEditFormOpenStatuses).every(
      (isEditFormOpenStatus) => !isEditFormOpenStatus
    );
    setCanProceed(isAllFormsClosed);
    setIsEditFormOpenStatuses(isEditFormOpenStatuses);
  };

  const updateUnitCartProgressOnServer = async () => {
    const unitCartBody = {
      id: unitCart.id,
      progress:
        unitCart.progress < SIDEBAR_ITEM_INDEX[URLS.KYC]
          ? SIDEBAR_ITEM_INDEX[URLS.KYC]
          : unitCart.progress,
    };
    const { data: unitCartdata, error: unitCartError } =
      await updateUnitCartAPI({ ...unitCartBody });

    if (unitCartError) {
      console.error('unitCartError', unitCartError);
      alert('unitCartError', unitCartError);
      return false;
    }

    if (!isEmpty(unitCartdata)) {
      dispatch(setUnitCart(unitCartdata));
    }
    return true;
  };

  const saveAndPay = async () => {
    dispatch(setAppSliceIsFetching(true));
    const handleUnitCartStatus = await updateUnitCartProgressOnServer();
    dispatch(setAppSliceIsFetching(false));

    if (!handleUnitCartStatus) {
      return false;
    }

    history.push(URLS.PAYMENT);
  };

  return (
    <div className='kyc'>
      <div className='kyc__body'>
        <div className='kyc-applicant-list'>
          <PrimaryApplicant
            updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
          />
          <CoApplicants
            updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
          />
        </div>
        <UnitSummaryCard />
      </div>
      <footer className='payment-plan__footer'>
        <button
          type='button'
          className='btn btn--transparent'
          onClick={() => {
            history.push(URLS.PAYMENT_PLAN);
          }}>
          Back to Payment Plan
        </button>
        <div className='payment-plan__footer-action-btns'>
          <span className='kyc-agree-checkbox'>
            <label className='checkbox'>
              <input type='checkbox' onClick={() => toggleAgree(!agree)} />
              <span>
                <p className='link-terms-conditions'>
                  I Agree to the{' '}
                  <a
                    className='font-accent-bold'
                    href='https://relata-temp.s3.ap-south-1.amazonaws.com/embassy/assets/terms_and_conditions.pdf'
                    target='_blank'
                    rel='noreferrer'>
                    Terms and Conditions
                  </a>
                </p>
              </span>
            </label>
          </span>

          <button
            type='button'
            className={`btn kyc-save-and-pay ${
              agree && canProceed && 'btn--accent'
            } ${(!agree || !canProceed) && 'click-disabled'}`}
            onClick={() => saveAndPay()}>
            {unitCart.unit.status !== BOOKED ? 'Save & Pay' : 'Proceed'}
          </button>
          <span className='vertical-line'></span>
          <BlockUnitButton />
        </div>
      </footer>
    </div>
  );
};

export default Kyc;
