import { useState } from 'react';

import { useForm } from 'react-hook-form';

import { randomId } from '../../../../../../../../utils/utils';
import { useAddProjectExecutiveMutation } from '../../../../../../services/projectsAPISlice';
import { useGetUserByEmailMutation } from '../../../../../../services/adminUserAPISlice';
import useToast from '../../../../../../../../hooks/useToast';
import Modal from '../../../../../../../../components/common/modal/Modal';
import TextInput from '../../../../../../../../components/common/inputs/TextInput';
import Button from '../../../../../../../../components/common/buttons/Button';
import Icon from '../../../../../../../../components/common/Icon';
import { UserRole } from '../../../../../../../../constants/userRoles';
import { NOT_FOUND } from '../../../../../../../../constants/status';
import { IDemoRoom } from '../AddDemoRoomModal';
import { useAddCompanyUserMutation } from '../../../../../../services/companyAPISlice';
import DropDownInput, {
  DropDownOptionType,
} from '../../../../../../../../components/common/inputs/DropDownInput';
import { IProject } from '../..';
import { ICompany, IUser } from '../../../../../../interfaces';

import styles from '../VsgLinkModal/styles.module.css';
import localStyles from './styles.module.css';

export interface IExecutivesWithDemoRoom {
  id?: string;
  executive: IUser;
  demoRoom: string | IDemoRoom;
  vrUrl: string;
}

interface IAddProjectExecutiveModal {
  project: IProject;

  onModalClose: Function;
  onAddExecutive: (updateProject: IProject) => void;
}

const AddProjectExecutiveModal = (props: IAddProjectExecutiveModal) => {
  const { project, onModalClose, onAddExecutive } = props;

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    // TODO: Add mobile number validation conditionally as mobile number input field is shown conditionally
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const [isCreatingNewExec, setIsCreatingNewExec] = useState(false);
  const [addToast] = useToast();

  const [getUserByEmailAPI] = useGetUserByEmailMutation();
  const [addProjectExecutiveAPI] = useAddProjectExecutiveMutation();
  const [addCompanyUserAPI] = useAddCompanyUserMutation();

  const primaryCompanyId = project.company.id as string;
  const projectName = project.name;
  const projectId = project.id as string;

  const companies: ICompany[] = [
    project.company,
    ...project.secondaryCompanies.map((c) => c.company as ICompany),
  ];
  const companyDropdownOptions: DropDownOptionType[] =
    companies.map((company) => {
      return {
        value: company.id as string,
        displayText: `${company.displayName} - ${
          company.id === primaryCompanyId ? 'primary' : 'secondary'
        }`,
      };
    }) || [];

  const defaultSelectedCompany = {
    value: primaryCompanyId,
    displayText: `${
      companies.find((c) => c.id === primaryCompanyId)?.displayName as string
    } - primary`,
  };

  const handleAddNewExecutive = async (formData: {
    email: string;
    name: string;
    mobile: string;
    vrUrl: string;
    companyId: string;
  }) => {
    try {
      const executive = {
        email: formData.email,
        name: formData.name,
        mobile: formData.mobile,
        role: UserRole.EXECUTIVE,
        password: randomId(),
      };
      // TODO: Remove any
      const executiveRes: any = await addCompanyUserAPI({
        user: executive,
        companyId: formData.companyId,
      }).unwrap();

      handleAddExecutiveToProject(executiveRes.data.id, formData.vrUrl);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add new Exec',
      });
    }
  };

  const handleAddExecutiveToProject = async (
    executiveId: string,
    vrUrl: string
  ) => {
    try {
      const updatedProject = await addProjectExecutiveAPI({
        projectId,
        executiveId,
        vrUrl,
      }).unwrap();

      onAddExecutive(updatedProject.data);
      onModalClose();

      addToast({
        type: 'SUCCESS',
        primaryMessage: 'Executive created Successfully',
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to add Exec to Project',
      });
    }
  };

  const handleIsExecutiveExists = async ({
    email,
    vrUrl,
  }: {
    email: string;
    vrUrl: string;
  }) => {
    try {
      const executive = await getUserByEmailAPI({ email }).unwrap();
      const executiveRole = executive.data.role;

      if (executiveRole !== UserRole.EXECUTIVE) {
        addToast({
          type: 'ERROR',
          primaryMessage: `User already exists with ${executiveRole}.`,
          secondaryMessage:
            'Only users with executives role can be added to a project',
        });
        return;
      }

      handleAddExecutiveToProject(executive.data.id, vrUrl);
    } catch (err) {
      const errCode = (err as any).data.code;
      const errMsg = (err as any).data.message;

      if (errCode === NOT_FOUND) {
        setIsCreatingNewExec(true);
        return;
      }

      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: 'Failed to check Exec if exists',
      });
    }
  };

  return (
    <Modal
      onOutsideClick={() => onModalClose(false)}
      additionalClasses={styles.modalAdditionalClasses}>
      {/* Modal Header */}
      <header className={styles.modalHeader}>
        <div className={styles.modalHeaderLeftContent}>
          <h1>Add Executive for {projectName}</h1>
          <p>
            Fill in the following info to add a new executive associated to the
            selected project. All executivesWithDemoRoom of a developer have
            access to all projects of the developer by default.
          </p>
        </div>
        <Icon
          name='close'
          propStyles={styles.modalCloseIcon}
          onClick={() => onModalClose(false)}
        />
      </header>

      {/* Modal Body */}
      <main>
        <TextInput
          label='Executive Organisation EmailID'
          placeHolder='Add Email of the Executive'
          register={register}
          setValue={setValue}
          name='email'
          unregister={unregister}
          validate={(value: string) => value.length > 0}
          errorMessage={errors.email && 'Please enter valid email'}
        />
        <TextInput
          label='Executive VSG Link'
          placeHolder='Add Executive VSG Link'
          register={register}
          setValue={setValue}
          name='vrUrl'
          unregister={unregister}
        />
        {isCreatingNewExec && (
          <div>
            <div className={styles.projectIdInputContainer}>
              <div className={styles.projectIdInput}>
                <TextInput
                  label='Full Name'
                  placeHolder='Add Name'
                  register={register}
                  setValue={setValue}
                  name='name'
                  unregister={unregister}
                  validate={(value: string) => value.length > 0}
                  errorMessage={errors.name && 'Name is required'}
                />
              </div>
              <div className={styles.projectIdInput}>
                <TextInput
                  label='Mobile Number'
                  placeHolder='Add Mobile'
                  register={register}
                  setValue={setValue}
                  name='mobile'
                  unregister={unregister}
                  validate={(value: string) => value.length > 0}
                  errorMessage={errors.name && 'Mobile Number is required'}
                />
              </div>
            </div>
            <div className={localStyles.dropDownContainer}>
              <DropDownInput
                defaultSelectedOption={defaultSelectedCompany}
                options={companyDropdownOptions}
                lable='Select Company'
                register={register}
                setValue={setValue}
                unregister={unregister}
                name='companyId'
              />
            </div>
          </div>
        )}
      </main>

      {/* Modal Footer */}
      <footer className={styles.modalFooter}>
        <div className={styles.projectIdInput}>
          <p className={styles.footerDisclaimer}>
            By adding executive, an autogenerated email will be sent to the
            executive with their randomly generated password and URL for the
            dashboard. Do this step the last, just before going for UAT.
          </p>
        </div>
        <div className={styles.projectIdInput}>
          <Button
            type='submit'
            onClick={() =>
              handleSubmit(
                isCreatingNewExec
                  ? handleAddNewExecutive
                  : handleIsExecutiveExists
              )()
            }
            propStyles={`${styles.verifyNowBtn}`}>
            {isCreatingNewExec
              ? 'Create & Add Executive Now'
              : 'Add Executive Now'}
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default AddProjectExecutiveModal;
