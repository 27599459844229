import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import Icon, { IconNames } from '../../../../../components/common/Icon';
import HeadTitle from '../../../../../components/common/TitleHeading';
import { userProfileDefaultImage } from '../../../../../constants/urls';
import { removeAuthTokenLocal } from '../../../../../utils/localStorage';
import { ICpUser } from '../../../interfaces';
import { getCpUser, removeCpUser } from '../../../slices/cpUserSlice';
import { removeCpUserLocal } from '../CpLoginPage/cpLocalStorage';
import Image from '../../../../../components/common/Image';

import styles from './styles.module.css';

const ProfilePage = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const cpUser = useSelector(getCpUser) as ICpUser;

  const cpLogout = () => {
    removeAuthTokenLocal();
    removeCpUserLocal();
    dispatch(removeCpUser());
  };

  const menuItems = [
    {
      detailsType: 'Personal Data',
      detailsSubText: 'Edit your name, email id, phone numbe...',

      iconName: 'person',
      redirectPath: '/profile',
    },
    {
      detailsType: 'Company Details',
      detailsSubText: 'Edit company name, pan number, gst n...',

      iconName: 'tune',
      redirectPath: '/company',
    },
    {
      detailsType: 'Preferences',
      detailsSubText: 'Change area of operation, property typ...',

      iconName: 'gps_fixed',
      redirectPath: '/preference',
    },
    {
      detailsType: 'Address',
      detailsSubText: 'Edit state, city, pincode, locality, street ...',

      iconName: 'gps_fixed',
      redirectPath: '/address',
    },
    {
      detailsType: 'Logout',
      detailsSubText: '',

      iconName: 'logout',
      redirectPath: 'cp/landing',
      onClick: cpLogout,
    },
  ];

  return (
    <div className={styles.containerWrap}>
      <div className={styles.headerTitleWrap}>
        <HeadTitle>My Profile</HeadTitle>

        <div className={styles.headerWrap}>
          <div className={styles.profileImage}>
            <Image src={userProfileDefaultImage} />
          </div>
          <div className={styles.titleWrap}>
            <p className={styles.headTitle}>{cpUser.user.name}</p>
            <p className={styles.subTitle}>Channel partner</p>
          </div>
        </div>
      </div>
      <div className={styles.itemsWarp}>
        {menuItems.map((menuItem, index) => (
          <NavLink
            key={index}
            to={`${pathname}${menuItem.redirectPath}`}
            className={styles.contentsWrap}
            onClick={menuItem.onClick}>
            <div className={styles.iconWrap}>
              <Icon
                name={menuItem.iconName as IconNames}
                propStyles={styles.profileIcon}
              />

              <div className={styles.dataDetails}>
                <p className={styles.detailsType}>{menuItem.detailsType}</p>

                <p className={styles.detailsSubText}>
                  {menuItem.detailsSubText}
                </p>
              </div>
            </div>
            <div className={styles.arrowIcon}>
              <Icon
                name='keyboard_arrow_right'
                propStyles={styles.rightArrowIcon}
              />
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default ProfilePage;
