import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setUnitCart } from '../../apps/booking/slices/selectedUnit';
import { ordinalSuffixOf, toIndianRupee } from '../../utils/utils';
import { COMPLETE_ICON, INCOMPLETE_ICON } from '../../constants/icons';
import { URLS } from '../../constants/urls';
import { SIDEBAR_ITEM_INDEX } from '../SidebarItem';

const BookedUnitCartCard = (props) => {
  const { unitCart } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const isDocComplete =
    unitCart.progress === SIDEBAR_ITEM_INDEX[URLS.AGREEMENT];
  const incompleteDocName = 'Agreement';

  const booked_on_date = new Date(unitCart.blocked_on);
  const [weekday, day, month, year] = [
    booked_on_date.toLocaleString('en', { weekday: 'short' }),
    booked_on_date.toLocaleString('en', { day: 'numeric' }),
    booked_on_date.toLocaleString('en', { month: 'short' }),
    booked_on_date.toLocaleString('en', { year: '2-digit' }),
  ];
  const booked_on_date_str = `${weekday}, ${ordinalSuffixOf(
    day
  )} ${month} ${year}`;

  const handleClick = () => {
    // Incomplete booking steps
    dispatch(setUnitCart(unitCart));

    if (isDocComplete) {
      // Booking steps are complete
      history.push(`/dashboard/unit/${unitCart.unit.id}`);
      return;
    }

    history.push(URLS.KYC_DETAILS);
  };

  return (
    <article className='booked-unit-cart-card' onClick={() => handleClick()}>
      <main className='booked-unit-cart-card__body'>
        <div className='booked-unit-cart-card__details'>
          <div className='booked-unit-cart-card__details-item'>
            Unit{' '}
            <span className='f-bold'>{`${unitCart.unit.unit_group} ${unitCart.unit.block}`}</span>
          </div>
          <div className='booked-unit-cart-card__details-item'>
            Project <span className='f-bold'>Embassy Edge</span>
          </div>
          <div className='booked-unit-cart-card__details-item'>
            Date <span className='f-bold'>{booked_on_date_str}</span>
          </div>
        </div>
        <div className='booked-unit-cart-card__amount'>
          <div className='booked-unit-cart-card__amount-all-inclusive'>
            {toIndianRupee(unitCart.cost_sheet.total_inclusive_amount)}
          </div>
          <div className='booked-unit-cart-card__amount-label'>
            <span className='f-bold'>All Inclusive Total</span>
            <span className='f-15'>(excluding registration charges)</span>
          </div>
        </div>
      </main>
      <footer
        className={`booked-unit-cart-card__status ${
          isDocComplete && 'booked-unit-cart-card__status--complete'
        }`}>
        <div className='booked-unit-cart-card__status-label'>
          Status{' '}
          <span className='f-bold'>
            {isDocComplete ? 'All Done' : `${incompleteDocName} Pending`}
          </span>
        </div>
        <span className='icon-container'>
          <span className='icon material-icons'>
            {isDocComplete ? COMPLETE_ICON : INCOMPLETE_ICON}
          </span>
        </span>
      </footer>
    </article>
  );
};

export default BookedUnitCartCard;
