import { useSelector } from 'react-redux';

import KycApplicantFrom from './KycApplicantFrom';

// import { useUpdateUserMutation } from '../../api/auth';
import { getUser } from '../../apps/booking/slices/auth';

const PrimaryApplicant = (props) => {
  const { updateIsEditFormOpenStatus } = props;
  const user = useSelector(getUser);
  // const [updateUserAPI] = useUpdateUserMutation();

  const mandatoryDetailsMissing =
    !user.fname ||
    !user.lname ||
    !user.fathers_name ||
    !user.email ||
    !user.mobile_no ||
    !user.dob ||
    !user.pan_no ||
    !user.aadhar_no ||
    !user.nationality;

  const handleSave = async (data) => {
    // TODO
    // const { error: updateUserError } = await updateUserAPI({
    //   id: user.id,
    //   ...data,
    // });
    // if (updateUserError) {
    //   const { data: { message } = {} } = updateUserError;
    //   alert(message);
    //   console.error('updateUserAPI', message);
    // }
  };

  const handleSaveWrap = async (data) => {
    await handleSave(data);
  };

  return (
    <KycApplicantFrom
      key={user.id}
      applicant={user}
      handleSave={(data) => handleSaveWrap(data)}
      isPrimary={true}
      isEditFormOpenByDefault={mandatoryDetailsMissing}
      updateIsEditFormOpenStatus={updateIsEditFormOpenStatus}
    />
  );
};

export default PrimaryApplicant;
