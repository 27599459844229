import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { URLS } from '../../../constants/urls';

import { getSelectedUnit, getUnitCart } from '../slices/selectedUnit';
import { getUser, setShowAuthForms } from '../slices/auth';
import { isEmpty } from '../../../utils/utils';

const ConditionalRoutes = (props) => {
  const {
    component: Component,
    requiredAuth,
    requiredUnit,
    redirectPath = URLS.UNIT_SELECTION,
    ...parentProps
  } = props;
  const dispatch = useDispatch();

  const unitCart = useSelector(getUnitCart);
  const user = useSelector(getUser);
  let selectedUnit = useSelector(getSelectedUnit);
  if (!isEmpty(unitCart)) {
    selectedUnit = unitCart.unit;
  }

  const unitSelected = !isEmpty(selectedUnit);
  const authenticated = !isEmpty(user);

  const newParentProps = { ...parentProps };

  if (!(!requiredAuth || authenticated)) {
    dispatch(setShowAuthForms(true));
  }
  return (
    <Route
      {...parentProps}
      render={(parentProps) =>
        (!requiredUnit || unitSelected) && (!requiredAuth || authenticated) ? (
          <Component {...newParentProps} />
        ) : (
          <Redirect to={redirectPath} />
        )
      }
    />
  );
};

export default ConditionalRoutes;
