import { useState } from 'react';
import { useSelector } from 'react-redux';

import 'ag-grid-enterprise';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { useHistory } from 'react-router-dom';

import Header from './Header';
import NameCard from '../../../../../../components/common/admin/NameCard';
import RegistrationRenderer, {
  DUMMY_GROWTH,
} from '../../SuperAdminDashboard/SuperAdminProjectList/cell-renderers/RegistrationRendererCell/index';
import IconWithLabel from '../../../common/IconWithLabel';
import MoreMenuCell, { IMoreMenuCell } from './MoreMenuCell/index';
import { useGetProjectsByUserIdQuery } from '../../../../services/projectsAPISlice';
import { getAdminUser } from '../../../../slices/adminSlice';
import DateCell from '../../../common/dates/DateCell';
import LiveSinceDateCell from '../../../common/dates/LiveSinceDateCell';
import { IProject } from '../../SuperAdminDashboard/SuperAdminProjectList';
import { awsBaseUrl } from '../../../../../../constants/urls';

import styles from './styles.module.css';

const AdminDashboardProjectList = () => {
  const [gridApi, setGridApi] = useState(null) as any; // We used type "any" at all other places previously as there is no type specified in the documentation

  const history = useHistory();

  const adminUser = useSelector(getAdminUser);
  const { data: projectsByExecutive = [], isLoading } =
    useGetProjectsByUserIdQuery(adminUser?.id);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //AG-grid Default column definition
  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: false,
    suppressMenu: true,
    editable: false,
    flex: 1,
  };

  //This function will automatically be called when ag grid is ready
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  //Ag-grid global search
  const handleGridSearch = (searchString: string) => {
    gridApi?.setQuickFilter(searchString);
  };

  const getProjectAvatarUrl = (params: ICellRendererParams) => {
    return `${awsBaseUrl}/common/AZAlphabets/${params.data.name[0].toUpperCase()}${
      +params.data.mobile % 5
    }.png`;
  };

  return (
    <div>
      <Header onGridSearch={handleGridSearch} />

      <div
        className={`ag-theme-alpine ${styles.gridContainer}`}
        style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowData={projectsByExecutive}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
          pagination={true}
          paginationPageSize={10}
          headerHeight={60}>
          <AgGridColumn
            hide={true}
            headerName=''
            field='name'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p>{params.value}</p>
            )}></AgGridColumn>
          <AgGridColumn
            headerName='Names'
            field='name'
            flex={2}
            cellRendererFramework={(params: ICellRendererParams) => (
              <div className={styles.adminNameCell}>
                <NameCard
                  {...{
                    imgUrl: `${getProjectAvatarUrl(params)}`,
                    title: params.data.name,
                    subtitle: params.data.company.displayName,
                    disabled: params.data.disabled,
                  }}
                />
              </div>
            )}></AgGridColumn>
          <AgGridColumn
            headerName='Status'
            field='status'
            flex={1.5}
            cellRendererFramework={(params: ICellRendererParams) => (
              <div
                className={`${
                  styles[`${params.data.status.toLowerCase()}Container`]
                } ${styles.projectStatusContainer}`}>
                <p
                  className={styles[`${params.data.status.toLowerCase()}Text`]}>
                  {params.data.status}
                </p>
              </div>
            )}></AgGridColumn>
          <AgGridColumn
            flex={1}
            headerName='Live Since'
            field='createdAt'
            cellRendererFramework={LiveSinceDateCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                liveSinceDate: params.data.createdAt,
                disabled: params.data.disabled,
              };
            }}></AgGridColumn>
          <AgGridColumn
            headerName='Expiry Date'
            field='expiryDate'
            cellRendererFramework={DateCell}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                date: params.data.expiryDate,
                disabled: params.data.disabled,
              };
            }}></AgGridColumn>
          <AgGridColumn
            flex={1}
            headerName='Registrations'
            field='Registration'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={RegistrationRenderer}
            cellRendererParams={(params: ICellRendererParams) => {
              return {
                disabled: params.data.disabled,
                value: params.data.leads.length,
                growth: DUMMY_GROWTH, //TODO: Implement growth function,
              };
            }}></AgGridColumn>
          <AgGridColumn
            flex={0.8}
            headerName='Executives'
            field='executives'
            cellClass='relata-ag-grid-cell-justify-center'
            cellRendererFramework={(params: ICellRendererParams) => (
              <p className={styles.executiveCellText}>
                {params.data.executivesWithDemoRoom.length}
              </p>
            )}></AgGridColumn>
          <AgGridColumn
            headerName='Analytics'
            field=''
            flex={0.8}
            cellRendererFramework={(params: ICellRendererParams) => (
              <IconWithLabel
                iconName='equalizer'
                label='Analytics'
                disabled={params.data.disabled}
                onClick={() => {
                  history.push('/admin/analytics-dashboard', {
                    project: params.data,
                  });
                }}
              />
            )}></AgGridColumn>
          <AgGridColumn
            flex={0.5}
            // More Actions
            headerName=''
            cellRendererFramework={MoreMenuCell}
            cellRendererParams={(
              params: ICellRendererParams
            ): IMoreMenuCell => ({
              project: params.data,
              onProjectUpdate: (updatedProject: IProject) =>
                params.node.setData(updatedProject),
            })}></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
};

export default AdminDashboardProjectList;
