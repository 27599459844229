import { floorPlanFallback } from '../../../constants/urls';
import Image from '../Image';

export interface INameCardProps {
  title: string;
  subtitle?: string;
  secondarySubtitle?: string;
  imgUrl?: string;
  disabled?: Boolean;
}

const NameCard = (props: INameCardProps) => {
  const { title, subtitle, secondarySubtitle, imgUrl, disabled } = props;
  return (
    <div className='executive-details-card'>
      <div className='executive-details-card__image-container'>
        {imgUrl && (
          <Image
            imgClass='executive-details-card__image'
            src={imgUrl}
            alt='Executive Details'
            fallbackSrc={floorPlanFallback}
          />
        )}
      </div>
      <div className='executive-details-card__content'>
        <div
          className={`executive-details-card__title ${
            disabled ? 'executive-details-card__title--disabled' : ''
          }`}>
          {title}
        </div>
        {secondarySubtitle && (
          <div className={`executive-details-card__subtitle`}>
            {secondarySubtitle}
          </div>
        )}
        <div
          title='Click to copy'
          onClick={() => {
            navigator.clipboard.writeText(`${subtitle}`);
          }}
          className={`executive-details-card__subtitle ${
            disabled ? 'executive-details-card__title--disabled' : ''
          }`}>
          {subtitle}
        </div>
      </div>
    </div>
  );
};

export default NameCard;
