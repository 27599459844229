import React from 'react';
import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { removeAdminUserLocal } from '../pages/AdminLogin/utils';
import Icon from '../../../../components/common/Icon';
import { dateToTimeDayDateMonth } from '../../../../utils/dateFormator';

const getISTDate = () => {
  var currentTime = new Date();
  var currentOffset = currentTime.getTimezoneOffset();
  var ISTOffset = 330; // IST offset UTC +5:30
  var ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );
  return ISTTime;
};

const AdminHeader = () => {
  const history = useHistory();
  const [today, setDateTime] = useState(getISTDate());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(getISTDate());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleAdminLogout = () => {
    removeAdminUserLocal();
    history.push('/admin');
  };

  const dateTime = dateToTimeDayDateMonth(today);

  return (
    <>
      <div className='app-header'>
        <nav className='nav'>
          <div className='nav__left-container'>
            <NavLink className='nav__brand' to='/admin'>
              <div className='nav__brand__relata-logo'>relata.io</div>
            </NavLink>
            {/* <div className='nav__developer-name'>Sheth Creations</div> */}
          </div>

          <ul className='list nav__list'>
            <li className='nav__item'>{dateTime}</li>

            <li className='nav__item'>
              <Icon name='help_outline' />
            </li>
            <li className='nav__item'>
              <Icon name='chat_bubble_outline' />
            </li>
            <li className='nav__item'>
              <Icon name='settings' />
            </li>
            <li className='nav__item'>
              <Icon name='apps' />
            </li>
            <li className='nav__item'>
              <div className='thumb' onClick={handleAdminLogout}>
                <img
                  className='img-fluid'
                  src='https://bootdey.com/img/Content/avatar/avatar7.png'
                  alt=''
                />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default AdminHeader;
