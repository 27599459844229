import { useState } from 'react';

import useToast from '../../../../../../../../hooks/useToast';
import TextInput from '../../../../../../../../components/common/inputs/TextInput';
import Button from '../../../../../../../../components/common/buttons/Button';
import { emailValidationSchema } from '../../../../../../../../utils/yupValidationSchemas';
import { LoginFormStates } from '../../index';
import {
  useAdminOtpSendMutation,
  useAdminOtpVerifyMutation,
} from '../../../../../../services/adminUserAPISlice';

import styles from '../../styles.module.css';

interface ISendOtpState {
  setLoginFormState: Function;
  handleEmail: Function;
  email: String;
}

const SendOtpState = (props: ISendOtpState) => {
  const { setLoginFormState, handleEmail, email } = props;

  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [addToast] = useToast();

  const [adminOtpSendAPI] = useAdminOtpSendMutation();
  const [adminOtpVerifyAPI] = useAdminOtpVerifyMutation();

  const handelEmailInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const email = event.target.value;
    handleEmail(email);
    // On email change, set is otp sent to false as we need to send opt for changed email
    setIsOtpSent(false);

    const isValid = await emailValidationSchema.isValid({
      value: event.target.value,
    });
    // Email is valid, hide error message
    isValid && setEmailErrorMessage('');
  };
  const handelEmailBlur = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const email = event.target.value;
    const isValid = await emailValidationSchema.isValid({ value: email });
    setEmailErrorMessage(!isValid ? 'Please enter a valid Email Address' : '');
  };

  const handleOtpInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const otp = event.target.value;
    setOtp(otp);
  };

  const handleOtpSend = async () => {
    try {
      await adminOtpSendAPI({ email }).unwrap();
      setIsOtpSent(true);
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'OTP Sent',
        secondaryMessage: '',
        timeout: 1000,
      });
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };
  const handleOtpVerify = async () => {
    try {
      await adminOtpVerifyAPI({ email, otp }).unwrap();
      addToast({
        type: 'SUCCESS',
        primaryMessage: 'OTP verified successfully',
        secondaryMessage: '',
        timeout: 1000,
      });
      setLoginFormState(LoginFormStates.RESET_PASSWORD);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
        secondaryMessage: '',
        timeout: 1000,
      });
    }
  };

  return (
    <>
      <h2 className={styles.loginFormHeading}>
        Verify your identity with OTP to proceed
      </h2>
      <p className={styles.loginFormDesc}>
        To reset your password, enter your email ID to verify you with your
        registered mobile.
      </p>
      <TextInput
        label='Email'
        placeHolder=''
        errorMessage={emailErrorMessage}
        onChange={handelEmailInputChange}
        onBlur={handelEmailBlur}
        type='email'
      />
      <TextInput
        label='Enter 6-digit OTP'
        placeHolder=''
        onChange={handleOtpInputChange}
        type='password'
        disabled={!isOtpSent}
      />
      <Button
        propStyles={styles.loginButton}
        onClick={isOtpSent ? handleOtpVerify : handleOtpSend}>
        {isOtpSent ? 'Verify OTP' : 'Send OTP Now'}
      </Button>
      <Button
        propStyles={styles.forgotPasswordButton}
        onClick={() => setLoginFormState(LoginFormStates.LOGIN)}>
        Back To Login
      </Button>
    </>
  );
};

export default SendOtpState;
