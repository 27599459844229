import { ordinalSuffixOf } from './utils';

/*

DaythMonthYear - 11th Oct 2022

*/
export const dateToDaythMonthYear = (date: Date) => {
  return `${ordinalSuffixOf(new Date(date).getDate())} ${new Date(
    date
  ).toLocaleDateString('en', { month: 'short' })} ${new Date(
    date
  ).toLocaleDateString('en', { year: 'numeric' })}`;
};

/*

TimeDayDateMonth - 16:40 Tue, 18 Oct

*/
export const dateToTimeDayDateMonth = (date: Date) => {
  const formattedDate = `${date.toLocaleDateString('en', {
    weekday: 'short',
  })}, ${date.getDate()} ${date.toLocaleDateString('en', { month: 'short' })}`;

  const time = date.toLocaleTimeString('en', {
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
  });

  return `${time} ${formattedDate}`;
};
