import { useHistory, useLocation } from 'react-router-dom';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import TextInput from '../../../../../components/common/inputs/TextInput';
import Button from '../../../../../components/common/buttons/Button';
import useToast from '../../../../../hooks/useToast';
import { ICpUserReq } from '../../../interfaces';
import { useAddCpUserMutation } from '../../../services/cpUserAPISlice';
import { randomId } from '../../../../../utils/utils';
import { UserRole } from '../../../../../constants/userRoles';
import { useSelector } from 'react-redux';
import { getCpUser } from '../../../slices/cpUserSlice';

import styles from './styles.module.css';

interface ICpKycPage {
  mobile: string;
}

// Validation
const schema = yup.object({
  panNumber: yup.string(),
  gstNumber: yup.string(),
  rera: yup.object({
    number: yup.string().required(),
  }),

  user: yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    // TODO: allow empty string also
    // alternateMobile: yup
    //   .string()
    //   .matches(/^[0-9]*$/)
    //   .length(10)
    //   .required(),
  }),
  companyTemp: yup.object({
    name: yup.string().required(),
    pocMobile: yup
      .string()
      .matches(/^[0-9]*$/)
      .length(10)
      .required(),
    websiteUrl: yup.string(),
  }),

  addressTemp: yup.string().required(),
});

const CpKycPage = (props: ICpKycPage) => {
  const location = useLocation<ICpKycPage>();
  const mobile = location?.state?.mobile;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const history = useHistory();
  const [addCpUserAPI] = useAddCpUserMutation();
  const [addToast] = useToast();

  const cpUser = useSelector(getCpUser);

  if (!mobile) {
    history.push('/cp/kyc/login');
  }
  if (cpUser) {
    history.push('/cp/kyc/approval-pending');
  }

  const handleRegisterUserDetails = async (data: ICpUserReq) => {
    const cpUser: ICpUserReq = {
      ...data,
      approved: false,
      rera: { ...data.rera, state: 'NA' },
      user: { ...data.user, mobile, role: UserRole.CP },
    };
    try {
      await addCpUserAPI(cpUser).unwrap();
      history.push('/cp/kyc/approval-pending');
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: 'ERROR',
        primaryMessage: errMsg,
      });
    }
  };

  return (
    <div className={styles.container}>
      <header>
        <div className={styles.title}>Register Now</div>
        <div className={styles.titleDesc}>
          Kindly fill in your details to register with us and to start accessing
          your leads & more
        </div>
      </header>
      <main>
        <TextInput
          label='Personal Name *'
          placeHolder='Add Personal Name'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          crApp
          name='user.name'
          errorMessage={errors?.user?.name && 'Personal Name is required'}
          propStyles={styles.input}
        />
        <TextInput
          label='Personal Email *'
          placeHolder='Add Email id'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='user.email'
          crApp
          errorMessage={
            errors?.user?.email && 'Enter valid email id & it is required'
          }
          propStyles={styles.input}
        />
        <TextInput
          label='Company Name *'
          placeHolder='Add Company Name'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='companyTemp.name'
          crApp
          errorMessage={errors?.companyTemp?.name && 'Company Name is required'}
          propStyles={styles.input}
        />
        <TextInput
          label='Company Contact Person Number *'
          placeHolder='Add Company Contact Person Number'
          type='number'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='companyTemp.pocMobile'
          crApp
          errorMessage={
            errors?.companyTemp?.pocMobile &&
            'Enter valid 10 digit mobile number, it is required'
          }
          propStyles={styles.input}
        />
        <TextInput
          label='RERA Number*'
          placeHolder='Add RERA Number'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='rera.number'
          errorMessage={errors?.rera?.number && 'RERA Number is required'}
          propStyles={styles.input}
          crApp
        />
        <TextInput
          label='Registered Address*'
          placeHolder='Add Registered Address'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='addressTemp'
          errorMessage={errors?.addressTemp && 'Registered Address is required'}
          propStyles={styles.input}
          crApp
        />
        <TextInput
          label='PAN Number'
          placeHolder='Add PAN Number'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='panNumber'
          errorMessage={errors?.panNumber && 'PAN Number is required'}
          propStyles={styles.input}
          crApp
        />
        <TextInput
          label='GST Number'
          placeHolder='Add GST Number'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='gstNumber'
          errorMessage={errors?.gstNumber && 'GST Number is required'}
          propStyles={styles.input}
          crApp
        />
        <TextInput
          label='Personal Mobile Number'
          placeHolder='Add Mobile Number'
          onBlur={() => {}}
          startIconText='+91'
          defaultValue={mobile}
          disabled={true}
          propStyles={styles.input}
          crApp
        />
        <TextInput
          label='Alternate Mobile Number'
          placeHolder='Add Alternate Mobile Number'
          type='number'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          crApp
          name='user.alternateMobile'
          errorMessage={
            errors?.user?.alternateMobile && 'Enter valid mobile number'
          }
          propStyles={styles.input}
        />
        <TextInput
          label='Website'
          placeHolder='Add Website'
          onBlur={() => {}}
          register={register}
          setValue={setValue}
          name='companyTemp.websiteUrl'
          propStyles={styles.input}
          crApp
        />

        <Button
          crApp
          type='button'
          onClick={() => handleSubmit(handleRegisterUserDetails)()}
          propStyles={styles.registerBtn}>
          Register Now
        </Button>
      </main>
    </div>
  );
};

export default CpKycPage;
