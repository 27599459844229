import { useState } from 'react';

import { useUpdateProjectMutation } from '../../../../../../services/projectsAPISlice';
import useToast from '../../../../../../../../hooks/useToast';
import ConfirmationModal, {
  IConfirmationModal,
} from '../../../../../../../../components/common/genericModals/ConfirmationModal/index';
import IconWithLabel from '../../../../../common/IconWithLabel';

import styles from '../../styles.module.css';

interface IBlockButtonCell {
  projectId: string;
  disabled: Boolean;
  onRowUpdate: Function;
}

const BlockButtonCell = (props: IBlockButtonCell) => {
  const { projectId, disabled, onRowUpdate } = props;

  const [confirmBlockProject, setConfirmBlockProject] =
    useState<IConfirmationModal>();
  const [addToast] = useToast();

  const [updateProject] = useUpdateProjectMutation();

  const handleBlockProjectClick = () => {
    setConfirmBlockProject({
      accent: disabled ? 'SUCCESS' : 'DANGER',
      heading: disabled
        ? 'Do you want to Unblock the Project?'
        : 'Do you want to block the Project?',
      onModalClose: () => setConfirmBlockProject(undefined),
      onResponse: async (confirmationResponse: any) => {
        //TODO: Remove type any of confirmationResponse
        try {
          await confirmationResponse;

          const updatedProject = await updateProject({
            disabled: !disabled,
            projectId: projectId,
          }).unwrap();

          onRowUpdate(updatedProject.data);

          setConfirmBlockProject(undefined);
        } catch (err) {
          const errMsg = (err as any).data.message;
          addToast({
            type: 'ERROR',
            primaryMessage: errMsg,
          });
          return;
        }
      },
    });
  };

  return (
    <>
      {confirmBlockProject && <ConfirmationModal {...confirmBlockProject} />}
      <IconWithLabel
        iconName='block'
        label={disabled ? 'Unblock' : 'Block'}
        propStyles={`${disabled ? styles.BlockedState : ''}`}
        onClick={() => handleBlockProjectClick()}
      />
    </>
  );
};

export default BlockButtonCell;
