import { ERROR } from '../../../../../../../../constants/status';
import useToast from '../../../../../../../../hooks/useToast';
import { TObjectId } from '../../../../../../../../interfaces';
import { ICpUser } from '../../../../../../../cp/interfaces';
import { useUpdateCpUserMutation } from '../../../../../../../cp/services/cpUserAPISlice';
import Button from './../../../../../../../../components/common/buttons/Button';
import Icon from './../../../../../../../../components/common/Icon/index';

import styles from './styles.module.css';

export interface IApproveCpButtonCellProps {
  cpUserId: TObjectId;
  approved: boolean;
  onRowUpdate: (updatedCpUser: ICpUser) => void;
}

const ApproveCpButtonCell = (props: IApproveCpButtonCellProps) => {
  const { cpUserId, approved, onRowUpdate } = props;

  const [addToast] = useToast();
  const [updateCpUserAPI] = useUpdateCpUserMutation();

  const handleCpUserApprove = async () => {
    if (approved) {
      return;
    }
    try {
      const response = await updateCpUserAPI({
        cpUserId,
        approved: true,
      }).unwrap();

      onRowUpdate(response.data as ICpUser);
    } catch (err) {
      const errMsg = (err as any).data.message;
      addToast({
        type: ERROR,
        primaryMessage: errMsg,
        secondaryMessage: 'CpUser approval failed',
        timeout: 1000,
      });
    }
  };

  return (
    <div className={styles.approveBtnContainer}>
      <Button
        accent='success'
        propStyles={styles.approveBtn}
        onClick={handleCpUserApprove}>
        <Icon name='task_alt' propStyles={styles.checkIcon} />
        {approved ? 'Approved' : 'Approve'}
      </Button>
    </div>
  );
};

export default ApproveCpButtonCell;
