import { UserAction } from './../../../../ActiveLeads/index';

export const leadStatusEnum = {
  PAYMENT_DONE: { value: 'PAYMENT_DONE', displayText: 'Payment Done' },
  BLOCKED: { value: 'BLOCKED', displayText: 'Blocked' },
  KYC_COMPLETED: { value: 'KYC_COMPLETED', displayText: 'KYC Completed' },
  EXPLORED: { value: 'EXPLORED', displayText: 'Explored' },
}; //TODO: Place this in parent component

export const cpLeadRequestData = [
  {
    leadName: 'Hoeger Ernie',
    leadEmail: 'hoeger@gmail.com',
    hotness: 0,
    requestSubmittedOn: {
      cpName: 'Homenick Maynard',
      submissionDate: '24th February 2022',
    },
    lastAssignedOn: {
      cpName: 'Nikolaus Tyrell',
      assignedDate: '8th June 2021',
    },
    lastActivityOn: {
      userAction: UserAction.EXPLORED_NEW_UNIT.value,
      actionDate: '27th Dec 2021',
    },
    enquiries: [
      {
        cpName: 'Nikolaus Tyrell',
        status: leadStatusEnum.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        cpName: 'Fritsch Emmet',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        cpName: 'Heidenreich Eldred',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        cpName: 'Stroman Luis',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
  },
  {
    leadName: 'Lang Brenda',
    leadEmail: 'lang678@gmail.com',
    hotness: 2,
    requestSubmittedOn: {
      cpName: 'Kohler Karli',
      submissionDate: '25th February 2022',
    },
    lastAssignedOn: {
      cpName: 'Gutmann Elody',
      assignedDate: '24th December 2021',
    },
    lastActivityOn: {
      userAction: UserAction.KYC_DETAILS_UPDATED.value,
      actionDate: '29th Dec 2021',
    },
    enquiries: [
      {
        cpName: 'Nikolaus Tyrell',
        status: leadStatusEnum.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        cpName: 'Fritsch Emmet',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        cpName: 'Heidenreich Eldred',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        cpName: 'Stroman Luis',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
  },
  {
    leadName: 'Hoeger Ernie',
    leadEmail: 'hoeger@gmail.com',
    hotness: 0,
    requestSubmittedOn: {
      cpName: 'Homenick Maynard',
      submissionDate: '24th February 2022',
    },
    lastAssignedOn: {
      cpName: 'Nikolaus Tyrell',
      assignedDate: '8th June 2021',
    },
    lastActivityOn: {
      userAction: UserAction.EXPLORED_NEW_UNIT.value,
      actionDate: '27th Dec 2021',
    },
    enquiries: [
      {
        cpName: 'Nikolaus Tyrell',
        status: leadStatusEnum.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        cpName: 'Fritsch Emmet',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        cpName: 'Heidenreich Eldred',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        cpName: 'Stroman Luis',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
  },
  {
    leadName: 'Lang Brenda',
    leadEmail: 'lang678@gmail.com',
    hotness: 2,
    requestSubmittedOn: {
      cpName: 'Kohler Karli',
      submissionDate: '25th February 2022',
    },
    lastAssignedOn: {
      cpName: 'Gutmann Elody',
      assignedDate: '24th December 2021',
    },
    lastActivityOn: {
      userAction: UserAction.KYC_DETAILS_UPDATED.value,
      actionDate: '29th Dec 2021',
    },
    enquiries: [
      {
        cpName: 'Nikolaus Tyrell',
        status: leadStatusEnum.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        cpName: 'Fritsch Emmet',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        cpName: 'Heidenreich Eldred',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        cpName: 'Stroman Luis',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
  },
  {
    leadName: 'Hoeger Ernie',
    leadEmail: 'hoeger@gmail.com',
    hotness: 0,
    requestSubmittedOn: {
      cpName: 'Homenick Maynard',
      submissionDate: '24th February 2022',
    },
    lastAssignedOn: {
      cpName: 'Nikolaus Tyrell',
      assignedDate: '8th June 2021',
    },
    lastActivityOn: {
      userAction: UserAction.EXPLORED_NEW_UNIT.value,
      actionDate: '27th Dec 2021',
    },
    enquiries: [
      {
        cpName: 'Nikolaus Tyrell',
        status: leadStatusEnum.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        cpName: 'Fritsch Emmet',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        cpName: 'Heidenreich Eldred',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        cpName: 'Stroman Luis',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
  },
  {
    leadName: 'Lang Brenda',
    leadEmail: 'lang678@gmail.com',
    hotness: 2,
    requestSubmittedOn: {
      cpName: 'Kohler Karli',
      submissionDate: '25th February 2022',
    },
    lastAssignedOn: {
      cpName: 'Gutmann Elody',
      assignedDate: '24th December 2021',
    },
    lastActivityOn: {
      userAction: UserAction.KYC_DETAILS_UPDATED.value,
      actionDate: '29th Dec 2021',
    },
    enquiries: [
      {
        cpName: 'Nikolaus Tyrell',
        status: leadStatusEnum.PAYMENT_DONE.value,
        unitDetails: 'Daisy, A 802',
        unitConfig: '3.5 BHK A',
        lastUpdate: '24th Dec 2021',
        id: 11,
      },
      {
        cpName: 'Fritsch Emmet',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, B 505',
        unitConfig: '2 BHK D',
        lastUpdate: '26th Dec 2021',
        id: 12,
      },
      {
        cpName: 'Heidenreich Eldred',
        status: leadStatusEnum.BLOCKED.value,
        unitDetails: 'Gardenia, A 602',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 13,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Hibiscus, D 406',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 14,
      },
      {
        cpName: 'Stroman Luis',
        status: leadStatusEnum.EXPLORED.value,
        unitDetails: 'Gardenia, A 605',
        unitConfig: '4.5 BHK A',
        lastUpdate: '22nd Dec 2021',
        id: 15,
      },
      {
        cpName: 'Boyer Ezekiel',
        status: leadStatusEnum.KYC_COMPLETED.value,
        unitDetails: 'Hibiscus, D 410',
        unitConfig: '3.5 BHK AVL',
        lastUpdate: '20th Dec 2021',
        id: 16,
      },
    ],
  },
];
