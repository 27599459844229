import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Button from '../../../../../components/common/buttons/Button';
import HeadTitle from '../../../../../components/common/TitleHeading';
import TextInput from '../../../../../components/common/inputs/TextInput';
import { ICpLeadReq, ICpUser } from '../../../interfaces';
import { useHistory } from 'react-router-dom';
import MultiSelectOptions from '../../../../../components/common/inputs/MultiSelectOptions';
import { DEFAULT_PROPERTY_CONFIGS } from '../../../../../constants';

import styles from './styles.module.css';

// const locationDetails = [
//   'Malad West',
//   'Malad West',
//   'Malad West',
//   'Andheri East - Maheshwari Nagar',
//   'Vashi',
// ];

// Validation
const schema = yup.object({
  name: yup.string().required(),
  mobileLastFourDigits: yup.string().required(),
});

const CrAddCpLeadPage = () => {
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const history = useHistory();

  const handleAddLead = async (cpLeadReq: ICpLeadReq) => {
    history.push('/cp/dashboard/addLead/recommendedProjects', { cpLeadReq });
  };

  return (
    <div className={styles.leadContainer}>
      <div className={styles.contentWrap}>
        <HeadTitle>Add Lead</HeadTitle>
        <form
          className={styles.formConatiner}
          onSubmit={handleSubmit(handleAddLead)}>
          {/* <div className={styles.nameWrapper}>
            <p className={styles.subHeading}>Name of the lead</p>
            <input type='text' className={styles.inputFeild} />
          </div> */}
          <TextInput
            label='Lead Name *'
            placeHolder='Add Lead Name'
            register={register}
            setValue={setValue}
            name='name'
            errorMessage={errors?.name && 'Lead Name is required'}
            // propStyles={styles.input}
            crApp
          />
          <TextInput
            label='Last 4 digits of the mobile number *'
            placeHolder='Last 4 digits'
            register={register}
            setValue={setValue}
            name='mobileLastFourDigits'
            maxLength={4}
            type='number'
            crApp
            errorMessage={
              errors?.mobileLastFourDigits &&
              'Last 4 digits of the mobile number is required'
            }
            // propStyles={styles.input}
          />
          {/* <div className={styles.nameWrapper}>
            <p className={styles.subHeading}>Enter last 4 digits</p>
            <input type='text' className={styles.inputFeild} />
          </div> */}

          <MultiSelectOptions
            label='Select configuration'
            options={DEFAULT_PROPERTY_CONFIGS}
            setValue={setValue}
            name='propertyConfigs'
          />

          <TextInput
            label='Select area preferences'
            placeHolder='Add area preferences'
            register={register}
            setValue={setValue}
            name='preferenceAreasTemp'
            maxLength={50}
            type='text'
            crApp
          />

          {/* <div className={styles.searchFilterWrap}>
            <p className={styles.subHeading}>Select area preferences</p>
            <div className={styles.searchContainer}>
              <Icon name='search' propStyles={styles.searchIcon} />
              <input type='search' placeholder='Search property' />
            </div>

            <div className={styles.listwrapper}>
              {locationDetails.map((location, index) => (
                <div
                  key={index}
                  className={`${styles.selectedItem} ${
                    index === 1 ? styles.active : ''
                  }`}>
                  <p className={styles.item}>{location}</p>
                  <Icon propStyles={styles.filterClose} name='close' />
                </div>
              ))}
            </div>
          </div> */}
          <div className={styles.leadBtnWrap}>
            <Button type='submit' crApp propStyles={styles.btnAddLead}>
              ADD LEAD
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CrAddCpLeadPage;
